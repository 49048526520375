import { NetworkUtils } from '../../utils/networks.utils'
import { logIfDev } from '../../utils/commons.utils'
import { FileUtils } from '../../utils/files.utils'

import {
  ManageMaterialModel,
  MaterialModel,
  MaterialModelsPagination,
} from '../../models/materialModels.models'

import { updateRequestStatus } from '@ngneat/elf-requests'
import { materialModelsStore } from './materialModels.store'
import { sessionQuery } from '../session'
import Constants from '../../constants'

import { MaterialModelsApi } from '../../api/materialModels.api'

const getLocalFilePath = (userId: string) => `${userId}/materialModels`
export class MaterialModelsService {
  store = materialModelsStore

  getMaterialModels = async (
    filters: MaterialModelsApi.GetListParams,
  ): Promise<MaterialModelsPagination> => {
    await NetworkUtils.checkConnected()
    return await MaterialModelsApi.getList({
      ...filters,
      lang: sessionQuery.getUserLang(),
    })
  }

  init = async (): Promise<void> => {
    let userId = sessionQuery.getUserId()
    if (!userId) {
      this.store.update((state) => ({
        ...state,
        list: [],
      }))
      return
    }
    this.store.update(updateRequestStatus('init', 'pending'))

    try {
      const isConnected = await NetworkUtils.isConnected()

      let materialModelsList: MaterialModel[] = []
      let fromApi = false

      if (isConnected) {
        try {
          materialModelsList = (
            await this.getMaterialModels({
              disablePaginate: true,
            })
          ).data
          fromApi = true
        } catch (err: any) {
          if (Constants.getIsLocal() && userId) {
            materialModelsList = await FileUtils.readJSON(getLocalFilePath(userId))
          } else {
            throw err
          }
        }
      } else if (Constants.getIsLocal() && userId) {
        materialModelsList = await FileUtils.readJSON(getLocalFilePath(userId))
      } else {
        throw new Error('NOT_CONNECTED')
      }

      this.store.update(
        (state) => ({
          ...state,
          list: materialModelsList,
        }),
        updateRequestStatus('init', 'success'),
      )

      if (fromApi && Constants.getIsLocal() && userId) {
        try {
          FileUtils.writeJSON(getLocalFilePath(userId), materialModelsList)
        } catch (err: any) {
          logIfDev(err)
        }
      }
    } catch (err: any) {
      this.store.update(updateRequestStatus('init', 'error', err))
      throw err
    }
  }

  createMaterialModel = async (materialModel: ManageMaterialModel): Promise<MaterialModel> => {
    await NetworkUtils.checkConnected()
    const createdMaterialModel = await MaterialModelsApi.create({
      ...materialModel,
      carbonModel: materialModel?.carbonModel?.value ?? null,
    })
    this.store.update((state) => ({
      ...state,
      list: [createdMaterialModel, ...state.list],
    }))

    return createdMaterialModel
  }

  updateMaterialModel = async (
    materialModelId: string,
    materialModel: ManageMaterialModel,
  ): Promise<MaterialModel> => {
    await NetworkUtils.checkConnected()
    let updateMaterialModel = await MaterialModelsApi.update(materialModelId, {
      ...materialModel,
      carbonModel: materialModel?.carbonModel?.value ?? null,
    })

    this.store.update((state) => ({
      ...state,
      list: state.list.map((model) =>
        model._id === updateMaterialModel._id ? updateMaterialModel : model,
      ),
    }))

    return updateMaterialModel
  }

  deleteMaterialModel = async (materialModelId: string): Promise<void> => {
    await NetworkUtils.checkConnected()
    await MaterialModelsApi.deleteById(materialModelId)
    this.store.update((state) => ({
      ...state,
      list: state.list.filter((model) => model._id !== materialModelId),
    }))
  }
}

export const materialModelsService = new MaterialModelsService()
