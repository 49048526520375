import { Typography, styled } from '@mui/material'
import Stack from '../common/Stack.common'

const Ribbon = styled(Stack)(({ theme }) => ({
  cursor: 'pointer',
  position: 'absolute',
  background: theme.palette.primary.main,
  height: '12px',
  width: '100px',
  top: 0,
  left: 0,
  boxShadow: `0 0 0 2px ${theme.palette.primary.main}`,
  transform: 'translate(-25%,150%) rotate(-45deg)',
  '& > .MuiTypography-root': {
    color: 'white',
    fontSize: '0.7rem',
    fontWeight: '700',
  },
}))
const Environment: React.FC = () => {
  return process.env.REACT_APP_ENV !== 'production' ? (
    <Ribbon alignItems="center" justifyContent="center">
      <Typography>{process.env.REACT_APP_ENV === 'staging' ? 'PREPROD' : 'PREPROD'}</Typography>
    </Ribbon>
  ) : (
    <></>
  )
}

export default Environment
