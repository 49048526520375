import { useMemo, useState, useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useObservable } from '@ngneat/react-rxjs'
import { alpha, styled, Typography, Button, Grid, Box } from '@mui/material'
import {
  Edit as EditIcon,
  Remove as RemoveIcon,
  ContentCopy as ContentCopyIcon,
  FavoriteBorder as FavoriteBorderIcon,
  Favorite as FavoriteIcon,
  InsertDriveFile as InsertDriveFileIcon,
} from '@mui/icons-material'

import dayjs from '../../utils/dayjs.utils'
import { createOptionsFromEnum } from '../../utils/i18n.utils'

import Constants from '../../constants'

import { materialsQuery, materialsService } from '../../store/materials'
import { organizationsService } from '../../store/organizations'
import { sessionQuery, sessionService } from '../../store/session'
import { catalogsService } from '../../store/catalogs'
import { cartsService } from '../../store/carts'
import { materialModelsService } from '../../store/materialModels'

import useModal from '../../hooks/useModal.hooks'
import useSnackbar from '../../hooks/useSnackbar.hooks'

import { Mode, RetrievalModality } from '../../models/commons.models'
import { FilterItem, Filter, ItemType, Actions } from '../../models/props.models'
import {
  BuildingType,
  isResources,
  CatalogType,
  Catalog,
  CatalogLight,
} from '../../models/catalogs.models'
import {
  MaterialsPagination,
  Material,
  ManageMaterial,
  MaterialType,
  MaterialQuality,
  ManageMultipleMaterial,
} from '../../models/materials.models'
import { ManageMaterialModel } from '../../models/materialModels.models'
import MaterialList, { MaterialListProps } from '../../components/material/List.material'
import Modal from '../../components/layout/Modal.layout'
import ModalForm from '../../components/common/ModalForm.common'
import ModalDetails from '../../components/material/ModalDetails.material'
import ModalFormMaterial from '../../components/material/ModalForm.material'
import ModalFormManyMaterial from '../../components/material/ModalFormMany.material'
import LoaderOverlay from '../../components/layout/LoaderOverlay.layout'
import ModalFormMaterialModel from '../../components/materialModel/ModalForm.materialModel'
import { ListRef } from '../common/List.common'
import Stack from '../common/Stack.common'
import { MaterialsApi } from '../../api/materials.api'
import { ObjectUtils } from '../../utils/commons.utils'
import InputCategory from '../category/Input.category'
import MaterialNeedLogin from '../material/NeedLogin.material'
import { traduceCategory } from '../../models/categories.models'
import { LangEnum } from '../../constants/langs'
import { carbonModelsQuery } from '../../store/carbonModels'

const MaterialListTitle = styled(Typography)({
  fontSize: '1.125rem',
  fontWeight: 700,
})

const StyledMaterialCountContainer = styled(Stack, {
  shouldForwardProp: (propName: string) => propName !== 'secondary',
})<{ secondary: boolean }>(({ theme, secondary }) => ({
  backgroundColor: alpha(
    secondary ? theme.palette.secondary.main : theme.palette.primary.main,
    0.1,
  ),
  borderTopRightRadius: '20px',
  borderBottomRightRadius: '20px',
  color: secondary ? theme.palette.secondary.main : theme.palette.primary.main,
  padding: '12px 20px 12px 15px',
}))

interface PageMaterialListProps
  extends Omit<
    MaterialListProps,
    | 'filter'
    | 'getValues'
    | 'onValueClick'
    | 'valueActions'
    | 'isValueSelected'
    | 'onValueSelect'
    | 'onFilterChange'
    | 'isCatalogPage'
    | 'showCerfa'
  > {
  type: MaterialType
  canUpdate?: boolean
  isPublic?: boolean
  matching?: string
  isFavoritePage?: boolean
  catalog?: Catalog
  useImperials: boolean
  updateList?: boolean | 'materialPemTable' | 'materialDTable'
}

const PageMaterialList: React.FC<PageMaterialListProps> = (props) => {
  const show = useSnackbar()
  const { t } = useTranslation()
  const listRef = useRef<(ListRef & { parse: 'pem' | 'd' | undefined }) | null>(null)
  const {
    updateList,
    matching,
    type,
    canUpdate,
    isPublic,
    isFavoritePage,
    catalog,
    useImperials,
    organization,
    ...listProps
  } = props
  const isAdmin = useMemo(() => Constants.mode === Mode.admin, [])
  const isApp = useMemo(() => Constants.mode === Mode.app, [])
  const [materialsLoading] = useObservable(materialsQuery.loading)
  const [materials] = useObservable(materialsQuery.materials)
  const [carbonModels] = useObservable(carbonModelsQuery.carbonModels)

  const [user] = useObservable(sessionQuery.user)
  const [loading, setLoading] = useState<boolean>(false)
  const [transferCatalog, setTransferCatalog] = useState<{ catalog: string }>({ catalog: '' })

  const showPublic = useMemo(() => {
    return sessionService.showPublic(type)
  }, [type])
  const showCerfa = useMemo(() => {
    return (
      sessionService.enableCerfa() &&
      (!catalog || !!catalog.enableCerfa) &&
      ((catalog?.type && catalog?.type === CatalogType.deconstruction) ||
        (!catalog?.type && type === MaterialType.resource))
    )
  }, [type, catalog])

  const parseFilter = useCallback(
    (filters: any): MaterialsApi.GetListParams => {
      const { location, ...othersfilters } = filters

      let periodStart, periodEnd
      if (filters?.periodStart) {
        periodStart = dayjs(filters?.periodStart).startOf('month').toDate()
      }

      if (filters?.periodEnd) {
        periodEnd = dayjs(filters?.periodEnd).endOf('month').toDate()
      }

      return {
        ...othersfilters,
        matching,
        organization:
          organization ?? (filters.organization ? filters.organization.value : undefined),
        catalog: catalog?._id || (filters.catalog ? filters.catalog.value : undefined),
        type,
        periodStart,
        periodEnd,
        ...(location?.location?.position?.length && location?.location?.city
          ? {
              coordinates: location.location.position,
              radius: location.radius,
            }
          : location?.location?.countryCode && !location?.location?.city
          ? { countryCode: location.location?.countryCode }
          : {}),
        public: isPublic || isFavoritePage,
        owned: !isPublic && !isAdmin && !!catalog,
        favorites: isFavoritePage,
      }
    },
    [organization, isFavoritePage, isPublic, type, isAdmin, catalog, matching],
  )
  const getMaterials = useCallback(
    async (filters: any): Promise<MaterialsPagination> => {
      try {
        if (catalog) {
          const filtered = materialsService.filterList({
            ...parseFilter(filters),
            selectAll: true,
            ids: [],
          })

          return {
            data: filtered,
            count: filtered.length,
            total: filtered.length,
          }
        } else if (isPublic || isAdmin || (isFavoritePage && user?.favorites?.length)) {
          return await materialsService.getMaterials(parseFilter(filters))
        }
      } catch (err: any) {
        show(err)
      }

      return {
        data: [],
        count: 0,
        total: 0,
      }
    },
    [user, isFavoritePage, parseFilter, catalog, isPublic, isAdmin, show],
  )
  const getTransferedMaterials = useCallback(
    async (filters: any) => {
      return await materialsService.getMaterials({
        ...parseFilter(filters),
        originalCatalog: catalog?._id,
        catalog: undefined,
      })
    },
    [catalog, parseFilter],
  )

  const [selectedMaterials, setSelectedMaterials] = useState<{
    selectAll: boolean
    values: string[]
  }>({
    selectAll: false,
    values: [],
  })
  const hasSelect = useMemo(
    () => selectedMaterials.selectAll || selectedMaterials.values.length > 0,
    [selectedMaterials],
  )

  // modal
  const [modal, setModal] = useModal<string>()
  const [openedCatalog, setOpenedCatalog] = useState<CatalogLight | undefined>(undefined)
  const [defaultValue, setDefaultValue] = useState<Partial<ManageMaterial> | undefined>(undefined)
  const materialModelDefaultValue: ManageMaterialModel | undefined = useMemo(() => {
    return defaultValue
      ? {
          ...(defaultValue as ManageMaterial),
          _id: '',
          name: { [LangEnum.FR]: defaultValue.name as string },
          tracksOfReuse: defaultValue.tracksOfReuse
            ? { [LangEnum.FR]: defaultValue.tracksOfReuse }
            : undefined,

          technicalDetails: defaultValue.technicalDetails?.map((technicalDetail) => ({
            [LangEnum.FR]: technicalDetail.name,
          })),
          wasteOnly: defaultValue.wasteOnly,
          ...(defaultValue.cerfaResource
            ? {
                ...defaultValue.cerfaResource,
                resourcePrimaryCategory: defaultValue.cerfaResource.primaryCategory,
                resourceSecondaryCategory: defaultValue.cerfaResource.secondaryCategory,
                resourceTertiaryCategory: defaultValue.cerfaResource.tertiaryCategory,
                constituentMaterials: defaultValue.cerfaResource.constituentMaterials?.map(
                  (constituentMat) => ({
                    [LangEnum.FR]: constituentMat,
                  }),
                ),
              }
            : {}),
          ...(defaultValue.cerfaWaste && defaultValue.cerfaWaste.length > 0
            ? {
                ...defaultValue.cerfaWaste[0],
                wastePrimaryCategory: defaultValue.cerfaWaste[0].primaryCategory,
                wasteSecondaryCategory: defaultValue.cerfaWaste[0].secondaryCategory,
                wasteTertiaryCategory: defaultValue.cerfaWaste[0].tertiaryCategory,
                asbestosPrecision: defaultValue.cerfaWaste[0].asbestosPrecision
                  ? { [LangEnum.FR]: defaultValue.cerfaWaste[0].asbestosPrecision }
                  : undefined,
              }
            : {}),
        }
      : undefined
  }, [defaultValue])

  useEffect(() => {
    if (modal === 'deleteMaterials' || modal === 'editMaterials' || modal === 'transferMaterials') {
      if (!hasSelect) {
        setModal('')
      }
      return
    }

    if (
      (modal.includes('model') ||
        modal.includes('duplicate') ||
        modal.includes('edit') ||
        modal.includes('detail')) &&
      !defaultValue
    ) {
      const getMaterial = async () => {
        setLoading(true)
        try {
          let material = materials?.find(
            (material) =>
              modal
                .replace('transfer.', '')
                .replace('duplicate.', '')
                .replace('edit.', '')
                .replace('detail.', '')
                .replace('model.', '') === material._id,
          )
          if (!material) {
            material = await materialsService.getMaterial(
              modal
                .replace('transfer.', '')
                .replace('duplicate.', '')
                .replace('edit.', '')
                .replace('detail.', '')
                .replace('model.', ''),
              isPublic,
            )
          }

          if (material) {
            setOpenedCatalog(material.catalog)
            const carbonModel = carbonModels.find(
              (carbonModel) => carbonModel._id === material!.carbonModel,
            )

            if (modal.includes('duplicate')) {
              const {
                _id,
                reference,
                initialQty,
                currentQty,
                reservedQty,
                state,
                quality,
                mainImageFile,
                imageFiles,
                files,
                ...duplicatedValues
              } = ObjectUtils.copy(material)

              setDefaultValue({
                ...duplicatedValues,
                catalog: material.catalog._id,
                quantities: [],
                initialQty: 0,
                currentQty: 0,
                reservedQty: 0,
                carbonModel: carbonModel
                  ? { label: carbonModel.name, value: carbonModel._id }
                  : undefined,
                ...(duplicatedValues.type === MaterialType.need
                  ? {
                      initialQty,
                      quality,
                    }
                  : {}),
              })
            } else {
              setDefaultValue({
                ...material,
                carbonModel: carbonModel
                  ? { label: carbonModel.name, value: carbonModel._id }
                  : undefined,
                catalog: material.catalog?._id,
              })
            }
          } else {
            setModal('')
          }
        } catch (err: any) {
          setModal('')
          show(err)
        }
        setLoading(false)
      }
      getMaterial()
    }

    if (!modal && defaultValue) {
      setDefaultValue(undefined)
    }
  }, [
    carbonModels,
    hasSelect,
    materials,
    isPublic,
    catalog,
    type,
    modal,
    defaultValue,
    show,
    setModal,
  ])
  useEffect(() => {
    if (updateList === 'materialPemTable' || updateList === 'materialDTable') {
      listRef.current?.updateMode(updateList)
    } else if (updateList) {
      listRef.current?.updateValues()
    }
  }, [updateList])

  // selection
  const isValueSelected = useCallback(
    (material: Material | 'all') => {
      if (material === 'all') {
        return selectedMaterials.selectAll
      }
      const selectAll = selectedMaterials.selectAll
      const inValues = !!selectedMaterials.values.find((id) => id === material._id)
      return (selectAll && !inValues) || (!selectAll && inValues)
    },
    [selectedMaterials],
  )
  const onSelect = useCallback((material: Material | boolean) => {
    if (typeof material === 'boolean') {
      setSelectedMaterials(() => ({
        selectAll: material,
        values: [],
      }))
    } else {
      setSelectedMaterials((state) => ({
        ...state,
        values: state.values.includes(material._id)
          ? state.values.filter((selectedId) => selectedId !== material._id)
          : state.values.concat(material._id),
      }))
    }
  }, [])

  // actions
  const transfer = useCallback(
    async ({ catalog }: { catalog: { data: CatalogLight } }) => {
      await materialsService.transferMaterials(
        {
          ...parseFilter(listRef.current?.filter),
          selectAll: modal === 'transferMaterials' && selectedMaterials.selectAll,
          ids:
            modal === 'transferMaterials'
              ? selectedMaterials.values
              : [modal.replace('transfer.', '')],
        },
        catalog.data,
      )
    },
    [modal, parseFilter, selectedMaterials],
  )
  const deleteMany = useCallback(async () => {
    await materialsService.deleteMaterials({
      ...parseFilter(listRef.current?.filter),
      selectAll: selectedMaterials.selectAll,
      ids: selectedMaterials.values,
    })
  }, [parseFilter, selectedMaterials])
  const updateMany = useCallback(
    async (update: Partial<ManageMultipleMaterial>) => {
      await materialsService.updateMaterials(
        {
          ...parseFilter(listRef.current?.filter),
          selectAll: selectedMaterials.selectAll,
          ids: selectedMaterials.values,
        },
        update,
      )
    },
    [parseFilter, selectedMaterials],
  )

  const exportCsv = useCallback(async () => {
    setLoading(true)
    try {
      await materialsService.getCsv(parseFilter(listRef.current?.filter))
    } catch (err: any) {
      show(err)
    }
    setLoading(false)
  }, [show, parseFilter])
  const onMaterialClick = useCallback(
    (material: Material, second = false) => {
      if (canUpdate && !second) {
        setModal(`edit.${material._id}`)
      } else {
        setModal(`detail.${material._id}`)
      }
    },
    [canUpdate, setModal],
  )
  const toogleFavorite = useCallback(
    async (material: Material) => {
      try {
        if (!user) {
          show(t('sessions:actions.needLog.favorites'), 'warning')
        } else {
          let newFavorites = user.favorites || []
          const indexOfMaterial = newFavorites.indexOf(material._id)
          if (indexOfMaterial === -1) {
            newFavorites.push(material._id)
          } else {
            newFavorites = newFavorites.filter((p) => p !== material._id)
          }
          await sessionService.updateMyAccount({
            favorites: newFavorites,
          })
        }
      } catch (err: any) {
        show(err)
      }
    },
    [show, user, t],
  )
  const addToCart = useCallback(
    (material: Material, quantity: number) => {
      if (material && quantity) {
        const currentQtyInCart =
          cartsService.store.getValue().cart.find((item) => item.material === material._id)
            ?.quantity || 0
        if (material.currentQty - currentQtyInCart < quantity) {
          return show(t('materials:actions.addToCart.warningQuantity'), 'warning')
        }
        cartsService.addToCart(material._id, quantity)
        show(t('materials:actions.addToCart.success'), 'success')
      }
    },
    [show, t],
  )
  const actions = useMemo(() => {
    if (canUpdate && !isPublic) {
      return (material: Material): Actions => ({
        items: [
          {
            onClick: () => setModal(`edit.${material._id}`),
            label: t('global:actions.edit'),
            icon: <EditIcon />,
          },
          {
            onClick: () => setModal(`duplicate.${material._id}`),
            label: t('global:actions.duplicate'),
            icon: <ContentCopyIcon />,
          },
          {
            onClick: () => setModal(`delete.${material._id}`),
            label: t('global:actions.delete'),
            icon: <RemoveIcon />,
          },
          ...(material.type === MaterialType.resource && !isApp && !material?.wasteOnly
            ? [
                {
                  onClick: () => setModal(`transfer.${material._id}`),
                  label: t('materials:actions.transfer.label'),
                  icon: <ContentCopyIcon />,
                },
              ]
            : []),
          ...(isAdmin
            ? [
                {
                  onClick: () => setModal(`model.${material._id}`),
                  label: t('materials:actions.createModel.label'),
                  icon: <InsertDriveFileIcon />,
                },
              ]
            : []),
        ],
      })
    } else if (isPublic) {
      if (type === MaterialType.need) {
        return undefined
      }
      return (material: Material): Actions => {
        const isFavorite = (user?.favorites || []).indexOf(material._id) !== -1
        return {
          onClick: toogleFavorite.bind(null, material),
          icon: isFavorite ? <FavoriteIcon /> : <FavoriteBorderIcon />,
        }
      }
    }
    return undefined
  }, [isApp, user, t, isPublic, canUpdate, toogleFavorite, setModal, isAdmin, type])

  //filters
  const [selectedCategories, setSelectedCategories] = useState<{
    primaryCategory?: number
    secondaryCategory?: number
    tertiaryCategory?: number
  }>({})
  // case where filter was set from filter saved in session
  const updateCategoriesFilters = useCallback((filter: Record<string, any>) => {
    setSelectedCategories({
      primaryCategory: filter?.primaryCategory,
      secondaryCategory: filter?.secondaryCategory,
      tertiaryCategory: filter?.tertiaryCategory,
    })
  }, [])
  const filter = useMemo<Filter>(() => {
    const categoriesItem = {
      type: ItemType.custom,
      key: 'primaryCategory',
      position: 'aside',
      isFilled: !!selectedCategories.primaryCategory,
      custom: (
        <InputCategory
          type="rae"
          label={t('materialModels:attributes.category')}
          placeholder={t('materialModels:attributes.category')}
          value={selectedCategories}
          onChange={(categoryValue: any) => {
            // must be set in filters too (to update second list ...)
            listRef.current?.setFilter((val: any) => ({
              ...val,
              ...categoryValue,
            }))

            setSelectedCategories((val: any) => ({
              ...val,
              ...categoryValue,
            }))
          }}
        />
      ),
    }

    const publicItems = [
      {
        type: ItemType.multiSelect,
        key: 'buildingTypes',
        position: 'aside',
        props: {
          multiple: true,
          label: t('materials:components.list.buildingTypesFilter'),
          items: createOptionsFromEnum(BuildingType, 'catalogs:buildingType'),
        },
      },
      {
        type: ItemType.date,
        key: 'periodStart',
        position: 'aside',
        props: {
          placeholder: t('catalogs:components.list.from'),
          label: t('catalogs:components.list.datesFilters'),
          format: 'MM/YYYY',
          blurredFormat: true,
        },
      },
      {
        type: ItemType.date,
        key: 'periodEnd',
        position: 'aside',
        props: {
          placeholder: t('catalogs:components.list.until'),
          format: 'MM/YYYY',
          blurredFormat: true,
        },
      },
      {
        type: ItemType.radio,
        key: 'qualities',
        position: 'aside',
        props: {
          multiple: true,
          column: true,
          label: t('materials:components.list.qualityFilter'),
          items: createOptionsFromEnum(MaterialQuality, 'materials:quality'),
        },
      },
      {
        type: ItemType.addressRadius,
        key: 'location',
        position: 'aside',
        props: {
          label: t('materials:components.list.locationFilter'),
          canChooseCountry: true,
        },
      },
      ...(type === MaterialType.resource
        ? [
            {
              type: ItemType.radio,
              key: 'retrievalModalities',
              position: 'aside',
              props: {
                multiple: true,
                column: true,
                label: t('materials:components.list.modalityFilter'),
                items: createOptionsFromEnum(RetrievalModality, 'global:retrievalModality'),
              },
            },
          ]
        : []),
    ]
    if (isAdmin && !catalog && !matching) {
      return {
        items: [
          {
            ...categoriesItem,
            position: 'modal',
          },
          ...publicItems.map((item) => ({ ...item, position: 'modal' })),
          ...(organization
            ? []
            : [
                {
                  type: ItemType.asyncSelect,
                  key: 'organization',
                  grid: { sm: 3, xs: 6 },
                  props: {
                    placeholder: t('materials:components.list.organization'),
                    minimumSearchLength: 3,
                    getOptions: (search?: string) =>
                      organizationsService.getOrganizationOptions(search),
                  },
                },
              ]),
          {
            type: ItemType.asyncSelect,
            key: 'catalog',
            grid: { sm: 3, xs: 6 },
            props: {
              placeholder: t('materials:components.list.catalog'),
              minimumSearchLength: 3,
              getOptions: (search?: string) => catalogsService.getCatalogOptions({ search }),
            },
          },
          {
            type: ItemType.search,
            key: 'search',
            grid: { sm: 3, xs: 6 },
            props: {
              placeholder: t('materials:components.list.searchFilter'),
            },
          },
          {
            type: ItemType.button,
            key: 'export',
            grid: { sm: 3, xs: 6 },
            props: {
              fullWidth: true,
              children: t('materials:components.list.export'),
              onClick: exportCsv,
            },
          },
        ] as FilterItem[],
      }
    }

    return {
      info:
        isPublic && !catalog
          ? (_: any, count: number) => {
              return isFavoritePage ? (
                <Typography variant="h2">{t('global:routes.favorites')}</Typography>
              ) : (
                <StyledMaterialCountContainer
                  secondary={type === MaterialType.need}
                  direction="row"
                  alignItems="center"
                  spacing="10px">
                  <Typography variant="h2">
                    {t('materials:components.list.count', { count })}
                  </Typography>
                </StyledMaterialCountContainer>
              )
            }
          : (_: any, count: number) => {
              if (selectedCategories.tertiaryCategory) {
                return (
                  <MaterialListTitle>{`${traduceCategory(
                    'rae',
                    selectedCategories.tertiaryCategory,
                  )} (${count})`}</MaterialListTitle>
                )
              }
              if (selectedCategories.secondaryCategory) {
                return (
                  <MaterialListTitle>{`${traduceCategory(
                    'rae',
                    selectedCategories.secondaryCategory,
                  )} (${count})`}</MaterialListTitle>
                )
              }
              if (selectedCategories.primaryCategory) {
                return (
                  <MaterialListTitle>{`${traduceCategory(
                    'rae',
                    selectedCategories.primaryCategory,
                  )} (${count})`}</MaterialListTitle>
                )
              }
              return (
                <MaterialListTitle>
                  {t('materials:components.list.count', { count })}
                </MaterialListTitle>
              )
            },
      items: [
        {
          type: ItemType.search,
          key: 'search',
          position: isPublic && !catalog ? 'aside' : undefined,
          grid:
            canUpdate && !isApp
              ? { xs: 12, lg: 6 }
              : canUpdate
              ? { xs: 12, sm: 6, lg: 9 }
              : { xs: 12 },
          props: {
            placeholder: t('materials:components.list.searchFilter'),
          },
        },
        categoriesItem,
        ...(isPublic && !catalog ? publicItems : []),
        ...(canUpdate && !isPublic && catalog
          ? [
              ...(!showPublic
                ? []
                : [
                    {
                      type: ItemType.checkbox,
                      key: 'visible',
                      position: 'aside',
                      props: {
                        label: t('materials:components.list.visibleFilter'),
                        falseValue: true,
                      },
                    },
                  ]),
              ...(type === MaterialType.need
                ? []
                : [
                    {
                      type: ItemType.checkbox,
                      key: 'defaultRetrieval',
                      position: 'aside',
                      props: {
                        label: t('materials:components.list.defaultRetrievalFilter'),
                        falseValue: true,
                      },
                    },
                  ]),
            ]
          : []),
      ] as FilterItem[],
    }
  }, [
    t,
    organization,
    isApp,
    type,
    canUpdate,
    isPublic,
    catalog,
    isFavoritePage,
    exportCsv,
    isAdmin,
    selectedCategories,
    showPublic,
    matching,
  ])

  return (
    <Box display="flex" flexGrow="1">
      {loading && <LoaderOverlay />}
      <MaterialList
        {...listProps}
        showCerfa={showCerfa}
        disabledComponent={
          !user && type === MaterialType.need ? <MaterialNeedLogin detail={!!catalog} /> : undefined
        }
        ref={listRef}
        onFilterChange={updateCategoriesFilters}
        type={type}
        organization={organization}
        canUpdate={canUpdate}
        isAdmin={isAdmin}
        isPublic={isPublic}
        isCatalogPage={!!catalog}
        disableTableScroll={!!catalog}
        disabledPagination={!!catalog}
        addToCart={addToCart}
        getValues={getMaterials}
        useImperials={useImperials}
        getSecondListValues={
          !isPublic && catalog?.type === CatalogType.deconstruction && !isApp
            ? getTransferedMaterials
            : undefined
        }
        onSecondValueClick={(m: Material) => onMaterialClick(m, true)}
        secondInfo={(count) => (
          <Typography variant="h2" paddingTop="48px" marginTop="32px">
            {t('materials:components.list.transferedCount', { count })}
          </Typography>
        )}
        loading={materialsLoading}
        isValueSelected={isValueSelected}
        onValueSelect={canUpdate ? onSelect : undefined}
        onValueClick={(m: Material) => onMaterialClick(m)}
        filter={filter}
        valueActions={actions}>
        <Box>
          {hasSelect && canUpdate && (
            <Grid
              container
              position="fixed"
              bottom="15px"
              display="flex"
              left="0"
              right="0"
              padding="0 15px"
              zIndex="3"
              justifyContent="center"
              alignItems="center"
              sx={{ pointerEvents: 'none' }}
              spacing={2}>
              <Grid item xs={12} sm={4} md={3} lg={2} sx={{ pointerEvents: 'auto' }}>
                <Button
                  fullWidth
                  variant="contained"
                  aria-label="error"
                  onClick={() => {
                    setModal(`deleteMaterials`)
                  }}>
                  {t('global:actions.delete')}
                </Button>
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={2} sx={{ pointerEvents: 'auto' }}>
                <Button
                  fullWidth
                  variant="contained"
                  aria-label="success"
                  onClick={() => {
                    setModal(`editMaterials`)
                  }}>
                  {t('global:actions.edit')}
                </Button>
              </Grid>
              {catalog && isResources(catalog.type) && !isApp && (
                <Grid item xs={12} sm={4} md={3} lg={2} sx={{ pointerEvents: 'auto' }}>
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={() => {
                      setModal(`transferMaterials`)
                    }}>
                    {t('materials:actions.transfer.label')}
                  </Button>
                </Grid>
              )}
            </Grid>
          )}
        </Box>
      </MaterialList>
      {modal && modal.includes('detail') && defaultValue && openedCatalog && (
        <ModalDetails
          useImperials={useImperials}
          isPublic={isPublic}
          isCatalogPage={!!catalog && catalog._id === openedCatalog._id}
          material={
            {
              ...defaultValue,
              carbonModel: defaultValue?.carbonModel?.value,
              catalog: openedCatalog,
            } as Material
          }
          addToCart={addToCart.bind(null, {
            ...defaultValue,
            catalog: openedCatalog,
          } as Material)}
          onClose={() => setModal('')}
        />
      )}

      {((modal === 'transferMaterials' && hasSelect) || (modal && modal.includes('transfer'))) && (
        <ModalForm
          title={t(`materials:actions.transfer.label`)}
          onSubmit={transfer}
          value={transferCatalog}
          setValue={setTransferCatalog}
          items={[
            {
              type: ItemType.asyncSelect,
              key: 'catalog',
              required: true,
              props: {
                label: t('materials:actions.transfer.catalog'),
                placeholder: t('materials:actions.transfer.catalogPlaceholder'),
                minimumSearchLength: 3,
                getOptions: (search?: string) =>
                  catalogsService.getCatalogOptions({ search, types: [CatalogType.storage] }),
              },
            },
          ]}
          onSuccess={() => {
            show(
              t(`materials:actions.transfer.success`, {
                count: modal === 'transferMaterials' ? 2 : 1,
              }),
            )
            setSelectedMaterials({
              selectAll: false,
              values: [],
            })
            listRef.current?.updateValues()
          }}
          onClose={() => setModal('')}
        />
      )}
      {modal === 'deleteMaterials' && hasSelect && (
        <Modal
          confirmLabel={t('global:actions.delete')}
          title={t(`materials:actions.deleteSeveral.label`)}
          onSuccess={() => {
            show(t(`materials:actions.deleteSeveral.success`))
            setSelectedMaterials({
              selectAll: false,
              values: [],
            })
            listRef.current?.updateValues()
          }}
          onClose={() => setModal('')}
          onConfirm={deleteMany}
        />
      )}
      {modal === 'editMaterials' && hasSelect && (
        <ModalFormManyMaterial
          showCerfa={showCerfa}
          useImperials={useImperials}
          type={type}
          onClose={() => setModal('')}
          onSubmit={updateMany}
          submitLabel={t(`global:actions.finish`)}
          title={t(`materials:actions.updateSeveral.label`)}
          onSuccess={() => {
            show(t(`materials:actions.updateSeveral.success`))
            setSelectedMaterials({
              selectAll: false,
              values: [],
            })
            listRef.current?.updateValues()
          }}
        />
      )}
      {modal && modal.includes('delete.') && (
        <Modal
          confirmLabel={t('global:actions.delete')}
          title={t(`materials:actions.delete${type}.label`)}
          onSuccess={() => {
            show(t(`materials:actions.delete${type}.success`))
            setSelectedMaterials((state) => ({
              selectAll: false,
              values: state.values.filter((val) => val !== modal.replace('delete.', '')),
            }))
            listRef.current?.updateValues()
          }}
          onClose={() => setModal('')}
          onConfirm={materialsService.deleteMaterialById.bind(null, modal.replace('delete.', ''))}
        />
      )}
      {modal &&
        defaultValue &&
        openedCatalog &&
        (modal.includes('duplicate') || modal.includes('edit')) && (
          <ModalFormMaterial
            checkError={listRef.current?.parse}
            showCerfa={showCerfa}
            linkedCatalog={isAdmin && !catalog ? openedCatalog : undefined}
            showValidationWeight={
              !!catalog &&
              catalog.type === CatalogType.deconstruction &&
              !catalog.hideValidationWeight
            }
            onHideValidationWeight={async () => {
              await catalogsService.updateCatalog(catalog!._id, { hideValidationWeight: true })
            }}
            material={defaultValue}
            useImperials={useImperials}
            onClose={() => setModal('')}
            actions={
              modal.includes('edit')
                ? [
                    {
                      onClick: () => setModal(`delete.${modal.replace('edit.', '')}`),
                      color: 'error',
                      label: t('global:actions.delete'),
                    },
                    {
                      onClick: () => setModal(`duplicate.${modal.replace('edit.', '')}`),
                      label: t('global:actions.duplicate'),
                    },
                    ...(defaultValue?.type === MaterialType.resource &&
                    !isApp &&
                    !defaultValue?.wasteOnly
                      ? [
                          {
                            onClick: () => setModal(`transfer.${modal.replace('edit.', '')}`),
                            label: t('materials:actions.transfer.label'),
                          },
                        ]
                      : []),
                    ...(isAdmin
                      ? [
                          {
                            onClick: () => setModal(`model.${modal.replace('edit.', '')}`),
                            label: t('materials:actions.createModel.label'),
                          },
                        ]
                      : []),
                  ]
                : []
            }
            onSubmit={
              modal.includes('edit')
                ? materialsService.updateMaterial.bind(null, {
                    ...defaultValue,
                    catalog: openedCatalog,
                  } as Material)
                : materialsService.createMaterial.bind(null, openedCatalog as CatalogLight)
            }
            submitLabel={
              modal.includes('edit') ? t('global:actions.save') : t(`global:actions.finish`)
            }
            title={
              modal.includes('edit')
                ? t(`materials:actions.update${type}.label`)
                : t(`materials:actions.add${type}.label`)
            }
            onSuccess={() => {
              listRef.current?.updateValues()
              show(
                modal.includes('edit')
                  ? t(`materials:actions.update${type}.success`)
                  : t(`materials:actions.add${type}.success`),
              )
            }}
          />
        )}

      {modal && materialModelDefaultValue && modal.includes('model') && (
        <ModalFormMaterialModel
          showCerfa={showCerfa}
          materialModel={materialModelDefaultValue}
          useImperials={useImperials}
          title={t(`materials:actions.createModel.label`)}
          onSubmit={materialModelsService.createMaterialModel}
          onClose={() => setModal('')}
          onSuccess={() => {
            show(t(`materials:actions.createModel.success`))
            listRef.current?.updateValues()
          }}
        />
      )}
    </Box>
  )
}
export default PageMaterialList
