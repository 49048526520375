import { useRef, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Edit as EditIcon, Remove as RemoveIcon } from '@mui/icons-material'
import { ListRef } from '../../components/common/List.common'

import CarbonModelList from '../../components/carbonModel/List.carbonModel'
import Modal from '../../components/layout/Modal.layout'
import ModalFormCarbonModel from '../../components/carbonModel/ModalForm.carbonModel'
import { CarbonModel } from '../../models/carbonModels.models'
import { Mode } from '../../models/commons.models'
import { Filter, ItemType } from '../../models/props.models'
import { carbonModelsService } from '../../store/carbonModels'
import useModal from '../../hooks/useModal.hooks'
import useSnackbar from '../../hooks/useSnackbar.hooks'
import useCheckRoute from '../../hooks/useCheckRoute.hooks'
import { AdminHeaderTitle } from '../../components/layout/AdminHeader.layout'
import { FilterableRoute } from '../../models/commons.models'
import { Box } from '@mui/material'

const CarbonModelListPage = () => {
  useCheckRoute('CarbonModelList', [Mode.admin])

  const { t } = useTranslation()
  const show = useSnackbar()
  const listRef = useRef<ListRef | null>(null)

  const [defaultValue, setDefaultValue] = useState<CarbonModel>()
  const [modal, setModal] = useModal<'add' | 'update' | 'delete'>()

  const filter = useMemo<Filter>(
    () => ({
      items: [
        {
          type: ItemType.search,
          key: 'search',
          grid: { sm: 6, xs: 6 },
          props: {
            placeholder: t('carbonModels:pages.list.searchFilter'),
          },
        },
        {
          type: ItemType.button,
          key: 'add',
          grid: { sm: 3, xs: 12 },
          props: {
            fullWidth: true,
            children: t('carbonModels:actions.add.label'),
            onClick: () => {
              setDefaultValue(undefined)
              setModal('add')
            },
          },
        },
      ],
    }),
    [t, setModal],
  )

  return (
    <Box>
      <AdminHeaderTitle title={t('global:routes.carbonModels')} />
      <CarbonModelList
        ref={listRef}
        filter={filter}
        valueActions={(carbonModel: CarbonModel) => ({
          items: [
            {
              onClick: () => {
                setDefaultValue(carbonModel)
                setModal('update')
              },
              label: t('global:actions.edit'),
              icon: <EditIcon />,
            },
            {
              onClick: () => {
                setDefaultValue(carbonModel)
                setModal('delete')
              },
              label: t('global:actions.delete'),
              icon: <RemoveIcon />,
            },
          ],
        })}
        getValues={carbonModelsService.getCarbonModels}
        filterKey={FilterableRoute.carbonModels}
      />
      {(modal === 'add' || (modal === 'update' && defaultValue)) && (
        <ModalFormCarbonModel
          carbonModel={defaultValue}
          title={t(`carbonModels:actions.${modal}.label`)}
          onSubmit={
            modal === 'add'
              ? carbonModelsService.createCarbonModel
              : carbonModelsService.updateCarbonModel.bind(null, (defaultValue as CarbonModel)._id)
          }
          onClose={() => setModal('')}
          onSuccess={() => {
            show(t(`carbonModels:actions.${modal}.success`))
            listRef.current?.updateValues()
          }}
        />
      )}
      {modal === 'delete' && defaultValue && (
        <Modal
          onClose={() => setModal('')}
          onConfirm={carbonModelsService.deleteCarbonModel.bind(null, defaultValue._id)}
          onSuccess={() => {
            show(t('carbonModels:actions.delete.success'))
            listRef.current?.updateValues()
          }}
          title={t('carbonModels:actions.delete.label')}
          description={t('carbonModels:actions.delete.description')}
        />
      )}
    </Box>
  )
}
export default CarbonModelListPage
