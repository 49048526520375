import { useTranslation } from 'react-i18next'
import { useEffect, useMemo, useState } from 'react'
import { Box, Typography } from '@mui/material'

import ModalForm, { ModalFormProps } from '../common/ModalForm.common'
import { YupUtils } from '../../utils/yup.utils'
import { createOptionsFromEnum } from '../../utils/i18n.utils'
import {
  OrganizationStatus,
  Organization,
  ManageOrganization,
  LegalForm,
} from '../../models/organizations.models'
import { ItemType, FormItem } from '../../models/props.models'
import { Mode } from '../../models/commons.models'
import { organizationsService } from '../../store/organizations'
import { sessionService } from '../../store/session'
import Modal from '../layout/Modal.layout'
import Info from '../common/item/Info.item'
import constants from '../../constants'

interface ModalFormOrganizationProps
  extends Omit<ModalFormProps, 'value' | 'items' | 'steps' | 'setValue'> {
  organization?: ManageOrganization
  isRegister?: boolean
}
const ModalFormOrganization: React.FC<ModalFormOrganizationProps> = (props) => {
  const { t } = useTranslation()
  const { isRegister, organization, onSubmit, onSuccess, onClose, ...formProps } = props
  const [value, setValue] = useState<ManageOrganization>(organization ?? ({} as ManageOrganization))
  const [siret, setSiret] = useState<{ format: string; validation: string } | undefined>(undefined)
  const [enableCerfa, setEnableCerfa] = useState<boolean>(false)
  useEffect(() => {
    const getSiret = async () => {
      if (constants.mode === Mode.admin && value?.location?.countryCode) {
        const country = await sessionService.getCountry(value?.location?.countryCode)
        setSiret(country.siret)
        setEnableCerfa(!!country.enableCerfa)
      } else {
        setSiret(
          sessionService.getCountryParam('siret') as
            | { format: string; validation: string }
            | undefined,
        )
        setEnableCerfa(!!sessionService.getCountryParam('enableCerfa'))
      }
    }
    getSiret()
  }, [value?.location?.countryCode])

  const [existingOrganization, setExistingOrganization] = useState<Organization | undefined>()

  const success = async (organization: any) => {
    if (organization !== false) {
      await onSuccess?.(organization)
      onClose()
    }
  }
  const submit = async () => {
    if (!value._id && isRegister) {
      const existing = value.siret
        ? await organizationsService.getOrganizationBySiret(value.siret)
        : null
      if (existing) {
        setExistingOrganization(existing)
        return false
      }
    }
    return await onSubmit?.({
      ...value,
      status: isRegister ? undefined : OrganizationStatus.accepted,
    })
  }

  const items: FormItem[] = useMemo(
    () => [
      {
        type: ItemType.checkbox,
        key: 'enableCerfa',
        hideItem: !enableCerfa,
        props: {
          label: t('organizations:attributes.enableCerfa'),
          tooltip: t('organizations:attributes.enableCerfaTooltip'),
        },
      },
      {
        type: ItemType.text,
        key: 'name',
        required: true,
        props: {
          label: t('organizations:attributes.name'),
          placeholder: t('organizations:attributes.name'),
        },
      },
      {
        type: ItemType.select,
        key: 'legalForm',
        required: true,
        props: {
          label: t('organizations:attributes.legalForm'),
          placeholder: t('global:inputs.selectPlaceholder'),
          items: createOptionsFromEnum(LegalForm, 'organizations:legalForms'),
        },
      },
      {
        type: ItemType.text,
        key: 'siret',
        required: true,
        rules: [
          siret
            ? { rule: YupUtils.FieldValidationType.regexp, params: siret.validation }
            : () => '',
        ],
        props: {
          pattern: siret?.format,
          label: t('organizations:attributes.siret'),
          placeholder: t('organizations:attributes.siret'),
        },
      },
      {
        type: ItemType.address,
        key: 'location',
        required: true,
        props: {
          label: t('organizations:attributes.location'),
          placeholder: t('organizations:attributes.location'),
        },
      },
      {
        type: ItemType.file,
        key: 'kbis',
        required: true,
        props: {
          label: t('organizations:attributes.kbis'),
          placeholder: t('global:actions.import'),
          accept: '.pdf',
        },
      },

      {
        type: ItemType.file,
        key: 'insurance',
        hideItem: !enableCerfa || !value.enableCerfa,
        props: {
          label: t('organizations:attributes.insurance'),
          placeholder: t('global:actions.import'),
          tooltip: t('organizations:attributes.insuranceTooltip'),
        },
      },
    ],
    [t, siret, enableCerfa, value.enableCerfa],
  )

  return (
    <Box>
      <ModalForm
        {...formProps}
        value={value}
        setValue={setValue}
        items={items}
        onClose={onClose}
        onSubmit={submit}
        onSuccess={success}
        maxWidth="sm"
        keepOpen
      />
      {!!existingOrganization && (
        <Modal
          title={t('organizations:components.modalForm.title')}
          confirmLabel={t('global:actions.yes')}
          closeLabel={t('global:actions.no')}
          onClose={() => setExistingOrganization(undefined)}
          onConfirm={async () => {
            const submiRes = await onSubmit?.(existingOrganization)
            success(submiRes)
          }}>
          <Info>
            {[
              {
                label: t('organizations:attributes.name'),
                value: existingOrganization.name,
              },
              {
                label: t('organizations:attributes.legalForm'),
                value: existingOrganization.legalForm
                  ? t(`organizations:legalForms.${existingOrganization.legalForm}`)
                  : '-',
              },
              { label: t('organizations:attributes.siret'), value: existingOrganization.siret },
              {
                label: t('organizations:attributes.location'),
                value: existingOrganization.location?.fullAddress?.replace(',', '\n'),
              },
            ]
              .filter((r) => !!r.value)
              .map((r) => (
                <Box key={r.label}>
                  <Typography>{r.label}</Typography>
                  <Typography variant="body1">{r.value}</Typography>
                </Box>
              ))}
          </Info>
        </Modal>
      )}
    </Box>
  )
}
export default ModalFormOrganization
