import React, { useState, useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Order, Product, computeOrderTotal } from '../../models/orders.models'
import { FormItem, ItemType } from '../../models/props.models'
import OrderListProduct from './ListProduct.order'
import ModalForm, { ModalFormProps } from '../common/ModalForm.common'
import { styled, FormHelperText, InputLabel, Box } from '@mui/material'
import constants from '../../constants'

const StyledInputContainer = styled('div')({
  background: constants.colors.textFieldBackground,
  borderRadius: 4,
  fontSize: '0.875rem',
  height: '45px',
  padding: '12px 14px',
})

interface ModalClientAcceptProps
  extends Omit<ModalFormProps, 'value' | 'items' | 'steps' | 'setValue' | 'title' | 'submitLabel'> {
  order: Order
}

const ModalClientAccept: React.FC<ModalClientAcceptProps> = (props) => {
  const { order, ...modalFormProps } = props
  const { t } = useTranslation()

  const [value, setValue] = useState<{
    products: Product[]
  }>({
    products: order.products,
  })

  const errorQuantity = useMemo(
    () => value.products.reduce((acc: number, p: Product) => acc + p.quantity, 0) === 0,
    [value.products],
  )
  const hasWrongValue = useMemo(
    () =>
      value.products.reduce((hasWrongValue: boolean, product: Product) => {
        return (
          hasWrongValue ||
          (product.quantity !== 0 &&
            (product.quantity < product.material.minQuantity ||
              product.quantity > product.material.currentQty))
        )
      }, false),
    [value.products],
  )
  const updateProduct = useCallback((product: Product) => {
    setValue((val: any) => ({
      ...val,
      products: val.products.map((prod: any) =>
        product.material._id === prod.material._id ? product : prod,
      ),
    }))
  }, [])

  const total = computeOrderTotal(value as Order)
  const totalHT = `${total.toFixed(2)}${t(`global:currency.${order.catalog.currency}`)}`
  const totalTTC = order.tax
    ? `${(total * (1 + order.tax)).toFixed(2)}${t(`global:currency.${order.catalog.currency}`)}`
    : ''

  const items: FormItem[] = useMemo(
    () => [
      {
        type: ItemType.custom,
        key: 'products',
        rules: [(_: any) => (errorQuantity || hasWrongValue ? 'error' : undefined)],
        custom: (
          <Box>
            <OrderListProduct
              values={value.products}
              canUpdate
              onProductChange={updateProduct}
              acceptClientStep
            />
            {errorQuantity && (
              <FormHelperText error>
                {t('orders:components.modalClientAccept.errorQuantity')}
              </FormHelperText>
            )}
          </Box>
        ),
      },
      {
        type: ItemType.group,
        key: 'totalPricing',
        props: {
          title: t('orders:components.modalClientAccept.paymentTitle'),
        },
        items: [
          {
            type: ItemType.custom,
            key: 'totalHT',
            grid: { xs: 6, md: 3 },
            custom: (
              <>
                <InputLabel>{t('orders:attributes.totalHT')}</InputLabel>
                <StyledInputContainer>{totalHT}</StyledInputContainer>
              </>
            ),
          },
          ...(order.tax
            ? [
                {
                  type: ItemType.text,
                  key: 'tax',
                  grid: { xs: 6, md: 3 },
                  formatValue: () =>
                    t('global:format.percent', { value: ((order.tax ?? 0) * 100).toFixed(2) }),
                  props: {
                    label: t('orders:attributes.tax'),
                    readOnly: true,
                  },
                },
                {
                  type: ItemType.custom,
                  key: 'totalTTC',
                  grid: { xs: 6, md: 3 },
                  custom: (
                    <>
                      <InputLabel>{t('orders:attributes.totalTTC')}</InputLabel>
                      <StyledInputContainer>{totalTTC}</StyledInputContainer>
                    </>
                  ),
                },
              ]
            : []),
        ],
      },
    ],
    [t, value, errorQuantity, hasWrongValue, totalHT, totalTTC, order.tax, updateProduct],
  )

  return (
    <ModalForm
      maxWidth="lg"
      {...modalFormProps}
      value={value}
      formatValue={(data: any) => {
        return {
          ...data,
          products: data.products.filter((product: Product) => product.quantity > 0),
        }
      }}
      setValue={setValue}
      items={items}
      title={t('orders:actions.clientAccept.label')}
      submitLabel={t('global:actions.confirm')}
    />
  )
}

export default ModalClientAccept
