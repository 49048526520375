import { useState, useMemo, useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Catalog } from '../../models/catalogs.models'
import { ItemType, FormItem } from '../../models/props.models'
import { sessionQuery } from '../../store/session'
import { createOptionsFromEnum } from '../../utils/i18n.utils'
import ModalForm, { ModalFormProps } from '../common/ModalForm.common'
import { Chat, MessageFrom } from '../../models/chats.models'
import { Order } from '../../models/orders.models'
import { chatsService } from '../../store/chats'
import LoaderOverlay from '../layout/LoaderOverlay.layout'
import useSnackbar from '../../hooks/useSnackbar.hooks'
import { logIfDev, StringUtils } from '../../utils/commons.utils'
import { alpha, Box, Typography } from '@mui/material'
import constants from '../../constants'

interface ModalFormChatProps
  extends Omit<
    ModalFormProps,
    'value' | 'items' | 'steps' | 'setValue' | 'onSubmit' | 'onSuccess' | 'title'
  > {
  from?: MessageFrom | 'admin'
  chatId?: string
  catalog?: Catalog
  order?: Order
  updateList?: () => void
}
const ModalFormChat: React.FC<ModalFormChatProps> = (props) => {
  const { t } = useTranslation()
  const { updateList, from, chatId, catalog, order, onClose, ...formProps } = props

  const messageRef = useRef<HTMLElement>(null)
  const show = useSnackbar()
  const [loading, setLoading] = useState<boolean>(true)
  const [loadingRequest, setLoadingRequests] = useState<boolean>(false)
  const [chat, setChat] = useState<Chat | undefined>()
  const [value, setValue] = useState<{ from?: MessageFrom; content: string }>({
    from: from !== 'admin' ? from : undefined,
    content: '',
  })
  const markAsRead = useCallback(async () => {
    setLoadingRequests(true)

    try {
      if (chat) {
        const resChat = await chatsService.updateChat(chat, {
          markAsRead: true,
          from: from === 'admin' ? undefined : from,
        })
        setChat(resChat)
        updateList?.()
        if (from === 'admin') {
          show(t('chats:actions.markAsread'), 'success')
        }
      }
    } catch (err: any) {
      if (from === 'admin') {
        show(err)
      } else {
        logIfDev(err)
      }
    }
    setLoadingRequests(false)
  }, [from, chat, show, t, updateList])

  useEffect(() => {
    if (!sessionQuery.getUserId()) {
      show(t('sessions:actions.needLog.chat'), 'warning')
      onClose()
      return
    }

    const getChat = async () => {
      setLoading(true)
      try {
        if (chatId) {
          setChat(await chatsService.getChatById(chatId))
        } else if (catalog) {
          if (from !== MessageFrom.client) {
            throw new Error('only client can initiate catalog chat')
          } else {
            const { data } = await chatsService.getChats({
              client: sessionQuery.getUserId(),
              catalog: catalog._id,
            })
            if (data.length === 1) {
              setChat(await chatsService.getChatById(data[0]._id))
            }
          }
        } else if (order) {
          const { data } = await chatsService.getChats({
            order: order._id,
          })
          if (data.length === 1) {
            setChat(await chatsService.getChatById(data[0]._id))
          }
        }
      } catch (err: any) {
        show(err)
        onClose()
      }
      setLoading(false)
    }

    if (
      !chat ||
      (chatId && chatId !== chat._id) ||
      (catalog && chat.catalog !== catalog._id) ||
      (order && chat.order !== order._id)
    ) {
      getChat()
    }

    if (
      chat &&
      ((from === 'seller' && !chat.sellerUpToDate) || (from === 'client' && !chat.clientUpToDate))
    ) {
      markAsRead()
    }
  }, [t, show, onClose, from, chatId, catalog, order, chat, markAsRead])

  useEffect(() => {
    if (chat) {
      setTimeout(() => {
        if (messageRef.current) {
          messageRef.current.parentElement?.scrollTo({
            behavior: 'smooth',
            top: messageRef.current.getBoundingClientRect().height + 500,
          })
        }
      }, 50)
    }
  }, [chat])

  const items: FormItem[] = useMemo(
    () => [
      ...((from !== 'admin'
        ? []
        : [
            {
              type: ItemType.radio,
              key: 'from',
              grid: { xs: 6 },
              required: true,
              props: {
                items: createOptionsFromEnum(MessageFrom, 'chats:messageFrom'),
                label: t('chats:attributes.messageFrom'),
              },
            },
          ]) as FormItem[]),
      {
        type: ItemType.text,
        key: 'content',
        required: true,
        props: {
          label: t('chats:attributes.content'),
          multiline: true,
          minRows: 2,
        },
      },
    ],
    [t, from],
  )

  const catalogName = chat?.catalogName ?? order?.catalog?.name ?? catalog?.name ?? ''
  const orderNumber = chat?.orderNumber ?? order?.orderNumber

  return (
    <Box>
      {loading && <LoaderOverlay />}
      {loadingRequest && <LoaderOverlay />}
      {!loading && (
        <ModalForm
          {...formProps}
          onClose={onClose}
          value={value}
          setValue={setValue}
          items={items}
          maxWidth="md"
          title={
            orderNumber
              ? t('chats:attributes.title.order', { orderNumber })
              : t('chats:attributes.title.catalog', { catalogName })
          }
          header={
            chat && (
              <Box
                display="flex"
                flexDirection="column"
                overflow="auto"
                paddingX="10px"
                height="50vh">
                <Box ref={messageRef}>
                  {chat.messages.map((message, messageIndex) => {
                    const right =
                      message.from === from ||
                      (from === 'admin' && message.from === MessageFrom.client)
                    return (
                      <Box
                        key={`message.${messageIndex}`}
                        sx={{
                          maxWidth: '80%',
                          marginLeft: right ? 'auto' : '',
                          marginRight: right ? '' : 'auto',
                          marginTop: '10px',
                        }}>
                        <Box>
                          {message.from === MessageFrom.seller
                            ? StringUtils.capitalize(chat.seller.name)
                            : `${StringUtils.capitalize(
                                chat.client.firstname,
                              )} ${StringUtils.capitalize(chat.client.lastname)}`}
                        </Box>

                        <Box
                          sx={{
                            marginLeft: right ? 'auto' : '',
                            marginRight: right ? '' : 'auto',
                            padding: '10px',
                            borderRadius: '5px',
                            backgroundColor: alpha(
                              right ? constants.colors.primary : constants.colors.secondary,
                              0.1,
                            ),
                          }}>
                          {message.content.split('\n').map((p, contentIndex) => (
                            <div key={`content${contentIndex}`}>
                              {p}
                              <br />
                            </div>
                          ))}
                        </Box>
                        <Typography textAlign="right" fontSize="0.75rem">
                          {t('global:format.dateAndHour', { date: new Date(message.createdAt) })}
                        </Typography>
                      </Box>
                    )
                  })}
                </Box>
              </Box>
            )
          }
          actions={
            from === 'admin' &&
            chat &&
            (chat.sellerUpToDate === false || chat.clientUpToDate === false)
              ? [{ label: t('chats:actions.markAsread'), onClick: markAsRead }]
              : []
          }
          submitLabel={t('chats:actions.send')}
          onSubmit={async (data: any) => {
            let resChat
            if (chat) {
              resChat = await chatsService.updateChat(chat, {
                ...data,
              })
            } else {
              resChat = await chatsService.createChat({
                ...data,
                order: order?._id,
                catalog: catalog?._id,
              })
            }

            setChat(resChat)
            setValue((value) => ({ ...value, content: '' }))
            updateList?.()
          }}
          keepOpen
          onSuccess={async () => {
            show(t('chats:actions.messageSended'), 'success')
          }}
        />
      )}
    </Box>
  )
}
export default ModalFormChat
