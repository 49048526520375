import { useCallback, useEffect, useMemo, useState } from 'react'
import { useObservable } from '@ngneat/react-rxjs'
import { useTranslation } from 'react-i18next'
import {
  Grid,
  alpha,
  Box,
  Button,
  Card,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import {
  ShoppingBasket as ShoppingBasketIcon,
  Delete as DeleteIcon,
  Mail as MailIcon,
  Scale as ScaleIcon,
} from '@mui/icons-material'

import { cartsService, cartsQuery } from '../store/carts'
import { ordersService } from '../store/orders'
import { materialsService } from '../store/materials'
import { sessionQuery, sessionService } from '../store/session'

import useCheckRoute from '../hooks/useCheckRoute.hooks'
import useRoute from '../hooks/useRoute.hooks'
import useSnackbar from '../hooks/useSnackbar.hooks'
import useModal from '../hooks/useModal.hooks'
import { Mode, Route, Currency } from '../models/commons.models'
import { Material, TermsOfSale } from '../models/materials.models'
import {
  Product,
  ApiProduct,
  convertOrder2Meters,
  convertOrder2Imperials,
} from '../models/orders.models'
import { Catalog } from '../models/catalogs.models'
import LoaderOverlay from '../components/layout/LoaderOverlay.layout'
import OrderListProduct from '../components/order/ListProduct.order'
import ModalDetails from '../components/material/ModalDetails.material'
import ModalFormChat from '../components/chat/ModalForm.chat'
import { MessageFrom } from '../models/chats.models'

const CardTitle = styled(Typography)({
  fontSize: '1.125rem',
  fontWeight: 700,
  marginBottom: '28px',
})

const TotalHTRow = styled(Box)(({ theme }) => ({
  padding: '16px 0',
  paddingTop: '0px',
  fontSize: '.6875rem',
  letterSpacing: -0.14,
  fontWeight: 300,
  borderBottom: `1px solid ${theme.palette.menuBorder}`,
}))
const TaxRow = styled(Box)({
  padding: '16px 0',
  fontSize: '.6875rem',
  letterSpacing: -0.14,
  fontWeight: 300,
})
const TotalTTCRow = styled(Box)(({ theme }) => ({
  padding: '16px 20px',
  fontSize: '.75rem',
  letterSpacing: -0.15,
  fontWeight: 500,
  color: theme.palette.primary.main,
  backgroundColor: alpha(theme.palette.primary.main, 0.1),
  textAlign: 'center',
}))

const Title = styled(Typography)({
  marginBottom: '10px',
})
const Subtitle = styled(Typography)({
  textAlign: 'center',
  marginBottom: '38px',
})

const ActionButton = styled(Button)({
  fontSize: '0.875rem',
  fontWeight: 500,
  padding: '0 15px',
  '& .MuiButton-startIcon': {
    marginRight: '7px',
  },
})
export const TableTitle = styled(Typography)({
  fontSize: '1.125rem',
  fontWeight: 700,
})
export const TableModality = styled(Typography)({
  fontSize: '0.875',
})

const ValidateButton = styled(Button)({ padding: '10px 55px' })

const TotalWeightLabel = styled(Typography)({
  marginLeft: '5px',
  fontSize: '0.75rem',
  fontWeight: 400,
})

type CatalogProducts = {
  catalog: Catalog
  products: Product[]
  hasUndefinedPrice: boolean
  hasWrongQuantity: boolean
  total: number
  totalWeight?: number
}
type ResumeProps = {
  loading: boolean
  hasWrongQuantity: boolean
  hasUndefinedPrice: boolean
  totalHT: string
  totalTTC?: string
  totalWeight?: number
  tax?: number
  onValidate: () => void
  type: 'global' | 'catalog'
}
const Resume: React.FC<ResumeProps> = ({
  tax,
  hasUndefinedPrice,
  totalHT,
  totalTTC,
  loading,
  hasWrongQuantity,
  totalWeight,
  onValidate,
  type,
}) => {
  const { t } = useTranslation()

  return (
    <Box>
      {tax ? (
        <TotalHTRow display="flex" justifyContent="space-between">
          <span>{t(`orders:pages.cart.${type}.totalHT`)}</span>
          <span>{hasUndefinedPrice ? t('orders:status.pricePending') : totalHT}</span>
        </TotalHTRow>
      ) : (
        <TotalTTCRow display="flex" justifyContent="space-between">
          <span>{t(`orders:pages.cart.${type}.totalHT`)}</span>
          <span>{hasUndefinedPrice ? t('orders:status.pricePending') : totalHT}</span>
        </TotalTTCRow>
      )}
      {totalTTC && tax && (
        <>
          <TaxRow display="flex" justifyContent="space-between">
            <span>{t(`orders:pages.cart.${type}.tax`)}</span>
            <span>{tax ? t('global:format.percent', { value: (tax * 100).toFixed(2) }) : '-'}</span>
          </TaxRow>
          <TotalTTCRow display="flex" justifyContent="space-between">
            <span>{t(`orders:pages.cart.${type}.totalTTC`)}</span>
            <span>{hasUndefinedPrice ? t('orders:status.pricePending') : totalTTC}</span>
          </TotalTTCRow>
        </>
      )}
      <Typography mt="5px" fontSize=".6875rem">
        {t('orders:pages.cart.priceChange')}
      </Typography>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        mt="15px"
        alignItems="center">
        <ScaleIcon />
        <TotalWeightLabel>
          {t(`orders:pages.cart.${type}.totalWeight`)} :&nbsp;
          <b>
            {totalWeight === undefined
              ? '-'
              : totalWeight > 999
              ? t('materials:attributes.weight.tonne', { value: totalWeight })
              : t('materials:attributes.weight.kg', { value: totalWeight })}
          </b>
        </TotalWeightLabel>
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center" mt="15px">
        <ValidateButton
          variant="contained"
          size="medium"
          color="primary"
          disabled={loading || hasWrongQuantity}
          onClick={onValidate}>
          {t(`orders:pages.cart.${type}.validate`)}
        </ValidateButton>
        {hasWrongQuantity && (
          <Box mt="15px">
            <Typography variant="h3" color="error">
              {t('orders:pages.cart.quantityError')}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  )
}

const Cart = () => {
  useCheckRoute('Cart', [Mode.front, Mode.storeFront])
  const [modal, setModal] = useModal<string>()
  const { t } = useTranslation()
  const { goTo } = useRoute()
  const [defaultValue, setDefaultValue] = useState<Material | undefined>(undefined)
  const show = useSnackbar()
  const [materials, setMaterials] = useState<Material[]>([])
  const [catalogProducts, setCatalogProducts] = useState<CatalogProducts[]>([])
  const [loading, setLoading] = useState(false)
  const [taxes, setTaxes] = useState<Record<string, number | undefined>>({})
  const [useImperials] = useObservable(sessionQuery.useImperials)
  const [user] = useObservable(sessionQuery.user)
  const [cart] = useObservable(cartsQuery.cart)
  const theme = useTheme()
  const downMd = useMediaQuery(theme.breakpoints.down('md'))

  const updateQuantity = useCallback(
    (product: Product) =>
      cartsService.updateQuantity(
        product.material._id,
        useImperials
          ? convertOrder2Meters({ products: [product] }).products[0].quantity
          : product.quantity,
      ),
    [useImperials],
  )
  const openProduct = useCallback(
    (product: Product) => setModal(`material.${product.material._id}`),
    [setModal],
  )

  const emptyCatalog = useCallback(
    async (catalogId?: string) => {
      cartsService.updateCart(
        cart.filter((item) => {
          const material = materials.find((material: Material) => material._id === item.material)
          return !material || material.catalog._id !== catalogId
        }),
      )
    },
    [materials, cart],
  )
  const handleValidateBasket = useCallback(
    async (catalogId?: string) => {
      setLoading(true)
      try {
        if (!user) {
          show(t('sessions:actions.needLog.order'), 'warning')
        } else {
          if (catalogId) {
            await ordersService.order(
              cart.filter((item) => {
                const material = materials.find(
                  (material: Material) => material._id === item.material,
                )
                return !!material && material.catalog._id === catalogId
              }),
            )
            cartsService.updateCart(
              cart.filter((item) => {
                const material = materials.find(
                  (material: Material) => material._id === item.material,
                )
                return !material || material.catalog._id !== catalogId
              }),
            )
          } else {
            await ordersService.order(cart)
            cartsService.emptyCart()
          }

          show(t('orders:pages.cart.orderSuccess'))
          goTo({ route: Route.myOrders })
        }
      } catch (err: any) {
        show(err)
      }
      setLoading(false)
    },
    [cart, goTo, show, t, user, materials],
  )

  useEffect(() => {
    if (modal?.includes('material') && !defaultValue) {
      const materialId = modal.replace('material.', '')
      const getMaterial = async () => {
        setLoading(true)
        try {
          const material = materials.find((material: Material) => material._id === materialId)
          setDefaultValue(material)
        } catch (err: any) {
          setModal('')
          show(err)
        }
        setLoading(false)
      }
      getMaterial()
    } else if (!modal && defaultValue) {
      setDefaultValue(undefined)
    }
  }, [materials, modal, defaultValue, show, setModal])

  useEffect(() => {
    if (cart.length > 0) {
      const materialsToLoad = cart.filter(
        (product: ApiProduct) =>
          !materials.find((material: Material) => product.material === material._id),
      )
      if (materialsToLoad.length > 0) {
        const loadMaterials = async () => {
          setLoading(true)
          const loaded: Material[] = []
          for (let i = 0; i < materialsToLoad.length; i++) {
            try {
              loaded.push(await materialsService.getMaterial(materialsToLoad[i].material, true))
            } catch (err: any) {
              cartsService.updateQuantity(materialsToLoad[i].material, 0)
              // show(err)
            }
          }
          setMaterials((materials) => materials.concat(loaded))

          setLoading(false)
        }
        loadMaterials()
      }
    }
  }, [materials, show, cart])

  useEffect(() => {
    if (!loading) {
      setCatalogProducts(
        cart
          ?.reduce((catalogProducts: CatalogProducts[], product: ApiProduct): CatalogProducts[] => {
            let material = materials.find((material: Material) => material._id === product.material)
            if (material && product.quantity > 0) {
              let catalogProduct = catalogProducts.find(
                (catalogProduct: CatalogProducts) =>
                  catalogProduct.catalog._id === material?.catalog._id,
              )

              let hasUndefinedPrice = material.termsOfSale === TermsOfSale.notDefined
              let hasWrongQuantity =
                product.quantity < material.minQuantity || product.quantity > material.currentQty
              let total = material.price ? material.price * product.quantity : 0
              let totalWeight = !material.unitWeight
                ? undefined
                : material.unitWeight * product.quantity

              if (catalogProduct) {
                catalogProduct.products.push({ material, quantity: product.quantity })
                catalogProduct.hasUndefinedPrice =
                  catalogProduct.hasUndefinedPrice || hasUndefinedPrice
                catalogProduct.hasWrongQuantity =
                  catalogProduct.hasWrongQuantity || hasWrongQuantity
                catalogProduct.total += total
                catalogProduct.totalWeight =
                  catalogProduct.totalWeight !== undefined && totalWeight !== undefined
                    ? catalogProduct.totalWeight + totalWeight
                    : undefined
              } else {
                catalogProducts.push({
                  catalog: material.catalog as Catalog,
                  products: [{ material, quantity: product.quantity }],
                  hasUndefinedPrice,
                  hasWrongQuantity,
                  total,
                  totalWeight,
                })
              }
            }
            return catalogProducts
          }, [] as CatalogProducts[])
          .map((catalogProduct: CatalogProducts) =>
            useImperials ? convertOrder2Imperials(catalogProduct) : catalogProduct,
          ),
      )
    }
  }, [materials, cart, loading, useImperials])

  useEffect(() => {
    const getTaxes = async () => {
      let taxes: Record<string, number | undefined> = {}

      for (let i = 0; i < catalogProducts.length; i++) {
        if (catalogProducts[i].catalog.organization?.location?.countryCode) {
          taxes[catalogProducts[i].catalog._id] = (
            await sessionService.getCountry(
              catalogProducts[i].catalog.organization?.location?.countryCode,
            )
          ).tax as number | undefined
        }
      }
      setTaxes(taxes)
    }
    getTaxes()
  }, [catalogProducts])

  const totals = useMemo(() => {
    const totals = catalogProducts.reduce<{
      hasUndefinedPrice: boolean
      hasWrongQuantity: boolean
      totalWeight?: number
      perCurrency: Record<Currency, number>
    }>(
      (totals, catalogProduct) => {
        return {
          perCurrency: {
            ...totals.perCurrency,
            [catalogProduct.catalog.currency]:
              (totals.perCurrency[catalogProduct.catalog.currency] ?? 0) + catalogProduct.total,
          },
          hasUndefinedPrice: totals.hasUndefinedPrice || catalogProduct.hasUndefinedPrice,
          hasWrongQuantity: totals.hasWrongQuantity || catalogProduct.hasWrongQuantity,
          totalWeight:
            catalogProduct.totalWeight !== undefined && totals.totalWeight !== undefined
              ? catalogProduct.totalWeight + totals.totalWeight
              : undefined,
        }
      },
      {
        hasUndefinedPrice: false,
        hasWrongQuantity: false,
        totalWeight: 0,
        perCurrency: {} as Record<Currency, number>,
      },
    )

    const tax =
      Object.keys(totals.perCurrency).length === 1
        ? Object.keys(taxes).reduce(
            (tax: number | undefined, catalogId: string) =>
              tax === undefined || taxes[catalogId] === undefined || tax !== taxes[catalogId]
                ? undefined
                : taxes[catalogId],
            catalogProducts?.[0]?.catalog?._id
              ? taxes[catalogProducts?.[0]?.catalog?._id]
              : undefined,
          )
        : undefined

    return {
      ...totals,
      perCurrency: undefined,
      totalHT: Object.keys(totals.perCurrency)
        .map(
          (currency: string) =>
            `${totals.perCurrency[currency as Currency].toFixed(2)}${t(
              `global:currency.${currency as Currency}`,
            )}`,
        )
        .join(', '),
      tax,
      totalTTC: tax
        ? Object.keys(totals.perCurrency)
            .map(
              (currency: string) =>
                `${(totals.perCurrency[currency as Currency] * (1 + tax)).toFixed(2)}${t(
                  `global:currency.${currency as Currency}`,
                )}`,
            )
            .join('')
        : '-',
    }
  }, [catalogProducts, taxes, t])

  return (
    <Grid padding="15px" container spacing={2} position="relative">
      {loading && <LoaderOverlay />}

      <Grid item display="flex" justifyContent="space-between" alignItems="center" xs={12}>
        <Typography variant="h2">{t('orders:pages.cart.title')}</Typography>
        {!!cart.length && (
          <ActionButton onClick={cartsService.emptyCart} color="primary" startIcon={<DeleteIcon />}>
            {t('orders:pages.cart.empty')}
          </ActionButton>
        )}
      </Grid>

      {cart.length === 0 && (
        <Grid item justifyContent="center" alignItems="center" xs={12}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            mt="100px">
            <Box mb="10px">
              <ShoppingBasketIcon sx={{ fontSize: '43px' }} />
            </Box>
            <Title variant="h3">{t('orders:pages.cart.emptyTitle')}</Title>
            <Subtitle>{t('orders:pages.cart.emptyDescription')}</Subtitle>

            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => goTo({ route: Route.publicMaterials })}>
              {t('orders:pages.cart.emptyButton')}
            </Button>
          </Box>
        </Grid>
      )}
      {cart.length > 0 && (
        <>
          <Grid item justifyContent="center" alignItems="flex-start" xs={12} lg={8}>
            {catalogProducts.map((catalogProduct: CatalogProducts) => (
              <Card
                key={catalogProduct.catalog._id}
                sx={{ padding: '15px 15px 0 15px', marginBottom: '15px' }}>
                <Box display="flex">
                  <TableTitle>{catalogProduct.catalog.name}</TableTitle>

                  {catalogProducts.length > 1 && (
                    <ActionButton
                      onClick={() => emptyCatalog(catalogProduct.catalog._id)}
                      color="primary"
                      sx={{ marginLeft: 'auto' }}
                      startIcon={<DeleteIcon />}>
                      {t('orders:pages.cart.emptyCatalog')}
                    </ActionButton>
                  )}
                </Box>

                {catalogProduct.catalog.retrieval && (
                  <TableModality>
                    <span>
                      {t('orders:pages.cart.retrieval')}
                      {catalogProduct.catalog.retrieval.location.fullAddress?.replace(', ', '\n') ||
                        ''}
                      {catalogProduct.catalog.retrieval.startDate ||
                      catalogProduct.catalog.retrieval.startDate
                        ? t('orders:pages.cart.fromTo', {
                            from: new Date(
                              catalogProduct.catalog.retrieval.startDate || 'invalid date',
                            ),
                            to: new Date(
                              catalogProduct.catalog.retrieval.endDate || 'invalid date',
                            ),
                          })
                        : ''}
                    </span>
                  </TableModality>
                )}
                {catalogProduct.catalog.retrieval?.retrievalModality && (
                  <TableModality>
                    {t(
                      `global:retrievalModality.${catalogProduct.catalog.retrieval.retrievalModality}`,
                    )}
                  </TableModality>
                )}
                {catalogProduct.catalog.retrievalInformation && (
                  <TableModality>{catalogProduct.catalog.retrievalInformation}</TableModality>
                )}
                <ActionButton
                  onClick={() => setModal(`chat.${catalogProduct.catalog._id}`)}
                  color="primary"
                  sx={{ paddingLeft: '0' }}
                  startIcon={<MailIcon />}>
                  {t('orders:pages.cart.chat')}
                </ActionButton>

                <OrderListProduct
                  values={catalogProduct.products}
                  onValueClick={openProduct}
                  canUpdate
                  onProductChange={updateQuantity}
                />
                {catalogProducts.length > 1 && (
                  <Box
                    sx={{
                      padding: '0 15px 15px 15px',
                      maxWidth: '400px',
                      [downMd ? 'margin' : 'marginLeft']: 'auto',
                    }}>
                    <Resume
                      {...catalogProduct}
                      type="catalog"
                      loading={loading}
                      totalHT={`${catalogProduct.total.toFixed(2)}${t(
                        `global:currency.${catalogProduct.catalog.currency}`,
                      )}`}
                      totalTTC={
                        taxes[catalogProduct.catalog._id]
                          ? `${(
                              catalogProduct.total *
                              (1 + taxes[catalogProduct.catalog._id]!)
                            ).toFixed(2)}${t(`global:currency.${catalogProduct.catalog.currency}`)}`
                          : '-'
                      }
                      tax={taxes[catalogProduct.catalog._id]}
                      onValidate={() => handleValidateBasket(catalogProduct.catalog._id)}
                    />
                  </Box>
                )}
              </Card>
            ))}
          </Grid>
          <Grid item justifyContent="center" alignItems="flex-start" xs={12} lg={4}>
            <Card sx={{ padding: '15px', maxWidth: '500px', margin: 'auto' }}>
              <CardTitle>{t('orders:pages.cart.summary')}</CardTitle>
              <Resume
                {...totals}
                type="global"
                loading={loading}
                onValidate={() => handleValidateBasket(undefined)}
              />
            </Card>
          </Grid>
        </>
      )}

      {modal?.includes('material') && defaultValue && (
        <ModalDetails
          useImperials={useImperials}
          isPublic
          material={defaultValue as Material}
          onClose={() => setModal('')}
        />
      )}
      {modal?.includes('chat') && (
        <ModalFormChat
          catalog={
            catalogProducts.find(
              (catalogProduct) => catalogProduct.catalog._id === modal?.replace('chat.', ''),
            )?.catalog
          }
          from={MessageFrom.client}
          onClose={() => setModal('')}
        />
      )}
    </Grid>
  )
}
export default Cart
