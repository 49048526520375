import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  alpha,
  AppBar,
  Box,
  Button,
  Fade,
  IconButton,
  Dialog,
  Menu,
  MenuItem,
  styled,
  Toolbar,
  useMediaQuery,
} from '@mui/material'
import { useTheme } from '@mui/material/styles/index.js'
import {
  AccountBox as AccountBoxIcon,
  Logout as LogoutIcon,
  Menu as MenuIcon,
  Close as CloseIcon,
  Apartment as ApartmentIcon,
} from '@mui/icons-material'
import { NavLink } from 'react-router-dom'
import { useObservable } from '@ngneat/react-rxjs'
import LayoutLangs from './Langs.layout'

import Constants from '../../constants'
import useRoute from '../../hooks/useRoute.hooks'

import { Route } from '../../models/commons.models'
import { getPlatform } from '../../models/platforms.models'
import { sessionService, sessionQuery } from '../../store/session'
import LinkButton from '../common/button/Link.button'
import Environment from './Environment.layout'

const NavButtonsContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  '& > a': {
    textDecoration: 'none',
  },
  '& > :first-of-type': {
    marginLeft: 'auto',
  },
  '& > *': {
    marginRight: '2vw',
  },
  '& .MuiButton-root': {
    color: theme.palette.black,
    fontSize: '0.875rem',
    fontWeight: 700,
    padding: 0,
    textAlign: 'center',
    textTransform: 'uppercase',
    '&[aria-label=active]': {
      color: theme.palette.primary.main,
    },
    '&:hover': {
      backgroundColor: alpha(theme.palette.black, 0.15),
    },
  },
}))

type AppHeader = {
  additionals?: { path: string; key: string }[]
}

const AppHeaderComponent = (_: AppHeader): JSX.Element => {
  const { t } = useTranslation()
  const { goTo, getPath, isAtRoute } = useRoute()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const onMenuClose = () => setAnchorEl(null)
  const [user] = useObservable(sessionQuery.user)

  const theme = useTheme()
  const onlyXs = useMediaQuery(theme.breakpoints.only('xs'))
  const isLoggin = isAtRoute(Route.login)

  const MenuComponent = ({ children }: { children: any }) => {
    return Boolean(anchorEl) ? (
      !onlyXs ? (
        <Menu
          open
          anchorEl={anchorEl}
          keepMounted
          onClose={onMenuClose}
          TransitionComponent={Fade}
          style={{ marginTop: '50px' }}
          transformOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}>
          {children}
        </Menu>
      ) : (
        <Dialog open onClose={onMenuClose} fullScreen TransitionComponent={Fade}>
          <Box
            height={Constants.ui.appBarSize + 'px'}
            padding="20px"
            display="flex"
            justifyContent="center"
            alignContent="flex-end">
            <IconButton sx={{ marginLeft: 'auto' }} onClick={() => setAnchorEl(null)}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box>{children}</Box>
        </Dialog>
      )
    ) : (
      <></>
    )
  }

  const platform = getPlatform(Constants.platform)

  return (
    <AppBar position="static">
      <Toolbar>
        <Environment />
        <Box mr="20px" sx={{ marginRight: 'auto' }}>
          <NavLink to={getPath({ route: Route.workspace })}>
            <img
              alt=""
              src={platform.logo}
              width={!onlyXs ? '250px' : '200px'}
              style={{ maxWidth: onlyXs ? 'calc(100vw - 130px)' : '' }}
            />
          </NavLink>
        </Box>
        {!onlyXs && !!user && (
          <>
            <NavButtonsContainer ml="10px">
              <NavLink to={getPath({ route: Route.workspace })}>
                {({ isActive }: { isActive: boolean }) => (
                  <Button aria-label={isActive ? 'active' : ''} variant="text">
                    {t('global:routes.workspace')}
                  </Button>
                )}
              </NavLink>
            </NavButtonsContainer>
            <NavButtonsContainer ml="10px">
              <NavLink to={getPath({ route: Route.account })}>
                {({ isActive }: { isActive: boolean }) => (
                  <Button aria-label={isActive ? 'active' : ''} variant="text">
                    {t('global:routes.account')}
                  </Button>
                )}
              </NavLink>
            </NavButtonsContainer>
            <NavButtonsContainer
              ml="10px"
              onClick={() => {
                onMenuClose()
                return sessionService.logout()
              }}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="18px"
                width="18px"
                mr="7px">
                <Button>
                  <LogoutIcon />
                </Button>
              </Box>
            </NavButtonsContainer>
          </>
        )}
        {onlyXs && !!user && (
          <IconButton
            onClick={(evt: React.MouseEvent<HTMLElement>) => setAnchorEl(evt.currentTarget)}>
            <MenuIcon />
          </IconButton>
        )}
        {!user && (
          <LinkButton onClick={() => goTo({ route: isLoggin ? Route.signup : Route.login })}>
            {isLoggin ? t('global:routes.signup') : t('global:routes.login')}
          </LinkButton>
        )}
        <LayoutLangs />
      </Toolbar>
      <MenuComponent>
        {onlyXs && (
          <MenuItem
            onClick={() => {
              onMenuClose()
              goTo({ route: Route.workspace })
            }}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              height="18px"
              width="18px"
              mr="7px">
              <ApartmentIcon />
            </Box>
            {t('global:routes.workspace')}
          </MenuItem>
        )}

        <MenuItem
          onClick={() => {
            onMenuClose()
            goTo({ route: Route.account })
          }}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="18px"
            width="18px"
            mr="7px">
            <AccountBoxIcon />
          </Box>
          {t('global:routes.account')}
        </MenuItem>

        <MenuItem
          aria-label="secondary"
          onClick={() => {
            onMenuClose()
            return sessionService.logout()
          }}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="18px"
            width="18px"
            mr="7px">
            <LogoutIcon />
          </Box>
          {t('sessions:actions.logout')}
        </MenuItem>
      </MenuComponent>
    </AppBar>
  )
}
export default AppHeaderComponent
