import { PlanLocatedMaterial } from '../../../models/materials.models'
import Constants from '../../../constants'

const LocatedMaterial = ({
  size = Constants.ui.pixelLocatedMaterial,
  isSelected,
  isTmp,
  _id,
  position,
  angle,
  primaryCategory,
  isMaterialSelected,
}: PlanLocatedMaterial & { size: number; angle: number }): JSX.Element => {
  const seleted = isTmp || isSelected
  if (primaryCategory) {
    const img = Constants.getCategoryIcon(primaryCategory)
    if (img) {
      return (
        <g>
          {(isMaterialSelected || seleted) && (
            <circle
              id={`locatedMaterial.${_id}`}
              cx={position[0]}
              cy={position[1]}
              r={size / 2}
              fill={'transparent'}
              stroke={Constants.colors.primary}
              strokeWidth={seleted ? 4 : 1}
            />
          )}
          <image
            transform={`rotate(${angle} ${position[0]} ${position[1]})`}
            id={`locatedMaterial.${_id}`}
            xlinkHref={img}
            x={position[0] - size / 2}
            y={position[1] - size / 2}
            height={size}
            width={size}
          />
        </g>
      )
    }
  }
  return (
    <circle
      id={`locatedMaterial.${_id}`}
      cx={position[0]}
      cy={position[1]}
      r={size / 2}
      fill={seleted ? Constants.colors.primary : ''}
    />
  )
}
export default LocatedMaterial
