import { Mode, News } from './commons.models'

import news1 from '../news/news-1.json'
import news2 from '../news/news-2.json'
import news3 from '../news/news-3.json'
import news4 from '../news/news-4.json'
import news5 from '../news/news-5.json'
import news6 from '../news/news-6.json'
import news7 from '../news/news-7.json'
import { CatalogIcon } from './catalogs.models'

export enum PlatformReference {
  rae = 1,
  tm = 2,
  mip = 3,
  batirecup = 4,
}

export type Platform = {
  name: string
  // mode
  mode: Mode.storeFront | Mode.front
  // reference of the platform
  reference: PlatformReference
  // used in header and certificate and admin header menu
  logo: string
  // override logo in certificate
  certificateLogo?: string
  // used in footer
  footerLogo: string
  // used in footer (default is 250px)
  footerLogoWidth?: string
  // second logo to display on footer
  footerCenterLogo?: string
  // display footer in black & some detail home page
  blackFooter?: boolean
  // used in store front header menu
  transparentLogoFilter?: string
  // used in admin header menu
  adminLogoFilter?: string
  //user on order if platform of user or catalog are != from current
  icon: string
  // enable create user certificate
  certificateEnable: boolean
  // delay to show isNew Chip
  isNewMaterialDelay: number
  isNewCatalogDelay: number
  // show 'made by rae' in footer
  madeByRaedificare?: boolean
  // hide contact us in footer
  disableContactUs?: boolean
  // news in homepage
  news?: News[]
  // whether object are auto validated (hide status and search by status if they are)
  autoValidations: {
    user: boolean
    resources: boolean
    needs: boolean
  }
  // Construction catalog and need materials are visible
  // => visible checkbox for construciton project & tab on public catalog page
  // => visible checkbox (modalForm & modalformMany) for needs materials (and filters isVisible) & tab on public material page
  publicNeeds: boolean
  // add catalogIcon input
  catalogIcon?: CatalogIcon
}

const MIP: Platform = {
  name: 'Made in past',
  mode: Mode.storeFront,
  reference: PlatformReference.mip,
  logo: '/mip/logo.png',
  footerLogo: '/mip/logo.png',
  icon: '/mip/logo192.png',
  adminLogoFilter: 'brightness(200%)',
  transparentLogoFilter: 'brightness(180%)',
  certificateEnable: false,
  isNewMaterialDelay: 7,
  isNewCatalogDelay: 30,
  disableContactUs: true,
  autoValidations: {
    user: true,
    resources: true,
    needs: true,
  },
  publicNeeds: false,
}

const BATIRECUP: Platform = {
  name: 'Bati récup',
  mode: Mode.storeFront,
  reference: PlatformReference.batirecup,
  logo: '/batirecup/logo.png',
  footerLogo: '/batirecup/logo.png',
  footerLogoWidth: '100px',
  icon: '/batirecup/logo192.png',
  certificateEnable: false,
  isNewMaterialDelay: 7,
  isNewCatalogDelay: 30,
  disableContactUs: true,
  autoValidations: {
    user: true,
    resources: true,
    needs: true,
  },
  publicNeeds: false,
}
const TM: Platform = {
  name: 'LIFE WASTE2BUILD',
  mode: Mode.front,
  reference: PlatformReference.tm,
  logo: '/tm/logo.svg',
  footerCenterLogo: '/tm/footerCenterImg.png',
  certificateLogo: '/tm/footerCenterImg.png',
  footerLogo: '/tm/footerLogo.svg',
  blackFooter: true,
  icon: '/tm/logo.svg',
  certificateEnable: true,
  isNewMaterialDelay: 7,
  isNewCatalogDelay: 30,
  madeByRaedificare: true,
  news: [news6, news5, news4, news3, news7, news1, news2] as News[],
  autoValidations: {
    user: false,
    resources: false,
    needs: false,
  },
  publicNeeds: true,
  catalogIcon: CatalogIcon.lifeWasteToBuild,
}
const RAE: Platform = {
  name: 'Raedificare',
  mode: Mode.front,
  reference: PlatformReference.rae,
  logo: '/logo.png',
  footerLogo: '/logo.png',
  icon: '/logo192.png',
  certificateEnable: true,
  isNewMaterialDelay: 7,
  isNewCatalogDelay: 30,
  autoValidations: {
    user: false,
    resources: false,
    needs: true,
  },
  publicNeeds: false,
}

export const getPlatform = (platform: PlatformReference): Platform => {
  switch (platform) {
    case PlatformReference.mip:
      return MIP
    case PlatformReference.tm:
      return TM
    case PlatformReference.rae:
      return RAE
    case PlatformReference.batirecup:
      return BATIRECUP
    default:
      const exhaustiveCheck: never = platform
      throw new Error(`platform ${exhaustiveCheck} is ot handle`)
  }
}
