import { useMemo, forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/material/styles/index.js'
import { useMediaQuery } from '@mui/material'
import { ColumnItem } from '../../models/props.models'
import List, { ListRef, ListProps, ListModeType, ListMode } from '../common/List.common'
import MaterialModelCard from './Card.materialModel'
import { traduceCategory } from '../../models/categories.models'
import { Traduction } from '../../models/commons.models'
import { LangEnum } from '../../constants/langs'
import { sessionQuery } from '../../store/session'

export interface MaterialModelListProps extends Omit<ListProps, 'modes'> {}
const MaterialModelList = forwardRef<ListRef, MaterialModelListProps>((props, ref) => {
  const theme = useTheme()
  const onlyXs = useMediaQuery(theme.breakpoints.only('xs'))

  const { t } = useTranslation()
  const columns = useMemo<ColumnItem[]>(
    () => [
      {
        label: t('materialModels:attributes.name'),
        key: 'name',
        formatValue: (name: Traduction) =>
          name[sessionQuery.getUserLang() || LangEnum.FR] || name[LangEnum.FR],
      },
      {
        label: t('materialModels:attributes.primaryCategory'),
        key: 'primaryCategory',
        formatValue: (category: number) => traduceCategory('rae', category),
      },
      {
        label: t('materialModels:attributes.secondaryCategory'),
        key: 'secondaryCategory',
        formatValue: (category: number) => traduceCategory('rae', category),
      },
      {
        label: t('materialModels:attributes.tertiaryCategory'),
        key: 'tertiaryCategory',
        formatValue: (category: number) => traduceCategory('rae', category),
      },
    ],
    [t],
  )

  return (
    <List
      ref={ref}
      {...props}
      modes={[
        {
          key: onlyXs ? 'materialModelCard' : 'materialModelTable',
          type: onlyXs ? ListModeType.card : ListModeType.table,
          columns: onlyXs ? undefined : columns,
          card: onlyXs ? MaterialModelCard : undefined,
        } as ListMode,
      ]}
    />
  )
})
export default MaterialModelList
