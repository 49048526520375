import { carbonModelsStore } from './carbonModels.store'
import { select } from '@ngneat/elf'
import { selectIsRequestPending } from '@ngneat/elf-requests'
import { traduceCarbonModel } from '../../models/carbonModels.models'
import { sessionQuery } from '../session'

export class CarbonModelsQuery {
  store = carbonModelsStore

  carbonModels = this.store.pipe(
    select((state) =>
      state.list.map((materialModel) =>
        traduceCarbonModel(materialModel, sessionQuery.getUserLang()),
      ),
    ),
  )
  loading = this.store.pipe(selectIsRequestPending('init'))
}

export const carbonModelsQuery = new CarbonModelsQuery()
