import { useMemo, forwardRef } from 'react'
import { useTranslation } from 'react-i18next'

import { ColumnItem } from '../../models/props.models'
import { LegalForm } from '../../models/organizations.models'
import List, { ListRef, ListProps, ListModeType } from '../common/List.common'
import OrganizationCard from './Card.organizations'

export interface OrganizationListProps extends Omit<ListProps, 'modes'> {}

const OrganizationList = forwardRef<ListRef, OrganizationListProps>((props, ref) => {
  const { t } = useTranslation()

  const columns = useMemo<ColumnItem[]>(
    () => [
      {
        label: t('organizations:attributes.name'),
        key: 'name',
      },
      {
        label: t('organizations:attributes.legalForm'),
        key: 'legalForm',
        formatValue: (form: LegalForm) => (form ? t(`organizations:legalForms.${form}`) : ''),
      },
      { label: t('organizations:attributes.siret'), key: 'siret' },
      {
        label: t('organizations:attributes.city'),
        key: 'location.city',
      },
      {
        label: t('organizations:attributes.createdAt'),
        key: 'createdAt',
        formatValue: (createdAt: string) =>
          createdAt
            ? t('global:format.date', {
                date: new Date(createdAt),
              })
            : '-',
      },
    ],
    [t],
  )

  return (
    <List
      ref={ref}
      {...props}
      modes={[
        { key: 'organizationTable', type: ListModeType.table, columns },
        { key: 'organizationCard', type: ListModeType.card, card: OrganizationCard },
      ]}
    />
  )
})
export default OrganizationList
