import { useCallback, useState } from 'react'

const useThrowError = () => {
  // eslint-disable-next-line
  const [state, setState] = useState<boolean>(false)
  const throwError = useCallback((error: any) => {
    setState(() => {
      throw error
    })
  }, [])
  return throwError
}
export default useThrowError
