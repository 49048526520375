import { useState, useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { User, Profession, NotificationFrequency } from '../../models/users.models'
import { ItemType, FormItem } from '../../models/props.models'
import { Mode } from '../../models/commons.models'
import { createOptionsFromEnum } from '../../utils/i18n.utils'
import { YupUtils } from '../../utils/yup.utils'
import ModalForm, { ModalFormProps } from '../common/ModalForm.common'
import { sessionService } from '../../store/session'
import constants from '../../constants'
interface ModalFormUserProps
  extends Omit<ModalFormProps, 'value' | 'items' | 'steps' | 'setValue'> {
  user?: User
  admin?: boolean
}
const ModalFormUser: React.FC<ModalFormUserProps> = (props) => {
  const { t } = useTranslation()
  const { admin, user, ...formProps } = props
  const [value, setValue] = useState<User>(user ?? ({} as User))
  const [phoneNumber, setPhoneNumber] = useState<
    { format: string; validation: string } | undefined
  >(undefined)
  const [canUseImperials, setCanUseImperials] = useState<boolean>(false)
  useEffect(() => {
    const getCounryParams = async () => {
      if (constants.mode === Mode.admin && user?.organization?.location?.countryCode) {
        const country = await sessionService.getCountry(user?.organization?.location?.countryCode)
        setPhoneNumber(country.phoneNumber)
        setCanUseImperials(!!country.canUseImperials)
      } else {
        setPhoneNumber(
          sessionService.getCountryParam('phoneNumber') as
            | { format: string; validation: string }
            | undefined,
        )
        setCanUseImperials(!!sessionService.getCountryParam('canUseImperials'))
      }
    }
    getCounryParams()
  }, [user?.organization?.location?.countryCode])

  const items: FormItem[] = useMemo(
    () => [
      {
        type: ItemType.text,
        key: 'lastname',
        required: true,
        props: {
          label: t('users:attributes.lastname'),
          placeholder: t('users:attributes.lastname'),
          disabled: !admin,
          autocomplete: 'family-name',
        },
      },
      {
        type: ItemType.text,
        key: 'firstname',
        required: true,
        props: {
          label: t('users:attributes.firstname'),
          placeholder: t('users:attributes.firstname'),
          disabled: !admin,
          autocomplete: 'given-name',
        },
      },
      {
        type: ItemType.text,
        key: 'email',
        required: true,
        rules: [YupUtils.FieldValidationType.email],
        props: {
          label: t('users:attributes.email'),
          placeholder: t('users:attributes.email'),
          type: 'email',
          disabled: !admin,
          autocomplete: 'email',
        },
      },
      {
        type: ItemType.text,
        key: 'phoneNumber',
        rules: [
          phoneNumber
            ? { rule: YupUtils.FieldValidationType.regexp, params: phoneNumber.validation }
            : () => '',
        ],
        props: {
          pattern: phoneNumber?.format,
          label: t('users:attributes.phoneNumber'),
          placeholder: t('users:attributes.phoneNumberFormat'),
          autocomplete: 'tel',
        },
      },
      {
        type: ItemType.text,
        key: 'address',
        props: {
          label: t('users:attributes.address'),
          placeholder: t('users:attributes.address'),
        },
      },
      {
        type: ItemType.radio,
        key: 'professions',
        required: true,
        props: {
          label: t('users:attributes.professions'),
          items: createOptionsFromEnum(Profession, 'users:professions'),
          multiple: true,
          disabled: !admin,
          split: true,
        },
      },
      {
        type: ItemType.checkbox,
        key: 'useImperials',
        hideItem: !canUseImperials,
        props: {
          label: t('users:attributes.useImperials'),
        },
      },
      {
        type: ItemType.checkbox,
        key: 'receiveNewsEmails',
        props: {
          label: t('users:attributes.receiveNewsEmails'),
        },
      },
      {
        type: ItemType.checkbox,
        key: 'receiveMatchingEmails',
        props: {
          label: t('users:attributes.receiveMatchingEmails'),
        },
      },
      {
        type: ItemType.radio,
        key: 'notificationFrequency',
        props: {
          label: t('users:attributes.notificationFrequency'),
          items: createOptionsFromEnum(NotificationFrequency, 'users:notificationFrequency'),
        },
      },
    ],
    [t, admin, phoneNumber, canUseImperials],
  )

  return <ModalForm {...formProps} value={value} setValue={setValue} items={items} maxWidth="sm" />
}
export default ModalFormUser
