import { AxiosResponse } from 'axios'
import { axiosApi, defaultItemPerPage } from './base.api'
import { CarbonModelsPagination, CarbonModel } from '../models/carbonModels.models'
import { PaginationParams } from '../models/commons.models'

import { LangEnum } from '../constants/langs'

export namespace CarbonModelsApi {
  export interface GetListParams extends PaginationParams {
    search?: string
    lang?: LangEnum
  }
  export const getList = async (params?: GetListParams): Promise<CarbonModelsPagination> => {
    const response: AxiosResponse<CarbonModelsPagination> = await axiosApi.get(`/carbon-models`, {
      params: {
        disablePaginate: false,
        itemsPerPage: defaultItemPerPage,
        page: 0,
        ...params,
      },
    })
    return response.data
  }
  export const create = async (createdCarbonModel: CarbonModel): Promise<CarbonModel> => {
    const response: AxiosResponse<CarbonModel> = await axiosApi.post(
      `/carbon-models`,
      createdCarbonModel,
    )
    return response.data
  }
  export const getById = async (carbonModelId: string): Promise<CarbonModel> => {
    const response: AxiosResponse<CarbonModel> = await axiosApi.get(
      `/carbon-models/${carbonModelId}`,
    )
    return response.data
  }
  export const update = async (
    carbonModelId: string,
    updatedCarbonModel: Partial<CarbonModel>,
  ): Promise<CarbonModel> => {
    const response: AxiosResponse<CarbonModel> = await axiosApi.patch(
      `/carbon-models/${carbonModelId}`,
      updatedCarbonModel,
    )
    return response.data
  }
  export const deleteById = async (carbonModelId: string): Promise<void> => {
    await axiosApi.delete(`/carbon-models/${carbonModelId}`)
  }
}
