import { Checkbox, Box, Card, styled, useMediaQuery, Chip as ClassicChip } from '@mui/material'
import { useTheme } from '@mui/material/styles/index.js'

import Stack from './Stack.common'
import Chip from './Chip.common'
import Actions from './Actions.common'
import ImageWithBackground from './ImageWithBackground.common'
import { Actions as ActionsInterface } from '../../models/props.models'

const ImageContainer = styled(Box)({
  height: '200px',
  position: 'relative',
  width: '100%',
})
const StyledCard = styled(Card)({
  '&:hover #clickableHover': {
    opacity: 0.05,
  },
})

const ClickableHover = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  pointerEvents: 'none',
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  borderRadius: 'inherit',
  opacity: 0,
  zIndex: 1,
  backgroundColor: theme.palette.black,

  transition: 'opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,',
}))

interface CardProps {
  children?: JSX.Element | JSX.Element[]
  ariaLabel?: string
  image?: string
  imageChildren?: JSX.Element
  imagePlaceholder?: JSX.Element
  chips?: { label: string; color: any; classic?: boolean }[]
  onClick?: () => void
  onSelect?: () => void
  actions?: ActionsInterface
  selected?: boolean
}

const CardComponent = (props: CardProps): JSX.Element => {
  const theme = useTheme()
  const onlyXs = useMediaQuery(theme.breakpoints.only('xs'))

  const {
    ariaLabel,
    image,
    imageChildren,
    imagePlaceholder,
    children,
    actions,
    onSelect,
    onClick,
    selected,
    chips,
  } = props

  return (
    <Box>
      <StyledCard
        aria-label={ariaLabel}
        sx={onlyXs ? { width: '100% !important', position: 'relative' } : { position: 'relative' }}
        onClick={() => onClick?.()}>
        {onClick && <ClickableHover id="clickableHover" />}
        <ImageContainer className="card-image">
          {image && <ImageWithBackground image={image} />}
          {!image && imagePlaceholder}

          {chips && chips.length > 0 && (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              position="absolute"
              left="20px"
              top="13px"
              right="13px">
              {chips.map((chip, chipIndex) => {
                const { classic, ...chipProps } = chip
                return !chip.classic ? (
                  <Chip key={chipIndex} {...chipProps} />
                ) : (
                  <ClassicChip
                    key={chipIndex}
                    {...chipProps}
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      fontSize: '0.875rem',
                      fontWeight: 500,
                      height: '30px',
                    }}
                  />
                )
              })}
            </Stack>
          )}
          {imageChildren}
        </ImageContainer>
        {children && (
          <Box p="14px 20px" width="100%">
            {children}
          </Box>
        )}
        {onSelect && (
          <Box position="absolute" left="14px" top="14px">
            <Checkbox
              color="primary"
              checked={selected}
              onClick={(evt) => evt.stopPropagation()}
              onChange={onSelect}
            />
          </Box>
        )}
        {actions && (
          <Box position="absolute" right="6px" top="6px">
            <Actions action={actions} />
          </Box>
        )}
      </StyledCard>
    </Box>
  )
}

export default CardComponent
