import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { useTranslation } from 'react-i18next'
import { Grid, Button, Box } from '@mui/material'

import useSnackbar from '../../hooks/useSnackbar.hooks'
import useRoute from '../../hooks/useRoute.hooks'
import useModal from '../../hooks/useModal.hooks'
import useCheckRoute from '../../hooks/useCheckRoute.hooks'

import { usersService } from '../../store/users'
import { sessionService } from '../../store/session'
import { organizationsService } from '../../store/organizations'
import { requestsService } from '../../store/requests'

import { Route, Mode } from '../../models/commons.models'
import { ItemType } from '../../models/props.models'
import { Request, RequestStatus } from '../../models/requests.models'
import { getPlatform } from '../../models/platforms.models'

import { User, UserStatus } from '../../models/users.models'

import CertificateTemplate from '../../components/template/Certificate.template'
import LinkButton from '../../components/common/button/Link.button'
import LoaderOverlay from '../../components/layout/LoaderOverlay.layout'
import ModalFormUser from '../../components/user/ModalForm.user'
import ModalFormOrganization from '../../components/organization/ModalForm.organization'
import Double from '../../components/layout/Double.layout'
import InformationUser from '../../components/user/Informations.user'
import InformationOrganization from '../../components/organization/Informations.organizations'
import Modal from '../../components/layout/Modal.layout'
import { AdminHeaderTitle } from '../../components/layout/AdminHeader.layout'
import ModalDeny from '../../components/request/ModalDeny.request'

const UserDetailsPage = () => {
  useCheckRoute('UserDetails', [Mode.admin])

  const { userId } = useParams()
  const show = useSnackbar()
  const { t } = useTranslation()
  const { goTo } = useRoute()
  const [user, setUser] = useState<User>()
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useModal<'edit' | 'delete' | 'editOrganization' | 'deny'>()
  const [request, setRequest] = useState<Request>()

  const showStatus = useMemo(() => {
    return sessionService.showStatus({ type: 'user' })
  }, [])

  useEffect(() => {
    const getUser = async () => {
      try {
        const user = await usersService.getUserById(userId as string)
        setUser(user)
        if (user.status === UserStatus.pending) {
          try {
            const { data } = await requestsService.getRequests({
              status: [RequestStatus.pending],
              user: userId as string,
            })
            if (data.length === 1) {
              setRequest(data[0])
            }
          } catch (err: any) {}
        }
      } catch (err: any) {
        show(err)
        goTo({ route: Route.users })
      }
    }
    getUser()
  }, [userId, show, goTo])

  const updateStatus = async (status: UserStatus, deniedFor?: string) => {
    setLoading(true)
    try {
      await usersService.updateUser(user?._id as string, { status, deniedFor })
      setUser((user) => ({ ...user, status } as User))
      if (status === UserStatus.denied) {
        show(t('users:actions.deny.success'))
      } else {
        show(t('users:actions.confirm.success'))
      }
    } catch (err: any) {
      show(err)
    }
    setLoading(false)
  }

  if (!user) return <LoaderOverlay />
  const certificateEnable = getPlatform(user.organization.platform).certificateEnable

  const handleChangePassword = async () => {
    setLoading(true)
    try {
      await sessionService.forgotPassword(user.email)
      show(t('users:pages.details.forgotPasswordSuccess'))
    } catch (err: any) {
      show(err)
    }
    setLoading(false)
  }

  return (
    <Box>
      {loading && <LoaderOverlay />}
      <AdminHeaderTitle title={`${user.firstname} ${user.lastname}`} canGoBack />

      {user.status === UserStatus.pending && (
        <Box>
          <Grid container spacing={3} justifyContent="center" pt="15px">
            <Grid item>
              <Button
                variant="contained"
                aria-label="error"
                disabled={loading}
                fullWidth
                onClick={() => setModal('deny')}>
                {t('users:actions.deny.label')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                aria-label="success"
                disabled={loading}
                fullWidth
                onClick={updateStatus.bind(null, UserStatus.accepted, undefined)}>
                {t('users:actions.confirm.label')}
              </Button>
            </Grid>
          </Grid>
          {request && (
            <Box sx={{ width: '100%', textAlign: 'center' }}>
              {t('users:pages.details.pendingRequest', { organization: user.organization.name })}
            </Box>
          )}
        </Box>
      )}
      <Double
        first={
          <InformationUser
            title={t('users:pages.details.user')}
            actions={{ onClick: () => setModal('edit') }}
            user={user}
            additionals={[
              {
                label: t('users:attributes.createdAt'),
                key: 'createdAt',
                formatValue: (createdAt: string) =>
                  createdAt
                    ? t('global:format.date', {
                        date: new Date(createdAt),
                      })
                    : '-',
              },
              ...(showStatus
                ? [
                    {
                      label: t('users:attributes.status'),
                      key: 'status',
                      formatValue: (status: UserStatus) => t(`users:status.${status}`),
                    },
                  ]
                : []),
              ...(user.status === UserStatus.accepted
                ? [
                    {
                      label: t('users:attributes.password'),
                      type: ItemType.link,
                      key: 'password',
                      props: {
                        disabled: loading,
                        children: t('users:pages.details.changePassword'),
                        onClick: handleChangePassword,
                      },
                    },
                    ...(certificateEnable
                      ? [
                          {
                            label: t('users:pages.details.certificate'),
                            type: ItemType.custom,
                            key: 'certificate',
                            custom: (
                              <PDFDownloadLink
                                document={<CertificateTemplate user={user} />}
                                fileName="attestation-visite-ressources.pdf">
                                {/* @ts-expect-error */}
                                {({ loading: pdfLoading }: { loading: boolean }) => (
                                  <>
                                    {pdfLoading && <LoaderOverlay />}
                                    <LinkButton
                                      color="primary"
                                      variant="text"
                                      disabled={pdfLoading}>
                                      {t('users:pages.details.generateCertificate')}
                                    </LinkButton>
                                  </>
                                )}
                              </PDFDownloadLink>
                            ),
                          },
                        ]
                      : []),
                  ]
                : []),
              {
                type: ItemType.button,
                key: 'deleteAccount',
                props: {
                  children: t('global:actions.delete'),
                  onClick: () => setModal('delete'),
                },
              },
            ]}
          />
        }
        second={
          <InformationOrganization
            title={t('users:pages.details.organization')}
            organization={user.organization}
            actions={{ onClick: () => setModal('editOrganization') }}
            onClick={() =>
              goTo({ route: Route.organization, organizationId: user.organization._id })
            }
            additionals={[
              {
                label: t('organizations:attributes.createdAt'),
                key: 'createdAt',
                formatValue: (createdAt: string) =>
                  createdAt
                    ? t('global:format.date', {
                        date: new Date(createdAt),
                      })
                    : '-',
              },
            ]}
          />
        }
      />
      {modal === 'deny' && (
        <ModalDeny
          description={t('users:actions.deny.description')}
          onClose={() => setModal('')}
          onSubmit={({ deniedFor }) => updateStatus.bind(null, UserStatus.denied, deniedFor)()}
        />
      )}
      {modal === 'delete' && (
        <Modal
          onClose={() => setModal('')}
          onConfirm={usersService.deleteUser.bind(null, user._id)}
          keepOpen
          onSuccess={() => {
            show(t('users:actions.delete.success'))
            goTo({ route: Route.users })
          }}
          title={t('users:actions.delete.label')}
          description={t('users:actions.delete.description')}
        />
      )}
      {modal === 'edit' && (
        <ModalFormUser
          user={user}
          admin
          title={t('users:actions.update.label')}
          onSubmit={usersService.updateUser.bind(null, user._id)}
          onClose={() => setModal('')}
          onSuccess={(updatedUser) => {
            setUser((user) => ({ ...user, ...updatedUser }))
            show(t('users:actions.update.success'))
          }}
        />
      )}
      {modal === 'editOrganization' && (
        <ModalFormOrganization
          organization={user.organization}
          title={t('organizations:actions.update.label')}
          onSubmit={organizationsService.updateOrganization.bind(null, user.organization._id)}
          onClose={() => setModal('')}
          onSuccess={(updatedOrganization) => {
            setUser(
              (user) =>
                ({
                  ...user,
                  organization: {
                    ...user?.organization,
                    ...updatedOrganization,
                  },
                } as User),
            )
            show(t('organizations:actions.update.success'))
          }}
        />
      )}
    </Box>
  )
}
export default UserDetailsPage
