import { useSnackbar, MaterialDesignContent } from 'notistack'
import { styled, IconButton } from '@mui/material'
import { Close as IconClose } from '@mui/icons-material'

interface SnackbarCloseProps {
  snackbarKey: any
}
export const SnackbarClose: React.FC<SnackbarCloseProps> = ({ snackbarKey }): JSX.Element => {
  const { closeSnackbar } = useSnackbar()

  return (
    <IconButton color="inherit" onClick={() => closeSnackbar(snackbarKey)}>
      <IconClose />
    </IconButton>
  )
}

const Snackbar = styled(MaterialDesignContent)(() => ({
  fontSize: '0.875rem',
  fontWeight: 500,
  whiteSpace: 'pre-line',
  '& > div:first-of-type > .MuiSvgIcon-root:first-of-type': {
    marginRight: '10px',
  },
}))
export default Snackbar
