import { useTranslation } from 'react-i18next'
import { Order, Product } from '../../models/orders.models'
import Modal from '../layout/Modal.layout'
import { useCallback, useEffect, useState } from 'react'
import { ManageMaterial, Material, MaterialType, TermsOfSale } from '../../models/materials.models'
import { materialsService } from '../../store/materials'
import constants from '../../constants'
import { Mode } from '../../models/commons.models'
import LoaderOverlay from '../layout/LoaderOverlay.layout'
import MaterialList from '../material/List.material'
import ModalFormMaterial from '../material/ModalForm.material'
import useOwnSnackbar from '../../hooks/useSnackbar.hooks'
import { CatalogLight } from '../../models/catalogs.models'
import { useObservable } from '@ngneat/react-rxjs'
import { carbonModelsQuery } from '../../store/carbonModels'
import { Box } from '@mui/material'

interface ModalFinishProps {
  order: Order
  onClose: () => void
  onSuccess: () => void
  useImperials: boolean
}
const ModalFixPrices: React.FC<ModalFinishProps> = (props) => {
  const { order, onClose, onSuccess, useImperials } = props
  const { t } = useTranslation()
  const show = useOwnSnackbar()

  const [modal, setModal] = useState<string>('')
  const [defaultValue, setDefaultValue] = useState<Partial<ManageMaterial> | undefined>(undefined)
  const [openedCatalog, setOpenedCatalog] = useState<CatalogLight | undefined>(undefined)
  const [carbonModels] = useObservable(carbonModelsQuery.carbonModels)

  const [loading, setLoading] = useState<boolean>(true)
  const [materials, setMaterials] = useState<Material[]>([])
  const getMaterials = useCallback(async () => {
    setLoading(true)
    try {
      const { data } = await materialsService.getMaterials({
        owned: constants.mode !== Mode.admin,
        type: MaterialType.resource,
        catalog: order.catalog._id,
      })
      const priceToSet = data.filter(
        (material: Material) =>
          material.termsOfSale === TermsOfSale.notDefined &&
          order.products.find((product: Product) => product.material._id === material._id),
      )
      if (priceToSet.length === 0) {
        onSuccess()
        onClose()
      } else {
        setMaterials(priceToSet)
      }
    } catch (err) {}
    setLoading(false)
  }, [order, onSuccess, onClose])
  const getValues = useCallback(
    () => ({ data: materials, count: materials.length, total: materials.length }),
    [materials],
  )
  useEffect(() => {
    getMaterials()
  }, [getMaterials])
  useEffect(() => {
    if (modal && modal !== defaultValue?._id) {
      const material = materials?.find((material) => modal === material._id)
      if (material) {
        setOpenedCatalog(material.catalog)
        const carbonModel = carbonModels.find(
          (carbonModel) => carbonModel._id === material!.carbonModel,
        )

        setDefaultValue({
          ...material,
          carbonModel: carbonModel
            ? { label: carbonModel.name, value: carbonModel._id }
            : undefined,
          catalog: material.catalog?._id,
        })
      }
    } else if (!modal && defaultValue) {
      setDefaultValue(undefined)
    }
  }, [defaultValue, modal, carbonModels, materials])

  if (loading) {
    return <LoaderOverlay />
  }

  // get material, on update material reget materials
  return (
    <Box>
      <Modal onClose={onClose} title={t('orders:actions.fixPrices.label')} hideAction maxWidth="xl">
        <MaterialList
          type={MaterialType.resource}
          isAdmin={constants.mode === Mode.admin}
          showCerfa={false}
          useImperials={useImperials}
          getValues={getValues}
          onValueClick={(material: Material) => {
            setModal(material._id)
          }}
        />
      </Modal>

      {defaultValue && (
        <ModalFormMaterial
          showCerfa={false}
          material={defaultValue}
          useImperials={useImperials}
          onClose={() => setModal('')}
          onSubmit={materialsService.updateMaterial.bind(null, {
            ...defaultValue,
            catalog: openedCatalog,
          } as Material)}
          submitLabel={t('global:actions.save')}
          title={t(`materials:actions.updateresource.label`)}
          onSuccess={() => {
            getMaterials()
            show(t(`materials:actions.updateresource.success`))
          }}
        />
      )}
    </Box>
  )
}
export default ModalFixPrices
