import { NetworkUtils } from '../../utils/networks.utils'
import dayjs from '../../utils/dayjs.utils'
import { OrdersApi } from '../../api/orders.api'
import { FilesApi } from '../../api/files.api'
import { sessionQuery, sessionService } from '../session'
import {
  Order,
  OrdersPagination,
  OrderStatus,
  ApiProduct,
  ApiUpdateOrder,
} from '../../models/orders.models'
import { Mode } from '../../models/commons.models'
import { FileDetails } from '../../models/files.models'
import { materialsService } from '../materials'
import { FileUtils } from '../../utils/files.utils'
import constants from '../../constants'
import { catalogsService } from '../catalogs'
import { logIfDev } from '../../utils/commons.utils'

export class OrdersService {
  async getOrders(filters: OrdersApi.GetListParams): Promise<OrdersPagination> {
    await NetworkUtils.checkConnected()
    return await OrdersApi.getList({
      ...filters,
      ...(constants.mode === Mode.admin && { platform: sessionService.getAdminPlatform() }),
    })
  }

  async getOrdersCsv(filters: OrdersApi.GetListParams): Promise<void> {
    await NetworkUtils.checkConnected()
    const data = await OrdersApi.getCsv({
      ...filters,
      ...(constants.mode === Mode.admin && { platform: sessionService.getAdminPlatform() }),
    })
    await FileUtils.downloadFile(
      filters.periodStart
        ? `orders_${dayjs(filters.periodStart).format('DD-MM-YYYY')}.csv`
        : 'orders.csv',
      data,
    )
  }

  getOrderById = async (orderId: string): Promise<Order> => {
    await NetworkUtils.checkConnected()
    return await OrdersApi.getById(orderId)
  }

  getOrderPdf = async (order: Order): Promise<void> => {
    await NetworkUtils.checkConnected()
    const data = await OrdersApi.getPdf(order._id)
    await FileUtils.downloadFile(`bdc-${order.orderNumber}.pdf`, data)
  }

  updateOrder = async (order: Order, update: ApiUpdateOrder): Promise<Order> => {
    await NetworkUtils.checkConnected()

    if (update.removalOrder && typeof update.removalOrder !== 'string') {
      update.removalOrder = (await FilesApi.create(update.removalOrder as File))._id
    }

    let updatedOrder = await OrdersApi.update(order._id, update)

    if (update.status === OrderStatus.accepted) {
      materialsService.acceptOrder(updatedOrder)
    } else if (update.status === OrderStatus.finished) {
      materialsService.finishOrder(updatedOrder)
    }
    try {
      this.updateStats(updatedOrder, order)
    } catch (err) {
      logIfDev(err)
    }
    return updatedOrder
  }

  order = async (products: ApiProduct[]): Promise<Order[]> => {
    await NetworkUtils.checkConnected()
    let userId = sessionQuery.getUserId()
    if (!userId) {
      throw new Error('NOT_CONNECTED')
    }
    const orders = await OrdersApi.create({
      client: userId,
      products,
    })

    try {
      orders.forEach((order) => this.updateStats(order))
    } catch (err) {
      logIfDev(err)
    }
    //TODO PAYMENT if (order?.data?.url) {
    // window.location.replace(checkoutSession.data.url)
    //}
    return orders
  }

  downloadRemovalOrder = async (file: FileDetails): Promise<void> => {
    await NetworkUtils.checkConnected()
    const response = await fetch(file.path)
    const data = await response.blob()
    await FileUtils.downloadFile(file.path.split(/\\|\//).pop() as string, data)
  }

  async updateStats(currentOrder: Order, previousOrder?: Order) {
    if (currentOrder.status !== previousOrder?.status) {
      const sellerStatus = [
        OrderStatus.pending,
        OrderStatus.pricePending,
        OrderStatus.clientAccepted,
        OrderStatus.accepted,
      ]
      const clientStatus = [OrderStatus.clientPending]
      const getInc = (statuses: OrderStatus[]) => {
        const currentIs = !!statuses.find((status) => currentOrder.status === status)
        const previousIs = !!statuses.find((status) => previousOrder?.status === status)
        return currentIs && !previousIs ? 1 : !currentIs && !!previousIs ? -1 : 0
      }

      catalogsService.updateStats(currentOrder.catalog._id, {
        ordersW4Client: getInc(clientStatus),
        ordersW4Seller: getInc(sellerStatus),
      })
      sessionService.updateStats({
        ordersW4Client:
          currentOrder.client._id === sessionQuery.getUserId() ? getInc(clientStatus) : 0,
        ordersW4Seller:
          currentOrder.catalog.organization._id === sessionQuery.getOrganizationId()
            ? getInc(sellerStatus)
            : 0,
      })
    }
  }
}

export const ordersService = new OrdersService()
