import { memo, ReactElement } from 'react'
import { Box, Button, FormHelperText, Typography } from '@mui/material'
import { ItemType, Item } from '../../../models/props.models'
import { YupUtils } from '../../../utils/yup.utils'
import Radio from '../input/Radio.input'
import TextField from '../input/Text.input'
import NumberTextField from '../input/Number.input'
import Select from '../input/Select.input'
import Address from '../input/Address.input'
import AddressRadius from '../input/AddressRadius.input'
import LinkButton from '../button/Link.button'
import Checkbox from '../input/Checkbox.input'
import Interval from '../input/Interval.input'
import AsyncSelectWithSearch from '../input/AsyncSelect.input'
import File from '../input/File.input'
import Image from '../input/Image.input'
import Documents from '../input/Documents.input'
import DateInput from '../input/Date.input'
import InfoItem from './Info.item'
import GroupItem from './Group.item'
import Search from '../input/Search.input'
import MultiSelect from '../input/MultiSelect.input'
import Array from '../input/Array.input'
import Chip from '../Chip.common'
import Tooltip from '../Tooltip.common'
import DirectoryInput from '../input/Directory.input'
import AsyncMultiSelect from '../input/AsyncMultiSelect.input'
import Autocomplete from '../input/Autocomplete.input'

const TypedItem: React.FC<Omit<Item & { children?: JSX.Element | JSX.Element[] }, 'key'>> = ({
  required,
  error,
  value,
  onChange,
  type,
  formatValue,
  children,
  custom,
  props,
  items,
}) => {
  const getValue = () => {
    const defaultVal =
      type === ItemType.multiSelect ||
      type === ItemType.autocomplete ||
      type === ItemType.array ||
      (type === ItemType.radio && (props as any)?.multiple) ||
      type === ItemType.documents ||
      type === ItemType.asyncMultiSelect
        ? []
        : ''
    return formatValue ? formatValue(value) : value ?? defaultVal
  }
  const itemProps: any = {
    ...(props ?? {}),
    onChange: onChange ?? (props as any)?.onChange,
    required,
    value: getValue(),
    children:
      type === ItemType.link && formatValue?.(value)
        ? formatValue(value)
        : (props as any)?.children,
    error:
      type === ItemType.link || type === ItemType.button || type === ItemType.info
        ? undefined
        : type === ItemType.group && error
        ? (error as Record<string, any>)?.global || YupUtils.checkIfErrors(error)
        : error,
  }

  switch (type) {
    case ItemType.typo:
      return (
        <Box display="flex" alignItems="center">
          {itemProps?.icon && (
            <img alt="" height="24px" src={itemProps?.icon} style={{ marginRight: '5px' }} />
          )}
          <Typography variant="body1" {...itemProps}>
            {getValue()}
            {typeof error === 'string' && <FormHelperText error>{error}</FormHelperText>}
          </Typography>
        </Box>
      )
    case ItemType.multiSelect:
      return <MultiSelect size="large" {...itemProps} />
    case ItemType.autocomplete:
      return <Autocomplete {...itemProps} />
    case ItemType.array:
      return <Array items={items} {...itemProps} />
    case ItemType.search:
      return <Search {...itemProps} />
    case ItemType.checkbox:
      return <Checkbox {...itemProps} />
    case ItemType.date:
      return <DateInput {...itemProps} />
    case ItemType.file:
      return <File {...itemProps} />
    case ItemType.directory:
      return <DirectoryInput {...itemProps} />
    case ItemType.address:
      return <Address {...itemProps} />
    case ItemType.addressRadius:
      return <AddressRadius {...itemProps} />
    case ItemType.select:
      return <Select {...itemProps} />
    case ItemType.text:
      return <TextField {...itemProps} />
    case ItemType.number:
      return <NumberTextField {...itemProps} />
    case ItemType.radio:
      return <Radio {...itemProps} />
    case ItemType.interval:
      return <Interval {...itemProps} />
    case ItemType.asyncSelect:
      return <AsyncSelectWithSearch {...itemProps} />
    case ItemType.asyncMultiSelect:
      return <AsyncMultiSelect {...itemProps} />
    case ItemType.image:
      return (
        <Box width="100%" maxWidth="500px" margin="auto">
          <Image {...itemProps} />
        </Box>
      )
    case ItemType.documents:
      return <Documents {...itemProps} />
    case ItemType.link:
      return <LinkButton {...itemProps} />
    case ItemType.button:
      return <Button color="primary" size="large" variant="contained" {...itemProps} />
    case ItemType.info:
      return <InfoItem {...itemProps} />
    case ItemType.chip:
      return <Chip {...itemProps} />
    case ItemType.custom:
      return (custom as ReactElement) ?? <></>
    case ItemType.group:
      return <GroupItem {...itemProps}>{children}</GroupItem>
    case ItemType.tooltip:
      return <Tooltip {...itemProps} />
  }
}

export default memo(TypedItem)
