import React, { useState } from 'react'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import { alpha, Box, InputLabel, styled, FormHelperText, IconButton } from '@mui/material'
import { Clear as ClearIcon, CalendarMonth as CalendarMonthIcon } from '@mui/icons-material'
import dayjs from '../../../utils/dayjs.utils'
import { DateProps } from '../../../models/props.models'
import { sessionService } from '../../../store/session'

const StyledRedDatePicker = styled(DesktopDatePicker, {
  shouldForwardProp: (prop) => prop !== 'required',
})<{ required?: boolean }>(({ required, theme }) => {
  const base: any = {
    borderRadius: '4px',
    '&[aria-label=large]': {
      height: 50,
      '& .MuiTextField-root': {
        height: 50,
      },
    },
    '&[aria-label=normal]': {
      height: 45,
      '& .MuiTextField-root': {
        height: 45,
      },
    },
    '& .MuiInput-root': {
      height: '100%',
    },
    '& .MuiInputBase-input': {
      padding: '18.5px 14px',
      fontSize: '0.875rem',
      fontWeight: 500,
      '&::placeholder': {
        fontWeight: 400,
        opacity: 0.6,
      },
    },
  }
  if (required) {
    base.backgroundColor = alpha(theme.palette.primary.main, 0.1)
    base['& .MuiSvgIcon-root'] = { color: theme.palette.primary.main }
  }
  return base
})

const DateComponent: React.FC<DateProps> = (props): JSX.Element => {
  const {
    blurredFormat,
    disabled,
    disableFuture,
    disablePast,
    format,
    onChange,
    size = 'normal',
    label,
    placeholder,
    readOnly,
    required,
    error,
    value,
  } = props
  const [open, setOpen] = useState<number>(0)

  const inputRef = React.useRef<HTMLInputElement>(null)
  return (
    <Box display="flex" flexDirection="column" position="relative">
      {!!open && (
        <Box
          onClick={() => setOpen(0)}
          sx={{ position: 'fixed', right: 0, left: 0, top: 0, bottom: 0, zIndex: 1 }}
        />
      )}
      {!!label && <InputLabel error={!!error}>{label + (required ? '*' : '')}</InputLabel>}
      <StyledRedDatePicker
        open={!!open}
        required={required}
        format={format ?? (sessionService.getCountryParam('date') as string)}
        disabled={disabled}
        readOnly={readOnly}
        disablePast={disablePast}
        orientation="portrait"
        disableFuture={disableFuture}
        value={value ? dayjs(value) : null}
        onChange={(date: any) => {
          try {
            onChange?.(date?.toISOString?.() || undefined)
          } catch (err) {}
          if (blurredFormat) {
            setOpen((step) => (step === 1 ? 2 : 0))
          } else {
            setOpen(0)
          }
        }}
        aria-label={size}
        views={blurredFormat ? ['month', 'year'] : undefined}
        inputRef={inputRef}
        slotProps={{ textField: { placeholder } }}
        slots={{
          inputAdornment: () => (
            <Box display="flex">
              {value && (
                <IconButton size="small" onClick={() => onChange?.(null)}>
                  <ClearIcon />
                </IconButton>
              )}
              <IconButton size="small" onClick={() => setOpen(1)}>
                <CalendarMonthIcon />
              </IconButton>
            </Box>
          ),
        }}
      />
      {typeof error === 'string' && (
        <FormHelperText
          error
          sx={{ position: 'absolute', bottom: '-2px', left: '5px', pointerEvents: 'none' }}>
          {error}
        </FormHelperText>
      )}
    </Box>
  )
}
export default DateComponent
