import React from 'react'
import { Box, InputLabel, InputAdornment, FormHelperText } from '@mui/material'
import { Description as DescriptionIcon, FileUpload as FileUploadIcon } from '@mui/icons-material'
import { FileProps } from '../../../models/props.models'
import { FileDetails } from '../../../models/files.models'
import StyledInput from './Styled.input'
import useSnackbar from '../../../hooks/useSnackbar.hooks'
import { useTranslation } from 'react-i18next'
import Tooltip from '../Tooltip.common'
import { Info as InfoIcon } from '@mui/icons-material'

const FileInput: React.FC<FileProps> = (props): JSX.Element => {
  const {
    tooltip,
    maxFileSize,
    accept,
    disabled,
    error,
    onChange,
    value,
    label,
    placeholder,
    required,
  } = props
  const inputLabel =
    (value as FileDetails)?.path?.split(/\\|\//)?.pop() ?? (value as File)?.name ?? ''
  const inputFileRef = React.useRef<HTMLInputElement | null>(null)
  const show = useSnackbar()
  const { t } = useTranslation()

  return (
    <Box display="flex" flexDirection="column" position="relative">
      {!!label && <InputLabel error={!!error}>{label + (required ? '*' : '')}</InputLabel>}
      <input
        type="file"
        accept={accept}
        ref={inputFileRef}
        style={{ display: 'none' }}
        onChange={(e) => {
          const file = e.target.files?.[0]
          if (!file) return

          if (maxFileSize && file.size / (1024 * 1024) > maxFileSize) {
            show(t('errors:fileSize', { size: maxFileSize }), 'warning')
          } else {
            onChange?.(file)
          }

          e.currentTarget.value = ''
        }}
        onClick={(e) => {
          e.stopPropagation()
        }}
      />
      <StyledInput
        aria-label="select-file"
        aria-selected={!!inputLabel}
        variant="outlined"
        fullWidth
        required={required}
        disabled={disabled}
        placeholder={placeholder}
        focused={false}
        value={inputLabel}
        onClick={(e: any) => {
          e.stopPropagation()
        }}
        InputProps={{
          readOnly: true,
          startAdornment: (
            <InputAdornment position="start">
              {!!inputLabel && <DescriptionIcon />}
              {!inputLabel && <FileUploadIcon />}
            </InputAdornment>
          ),
          endAdornment:
            maxFileSize || tooltip ? (
              <InputAdornment position="end">
                {maxFileSize && `(${t('global:inputs.maxSize', { size: maxFileSize })})`}
                {tooltip && (
                  <Box mt="3px">
                    <Tooltip title={tooltip}>
                      <InfoIcon color="primary" />
                    </Tooltip>
                  </Box>
                )}
              </InputAdornment>
            ) : undefined,
        }}
        inputProps={{
          onClick: (e) => {
            inputFileRef.current?.click()
            e.stopPropagation()
          },
        }}
        error={!!error}
      />
      {typeof error === 'string' && (
        <FormHelperText
          error
          sx={{ position: 'absolute', bottom: '-2px', left: '50px', pointerEvents: 'none' }}>
          {error}
        </FormHelperText>
      )}
    </Box>
  )
}
export default FileInput
