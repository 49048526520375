import React, { useState, useMemo } from 'react'
import {
  GoogleMap,
  InfoWindow,
  Marker,
  MarkerClusterer,
  useJsApiLoader,
} from '@react-google-maps/api'
import { alpha, Box, Grid, IconButton, styled } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { ZoomOutMap as ZoomOutMapIcon } from '@mui/icons-material'

import Constants from '../../constants'

import Stack from './Stack.common'
import AddressInput from './input/Address.input'
import { Location, Point } from '../../models/commons.models'
import { sessionService } from '../../store/session'
import { getInitLanguage } from '../../utils/i18n.utils'

const Container = styled(Stack)({
  '& .gm-style-iw': {
    borderRadius: '4px',
    maxHeight: '400px !important',
    maxWidth: 'fit-content !important',
    overflowY: 'auto !important',
    overflowX: 'hidden !important',
    padding: 0,
    width: 'fit-content',
  },
  '& .gm-style-iw-d': {
    overflow: 'visible !important',
  },
  '& .gm-ui-hover-effect': {
    display: 'none !important',
  },
})

const ZoomButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.white,
  borderRadius: 0,
  height: '40px',
  padding: 0,
  width: '40px',
  '&:hover': {
    backgroundColor: alpha(theme.palette.white, 0.8),
  },
}))

interface GoogleMapMarker {
  id?: string
  secondary?: boolean
  lat: number
  lng: number
  onClick?: () => void
  preview?: (inGrid?: boolean) => JSX.Element
}

interface GoogleMapComponentProps {
  zoom?: number
  height?: string
  center?: [number, number]
  canChooseCountry?: boolean
  disableControls?: boolean
  hideAutocomplete?: boolean
  markers?: GoogleMapMarker[]
  onFullScreen?: () => void
  showPreview?: boolean
  value?: Location
  onChange?: (value?: Location) => void
}

// marker won't show in dev because of strict mode (src/index.tsx)
const GoogleMapComponent = React.memo(
  (props: GoogleMapComponentProps) => {
    const {
      height,
      canChooseCountry,
      disableControls,
      hideAutocomplete,
      markers = [],
      onFullScreen,
      showPreview,
      onChange,
      value,
    } = props

    const { t } = useTranslation()

    const libraries: any = useMemo(() => ['places', 'geometry', 'drawing'], [])
    const { isLoaded } = useJsApiLoader({
      id: 'google-map-script',
      googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY as string,
      libraries,
      language: getInitLanguage(),
    })

    const MAX_ZOOM = 15

    const sessionCoodinates = sessionService.getCountryParam('coordinates') as Point
    const [center, setCenter] = useState(
      props.center
        ? { lng: props.center[0], lat: props.center[1] }
        : value?.position
        ? { lng: value?.position[0], lat: value?.position[1] }
        : Constants.ui?.mapCenter
        ? { lng: Constants.ui.mapCenter[0], lat: Constants.ui.mapCenter[1] }
        : { lng: sessionCoodinates[0], lat: sessionCoodinates[1] },
    )
    const [zoom, setZoom] = useState(
      props.zoom ? props.zoom : value?.position ? 11 : Constants.ui.mapZoom,
    )

    const [showMarkerPreview, setShowMarkerPreview] = useState<GoogleMapMarker | false>(false)

    const [showMarkersPreview, setShowMarkersPreview] = useState<
      { markers: GoogleMapMarker[]; cluster: any } | false
    >(false)

    if (!window.google?.maps?.Map || !isLoaded) {
      return <></>
    }

    return (
      <Container height={height || '100%'} width="100%" position="relative">
        <GoogleMap
          mapContainerStyle={{ height: '100%', width: '100%' }}
          center={center}
          onClick={() => {
            setShowMarkerPreview(false)
            setShowMarkersPreview(false)
          }}
          clickableIcons={false}
          options={{
            disableDefaultUI: true,
            gestureHandling: disableControls ? 'none' : undefined,
            keyboardShortcuts: false,
            maxZoom: MAX_ZOOM,
          }}
          zoom={zoom}>
          {showPreview && showMarkerPreview && !!showMarkerPreview.preview && (
            <InfoWindow
              onCloseClick={() => {
                setShowMarkerPreview(false)
                setShowMarkersPreview(false)
              }}
              options={{ pixelOffset: new window.google.maps.Size(0, -40) }}
              position={{
                lat: showMarkerPreview.lng,
                lng: showMarkerPreview.lat,
              }}>
              {showMarkerPreview.preview?.()}
            </InfoWindow>
          )}
          {showPreview &&
            showMarkersPreview &&
            !showMarkersPreview.markers.some((marker) => !marker.preview) && (
              <InfoWindow
                onCloseClick={() => {
                  setShowMarkerPreview(false)
                  setShowMarkersPreview(false)
                }}
                options={{ pixelOffset: new window.google.maps.Size(0, -40) }}
                position={{
                  lat: showMarkersPreview.cluster.center?.lat()!,
                  lng: showMarkersPreview.cluster.center?.lng()!,
                }}>
                <Box style={{ padding: '10px' }}>
                  <Grid container spacing={1}>
                    {showMarkersPreview.markers.map((marker) => (
                      <Grid item xs={6} key={marker.id}>
                        {marker.preview?.(true)}
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </InfoWindow>
            )}

          <MarkerClusterer
            options={{ styles: Constants.maps.clusterStyle }}
            onClick={(cluster: any) => {
              if (cluster.map?.getZoom()) {
                if (cluster.map?.getZoom()! >= MAX_ZOOM) {
                  const clickedMarkers = markers.filter((marker) => {
                    return cluster.getMarkers().find((m: any) => {
                      return (
                        m.getPosition()?.lat() === marker.lng &&
                        m.getPosition()?.lng() === marker.lat
                      )
                    })
                  })
                  setShowMarkersPreview({ markers: clickedMarkers, cluster })
                  setShowMarkerPreview(false)
                }
              }
            }}>
            {(cluster: any) => (
              <Box>
                {markers.map((marker, index) => {
                  return (
                    <Marker
                      key={marker.id ?? `marker ${index}`}
                      icon={
                        marker.secondary
                          ? Constants.resources.secondaryLocation
                          : Constants.resources.location
                      }
                      clickable={!!showPreview || !!marker?.onClick}
                      clusterer={cluster}
                      onClick={
                        showPreview
                          ? () => {
                              setShowMarkerPreview(marker)
                              setShowMarkersPreview(false)
                            }
                          : marker.onClick
                      }
                      position={{ lat: marker.lng, lng: marker.lat }}
                    />
                  )
                })}
              </Box>
            )}
          </MarkerClusterer>
        </GoogleMap>
        {!hideAutocomplete && (
          <Box position="absolute" px="9px" pt="10px" top="0" left="0" right="0" width="100%">
            <AddressInput
              value={value}
              onChange={(value) => {
                if (value && value.position) {
                  setCenter({ lng: value.position[0], lat: value.position[1] })
                  setZoom(11)
                  onChange?.(value)
                } else {
                  onChange?.(undefined)
                }
              }}
              types={['(cities)']}
              canChooseCountry={canChooseCountry}
              placeholder={t('global:inputs.searchCity')}
            />
          </Box>
        )}
        {!!onFullScreen && (
          <Box position="absolute" bottom="20px" right="10px">
            <ZoomButton onClick={onFullScreen} centerRipple={false}>
              <ZoomOutMapIcon color="primary" />
            </ZoomButton>
          </Box>
        )}
      </Container>
    )
  },
  (prevProps, nextProps) => {
    return prevProps.markers === nextProps.markers
  },
)
export default GoogleMapComponent
