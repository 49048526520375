import { useMemo, forwardRef, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/material/styles/index.js'
import { useMediaQuery } from '@mui/material'
import { RequestStatus, RequestType, Request } from '../../models/requests.models'
import { ColumnItem } from '../../models/props.models'
import List, { ListRef, ListProps, ListModeType, ListMode } from '../common/List.common'
import RequestCard from './Card.request'

export interface RequestListProps extends Omit<ListProps, 'modes'> {
  received?: boolean
  sent?: boolean
}
const RequestList = forwardRef<ListRef, RequestListProps>((props, ref) => {
  const { sent, received, ...listProps } = props
  const theme = useTheme()
  const onlyXs = useMediaQuery(theme.breakpoints.only('xs'))

  const { t } = useTranslation()
  const columns = useMemo<ColumnItem[]>(
    () => [
      {
        label: t('requests:attributes.type'),
        key: 'type',
        formatValue: (type: RequestType) => t(`requests:type.${type}`),
      },
      (request: Request | undefined) => ({
        label: t('requests:attributes.content'),
        key: 'content',
        formatValue: (_: any) =>
          request?.type === RequestType.catalogTransfer
            ? request?.catalog?.name
            : request?.user?.email,
      }),
      {
        label: t('requests:attributes.status'),
        key: 'status',
        formatValue: (status: RequestStatus) => t(`requests:status.${status}`),
      },
      (request: Request | undefined) => ({
        label: t('requests:attributes.sender'),
        key: 'sender',
        hideItem: sent,
        formatValue: (_: any) =>
          request?.type === RequestType.catalogTransfer
            ? request?.sender?.name
            : request?.user?.email,
      }),
      (request: Request | undefined) => ({
        label: t('requests:attributes.receiver'),
        key: 'receiver',
        hideItem: received,
        formatValue: (_: any) => request?.customReceiver?.organization ?? request?.receiver?.name,
      }),
    ],
    [t, sent, received],
  )

  const Card = useCallback(
    (props: any) => <RequestCard {...props} received={received} sent={sent} />,
    [sent, received],
  )

  return (
    <List
      ref={ref}
      {...listProps}
      modes={[
        {
          key: onlyXs ? 'requestCard' : 'requestTable',
          type: onlyXs ? ListModeType.card : ListModeType.table,
          columns: onlyXs ? undefined : columns,
          card: onlyXs ? Card : undefined,
        } as ListMode,
      ]}
    />
  )
})
export default RequestList
