import { materialModelsStore } from './materialModels.store'
import { select } from '@ngneat/elf'
import { selectIsRequestPending } from '@ngneat/elf-requests'
import { sessionQuery } from '../session'
import { traduceMaterialModel } from '../../models/materialModels.models'

export class MaterialModelsQuery {
  store = materialModelsStore

  materialModels = this.store.pipe(
    select((state) =>
      state.list.map((materialModel) =>
        traduceMaterialModel(materialModel, sessionQuery.getUserLang()),
      ),
    ),
  )
  loading = this.store.pipe(selectIsRequestPending('init'))
}

export const materialModelsQuery = new MaterialModelsQuery()
