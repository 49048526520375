import { IntervalProps } from '../../../models/props.models'
import { Slider, Box, InputLabel, FormHelperText } from '@mui/material'

const IntervalInput: React.FC<IntervalProps> = ({
  label,
  valueLabel,
  min,
  max,
  readOnly,
  disabled,
  error,
  onChange,
  value,
  required,
}) => {
  return (
    <Box display="flex" flexDirection="column" width="100%" position="relative">
      {!!label && <InputLabel error={!!error}>{label + (required ? '*' : '')}</InputLabel>}
      <Box sx={{ marginRight: '10px' }}>
        <Slider
          value={value || [min, max]}
          disabled={disabled || readOnly}
          onChange={(_, value) =>
            typeof value === 'number' ? undefined : onChange?.(value as [number, number])
          }
          min={min}
          max={max}
        />
      </Box>
      {valueLabel && valueLabel(value || [min, max])}
      {typeof error === 'string' && (
        <FormHelperText
          error
          sx={{ position: 'absolute', bottom: '-2px', left: '5px', pointerEvents: 'none' }}>
          {error}
        </FormHelperText>
      )}
    </Box>
  )
}
export default IntervalInput
