import { LangEnum } from '../constants/langs'
import i18n from '../utils/i18n.utils'
import { Pagination, Traduction } from './commons.models'
import { DimensionUnit, ManageMaterial, Unit } from './materials.models'

export enum RuleType {
  intervals = 'intervals',
  items = 'items',
  value = 'value',
  fixed = 'fixed',
}

export enum CarbonModulator {
  length = 'length',
  width = 'width',
  height = 'height',
  diameter = 'diameter',
  unitWeight = 'unitWeight',
  density = 'density',
}

export enum CarbonUnit {
  cubicMeters = 'm3',
  squareMeters = 'm2',
  linearMeters = 'lm',
  unitary = 'u',
  tone = 't',
}

export interface Interval {
  min?: number
  max?: number
  value: number
}
export interface Item {
  label: Traduction
  value: number
}
export interface Rule {
  type: RuleType
  modulator?: CarbonModulator
  customModulator?: Traduction
  intervals?: Interval[]
  items?: Item[]
  value?: number
}

interface TraducedItem extends Omit<Item, 'label'> {
  label: string
}
export interface TraducedRule extends Omit<Rule, 'items' | 'customModulator'> {
  items?: TraducedItem[]
  customModulator?: string
}

export interface CarbonModel {
  _id: string
  updatedAt?: Date
  name: Traduction
  unit: CarbonUnit
  productionRule: Rule
  endOfLifeRule: Rule
}

export interface RuleInput {
  rule: string
  values: Record<LangEnum, string[]>
}

export interface TraducedCarbonModel
  extends Omit<CarbonModel, 'name' | 'productionRule' | 'endOfLifeRule'> {
  name: string
  productionRule: TraducedRule
  endOfLifeRule: TraducedRule
}

export interface CarbonModelsPagination extends Pagination {
  data: CarbonModel[]
}

export const traduceCarbonModel = (
  carbonModel: CarbonModel,
  lang: LangEnum = LangEnum.FR,
): TraducedCarbonModel => {
  const prodRule = carbonModel.productionRule
  const endRule = carbonModel.endOfLifeRule
  return {
    ...carbonModel,
    name: carbonModel.name[lang] || carbonModel.name[LangEnum.FR],
    productionRule: {
      ...prodRule,
      customModulator: prodRule.customModulator
        ? prodRule.customModulator[lang] || prodRule.customModulator[LangEnum.FR]
        : undefined,
      items: prodRule.items
        ? prodRule.items.map((item) => ({
            ...item,
            label: item.label[lang] || item.label[LangEnum.FR],
          }))
        : undefined,
    },
    endOfLifeRule: {
      ...endRule,
      customModulator: endRule.customModulator
        ? endRule.customModulator[lang] || endRule.customModulator[LangEnum.FR]
        : undefined,
      items: endRule.items
        ? endRule.items.map((item) => ({
            ...item,
            label: item.label[lang] || item.label[LangEnum.FR],
          }))
        : undefined,
    },
  }
}

interface ComputeRes {
  productionCarbon?: number
  endOfLifeCarbon?: number
}
export const computeCarbon = (
  carbonModel: TraducedCarbonModel,
  material: ManageMaterial,
): ComputeRes => {
  const errors = []
  const res: ComputeRes = {
    productionCarbon: undefined,
    endOfLifeCarbon: undefined,
  }
  let requiredFields: string[] = []
  const materialUnit = (material.unit === Unit.set
    ? Unit.unitary
    : material.unit) as string as CarbonUnit

  const meterDimension: {
    length?: number
    width?: number
    height?: number
    diameter?: number
  } = {}
  if (material.dimensions) {
    const dimensionUnitFactor = {
      [DimensionUnit.centimeters]: 100,
      [DimensionUnit.millimeters]: 1000,
      [DimensionUnit.meters]: 1,
    }[material.dimensions.unit]
    ;(['length', 'width', 'height', 'diameter'] as (keyof typeof meterDimension)[]).forEach(
      (dimensionKey) => {
        if (material.dimensions[dimensionKey]) {
          meterDimension[dimensionKey] = material.dimensions[dimensionKey]! / dimensionUnitFactor
        }
      },
    )
  }

  let quantityFactor: number | undefined = undefined
  if (materialUnit !== carbonModel.unit) {
    switch (carbonModel.unit) {
      case CarbonUnit.cubicMeters:
        if (materialUnit === CarbonUnit.unitary) {
          if (meterDimension.width && meterDimension.height && meterDimension.length) {
            quantityFactor = meterDimension.width * meterDimension.height * meterDimension.length
          } else if ((meterDimension.length ?? meterDimension.height) && meterDimension.diameter) {
            quantityFactor =
              (meterDimension.diameter / 2) *
              (meterDimension.diameter / 2) *
              Math.PI *
              (meterDimension.length ?? meterDimension.height)!
          } else {
            requiredFields = ['diameter', 'width', 'height', 'length']
          }
        } else if (materialUnit === CarbonUnit.linearMeters) {
          if (meterDimension.width && meterDimension.height) {
            quantityFactor = meterDimension.width * meterDimension.height
          } else {
            requiredFields = ['width', 'height']
          }
        } else if (materialUnit === CarbonUnit.squareMeters) {
          if (meterDimension.height) {
            quantityFactor = meterDimension.height
          } else {
            requiredFields = ['height']
          }
        } else if (materialUnit === CarbonUnit.tone) {
          if (material.density) {
            quantityFactor = 1000 / material.density
          } else {
            requiredFields = ['density']
          }
        }
        break
      case CarbonUnit.squareMeters:
        if (materialUnit === CarbonUnit.linearMeters) {
          if (meterDimension.height) {
            quantityFactor = meterDimension.height
          } else {
            requiredFields = ['height']
          }
        } else if (materialUnit === CarbonUnit.unitary) {
          if (meterDimension.width && meterDimension.length) {
            quantityFactor = meterDimension.width && meterDimension.length
          } else {
            requiredFields = ['width', 'length']
          }
        }
        break
      case CarbonUnit.linearMeters:
        if (materialUnit === CarbonUnit.unitary) {
          if (meterDimension.length) {
            quantityFactor = meterDimension.length
          } else {
            requiredFields = ['length']
          }
        }
        break
      case CarbonUnit.unitary:
        break
      case CarbonUnit.tone:
        if (material.unitWeight) {
          quantityFactor = material.unitWeight / 1000
        } else {
          requiredFields = ['unitWeight']
        }
        break
    }
  } else {
    quantityFactor = 1
  }

  if (requiredFields.length > 0) {
    errors.push(
      i18n.t('carbonModels:attributes.rule.errorMissingConversion', {
        data: requiredFields
          .map((requiredField) =>
            i18n.t(`carbonModels:modulator.${requiredField as CarbonModulator}`),
          )
          .join(', '),
      }),
    )
  } else if (!requiredFields.length && !quantityFactor) {
    errors.push(i18n.t('carbonModels:attributes.rule.errorBadConversion'))
  }

  const getModulateurValue = (
    rule: TraducedRule,
    modulator: number | string | undefined,
  ): number | undefined => {
    const modulatorName = rule.modulator
      ? i18n.t(`carbonModels:modulator.${rule.modulator}`)
      : rule.customModulator

    switch (rule.type) {
      case RuleType.fixed:
        if (!rule.value && rule.value !== 0) {
          errors.push(i18n.t('carbonModels:attributes.rule.errorBadRule'))
          return undefined
        } else {
          return rule.value
        }
      case RuleType.value:
        if (!rule.value && rule.value !== 0) {
          errors.push(i18n.t('carbonModels:attributes.rule.errorBadRule'))
          return undefined
        } else {
          if (Number.isNaN(Number(modulator))) {
            errors.push(
              i18n.t('carbonModels:attributes.rule.errorBadModulator', {
                data: modulatorName,
              }),
            )
            return undefined
          } else {
            return rule.value * Number(modulator)
          }
        }
      case RuleType.intervals:
        if (!rule.intervals || rule.intervals.length === 0) {
          errors.push(i18n.t('carbonModels:attributes.rule.errorBadRule'))
          return undefined
        } else {
          if (!Number.isNaN(Number(modulator))) {
            const val = Number(modulator)
            for (let i = 0; i < rule.intervals.length; i++) {
              if (
                (rule.intervals[i].min === undefined || val >= rule.intervals[i].min!) &&
                (rule.intervals[i].max === undefined || val < rule.intervals[i].max!)
              ) {
                if (!rule.intervals[i].value && rule.intervals[i].value !== 0) {
                  i18n.t('carbonModels:attributes.rule.errorBadModulator', {
                    data: modulatorName,
                  })
                  return undefined
                } else {
                  return rule.intervals[i].value
                }
              }
            }
          }
          errors.push(
            i18n.t('carbonModels:attributes.rule.errorBadModulator', {
              data: modulatorName,
            }),
          )
          return undefined
        }
      case RuleType.items:
        if (!rule.items || rule.items.length === 0) {
          errors.push(i18n.t('carbonModels:attributes.rule.errorBadRule'))
          return undefined
        } else {
          const item = rule.items.find((item) => item.label === modulator)
          if (item) {
            if (!item.value && item.value !== 0) {
              errors.push(i18n.t('carbonModels:attributes.rule.errorBadRule'))
              return undefined
            } else {
              return item.value
            }
          }

          errors.push(
            i18n.t('carbonModels:attributes.rule.errorBadModulator', {
              data: modulatorName,
            }),
          )
          return undefined
        }
    }
  }

  ;(['productionCarbon', 'endOfLifeCarbon'] as (keyof typeof res)[]).forEach((key) => {
    const rule = carbonModel[key.replace('Carbon', 'Rule') as keyof CarbonModel] as TraducedRule
    let modulator: number | string | undefined = undefined
    let modulatorValue: number | undefined = undefined

    if (rule.modulator) {
      modulator =
        rule.modulator === CarbonModulator.unitWeight || rule.modulator === CarbonModulator.density
          ? material[rule.modulator]
          : meterDimension?.[rule.modulator]
      if (!modulator) {
        errors.push(
          i18n.t('carbonModels:attributes.rule.errorMissingModulator', {
            data: i18n.t(`carbonModels:modulator.${rule.modulator}`),
          }),
        )
      } else {
        modulatorValue = getModulateurValue(rule, modulator)
      }
    } else if (rule.customModulator) {
      modulator = material.technicalDetails.find(
        (technicalDetail) => rule.customModulator === technicalDetail.name,
      )?.value
      if (!modulator) {
        errors.push(
          i18n.t('carbonModels:attributes.rule.errorMissingModulator', {
            data: rule.customModulator,
          }),
        )
      } else {
        modulatorValue = getModulateurValue(rule, modulator)
      }
    } else {
      modulatorValue = getModulateurValue(rule, modulator)
    }

    if ((!!modulatorValue || modulatorValue === 0) && !!quantityFactor) {
      res[key] = modulatorValue * quantityFactor
    }
  })

  if (errors.length > 0) {
    const error = new Error('Compute rule missing data')
    ;(error as any).messages = errors
    throw error
  } else {
    return res
  }
}
