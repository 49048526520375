import { useState, useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography, styled } from '@mui/material'
import { ManageMaterialModel } from '../../models/materialModels.models'
import {
  Unit,
  DimensionUnit,
  ImperialUnit,
  ImperialDimensionUnit,
  convert2Imperials,
  convert2Meters,
  Assembly,
} from '../../models/materials.models'
import { FormItem, ItemType } from '../../models/props.models'
import ModalForm, { ModalFormProps } from '../common/ModalForm.common'
import { createOptionsFromEnum } from '../../utils/i18n.utils'
import InputCategory from '../category/Input.category'
import { YupUtils } from '../../utils/yup.utils'
import { LangEnum } from '../../constants/langs'
import { useObservable } from '@ngneat/react-rxjs'
import { carbonModelsQuery } from '../../store/carbonModels'
import { wasteAsbestos } from '../../models/categories.models'
const EndAdornmentLabel = styled(Typography)({ fontSize: '0.875rem', fontWeight: 500 })

interface ModalFormMaterialModelProps
  extends Omit<ModalFormProps, 'value' | 'items' | 'steps' | 'setValue' | 'tabs'> {
  materialModel?: ManageMaterialModel
  useImperials: boolean
  showCerfa: boolean
}
const ModalFormMaterialModel: React.FC<ModalFormMaterialModelProps> = (props) => {
  const { t } = useTranslation()
  const { useImperials, materialModel, onSubmit, ...formProps } = props
  const [carbonModels] = useObservable(carbonModelsQuery.carbonModels)
  const carbonModelOptions = useMemo(
    () =>
      carbonModels.map((carbonModel) => ({
        label: carbonModel.name,
        value: carbonModel._id,
      })),
    [carbonModels],
  )

  const [value, setValue] = useState<Partial<ManageMaterialModel>>(
    useImperials
      ? convert2Imperials(materialModel ?? ({} as ManageMaterialModel))
      : materialModel ?? ({} as ManageMaterialModel),
  )
  const submitMaterialModel = useCallback(
    async (value: ManageMaterialModel) => {
      await onSubmit?.(useImperials ? convert2Meters(value) : value)
    },
    [onSubmit, useImperials],
  )

  const items: FormItem[] = useMemo(
    () => [
      {
        type: ItemType.group,
        key: 'general',
        items: [
          ...(Object.keys(LangEnum).map((langKey: string) => ({
            type: ItemType.text,
            grid: { xs: 12, md: 4 },
            key: `name.${LangEnum[langKey as keyof typeof LangEnum]}`,
            required: LangEnum[langKey as keyof typeof LangEnum] === LangEnum.FR,
            sx: { xs: 4 },
            props: {
              label: `${t('materialModels:attributes.name')} ${langKey}`,
              placeholder: t('materialModels:attributes.name'),
            },
          })) as FormItem[]),
          {
            type: ItemType.select,
            grid: { xs: 6, md: 4 },
            key: 'unit',
            required: true,
            props: {
              label: t('materialModels:attributes.unit'),
              placeholder: t('global:inputs.selectPlaceholder'),
              items: createOptionsFromEnum(useImperials ? ImperialUnit : Unit, 'materials:unit'),
            },
          },
          {
            type: ItemType.custom,
            key: 'tertiaryCategory',
            required: true,
            grid: { xs: 6, md: 8 },
            custom: (
              <InputCategory
                type="rae"
                label={t('materialModels:attributes.category')}
                placeholder={t('materialModels:attributes.category')}
                required
                accurateCategory
                value={{
                  primaryCategory: value.primaryCategory,
                  secondaryCategory: value.secondaryCategory,
                  tertiaryCategory: value.tertiaryCategory,
                }}
                onChange={(categoryValue: any) => {
                  setValue((val: any) => ({
                    ...val,
                    ...categoryValue,
                  }))
                }}
              />
            ),
          },
          (materialModel: ManageMaterialModel) => ({
            type: ItemType.number,
            grid: { xs: 6, md: 4 },
            key: 'unitWeight',
            props: {
              label: t('materials:attributes.weight.unit'),
              placeholder: t('materials:attributes.weight.unit'),
              endAdornment: materialModel?.unit ? (
                <EndAdornmentLabel>
                  {t(`materials:attributes.weight.weightByUnit`, {
                    unit: t(`materials:unitSymbol.${materialModel.unit}`),
                  })}
                </EndAdornmentLabel>
              ) : undefined,
            },
          }),
          {
            type: ItemType.number,
            grid: { xs: 6, md: 4 },
            key: 'density',
            props: {
              label: t('materials:attributes.density'),
              placeholder: t('materials:attributes.density'),
              endAdornment: (
                <EndAdornmentLabel>
                  {t(`materials:attributes.weight.weightByUnit`, {
                    unit: t(`materials:unitSymbol.m3`),
                  })}
                </EndAdornmentLabel>
              ),
            },
          },
          {
            type: ItemType.checkbox,
            grid: { xs: 6, md: 4 },
            key: 'wasteOnly',
            hideItem: !props.showCerfa,
            props: {
              label: t('materials:attributes.wasteOnly'),
              placeholder: t('materials:attributes.wasteOnly'),
            },
          },
          ...(Object.keys(LangEnum).map(
            (langKey: string) => (materialModel: ManageMaterialModel | undefined) => ({
              type: ItemType.text,
              grid: { xs: 12, md: 4 },
              key: `tracksOfReuse.${LangEnum[langKey as keyof typeof LangEnum]}`,
              required:
                !!Object.keys(LangEnum).find(
                  (langKey2) =>
                    !!materialModel?.tracksOfReuse?.[LangEnum[langKey2 as keyof typeof LangEnum]],
                ) && LangEnum[langKey as keyof typeof LangEnum] === LangEnum.FR,
              sx: { xs: 4 },
              props: {
                label: `${t('materialModels:attributes.carbon.tracksOfReuse')} ${langKey}`,
                placeholder: t('materialModels:attributes.carbon.tracksOfReuse'),
              },
            }),
          ) as FormItem[]),
        ],
      },
      (materialModel: ManageMaterialModel) => ({
        type: ItemType.group,
        key: 'dimensionsGroup',
        hideItem: value.wasteOnly,
        props: {
          title: t('materialModels:attributes.dimensions.title'),
        },
        items: [
          {
            type: ItemType.select,
            grid: { xs: 6, sm: 4, md: 3 },
            key: 'dimensions.unit',
            props: {
              label: t('materialModels:attributes.dimensions.unit'),
              placeholder: t('global:inputs.selectPlaceholder'),
              items: createOptionsFromEnum(
                useImperials ? ImperialDimensionUnit : DimensionUnit,
                'materials:dimensionUnit',
              ),
            },
          },
          {
            type: ItemType.number,
            grid: { xs: 6, sm: 4, md: 3 },
            key: 'dimensions.length',
            props: {
              label: t('materialModels:attributes.dimensions.length'),
              placeholder: t('materialModels:attributes.dimensions.length'),
              endAdornment: materialModel.dimensions?.unit ? (
                <EndAdornmentLabel>
                  {t(`materials:dimensionUnitSymbol.${materialModel.dimensions.unit}`)}
                </EndAdornmentLabel>
              ) : undefined,
            },
          },
          {
            type: ItemType.number,
            grid: { xs: 6, sm: 4, md: 3 },
            key: 'dimensions.width',
            props: {
              label: t('materialModels:attributes.dimensions.width'),
              placeholder: t('materialModels:attributes.dimensions.width'),
              endAdornment: materialModel.dimensions?.unit ? (
                <EndAdornmentLabel>
                  {t(`materials:dimensionUnitSymbol.${materialModel.dimensions.unit}`)}
                </EndAdornmentLabel>
              ) : undefined,
            },
          },
          {
            type: ItemType.number,
            grid: { xs: 6, sm: 4, md: 3 },
            key: 'dimensions.height',
            props: {
              label: t('materialModels:attributes.dimensions.height'),
              placeholder: t('materialModels:attributes.dimensions.height'),
              endAdornment: materialModel.dimensions?.unit ? (
                <EndAdornmentLabel>
                  {t(`materials:dimensionUnitSymbol.${materialModel.dimensions.unit}`)}
                </EndAdornmentLabel>
              ) : undefined,
            },
          },
          {
            type: ItemType.number,
            grid: { xs: 6, sm: 4, md: 3 },
            key: 'dimensions.diameter',
            props: {
              label: t('materialModels:attributes.dimensions.diameter'),
              placeholder: t('materialModels:attributes.dimensions.diameter'),
              endAdornment: materialModel.dimensions?.unit ? (
                <EndAdornmentLabel>
                  {t(`materials:dimensionUnitSymbol.${materialModel.dimensions.unit}`)}
                </EndAdornmentLabel>
              ) : undefined,
            },
          },
        ],
      }),
      {
        type: ItemType.group,
        key: 'technicalDetailsGroup',
        hideItem: value.wasteOnly,
        props: {
          title: t('materialModels:attributes.technicalDetails.title'),
        },
        items: [
          {
            type: ItemType.array,
            key: 'technicalDetails',
            props: {
              orderable: true,
              addLabel: t('materialModels:attributes.technicalDetails.addButton'),
            },
            items: [
              ...(Object.keys(LangEnum).map((langKey: string) => ({
                type: ItemType.text,
                grid: { xs: 12, md: 4 },
                key: LangEnum[langKey as keyof typeof LangEnum],
                required: LangEnum[langKey as keyof typeof LangEnum] === LangEnum.FR,
                sx: { xs: 4 },
                props: {
                  label: langKey,
                  placeholder: langKey,
                },
              })) as FormItem[]),
            ] as FormItem[],
          },
        ],
      },
      {
        type: ItemType.group,
        key: 'carbon',
        hideItem: value.wasteOnly,
        props: {
          title: t('materialModels:attributes.carbon.title'),
        },
        items: [
          {
            type: ItemType.asyncSelect,
            key: 'carbonModel',
            grid: { xs: 12, sm: 6, md: 4 },
            props: {
              label: t('materialModels:attributes.carbon.carbonModel'),
              searchIfEmpty: true,
              placeholder: t('global:inputs.selectPlaceholder'),
              options: carbonModelOptions,
              tooltip: t('materialModels:attributes.carbon.carbonModelTooltip'),
            },
          },
          {
            type: ItemType.number,
            grid: { xs: 12, sm: 6, md: 4 },
            key: 'productionCarbon',
            props: {
              label: t('materials:attributes.carbon.productionCarbon'),
              placeholder: t('materials:attributes.carbon.productionCarbonPlaceholder'),
              endAdornment: <EndAdornmentLabel>kgCO2eq/UR</EndAdornmentLabel>,
            },
          },
          {
            type: ItemType.number,
            grid: { xs: 12, sm: 6, md: 4 },
            key: 'endOfLifeCarbon',
            props: {
              label: t('materials:attributes.carbon.endOfLifeCarbon'),
              placeholder: t('materials:attributes.carbon.endOfLifeCarbonPlaceholder'),
              endAdornment: <EndAdornmentLabel>kgCO2eq/UR</EndAdornmentLabel>,
            },
          },
        ],
      },
      {
        type: ItemType.group,
        hideItem: !props.showCerfa || value.wasteOnly,
        key: 'cerfaResource',

        props: {
          title: t('materials:attributes.cerfaResource.title'),
          tooltip: t('materials:attributes.cerfaResource.tooltip'),
        },
        items: [
          {
            type: ItemType.custom,
            key: 'resourcePrimaryCategory',
            grid: { xs: 6 },
            custom: (
              <InputCategory
                type="resource"
                label={t('materials:attributes.cerfaResource.category')}
                placeholder={t('materials:attributes.cerfaResource.category')}
                accurateCategory
                value={{
                  primaryCategory: value.resourcePrimaryCategory,
                  secondaryCategory: value.resourceSecondaryCategory,
                  tertiaryCategory: value.resourceTertiaryCategory,
                }}
                onChange={(categoryValue: any) => {
                  setValue((val: any) => ({
                    ...val,
                    resourcePrimaryCategory: categoryValue.primaryCategory,
                    resourceSecondaryCategory: categoryValue.secondaryCategory,
                    resourceTertiaryCategory: categoryValue.tertiaryCategory,
                  }))
                }}
              />
            ),
          },
          {
            type: ItemType.select,
            key: 'assembly',
            grid: { xs: 6 },
            props: {
              label: t('materials:attributes.cerfaResource.assembly'),
              placeholder: t('materials:attributes.cerfaResource.assembly'),
              items: createOptionsFromEnum(Assembly, 'materials:assembly'),
            },
          },
          {
            type: ItemType.array,
            key: 'constituentMaterials',
            hideItem: value.wasteOnly,
            props: {
              label: t('materials:attributes.cerfaResource.constituentMaterials'),
            },
            items: [
              ...(Object.keys(LangEnum).map((langKey: string) => ({
                type: ItemType.text,
                grid: { xs: 12, md: 4 },
                key: `${LangEnum[langKey as keyof typeof LangEnum]}`,
                required: LangEnum[langKey as keyof typeof LangEnum] === LangEnum.FR,
                sx: { xs: 4 },
                props: {
                  label: langKey,
                  placeholder: langKey,
                },
              })) as FormItem[]),
            ] as FormItem[],
          },
        ],
      },
      {
        type: ItemType.group,
        hideItem: !props.showCerfa,
        key: 'cerfaWaste',
        props: {
          title: t('materials:attributes.cerfaWaste.title'),
          tooltip: t('materials:attributes.cerfaWaste.tooltip'),
        },
        items: [
          {
            type: ItemType.custom,
            key: 'wasteCategory',
            grid: { xs: 12, md: 6 },
            custom: (
              <InputCategory
                type="waste"
                label={t('materials:attributes.cerfaWaste.category')}
                placeholder={t('materials:attributes.cerfaWaste.category')}
                accurateCategory
                value={{
                  primaryCategory: value.wastePrimaryCategory,
                  secondaryCategory: value.wasteSecondaryCategory,
                  tertiaryCategory: value.wasteTertiaryCategory,
                }}
                onChange={(categoryValue: any) => {
                  setValue((val: any) => ({
                    ...val,
                    wastePrimaryCategory: categoryValue.primaryCategory,
                    wasteSecondaryCategory: categoryValue.secondaryCategory,
                    wasteTertiaryCategory: categoryValue.tertiaryCategory,
                  }))
                }}
              />
            ),
          },
          {
            key: 'wasteCode',
            type: ItemType.text,
            grid: { xs: 12, md: 6 },
            props: {
              label: t('materials:attributes.cerfaWaste.wasteCode'),
              placeholder: t('materials:attributes.cerfaWaste.wasteCode'),
            },
          },
          ...(value.wasteSecondaryCategory === wasteAsbestos
            ? (Object.keys(LangEnum).map((langKey: string) => ({
                type: ItemType.text,
                grid: { xs: 12, md: 4 },
                key: `asbestosPrecision.${LangEnum[langKey as keyof typeof LangEnum]}`,
                sx: { xs: 4 },
                props: {
                  label: `${t('materials:attributes.cerfaWaste.asbestosPrecision')} ${langKey}`,
                  placeholder: t('materials:attributes.cerfaWaste.asbestosPrecision'),
                },
              })) as FormItem[])
            : []),
          {
            type: ItemType.typo,
            key: 'cerfaWasteValorisation',
            formatValue: () => t('materials:attributes.cerfaWaste.valorisation'),
            rules: [
              (materialModel: any) => {
                const total =
                  (materialModel.reusePercent ?? 0) +
                  (materialModel.recyclablePercent ?? 0) +
                  (materialModel.backfillingPercent ?? 0) +
                  (materialModel.incineratedWithEnergyPercent ?? 0) +
                  (materialModel.incineratedWithoutEnergyPercent ?? 0) +
                  (materialModel.nonRecoverablePercent ?? 0)

                return total !== 0 && total !== 100 ? t('errors:sumPercent') : ''
              },
            ],
            props: {
              variant: 'subtitle2',
            },
          },
          {
            type: ItemType.number,
            grid: { xs: 6, md: 3 },
            key: 'reusePercent',
            rules: [YupUtils.FieldValidationType.percent],
            props: {
              label: t('materials:attributes.cerfaWaste.reusePercent'),
              placeholder: t('materials:attributes.cerfaWaste.reusePercent'),
              endAdornment: <EndAdornmentLabel>%</EndAdornmentLabel>,
            },
          },
          {
            type: ItemType.number,
            grid: { xs: 6, md: 3 },
            key: 'recyclablePercent',
            rules: [YupUtils.FieldValidationType.percent],
            props: {
              label: t('materials:attributes.cerfaWaste.recyclablePercent'),
              placeholder: t('materials:attributes.cerfaWaste.recyclablePercent'),
              endAdornment: <EndAdornmentLabel>%</EndAdornmentLabel>,
            },
          },
          {
            type: ItemType.number,
            grid: { xs: 6, md: 3 },
            key: 'backfillingPercent',
            rules: [YupUtils.FieldValidationType.percent],
            props: {
              label: t('materials:attributes.cerfaWaste.backfillingPercent'),
              placeholder: t('materials:attributes.cerfaWaste.backfillingPercent'),
              endAdornment: <EndAdornmentLabel>%</EndAdornmentLabel>,
            },
          },
          {
            type: ItemType.number,
            grid: { xs: 6, md: 3 },
            key: 'incineratedWithEnergyPercent',
            rules: [YupUtils.FieldValidationType.percent],
            props: {
              label: t('materials:attributes.cerfaWaste.incineratedWithEnergyPercent'),
              placeholder: t('materials:attributes.cerfaWaste.incineratedWithEnergyPercent'),
              endAdornment: <EndAdornmentLabel>%</EndAdornmentLabel>,
            },
          },
          {
            type: ItemType.typo,
            key: 'cerfaWasteElimination',
            formatValue: () => t('materials:attributes.cerfaWaste.elimination'),
            rules: [
              (materialModel: any) => {
                const total =
                  (materialModel.reusePercent ?? 0) +
                  (materialModel.recyclablePercent ?? 0) +
                  (materialModel.backfillingPercent ?? 0) +
                  (materialModel.incineratedWithEnergyPercent ?? 0) +
                  (materialModel.incineratedWithoutEnergyPercent ?? 0) +
                  (materialModel.nonRecoverablePercent ?? 0)

                return total !== 0 && total !== 100 ? t('errors:sumPercent') : ''
              },
            ],
            props: {
              variant: 'subtitle2',
            },
          },
          {
            type: ItemType.number,
            grid: { xs: 6 },
            key: 'incineratedWithoutEnergyPercent',
            rules: [YupUtils.FieldValidationType.percent],
            props: {
              label: t('materials:attributes.cerfaWaste.incineratedWithoutEnergyPercent'),
              placeholder: t('materials:attributes.cerfaWaste.incineratedWithoutEnergyPercent'),
              endAdornment: <EndAdornmentLabel>%</EndAdornmentLabel>,
            },
          },
          {
            type: ItemType.number,
            grid: { xs: 6 },
            key: 'nonRecoverablePercent',
            rules: [YupUtils.FieldValidationType.percent],
            props: {
              label: t('materials:attributes.cerfaWaste.nonRecoverablePercent'),
              placeholder: t('materials:attributes.cerfaWaste.nonRecoverablePercent'),
              endAdornment: <EndAdornmentLabel>%</EndAdornmentLabel>,
            },
          },
        ],
      },
    ],
    [
      t,
      useImperials,
      value.wasteOnly,
      value.resourcePrimaryCategory,
      value.resourceSecondaryCategory,
      value.resourceTertiaryCategory,
      value.wastePrimaryCategory,
      value.wasteSecondaryCategory,
      value.wasteTertiaryCategory,
      value.primaryCategory,
      value.secondaryCategory,
      value.tertiaryCategory,
      props.showCerfa,
      carbonModelOptions,
    ],
  )

  return (
    <ModalForm
      {...formProps}
      value={value}
      setValue={setValue}
      items={items}
      maxWidth="lg"
      onSubmit={submitMaterialModel}
    />
  )
}
export default ModalFormMaterialModel
