import { NetworkUtils } from '../../utils/networks.utils'
import { logIfDev } from '../../utils/commons.utils'
import { FileUtils } from '../../utils/files.utils'

import { CarbonModel, CarbonModelsPagination } from '../../models/carbonModels.models'
import { updateRequestStatus } from '@ngneat/elf-requests'
import { carbonModelsStore } from './carbonModels.store'
import { sessionQuery } from '../session'
import Constants from '../../constants'

import { CarbonModelsApi } from '../../api/carbonModels.api'
import { catalogsService } from '../catalogs'

const getLocalFilePath = (userId: string) => `${userId}/carbonModels`
export class CarbonModelsService {
  store = carbonModelsStore

  getCarbonModels = async (
    filters: CarbonModelsApi.GetListParams,
  ): Promise<CarbonModelsPagination> => {
    await NetworkUtils.checkConnected()
    return await CarbonModelsApi.getList({
      ...filters,
      lang: sessionQuery.getUserLang(),
    })
  }

  init = async (): Promise<void> => {
    let userId = sessionQuery.getUserId()
    if (!userId) {
      this.store.update((state) => ({
        ...state,
        list: [],
      }))
      return
    }
    this.store.update(updateRequestStatus('init', 'pending'))

    try {
      const isConnected = await NetworkUtils.isConnected()

      let carbonModelsList: CarbonModel[] = []
      let fromApi = false

      if (isConnected) {
        try {
          carbonModelsList = (
            await this.getCarbonModels({
              disablePaginate: true,
            })
          ).data
          fromApi = true
        } catch (err: any) {
          if (Constants.getIsLocal() && userId) {
            carbonModelsList = await FileUtils.readJSON(getLocalFilePath(userId))
          } else {
            throw err
          }
        }
      } else if (Constants.getIsLocal() && userId) {
        carbonModelsList = await FileUtils.readJSON(getLocalFilePath(userId))
      } else {
        throw new Error('NOT_CONNECTED')
      }

      this.store.update(
        (state) => ({
          ...state,
          list: carbonModelsList,
        }),
        updateRequestStatus('init', 'success'),
      )

      if (fromApi && Constants.getIsLocal() && userId) {
        // delete material link to deleted or updated carbonModel
        try {
          const previousCarbonModels = await FileUtils.readJSON(getLocalFilePath(userId))

          const removeLinks: string[] = []
          for (let i = 0; i < previousCarbonModels.length; i++) {
            let previous = previousCarbonModels[i]
            let current = carbonModelsList.find((current) => current._id === previous._id)
            if (
              !current ||
              new Date(previous.updatedAt).getTime() < new Date(current.updatedAt!).getTime()
            ) {
              removeLinks.push(previous._id)
            }
          }
          if (removeLinks.length > 0) {
            await catalogsService.migrateLocals(async ({ catalog, materials }: any) => {
              return {
                catalog,
                materials: materials.map((material: any) => {
                  return {
                    ...material,
                    carbonModel: !!removeLinks.find((carbonId) => carbonId === material.carbonModel)
                      ? undefined
                      : material.carbonModel,
                  }
                }),
              }
            })
          }
        } catch (err) {
          logIfDev(err)
        }

        try {
          FileUtils.writeJSON(getLocalFilePath(userId), carbonModelsList)
        } catch (err: any) {
          logIfDev(err)
        }
      }
    } catch (err: any) {
      this.store.update(updateRequestStatus('init', 'error', err))
      throw err
    }
  }

  createCarbonModel = async (carbonModel: CarbonModel): Promise<CarbonModel> => {
    await NetworkUtils.checkConnected()
    const createdCarbonModel = await CarbonModelsApi.create(carbonModel)

    this.store.update((state) => ({
      ...state,
      list: [createdCarbonModel, ...state.list],
    }))

    return createdCarbonModel
  }

  updateCarbonModel = async (
    carbonModelId: string,
    carbonModel: CarbonModel,
  ): Promise<CarbonModel> => {
    await NetworkUtils.checkConnected()
    let updateCarbonModel = await CarbonModelsApi.update(carbonModelId, carbonModel)

    this.store.update((state) => ({
      ...state,
      list: state.list.map((model) =>
        model._id === updateCarbonModel._id ? updateCarbonModel : model,
      ),
    }))

    return updateCarbonModel
  }

  deleteCarbonModel = async (carbonModelId: string): Promise<void> => {
    await NetworkUtils.checkConnected()
    await CarbonModelsApi.deleteById(carbonModelId)
    this.store.update((state) => ({
      ...state,
      list: state.list.filter((model) => model._id !== carbonModelId),
    }))
  }
}

export const carbonModelsService = new CarbonModelsService()
