import React, { memo, useCallback } from 'react'
import { Delete as DeleteIcon } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import Table from '../common/Table.common'
import { createOptionsFromEnum } from '../../utils/i18n.utils'

import { ColumnItem, ItemType } from '../../models/props.models'
import {
  ManageMaterial,
  MaterialQuality,
  getMaterialQuantityLabel,
  MaterialQuantity,
  Unit,
  computeResourceQuantities,
  QuantityType,
  CerfaWaste,
} from '../../models/materials.models'
import LinkButton from '../common/button/Link.button'
import { Box, Typography, styled } from '@mui/material'
import { traduceCategory } from '../../models/categories.models'

const EndAdornmentLabel = styled(Typography)({ fontSize: '0.875rem', fontWeight: 500 })

interface MaterialTableQuantitiesProps {
  quantities: MaterialQuantity[]
  unit: Unit
  setValue: React.Dispatch<React.SetStateAction<ManageMaterial>>
  showCerfa?: boolean
  wasteOnly?: boolean
  cerfaWaste?: CerfaWaste[]
}

const MaterialTableQuantities: React.FC<MaterialTableQuantitiesProps> = ({
  unit,
  quantities,
  setValue,
  wasteOnly,
  showCerfa,
  cerfaWaste,
}) => {
  const { t } = useTranslation()
  const updateValue = useCallback(
    (update: (quantities: MaterialQuantity[]) => MaterialQuantity[]) => {
      setValue((value) => ({
        ...value,
        ...computeResourceQuantities(update(value.quantities)),
      }))
    },
    [setValue],
  )
  const initialQuantities = quantities.filter((quantity) => quantity.initial !== false)
  const currentQuantities = quantities.filter((quantity) => quantity.initial === false)

  const addQuantity = (initial: boolean) => {
    updateValue((quantities) => [
      ...quantities,
      {
        _id: `local${Math.random().toString().split('.')[1]}`,
        type: QuantityType.inventory,
        initial,
        reusable: !wasteOnly,
        quantity: 1,
        quality: MaterialQuality.slightlyDamaged,
        linkToRoom: false,
      },
    ])
  }
  const valueAction = (deleted: MaterialQuantity) => {
    if (deleted?.order) {
      return undefined
    }
    return {
      onClick: () => {
        updateValue((quantities) => quantities.filter((quantity) => quantity._id !== deleted._id))
      },
      icon: <DeleteIcon />,
    }
  }

  const editQuantity = useCallback(
    (initial: boolean, key: string, editted: number, value: any) => {
      updateValue((quantities) => {
        const updatedId = quantities.filter(
          (quantity) =>
            (initial && quantity.initial !== false) || (!initial && quantity.initial === false),
        )[editted]._id

        return quantities.map((quantity: MaterialQuantity) =>
          quantity._id === updatedId ? { ...quantity, [key]: value, linkToRoom: false } : quantity,
        )
      })
    },
    [updateValue],
  )
  const getCerfaName = useCallback(
    (cerfaWasteIndex: number) => {
      const cerfa = cerfaWaste?.[cerfaWasteIndex]
      if (!cerfa) {
        return ''
      }
      return cerfa.tertiaryCategory
        ? traduceCategory('waste', cerfa.tertiaryCategory)
        : cerfa.secondaryCategory
        ? traduceCategory('waste', cerfa.secondaryCategory)
        : cerfa.primaryCategory
        ? traduceCategory('waste', cerfa.primaryCategory)
        : t('materials:attributes.cerfaWaste.labelIndex', {
            index: cerfaWasteIndex,
          })
    },
    [cerfaWaste, t],
  )

  const columns = useCallback<(initial?: boolean) => ColumnItem[]>(
    (initial = true) => [
      ...(initial
        ? []
        : [
            (materialQuantity: MaterialQuantity | undefined) => ({
              type: ItemType.select,
              required: true,
              label: t('materials:attributes.quantities.type'),
              key: 'type',
              onChange: editQuantity.bind(null, initial, 'type'),
              props: {
                items: createOptionsFromEnum(QuantityType, 'materials:quantityType').filter(
                  (option) => initial || option.value !== QuantityType.inventory,
                ),
                disabled:
                  !!materialQuantity?.order || !!materialQuantity?.plan || !!materialQuantity?.room,
              },
            }),
          ]),
      (materialQuantity: MaterialQuantity | undefined) => ({
        type: ItemType.text,
        label: initial
          ? t('materials:components.tableQuantities.localisation')
          : t('materials:components.tableQuantities.description'),
        key: 'name',
        minWidth: '100px',
        onChange: editQuantity.bind(null, initial, 'description'),
        formatValue: (_: any) => {
          return materialQuantity ? getMaterialQuantityLabel(materialQuantity) : ''
        },
        props: {
          disabled:
            !!materialQuantity?.order || !!materialQuantity?.plan || !!materialQuantity?.room,
        },
      }),
      (materialQuantity: MaterialQuantity | undefined) => ({
        type: ItemType.number,
        minWidth: '130px',
        label: `${t('materials:components.tableQuantities.variation')}`,
        key: 'quantity',
        onChange: editQuantity.bind(null, initial, 'quantity'),
        props: {
          disabled: !!materialQuantity?.order,
          endAdornment: unit ? (
            <EndAdornmentLabel>{t(`materials:unitSymbol.${unit}`)}</EndAdornmentLabel>
          ) : undefined,
        },
      }),
      ...(initial && showCerfa && !wasteOnly
        ? [
            {
              type: ItemType.checkbox,
              label: t('materials:attributes.quantities.reusable'),
              key: 'reusable',
              onChange: editQuantity.bind(null, initial, 'reusable'),
            },
          ]
        : []),
      ...(initial && showCerfa && cerfaWaste && cerfaWaste.length > 1
        ? [
            {
              type: ItemType.select,
              label: t('materials:attributes.quantities.cerfaWaste'),
              key: 'cerfaWaste',
              onChange: editQuantity.bind(null, initial, 'cerfaWaste'),
              formatValue: (cerfaWaste: number) => cerfaWaste ?? 0,
              props: {
                maxWidth: '200px',
                placeholder: getCerfaName(0),
                items: cerfaWaste.map((_: CerfaWaste, cerfaWasteIndex: number) => {
                  return {
                    value: cerfaWasteIndex,
                    label: getCerfaName(cerfaWasteIndex),
                  }
                }),
              },
            },
          ]
        : []),
      ...(wasteOnly
        ? []
        : [
            (materialQuantity: MaterialQuantity | undefined) => ({
              type: materialQuantity?.reusable === false ? ItemType.typo : ItemType.select,
              formatValue: materialQuantity?.reusable === false ? () => '' : undefined,
              key: 'quality',
              minWidth: '120px',
              label: t('materials:attributes.quantities.quality'),
              onChange: editQuantity.bind(null, initial, 'quality'),
              props: {
                items: createOptionsFromEnum(MaterialQuality, 'materials:quality'),
                disabled: !!materialQuantity?.order,
              },
            }),
          ]),
    ],
    [t, editQuantity, unit, showCerfa, cerfaWaste, getCerfaName, wasteOnly],
  )

  return (
    <Box width="100%">
      <Typography variant="subtitle2">
        {t('materials:components.tableQuantities.initial')}
      </Typography>

      {t('materials:components.tableQuantities.initialDescription')}
      <Table
        disableScroll
        valueActions={valueAction}
        values={{
          count: initialQuantities?.length || 0,
          total: initialQuantities?.length || 0,
          data: initialQuantities ?? [],
        }}
        columns={columns()}
        paginationValue={{ disablePaginate: true }}
      />
      <LinkButton onClick={addQuantity.bind(null, true)}>
        {t('materials:components.tableQuantities.addInitial')}
      </LinkButton>

      {!wasteOnly && (
        <>
          <Typography variant="subtitle2">
            {t('materials:components.tableQuantities.current')}
          </Typography>
          {t('materials:components.tableQuantities.currentDescription')}
          <Table
            disableScroll
            valueActions={valueAction}
            values={{
              count: currentQuantities?.length || 0,
              total: currentQuantities?.length || 0,
              data: currentQuantities ?? [],
            }}
            columns={columns(false)}
            paginationValue={{ disablePaginate: true }}
          />
          <LinkButton onClick={addQuantity.bind(null, false)} sx={{ marginBottom: '12px' }}>
            {t('materials:components.tableQuantities.addCurrent')}
          </LinkButton>
        </>
      )}
    </Box>
  )
}

export default memo(MaterialTableQuantities)
