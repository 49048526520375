import {
  useMemo,
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import {
  Search as SearchIcon,
  SearchOff as SearchOffIcon,
  Map as MapIcon,
  ViewModule as ViewModuleIcon,
  ViewList as ViewListIcon,
  Save as SaveIcon,
  FilterAlt as FilterAltIcon,
} from '@mui/icons-material'
import {
  Paper,
  Box,
  Grid,
  useMediaQuery,
  styled,
  alpha,
  IconButton,
  InputLabel,
} from '@mui/material'
import useSnackbar from '../../hooks/useSnackbar.hooks'
import Constants from '../../constants'
import { useTheme } from '@mui/material/styles/index.js'
import {
  Point,
  Pagination,
  PaginationParams,
  FilterableRoute,
  Mode,
} from '../../models/commons.models'
import { Filter, FilterItem, Actions, ColumnItem } from '../../models/props.models'
import { sessionService } from '../../store/session'
import ArrayItem from './item/Array.item'
import Stack from './Stack.common'
import InfiniteScroll from './InfiniteScroll.common'
import Loader from './Loader.common'
import Table from './Table.common'
import MapComponent from './Map.common'
import CheckboxInput from './input/Checkbox.input'
import Modal from '../layout/Modal.layout'
import LinkButton from './button/Link.button'
import LoaderOverlay from '../layout/LoaderOverlay.layout'

const AsideFilter = styled(Stack)<{ width: string }>(({ theme, width }) => ({
  borderRight: `1px solid ${theme.palette.lightBorder}`,
  boxShadow: `0px 2px 20px ${alpha(theme.palette.black, 0.05)}`,
  backgroundColor: theme.palette.white,
  minWidth: width,
  width,
  padding: '23px',
  overflowY: 'auto',
  overflowX: 'hidden',
  flexGrow: 1,
}))

export type ListRef = {
  updateValues: () => void
  filter: Record<string, any>
  modeKey: string
  updateMode: (modeKey: string) => void
  setFilter: React.Dispatch<React.SetStateAction<Record<string, any>>>
}
export enum ListModeType {
  table,
  card,
  map,
}
export type ListMode = { key: string; icon?: (selected: boolean) => JSX.Element } & (
  | {
      type: ListModeType.table
      columns: ColumnItem[]
    }
  | {
      type: ListModeType.card
      card: React.FC<any>
    }
  | {
      type: ListModeType.map
      card: React.FC<any>
      getCoordinates: (value: any) => Point
    }
)

export type ListProps = {
  modeKey?: string
  modes: ListMode[]
  getCardKey?: (value: any) => string
  filterKey?: FilterableRoute
  noLoading?: boolean
  withTabs?: boolean
  withGroups?: boolean
  canChooseCountry?: boolean
  disableTableScroll?: boolean
  disabledPagination?: boolean
  loading?: boolean
  children?: JSX.Element | JSX.Element[]
  onValueClick?: (value: any) => void
  onSecondValueClick?: (value: any) => void
  valueActions?: (value: any) => Actions | undefined
  isValueSelected?: (value: any) => boolean
  onValueSelect?: (value: any) => void
  valueIcon?: (value: any) => JSX.Element | undefined
  filter?: Filter
  onFilterChange?: (
    filter: Record<string, any>,
    setFilter: React.Dispatch<React.SetStateAction<Record<string, any>>>,
  ) => void
  getValues: (filterAndPagination: any) => Promise<Pagination> | Pagination
  getSecondListValues?: (filterAndPagination: any) => Promise<Pagination> | Pagination
  secondInfo?: (total: number) => JSX.Element
  disabledComponent?: JSX.Element | JSX.Element[]
  isSecondary?: (value: any) => boolean
}

const defaultItemsPerPage = 20
const getDefaultMode = (modes: ListMode[], onlyXs: boolean, modeKey?: string): ListMode => {
  return (
    modes.find((mode) => mode.key === modeKey) ??
    (onlyXs
      ? modes.find((mode) => mode.type === ListModeType.card)
      : modes.find((mode) => mode.type === ListModeType.table)) ??
    modes[0] ?? { key: 'none', type: ListModeType.card, card: () => <></> }
  )
}
const List = forwardRef<ListRef, ListProps>(
  (
    {
      loading: propsLoading,
      noLoading,
      withTabs,
      withGroups,
      disableTableScroll,
      disabledPagination,
      getCardKey,
      children,
      isSecondary,
      onValueClick,
      onSecondValueClick,
      valueActions,
      isValueSelected,
      onValueSelect,
      filter,
      onFilterChange,
      getValues,
      getSecondListValues,
      secondInfo,
      canChooseCountry,
      disabledComponent,
      filterKey,
      valueIcon,
      modes,
      modeKey,
    },
    ref,
  ) => {
    const theme = useTheme()
    const downMd = useMediaQuery(theme.breakpoints.down('md'))
    const onlyXs = useMediaQuery(theme.breakpoints.only('xs'))
    const { t } = useTranslation()
    const cardContainerRef = useRef(null)

    const location = useLocation()
    const locationRef = useRef(location.pathname)
    useEffect(() => {
      locationRef.current = location.pathname
    }, [location])

    const [modalFilter, setModalFilter] = useState<boolean>(false)
    const [currentMode, setCurrentMode] = useState<ListMode>(
      getDefaultMode(modes, onlyXs, sessionService.getModeKey(locationRef.current) ?? modeKey),
    )
    useEffect(() => {
      if (!modes.find((mode) => currentMode.key === mode.key)) {
        setCurrentMode(getDefaultMode(modes, onlyXs, currentMode.key))
      }
    }, [onlyXs, modes, currentMode])
    useEffect(() => {
      sessionService.setModeKey(locationRef.current, currentMode.key)
    }, [currentMode])
    const mapMode = useMemo(() => modes.find((mode) => mode.type === ListModeType.map), [modes])

    const show = useSnackbar()

    const [asideOpen, setAsideOpen] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(true)
    const [loadingOverlay, setLoadingOverlay] = useState<boolean>(false)

    const saveFilter = useCallback(async () => {
      if (!filterKey) {
        return
      }
      setLoadingOverlay(true)
      try {
        await sessionService.saveFilter(filterKey)
        show(t('global:components.list.filterSaved'), 'success')
      } catch (err: any) {
        show(err)
      }
      setLoadingOverlay(false)
    }, [t, show, filterKey])

    const [values, setValues] = useState<Pagination>({
      data: [],
      total: 0,
      count: 0,
    })
    const [secondValues, setSecondValues] = useState<Pagination | undefined>(undefined)
    const fetchDirectrly = useRef<boolean>(false)

    // count cardPerRow to justify left adding phantom card(maybe not the best solution)
    const [cardPerRow, setCardPerRow] = useState(0)
    const [cardWidth, setCardWidth] = useState(0)
    const [listWidth, setListWidth] = useState(0)
    const [justifyLoading, setJustifyLoading] = useState(true)
    useEffect(() => {
      function handleResize() {
        if (currentMode.type === ListModeType.card) {
          setJustifyLoading(true)
          const container = cardContainerRef.current as any
          const cardContainer = container?.children?.[0]
          const card = cardContainer?.children?.[0]?.children?.[0].children?.[0]
          const total = container?.clientWidth
          const cardWidth = card?.clientWidth

          setListWidth(total ?? 0)
          if (!total || !cardWidth) {
            setCardPerRow(0)
            setCardWidth(0)
            if (values?.count > 0) {
              setTimeout(handleResize, 50)
            } else {
              setJustifyLoading(false)
            }
          } else {
            // 16 is the padding
            const cardPerRow = Math.floor(total / (cardWidth + 16))
            setCardPerRow(cardPerRow)
            setCardWidth(cardWidth)
            setJustifyLoading(false)
          }
        }
      }

      handleResize()
      window.addEventListener('resize', handleResize)
      return () => window.removeEventListener('resize', handleResize)
    }, [currentMode, values])

    const [infiniteScrollPage, setInfiniteScrollPage] = useState<{ first: number; second: number }>(
      { first: 0, second: -1 },
    )
    const [paginationValue, setPaginationValue] = useState<PaginationParams>({
      page: 0,
      itemsPerPage: defaultItemsPerPage,
      disablePaginate: disabledPagination,
    })
    const updatePaginationValue = useCallback((val: any, direct = true) => {
      fetchDirectrly.current = direct
      setPaginationValue(val)
      setInfiniteScrollPage({ first: 0, second: -1 })
      setSecondValues(undefined)
    }, [])

    const updateMode = useCallback(
      async (mode: ListMode) => {
        setCurrentMode(mode)

        if (infiniteTimeoutRef.current) {
          clearTimeout(infiniteTimeoutRef.current)
        }

        // will trigger fetch values (directly)
        updatePaginationValue((paginationValue: any) => ({
          ...paginationValue,
          page: 0,
        }))
      },
      [updatePaginationValue],
    )

    const [filterValue, setFilterValue] = useState<Record<string, any>>(
      sessionService.getFilter(locationRef.current),
    )
    const updateFilterValue = useCallback(
      (val: any) => {
        // will trigger fetch values (with debounce)
        updatePaginationValue(
          (paginationValue: any) => ({
            ...paginationValue,
            page: 0,
          }),
          false,
        )
        setFilterValue(val)
      },
      [updatePaginationValue],
    )

    const timeoutRef = useRef<any>()
    const infiniteTimeoutRef = useRef<any>()
    const fetchValues = useCallback(
      async (paginationValue: PaginationParams, second = false) => {
        if (modalFilter) {
          return
        }
        if (!noLoading) {
          setLoading(true)
        }
        let values
        try {
          values = second
            ? await getSecondListValues?.({ ...filterValue, ...paginationValue })
            : await getValues({ ...filterValue, ...paginationValue })
        } catch (err: any) {
          show(err)
        }
        setLoading(false)
        return values
      },
      [noLoading, modalFilter, getSecondListValues, getValues, show, filterValue],
    )
    const updateValues = useCallback(async () => {
      if (disabledComponent) {
        return
      }

      if (currentMode.type === ListModeType.card) {
        // will trigger fetch values (we have to reset page to 0)
        updatePaginationValue((paginationValue: any) => ({
          ...paginationValue,
          page: 0,
        }))
      } else {
        const newValues = await fetchValues(paginationValue)
        if (newValues) {
          setValues(newValues)
        }

        // when table always getSecondListValues
        if (getSecondListValues && currentMode.type === ListModeType.table) {
          const newSecondValues = await fetchValues({ disablePaginate: true }, true)
          if (newSecondValues) {
            setSecondValues(newSecondValues)
          }
        }
      }
    }, [
      disabledComponent,
      currentMode,
      updatePaginationValue,
      fetchValues,
      getSecondListValues,
      paginationValue,
    ])
    useImperativeHandle(ref, () => ({
      updateValues,
      filter: filterValue,
      setFilter: updateFilterValue,
      modeKey: currentMode.key,
      updateMode: (modeKey: string) => {
        const mode = modes.find((mode) => mode.key === modeKey)
        if (mode) {
          updateMode(mode)
        }
      },
    }))

    const onInfiniteNextPage = useCallback(() => {
      if (
        ((values.data.length < values.total && !paginationValue.disablePaginate) ||
          (getSecondListValues &&
            (!secondValues || secondValues.data.length < secondValues.total))) &&
        !loading &&
        !disabledComponent
      ) {
        if (infiniteTimeoutRef.current) {
          clearTimeout(infiniteTimeoutRef.current)
        }

        infiniteTimeoutRef.current = setTimeout(async () => {
          const getSecond = values.data.length === values.total
          const nextPage = (getSecond ? infiniteScrollPage.second : infiniteScrollPage.first) + 1
          setInfiniteScrollPage(({ first, second }) => ({
            first: first + (!getSecond ? 1 : 0),
            second: second + (getSecond ? 1 : 0),
          }))

          fetchDirectrly.current = true
          const newValues = await fetchValues(
            {
              ...paginationValue,
              page: nextPage,
            },
            values.data.length === values.total,
          )
          if (newValues) {
            if (getSecond) {
              setSecondValues((currentValues) =>
                currentValues
                  ? {
                      data: currentValues.data.concat(newValues.data),
                      total: newValues.total,
                      count: currentValues.count + newValues.count,
                    }
                  : newValues,
              )
            } else {
              setValues((currentValues) => ({
                data: currentValues.data.concat(newValues.data),
                total: newValues.total,
                count: currentValues.count + newValues.count,
              }))
            }
          }
          // must be > of !fetchDirectrly delay of 500ms (to not launch second before first)
        }, 1000)
      }
    }, [
      disabledComponent,
      loading,
      getSecondListValues,
      secondValues,
      values,
      paginationValue,
      fetchValues,
      infiniteScrollPage,
    ])
    const onInfiniteNextPageRef = useRef(onInfiniteNextPage)
    useEffect(() => {
      onInfiniteNextPageRef.current = onInfiniteNextPage
    }, [onInfiniteNextPage])
    useEffect(() => {
      if (disabledComponent) {
        return
      }

      onFilterChange?.(filterValue, setFilterValue)
      sessionService.setFilter(locationRef.current, filterValue)

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }

      const getValues = async () => {
        const newValues = await fetchValues(paginationValue)
        if (newValues) {
          setValues(newValues)
        }

        if (getSecondListValues) {
          //force getting second list when all first values were get at the first call (and n scroll are needed)
          if (
            currentMode.type === ListModeType.card &&
            newValues &&
            newValues.data.length === newValues.total
          ) {
            // wait values are really set
            setTimeout(() => {
              onInfiniteNextPageRef.current?.()
            }, 10)
          } else if (currentMode.type === ListModeType.table) {
            const newSecondValues = await fetchValues({ disablePaginate: true }, true)
            if (newSecondValues) {
              setSecondValues(newSecondValues)
            }
          }
        }
      }
      // only filter will be debounce
      if (!fetchDirectrly.current) {
        timeoutRef.current = setTimeout(() => {
          getValues()
          fetchDirectrly.current = true
        }, 500)
      } else {
        getValues()
      }
    }, [
      disabledComponent,
      currentMode,
      getSecondListValues,
      onFilterChange,
      fetchValues,
      filterValue,
      paginationValue,
    ])

    const filterInfo =
      filter && filterValue && filter.info ? filter.info(filterValue, values.total) : undefined

    const topFilters = useMemo(() => {
      return filter?.items.filter((filterItem: FilterItem) => !filterItem.position) ?? []
    }, [filter])
    const asideFilters = useMemo(() => {
      return filter?.items.filter((filterItem: FilterItem) => filterItem.position === 'aside') ?? []
    }, [filter])
    const modalFilters = useMemo(() => {
      return filter?.items.filter((filterItem: FilterItem) => filterItem.position === 'modal') ?? []
    }, [filter])
    const hasTopFilter = topFilters.length > 0 && filterValue
    const hasAsideFilter = asideFilters.length > 0 && filterValue
    const hasModalFilter = modalFilters.length > 0 && filterValue
    const hasModalFilledFilter = useMemo(() => {
      return !!modalFilters.find(
        (filterItem: FilterItem) =>
          (filterValue &&
            (!Array.isArray(filterValue[filterItem.key]) ||
              filterValue[filterItem.key].length > 0) &&
            (!!filterValue[filterItem.key] ||
              filterValue[filterItem.key] === false ||
              filterValue[filterItem.key] === 0)) ||
          filterItem.isFilled,
      )
    }, [modalFilters, filterValue])
    const reinitModalFilters = useCallback(() => {
      setFilterValue((val) => {
        modalFilters.forEach((filterItem: FilterItem) => {
          val[filterItem.key] = undefined
        })
        return { ...val }
      })
    }, [modalFilters])

    const closedAside = !disabledComponent && hasAsideFilter && (
      <Box
        position="absolute"
        zIndex={1}
        top={filterInfo || hasTopFilter ? '15px' : '-5px'}
        left={filterInfo || hasTopFilter ? '5px' : '-5px'}>
        <IconButton size="large" onClick={() => setAsideOpen(true)}>
          <SearchIcon color="primary" />
        </IconButton>
      </Box>
    )
    const selectAll = isValueSelected && onValueSelect && (
      <Box display="flex" justifyContent="cetner" alignItems="center">
        <CheckboxInput
          value={isValueSelected('all')}
          label={t('global:inputs.selectAll')}
          onChange={onValueSelect}
        />
      </Box>
    )

    const usefilterKey = Constants.mode === Mode.admin && filterKey
    const endAction = (modes.length > 1 || hasModalFilter || usefilterKey) && (
      <Box ml="auto" display="flex" mb="auto">
        {usefilterKey && (
          <IconButton size="large" onClick={saveFilter}>
            <SaveIcon />
          </IconButton>
        )}
        {hasModalFilter && (
          <IconButton size="large" onClick={setModalFilter.bind(null, true)}>
            <FilterAltIcon color={hasModalFilledFilter ? 'primary' : 'inherit'} />
          </IconButton>
        )}
        {modes.length > 1 &&
          modes.map((mode) => {
            const selected = currentMode.key === mode.key
            return (
              <Box onClick={!selected ? updateMode.bind(null, mode) : undefined} key={mode.key}>
                {(mode.icon && mode.icon(selected)) ?? (
                  <IconButton size="large">
                    {mode.type === ListModeType.table ? (
                      <ViewListIcon color={selected ? 'primary' : 'inherit'} />
                    ) : mode.type === ListModeType.card ? (
                      <ViewModuleIcon color={selected ? 'primary' : 'inherit'} />
                    ) : (
                      <MapIcon color={selected ? 'primary' : 'inherit'} />
                    )}
                  </IconButton>
                )}
              </Box>
            )
          })}
      </Box>
    )

    const CardComponent = useMemo(() => {
      return ({ value, second }: any) => {
        if (currentMode.type === ListModeType.card || currentMode.type === ListModeType.map) {
          return currentMode.card({
            value,
            ...(second
              ? {
                  onClick: onSecondValueClick?.bind(null, value),
                }
              : {
                  actions: valueActions?.(value) || undefined,
                  selected: isValueSelected?.(value) || false,
                  onClick: onValueClick?.bind(null, value),
                  onSelect: onValueSelect?.bind(null, value),
                  icon: valueIcon?.(value),
                }),
          })
        }
        return <></>
      }
    }, [
      currentMode,
      valueIcon,
      valueActions,
      isValueSelected,
      onSecondValueClick,
      onValueClick,
      onValueSelect,
    ])

    const CardList = useMemo(() => {
      const computeCard = (data: any[], type = 'first') => {
        const cards = data.map((value) => (
          <Box
            key={`${type}-${getCardKey ? getCardKey(value) : value._id}`}
            sx={{ visibility: justifyLoading ? 'hidden' : '' }}>
            <CardComponent value={value} second={type === 'second'} />
          </Box>
        ))
        if (!onlyXs && cards.length % cardPerRow) {
          const toAdd = cardPerRow - (cards.length % cardPerRow)
          for (let i = 0; i < toAdd; i++) {
            cards.push(
              <Box key={`${type}-justifyLeft-${i}`}>
                <Box sx={{ width: `${cardWidth}px` }} />
              </Box>,
            )
          }
        }
        return cards
      }
      let cards = computeCard(values.data)

      if (secondValues && secondValues.data.length > 0 && secondInfo) {
        cards.push(
          <Box
            key="second-title"
            sx={{
              minWidth: `${listWidth}px`,
              borderTop: `1px solid ${Constants.colors.menuBorder}`,
            }}>
            {secondInfo(secondValues.total)}
          </Box>,
        )
        cards = cards.concat(computeCard(secondValues.data, 'second'))
      }
      return cards
    }, [
      listWidth,
      getCardKey,
      secondInfo,
      justifyLoading,
      onlyXs,
      secondValues,
      values,
      CardComponent,
      cardPerRow,
      cardWidth,
    ])

    const openAside = hasAsideFilter && (
      <Box
        flexGrow="1"
        display="flex"
        flexDirection="column"
        position={downMd ? 'absolute' : 'relative'}
        sx={{ backgroundColor: alpha(Constants.colors.black, 0.2) }}
        top="0"
        left="0"
        right="0"
        bottom="0"
        zIndex="1"
        onClick={() => setAsideOpen(false)}>
        <AsideFilter width="310px" flexGrow="1" onClick={(e: any) => e.stopPropagation()}>
          <ArrayItem items={asideFilters} value={filterValue} setValue={updateFilterValue}>
            {mapMode && currentMode.type !== ListModeType.map ? (
              <Grid item width="100%" justifyContent="center" mb="15px" alignItems="center">
                <InputLabel>{t('global:inputs.location')}</InputLabel>
                <Paper sx={{ position: 'relative', width: '100%', height: '250px' }}>
                  <MapComponent
                    value={filterValue.location}
                    onFullScreen={updateMode.bind(null, mapMode)}
                    canChooseCountry={canChooseCountry}
                    onChange={(value) =>
                      updateFilterValue((filter: any) => ({
                        ...filter,
                        location: value,
                      }))
                    }
                    markers={values.data.map((value: any, index: number) => {
                      const coordinates = (mapMode as any).getCoordinates?.(value) || [0, 0]
                      return {
                        id: index + '',
                        secondary: isSecondary && isSecondary(value),
                        onClick: () => {
                          onValueClick?.(value)
                        },
                        lat: coordinates[0],
                        lng: coordinates[1],
                      }
                    })}
                  />
                </Paper>
              </Grid>
            ) : undefined}
          </ArrayItem>
        </AsideFilter>

        {downMd && (
          <IconButton
            onClick={() => setAsideOpen(false)}
            sx={{ position: 'absolute', top: '5px', left: '255px' }}>
            <SearchOffIcon color="primary" />
          </IconButton>
        )}
        {disabledComponent && (
          <Box
            sx={{
              position: 'absolute',
              top: '0',
              left: '0',
              right: '0',
              bottom: '0',
              zIndex: '2',
              backgroundColor: 'white',
              opacity: 0.5,
            }}
          />
        )}
      </Box>
    )

    return (
      <Box display="flex" flexGrow="1">
        {loadingOverlay && <LoaderOverlay />}
        <Box width="100%" display="flex" overflow="hidden" flexGrow="1" position="relative">
          {downMd && !asideOpen && closedAside}
          {(!downMd || asideOpen) && openAside}

          <Box
            padding={`15px ${onlyXs ? '10px' : '15px'}`}
            width="100%"
            display="flex"
            position="relative"
            flexDirection="column"
            flexGrow="1">
            {!disabledComponent && (
              <>
                <Box paddingLeft={hasAsideFilter && downMd && !asideOpen ? '40px' : '0'}>
                  {(hasTopFilter || ((selectAll || endAction) && !filterInfo)) && (
                    <Box mb="15px" display="flex" flexDirection={downMd ? 'column' : 'row'}>
                      {selectAll}
                      {hasTopFilter && (
                        <ArrayItem
                          items={topFilters}
                          value={filterValue}
                          setValue={updateFilterValue}
                        />
                      )}
                      {endAction}
                    </Box>
                  )}
                </Box>
                <Box
                  paddingLeft={
                    hasAsideFilter && downMd && !asideOpen && !hasTopFilter ? '40px' : '0'
                  }>
                  {filterInfo && (
                    <Box mb="15px" display="flex">
                      {filterInfo}
                      {!hasTopFilter && selectAll}
                      {!hasTopFilter && endAction}
                    </Box>
                  )}
                </Box>

                <Box flex={1} position="relative">
                  {currentMode.type === ListModeType.card && (
                    <Box>
                      <Box ref={cardContainerRef}>
                        <InfiniteScroll
                          onNextPage={onInfiniteNextPage}
                          itemsPerPage={paginationValue.itemsPerPage ?? defaultItemsPerPage}
                          justifyContent={onlyXs ? 'space-around' : 'flex-start'}
                          xs={onlyXs ? 12 : undefined}
                          listItems={CardList}
                        />
                      </Box>
                      {(loading || propsLoading) && (
                        <Box position="fixed" right="20px" bottom="20px" zIndex="10">
                          <Loader size={40} />
                        </Box>
                      )}
                    </Box>
                  )}
                  {currentMode.type === ListModeType.table && (
                    <Table
                      valueIcon={valueIcon}
                      disableScroll={disableTableScroll}
                      withTabs={withTabs}
                      withGroups={withGroups}
                      loading={loading || propsLoading}
                      columns={currentMode.columns}
                      values={values}
                      paginationValue={paginationValue}
                      setPaginationValue={updatePaginationValue}
                      onValueClick={onValueClick}
                      valueActions={valueActions}
                      isValueSelected={isValueSelected}
                      onValueSelect={onValueSelect}
                    />
                  )}
                  {currentMode.type === ListModeType.table &&
                    secondValues &&
                    secondValues.data.length > 0 &&
                    secondInfo && (
                      <Box key="second-table">
                        {secondInfo(secondValues.total)}
                        <Table
                          disableScroll={true}
                          loading={loading || propsLoading}
                          columns={currentMode.columns}
                          values={secondValues}
                          paginationValue={{ disablePaginate: true }}
                          onValueClick={onSecondValueClick}
                        />
                      </Box>
                    )}
                  {currentMode.type === ListModeType.map && (
                    <Box
                      height="100%"
                      width="100%"
                      justifyContent="center"
                      mb="15px"
                      alignItems="center"
                      position="relative">
                      <MapComponent
                        showPreview
                        value={filterValue.location}
                        onChange={(value) =>
                          updateFilterValue((filter: any) => ({
                            ...filter,
                            location: value,
                          }))
                        }
                        markers={values.data.map((value: any, index: number) => {
                          const coordinates = currentMode.getCoordinates?.(value) || [0, 0]
                          return {
                            id: value?.id ?? index,
                            secondary: isSecondary && isSecondary(value),
                            preview: () => <CardComponent value={value} />,
                            lat: coordinates[0],
                            lng: coordinates[1],
                          }
                        })}
                      />
                    </Box>
                  )}
                  {children}
                </Box>
              </>
            )}
            {disabledComponent}
          </Box>
        </Box>

        {modalFilter && (
          <Modal
            permanent
            onClose={setModalFilter.bind(null, false)}
            title={t('global:actions.filter')}
            maxWidth="md">
            <LinkButton onClick={reinitModalFilters}>{t('global:actions.reinit')}</LinkButton>
            <ArrayItem items={modalFilters} value={filterValue} setValue={updateFilterValue} />
          </Modal>
        )}
      </Box>
    )
  },
)
export default List
