import axios from 'axios'
import { PlatformReference } from '../models/platforms.models'
export const defaultItemPerPage = 20

export const axiosApi = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  headers: {
    'Content-Type': 'application/json',
  },
})

export const setAuthorization = (token?: string) => {
  axiosApi.defaults.headers.common['Authorization'] = `Bearer ${token}`
}
export const setPlatform = (platform: PlatformReference) => {
  axiosApi.defaults.headers.common['platform'] = platform
}
export const setAppVersion = (appVersion: number) => {
  axiosApi.defaults.headers.common['app-version'] = appVersion
}
export const setLang = (lang: string) => {
  axiosApi.defaults.headers.common['x-lang'] = lang
}
export const sendError = async (errorData: any): Promise<void> => {
  try {
    await axiosApi.post(`/errors`, errorData)
  } catch (err) {}
}
