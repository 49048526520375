import Card from '../common/Card.common'
import { Badge, Box, styled, Typography, alpha } from '@mui/material'
import { Person as PersonIcon } from '@mui/icons-material'
import { User, getUserTypeColor, UserStatus } from '../../models/users.models'
import { Actions } from '../../models/props.models'
import { useTranslation } from 'react-i18next'

const OneLineTypography = styled(Typography)({
  overflow: 'hidden',
  textAlign: 'center',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  width: '100%',
})

const CompanyLabel = styled(OneLineTypography)({
  fontSize: '0.75rem',
  fontWeight: 400,
})
const NameLabel = styled(OneLineTypography)({
  fontSize: '0.875rem',
  whiteSpace: 'normal',
  fontWeight: 600,
})
const OtherInfoLabel = styled(OneLineTypography)(({ theme }) => ({
  fontSize: '0.75rem',
  color: theme.palette.text.secondary,
  fontWeight: 400,
}))

const RoleLabel = styled(OneLineTypography)<{ color: string }>(({ color }) => ({
  color: color,
  fontSize: '0.75rem',
  fontWeight: 400,
  paddingLeft: '4px',
}))

const AvatarBox = styled(Box)<{ color: string }>(({ color }) => ({
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: alpha(color, 0.1),
  borderRadius: '50%',
  display: 'flex',
  height: '50px',
  margin: 'auto',
  width: '50px',
  '& > .MuiSvgIcon-root': {
    color: color,
  },
}))

const NotSeenBadge = styled(Badge)({
  position: 'absolute',
  top: 5,
  left: 5,
  zIndex: 10,
  '& .MuiBadge-dot': {
    borderRadius: '50%',
    height: '10px',
    width: '10px',
  },
})

interface UserCardProps {
  value: User
  onClick?: () => void
  actions?: Actions
  onSelect?: () => void
  selected?: boolean
}

const UserCard: React.FC<UserCardProps> = ({
  value,
  onClick,
  actions,
  onSelect,
  selected,
}): JSX.Element => {
  const { t } = useTranslation()
  return (
    <Card
      ariaLabel="user-card"
      onClick={onClick}
      actions={actions}
      onSelect={onSelect}
      selected={selected}
      imagePlaceholder={
        <Box height="100%" display="flex" alignItems="center" justifyContent="center">
          <AvatarBox color={getUserTypeColor(value.type)}>
            <PersonIcon />
          </AvatarBox>
        </Box>
      }>
      <Box>
        {value.status === UserStatus.pending && <NotSeenBadge variant="dot" color="secondary" />}
        <CompanyLabel>{value.organization?.name}</CompanyLabel>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="100%">
          <NameLabel>
            {value.lastname} {value.firstname}
          </NameLabel>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          mt="5px"
          width="100%">
          <OtherInfoLabel>{value.phoneNumber}</OtherInfoLabel>
          <OtherInfoLabel>{value.email}</OtherInfoLabel>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center" mt="10px" maxWidth="100%">
          <Box
            height="5.4px"
            width="5.4px"
            bgcolor={getUserTypeColor(value.type)}
            borderRadius="50%"
          />
          <RoleLabel color={getUserTypeColor(value.type)}>
            {t(`users:types.${value.type}`)}
          </RoleLabel>
        </Box>
      </Box>
    </Card>
  )
}

export default UserCard
