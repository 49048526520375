import home from '../images/home.jpg'
import cycle1 from '../images/cycle1.svg'
import cycle2 from '../images/cycle2.svg'
import cycle3 from '../images/cycle3.svg'
import cycle4 from '../images/cycle4.svg'
import cycle5 from '../images/cycle5.svg'
import cycle6 from '../images/cycle6.svg'

import framework from '../images/framework.png'
import insulation from '../images/insulation.png'
import cover from '../images/cover.png'
import door from '../images/door.png'
import exterior from '../images/exterior.png'
import technical from '../images/technical.png'
import fence from '../images/fence.png'
import interior from '../images/interior.png'
import brick from '../images/brick.png'
import floor from '../images/floor.png'
import location from '../images/location.svg'
import secondaryLocation from '../images/secondaryLocation.svg'
import cluster from '../images/cluster.svg'
import materialIcon from '../images/icon_material.svg'
import handIcon from '../images/icon_hand.svg'
import projectsIcon from '../images/icon_projects.svg'

export const DEFAULT_RESOURCES = {
  exterior,
  technical,
  fence,
  framework,
  insulation,
  cover,
  door,
  interior,
  brick,
  floor,
  location,
  secondaryLocation,
  cluster,
  home,
  cycle1,
  cycle2,
  cycle3,
  cycle4,
  cycle5,
  cycle6,
  materialIcon,
  handIcon,
  projectsIcon,
}
export type Resources = typeof DEFAULT_RESOURCES

export const Category2File = {
  1: 'exterior',
  42: 'brick',
  88: 'fence',
  116: 'door',
  140: 'framework',
  150: 'floor',
  163: 'technical',
  105: 'insulation',
  71: 'cover',
  203: 'interior',
} as Record<number, string>
