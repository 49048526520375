import { useRef, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Edit as EditIcon, Remove as RemoveIcon } from '@mui/icons-material'
import { ListRef } from '../../components/common/List.common'

import MaterialModelList from '../../components/materialModel/List.materialModel'
import Modal from '../../components/layout/Modal.layout'
import ModalFormMaterialModel from '../../components/materialModel/ModalForm.materialModel'
import { ManageMaterialModel, MaterialModel } from '../../models/materialModels.models'
import { Mode } from '../../models/commons.models'
import { Filter, ItemType } from '../../models/props.models'
import { materialModelsService } from '../../store/materialModels'
import useModal from '../../hooks/useModal.hooks'
import useSnackbar from '../../hooks/useSnackbar.hooks'
import useCheckRoute from '../../hooks/useCheckRoute.hooks'
import { AdminHeaderTitle } from '../../components/layout/AdminHeader.layout'
import { sessionQuery, sessionService } from '../../store/session'
import { useObservable } from '@ngneat/react-rxjs'
import { FilterableRoute } from '../../models/commons.models'
import { carbonModelsQuery } from '../../store/carbonModels'
import { Box } from '@mui/material'

const MaterialModelListPage = () => {
  useCheckRoute('MaterialModelList', [Mode.admin])

  const { t } = useTranslation()
  const show = useSnackbar()
  const listRef = useRef<ListRef | null>(null)
  const [useImperials] = useObservable(sessionQuery.useImperials)
  const [carbonModels] = useObservable(carbonModelsQuery.carbonModels)

  const [defaultValue, setDefaultValue] = useState<ManageMaterialModel>()
  const [modal, setModal] = useModal<'add' | 'update' | 'delete'>()
  const showCerfaRef = useRef(sessionService.enableCerfa())

  const filter = useMemo<Filter>(
    () => ({
      items: [
        {
          type: ItemType.search,
          key: 'search',
          grid: { sm: 6, xs: 6 },
          props: {
            placeholder: t('materialModels:pages.list.searchFilter'),
          },
        },
        {
          type: ItemType.button,
          key: 'add',
          grid: { sm: 3, xs: 12 },
          props: {
            fullWidth: true,
            children: t('materialModels:actions.add.label'),
            onClick: () => {
              setDefaultValue(undefined)
              setModal('add')
            },
          },
        },
      ],
    }),
    [t, setModal],
  )

  return (
    <Box>
      <AdminHeaderTitle title={t('global:routes.materialModels')} />
      <MaterialModelList
        ref={listRef}
        filter={filter}
        valueActions={(materialModel: MaterialModel) => ({
          items: [
            {
              onClick: () => {
                const carbonModel = carbonModels.find(
                  (carbonModel) => carbonModel._id === materialModel.carbonModel,
                )
                setDefaultValue({
                  ...materialModel,
                  carbonModel: carbonModel
                    ? { label: carbonModel.name, value: carbonModel._id }
                    : undefined,
                })
                setModal('update')
              },
              label: t('global:actions.edit'),
              icon: <EditIcon />,
            },
            {
              onClick: () => {
                const carbonModel = carbonModels.find(
                  (carbonModel) => carbonModel._id === materialModel.carbonModel,
                )
                setDefaultValue({
                  ...materialModel,
                  carbonModel: carbonModel
                    ? { label: carbonModel.name, value: carbonModel._id }
                    : undefined,
                })
                setModal('delete')
              },
              label: t('global:actions.delete'),
              icon: <RemoveIcon />,
            },
          ],
        })}
        getValues={materialModelsService.getMaterialModels}
        filterKey={FilterableRoute.materialModels}
      />
      {(modal === 'add' || (modal === 'update' && defaultValue)) && (
        <ModalFormMaterialModel
          showCerfa={showCerfaRef.current}
          materialModel={defaultValue}
          useImperials={useImperials}
          title={t(`materialModels:actions.${modal}.label`)}
          onSubmit={
            modal === 'add'
              ? materialModelsService.createMaterialModel
              : materialModelsService.updateMaterialModel.bind(
                  null,
                  (defaultValue as MaterialModel)._id,
                )
          }
          onClose={() => setModal('')}
          onSuccess={() => {
            show(t(`materialModels:actions.${modal}.success`))
            listRef.current?.updateValues()
          }}
        />
      )}
      {modal === 'delete' && defaultValue && (
        <Modal
          onClose={() => setModal('')}
          onConfirm={materialModelsService.deleteMaterialModel.bind(null, defaultValue._id)}
          onSuccess={() => {
            show(t('materialModels:actions.delete.success'))
            listRef.current?.updateValues()
          }}
          title={t('materialModels:actions.delete.label')}
          description={t('materialModels:actions.delete.description')}
        />
      )}
    </Box>
  )
}
export default MaterialModelListPage
