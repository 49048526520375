import { Assembly, Material } from './materials.models'
import { Pagination, Traduction } from './commons.models'
import { LangEnum } from '../constants/langs'
import { SelectOption } from './props.models'
export interface MaterialModel
  extends Pick<
    Material,
    | '_id'
    | 'primaryCategory'
    | 'secondaryCategory'
    | 'tertiaryCategory'
    | 'unit'
    | 'unitWeight'
    | 'density'
    | 'dimensions'
    | 'carbonModel'
    | 'productionCarbon'
    | 'endOfLifeCarbon'
  > {
  name: Traduction
  tracksOfReuse?: Traduction
  technicalDetails?: Traduction[]
  constituentMaterials?: Traduction[]
  wasteOnly?: boolean
  resourcePrimaryCategory?: number
  resourceSecondaryCategory?: number
  resourceTertiaryCategory?: number
  assembly?: Assembly
  wastePrimaryCategory?: number
  wasteSecondaryCategory?: number
  wasteTertiaryCategory?: number
  wasteCode?: string
  asbestosPrecision?: Traduction
  reusePercent?: number
  recyclablePercent?: number
  backfillingPercent?: number
  incineratedWithEnergyPercent?: number
  incineratedWithoutEnergyPercent?: number
  nonRecoverablePercent?: number
}

export interface ManageMaterialModel extends Omit<MaterialModel, 'carbonModel'> {
  carbonModel?: SelectOption
}

export interface TraducedMaterialModel
  extends Omit<
    MaterialModel,
    'tracksOfReuse' | 'name' | 'technicalDetails' | 'constituentMaterials' | 'asbestosPrecision'
  > {
  name: string
  tracksOfReuse?: string
  technicalDetails?: string[]
  constituentMaterials?: string[]
  asbestosPrecision?: string
}

export interface MaterialModelsPagination extends Pagination {
  data: MaterialModel[]
}

export const traduceMaterialModel = (
  materialModel: MaterialModel,
  lang: LangEnum = LangEnum.FR,
): TraducedMaterialModel => {
  return {
    ...materialModel,
    name: materialModel.name[lang] || materialModel.name[LangEnum.FR],
    tracksOfReuse: materialModel.tracksOfReuse
      ? materialModel.tracksOfReuse[lang] || materialModel.tracksOfReuse[LangEnum.FR]
      : undefined,
    asbestosPrecision: materialModel.asbestosPrecision
      ? materialModel.asbestosPrecision[lang] || materialModel.asbestosPrecision[LangEnum.FR]
      : undefined,
    constituentMaterials: materialModel.constituentMaterials
      ? materialModel.constituentMaterials.map((trad) => trad[lang] || trad[LangEnum.FR])
      : undefined,
    technicalDetails: materialModel.technicalDetails
      ? materialModel.technicalDetails.map((trad) => trad[lang] || trad[LangEnum.FR])
      : undefined,
  }
}
