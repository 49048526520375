import { Request, RequestStatus, RequestType } from '../../models/requests.models'
import { Actions } from '../../models/props.models'
import Card from '../common/Card.common'
import DetailsComponent from '../common/Details.common'
import { useTranslation } from 'react-i18next'

interface RequestCardProps {
  value: Request
  onClick?: () => void
  actions?: Actions
  onSelect?: () => void
  selected?: boolean

  received?: boolean
  sent?: boolean
}
const RequestCard = ({
  value,
  onClick,
  actions,
  selected,
  onSelect,
  sent,
  received,
}: RequestCardProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Card
      ariaLabel="request-card"
      actions={actions}
      onClick={onClick}
      onSelect={onSelect}
      selected={selected}>
      <DetailsComponent
        noElevation
        title={t(`requests:type.${value.type}`)}
        value={value}
        items={[
          {
            label: t('requests:attributes.content'),
            key: 'content',
            formatValue: (_: any) =>
              value.type === RequestType.catalogTransfer
                ? value?.catalog?.name
                : value?.user?.email,
          },
          {
            label: t('requests:attributes.status'),
            key: 'status',
            formatValue: (status: RequestStatus) => t(`requests:status.${status}`),
          },
          ...(sent
            ? []
            : [
                {
                  label: t('requests:attributes.sender'),
                  key: 'sender',
                  formatValue: (_: any) =>
                    value?.type === RequestType.catalogTransfer
                      ? value?.sender?.name
                      : value?.user?.email,
                },
              ]),
          ...(received
            ? []
            : [
                {
                  label: t('requests:attributes.receiver'),
                  key: 'receiver',
                  formatValue: (_: any) =>
                    value?.customReceiver?.organization ?? value?.receiver?.name,
                },
              ]),
        ]}
      />
    </Card>
  )
}

export default RequestCard
