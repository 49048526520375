import { AxiosResponse } from 'axios'
import { axiosApi, defaultItemPerPage } from './base.api'
import {
  MaterialType,
  ApiMaterial,
  MaterialsPagination,
  Material,
  MaterialQuality,
  ApiMultipleUpdateMaterial,
} from '../models/materials.models'
import { BuildingType } from '../models/catalogs.models'
import { RetrievalModality, Point, PaginationParams } from '../models/commons.models'
import { PlatformReference } from '../models/platforms.models'

export namespace MaterialsApi {
  interface Params {
    platform?: PlatformReference
    search?: string
    matching?: string
    public?: boolean
    visible?: boolean
    defaultRetrieval?: boolean
    owned?: boolean
    favorites?: boolean
    catalog?: string
    originalCatalog?: string
    type?: MaterialType
    countryCode?: string
    organization?: string
    primaryCategory?: number
    secondaryCategory?: number
    tertiaryCategory?: number
    radius?: Number
    coordinates?: Point
    buildingTypes?: BuildingType[]
    qualities?: MaterialQuality[]
    retrievalModalities?: RetrievalModality[]
    periodEnd?: Date
    periodStart?: Date
  }
  export interface GetListParams extends PaginationParams, Params {}
  export interface ActionListParams extends Params {
    selectAll: boolean
    ids: string[]
  }
  const parseParams = (params?: GetListParams | ActionListParams) => {
    return {
      ...params,
      coordinates: params?.coordinates?.length ? params.coordinates : undefined,
      periodStart: params?.periodStart?.toISOString(),
      periodEnd: params?.periodEnd?.toISOString(),
      buildingTypes: params?.buildingTypes?.length ? params.buildingTypes : undefined,
      qualities: params?.qualities?.length ? params.qualities : undefined,
      retrievalModalities: params?.retrievalModalities?.length
        ? params.retrievalModalities
        : undefined,
    }
  }

  export const getList = async (params?: GetListParams): Promise<MaterialsPagination> => {
    const response: AxiosResponse<MaterialsPagination> = await axiosApi.get(`/materials`, {
      params: {
        disablePaginate: false,
        itemsPerPage: defaultItemPerPage,
        page: 0,
        ...parseParams(params),
      },
    })
    return response.data
  }

  export const getCsv = async (params?: GetListParams): Promise<Blob> => {
    const response: AxiosResponse<Blob> = await axiosApi.get(`/materials/csv`, {
      responseType: 'blob',
      params: parseParams(params),
    })
    return response.data
  }
  export const getById = async (
    materialId: string,
    publicMaterial?: boolean,
  ): Promise<Material> => {
    const response: AxiosResponse<Material> = await axiosApi.get(
      `/materials/${materialId}${!!publicMaterial ? `?public=true` : ''}`,
    )
    return response.data
  }
  export const create = async (createdMaterial: ApiMaterial): Promise<Material> => {
    const response: AxiosResponse<Material> = await axiosApi.post(`/materials`, createdMaterial)
    return response.data
  }
  export const update = async (
    materialId: string,
    updatedMaterial: Partial<ApiMaterial>,
  ): Promise<Material> => {
    const response: AxiosResponse<Material> = await axiosApi.patch(
      `/materials/${materialId}`,
      updatedMaterial,
    )
    return response.data
  }
  export const remove = async (materialId: string): Promise<void> => {
    await axiosApi.delete(`/materials/${materialId}`)
  }

  export const removeMany = async (params: ActionListParams): Promise<void> => {
    await axiosApi.delete(`/materials`, {
      params: {
        ...parseParams(params),
      },
    })
  }
  export const updateMany = async (
    params: ActionListParams,
    updatedMaterial: Partial<ApiMultipleUpdateMaterial>,
  ): Promise<Material[]> => {
    const response: AxiosResponse<Material[]> = await axiosApi.patch(
      `/materials`,
      updatedMaterial,
      {
        params: {
          ...parseParams(params),
        },
      },
    )
    return response.data
  }
  export const transfer = async (
    params: ActionListParams,
    catalogId: string,
  ): Promise<Material[]> => {
    const response: AxiosResponse<Material[]> = await axiosApi.patch(
      `/materials/transfer`,
      { catalog: catalogId },
      {
        params: {
          ...parseParams(params),
        },
      },
    )
    return response.data
  }
}
