import { useState, useEffect, useRef, useCallback, memo } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Box,
  Checkbox,
  useMediaQuery,
} from '@mui/material'
import { useTheme } from '@mui/material/styles/index.js'

import Loader from './Loader.common'
import Actions from './Actions.common'

import TypedItem from './item/Typed.item'

import { ObjectUtils } from '../../utils/commons.utils'
import Constants from '../../constants'
import {
  ColumnItem,
  Actions as ActionsInterface,
  ItemType,
  BaseColumnItem,
} from '../../models/props.models'
import { Pagination, PaginationParams } from '../../models/commons.models'

interface RowProps {
  value: any
  valueIndex: number
  selectable: boolean
  computeColumns: (value: any) => BaseColumnItem[]
  onValueClick?: (value: any, index: number) => void
  valueActions?: (value: any) => ActionsInterface | undefined
  isValueSelected?: (value: any) => boolean
  onValueSelect?: (value: any) => void
  valueIcon?: (value: any) => JSX.Element | undefined
}
const MemoRow = memo(
  ({
    value,
    computeColumns,
    isValueSelected,
    valueIndex,
    selectable,
    onValueClick,
    onValueSelect,
    valueActions,
    valueIcon,
  }: RowProps) => {
    return (
      <TableRow
        hover
        tabIndex={-1}
        selected={isValueSelected?.(value)}
        onClick={onValueClick?.bind(null, value, valueIndex)}
        sx={{ cursor: onValueClick ? 'pointer' : undefined }}>
        {selectable && (
          <TableCell padding="checkbox" onClick={(event: any) => event.stopPropagation()}>
            <Checkbox
              color="primary"
              checked={isValueSelected?.(value)}
              onChange={() => onValueSelect?.(value)}
            />
          </TableCell>
        )}
        {computeColumns(value).map(({ key, ...column }) => {
          return (
            <TableCell key={`${key}.${valueIndex}`}>
              <TypedItem
                type={column.type || ItemType.typo}
                {...column}
                value={ObjectUtils.getKeyValue(value, key)}
                onChange={column?.onChange?.bind(null, valueIndex)}
                parentValue={value}
              />
            </TableCell>
          )
        })}
        {valueActions && (
          <TableCell padding="checkbox" onClick={(event: any) => event.stopPropagation()}>
            {(() => {
              const actions = valueActions(value)
              if (actions) {
                return <Actions action={actions} />
              }
              return <></>
            })()}
          </TableCell>
        )}

        {valueIcon && (
          <TableCell padding="checkbox" onClick={(event: any) => event.stopPropagation()}>
            {valueIcon?.(value)}
          </TableCell>
        )}
      </TableRow>
    )
  },
)

interface TableProps {
  disableScroll?: boolean
  withTabs?: boolean
  withGroups?: boolean
  loading?: boolean
  values: Pagination
  columns: ColumnItem[]
  setPaginationValue?: React.Dispatch<React.SetStateAction<PaginationParams>>
  paginationValue?: PaginationParams
  onValueClick?: (value: any, index: number) => void
  valueActions?: (value: any) => ActionsInterface | undefined
  isValueSelected?: (value: any) => boolean
  onValueSelect?: (value: any) => void
  valueIcon?: (value: any) => JSX.Element | undefined
}
const TableComponent: React.FC<TableProps> = ({
  disableScroll,
  paginationValue,
  loading,
  setPaginationValue,
  values,
  columns,
  withTabs,
  withGroups,
  onValueClick,
  valueActions,
  isValueSelected,
  onValueSelect,
  valueIcon,
}): JSX.Element => {
  const { t } = useTranslation()
  const theme = useTheme()
  const downLg = useMediaQuery(theme.breakpoints.down('lg'))

  const [maxHeight, setMaxHeight] = useState<string>('none')
  const tableRef = useRef<any>()
  const paginationRef = useRef<any>()
  useEffect(() => {
    if (disableScroll || downLg) {
      return
    }
    function computeMaxHeight() {
      let subtract = `- ${Constants.ui.appBarSize}px`
      const list = tableRef.current?.parentNode?.parentNode
      if (list && paginationRef.current) {
        subtract += `  - ${paginationRef.current.offsetHeight}px`
        const styleList = list.currentStyle || window.getComputedStyle(list)
        if (styleList['padding-top']) {
          subtract += ` - ${styleList['padding-top']}`
        }
        if (styleList['padding-bottom']) {
          subtract += `  - ${styleList['padding-bottom']}`
        }

        const siblings = list.children
        if (siblings) {
          for (let i = 0; i < siblings.length - 1; i++) {
            subtract += `  - ${siblings[i].offsetHeight}px`
          }
        }
        if (withTabs) {
          // 49 is the height of tabs (should be done better ^^')
          subtract += `  - 49px`
          if (withGroups) {
            subtract += `  - 36px`
          }
        }
        setMaxHeight(`calc(100vh  ${subtract})`)
      }
    }

    computeMaxHeight()
    window.addEventListener('resize', computeMaxHeight)
    return () => {
      window.removeEventListener('resize', computeMaxHeight)
    }
  }, [disableScroll, downLg, withTabs, withGroups])

  const hasValues = values.total > 0
  const selectable = !!isValueSelected && !!onValueSelect
  const oneCheck = selectable && !!values.data.find((value) => isValueSelected(value))
  const allCheck = selectable && hasValues && !values.data.find((value) => !isValueSelected(value))
  const toogleAll = () => {
    for (let i = 0; i < values.data.length; i++) {
      if (allCheck || !isValueSelected?.(values.data[i])) {
        onValueSelect?.(values.data[i])
      }
    }
  }
  const computeColumns = useCallback(
    (value?: any) => {
      return columns.map((column: ColumnItem) =>
        typeof column === 'function' ? column(value) : column,
      )
    },
    [columns],
  )

  return (
    <>
      <TableContainer sx={{ maxHeight: maxHeight }} ref={tableRef}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {selectable && (
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    indeterminate={oneCheck}
                    checked={allCheck}
                    onChange={toogleAll}
                  />
                </TableCell>
              )}
              {computeColumns().map((column) => (
                <TableCell key={column.key} sx={{ minWidth: column.minWidth }}>
                  {column.label}
                </TableCell>
              ))}
              {valueActions && <TableCell padding="checkbox" />}
              {valueIcon && <TableCell padding="checkbox" />}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading && (
              <TableRow>
                <TableCell
                  colSpan={columns.length + (selectable ? 1 : 0) + (valueActions ? 1 : 0)}
                  sx={{ border: 'none' }}>
                  <Box display="flex" justifyContent="center">
                    <Loader size={40} />
                  </Box>
                </TableCell>
              </TableRow>
            )}
            {!hasValues && !loading && (
              <TableRow>
                <TableCell
                  colSpan={columns.length + (selectable ? 1 : 0) + (valueActions ? 1 : 0)}
                  align="center"
                  sx={{ border: 'none' }}>
                  {t('global:inputs.noData')}
                </TableCell>
              </TableRow>
            )}
            {hasValues &&
              !loading &&
              values.data.map((value, valueIndex) => (
                <MemoRow
                  key={valueIndex}
                  value={value}
                  valueIcon={valueIcon}
                  computeColumns={computeColumns}
                  isValueSelected={isValueSelected}
                  valueIndex={valueIndex}
                  onValueClick={onValueClick}
                  selectable={selectable}
                  onValueSelect={onValueSelect}
                  valueActions={valueActions}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {setPaginationValue && paginationValue && !paginationValue.disablePaginate && (
        <TablePagination
          ref={paginationRef}
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={values.total}
          rowsPerPage={paginationValue?.itemsPerPage ?? 20}
          page={paginationValue?.page ?? 0}
          labelRowsPerPage={t('global:inputs.itemsPerPage')}
          onPageChange={(_: unknown, newPage: number) => {
            setPaginationValue((paginationValue) => ({ ...paginationValue, page: newPage }))
          }}
          onRowsPerPageChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setPaginationValue((paginationValue) => ({
              ...paginationValue,
              itemsPerPage: +event.target.value,
              page: 0,
            }))
          }}
        />
      )}
    </>
  )
}

export default TableComponent
