import { SelectOption } from './props.models'
import i18n from '../utils/i18n.utils'

type Cat = {
  id: number
  name: string
  children?: Cat[]

  abbreviation?: string
  unite?: any
  exemples?: any
  label?: any
}

export const Category: Cat[] = [
  {
    id: 1,
    name: 'Aménagements extérieurs, VRD',
    children: [
      {
        id: 2,
        name: 'Voirie',
        children: [
          {
            id: 3,
            name: 'Granulat',
          },
          {
            id: 4,
            name: 'Bordure',
          },
          {
            id: 5,
            name: 'Signalisation',
          },
          {
            id: 6,
            name: 'Couche de fome, assise',
          },
          {
            id: 7,
            name: 'Autre élément de voirie',
          },
        ],
      },
      {
        id: 8,
        name: 'Revêtement de voirie',
        children: [
          {
            id: 9,
            name: 'Enrobé',
          },
          {
            id: 10,
            name: 'Voirie béton',
          },
          {
            id: 11,
            name: 'Pierre',
          },
          {
            id: 12,
            name: 'Autre revêtement de voierie',
          },
        ],
      },
      {
        id: 13,
        name: 'Réseaux divers',
        children: [
          {
            id: 14,
            name: 'Adduction, évacuation (VRD)',
          },
          {
            id: 15,
            name: 'Gaine, fourreau',
          },
          {
            id: 16,
            name: 'Regard, chambre',
          },
          {
            id: 17,
            name: 'Caniveau',
          },
          {
            id: 18,
            name: 'Autre élément de réseaux divers',
          },
        ],
      },
      {
        id: 19,
        name: 'Paysage',
        children: [
          {
            id: 20,
            name: 'Terre',
          },
          {
            id: 21,
            name: 'Végétaux',
          },
          {
            id: 22,
            name: 'Paillage',
          },
          {
            id: 23,
            name: 'Clôture',
          },
          {
            id: 24,
            name: 'Portail, portillon',
          },
          {
            id: 25,
            name: 'Revêtement sportif et de jeux',
          },
          {
            id: 26,
            name: 'Autre élément de paysage',
          },
        ],
      },
      {
        id: 27,
        name: 'Stockage',
        children: [
          {
            id: 28,
            name: 'Cuve, réservoir, citerne',
          },
          {
            id: 29,
            name: 'Puits',
          },
          {
            id: 30,
            name: 'Bassin paysager',
          },
          {
            id: 31,
            name: 'Autre élément de stockage',
          },
        ],
      },
      {
        id: 32,
        name: 'Maçonnerie extérieure',
        children: [
          {
            id: 33,
            name: 'Soutènement',
          },
          {
            id: 34,
            name: 'Autre élément de maçonnerie extérieure',
          },
        ],
      },
      {
        id: 35,
        name: 'Mobilier extérieur',
        children: [
          {
            id: 36,
            name: 'Rack à vélo',
          },
          {
            id: 37,
            name: 'Arceau',
          },
          {
            id: 38,
            name: 'Banc',
          },
          {
            id: 39,
            name: 'Abri',
          },
          {
            id: 40,
            name: 'Mobilier sportif et de jeux',
          },
          {
            id: 41,
            name: 'Autre élément de mobilier extérieur',
          },
        ],
      },
    ],
  },
  {
    id: 42,
    name: 'Structure, Gros œuvre',
    children: [
      {
        id: 43,
        name: 'Fondation et infrastructure',
        children: [
          {
            id: 44,
            name: 'Pieux, micropieux',
          },
          {
            id: 45,
            name: 'Plots, massifs, longrines',
          },
          {
            id: 46,
            name: 'Dallage sur terre plein',
          },
          {
            id: 47,
            name: 'Paroi moulée',
          },
          {
            id: 48,
            name: 'Autre élément de fondation',
          },
        ],
      },
      {
        id: 49,
        name: 'Béton, pierre, maçonnerie',
        children: [
          {
            id: 50,
            name: 'Béton',
          },
          {
            id: 51,
            name: 'Escalier, rampe béton',
          },
          {
            id: 52,
            name: 'Bac acier (plancher collaborant)',
          },
          {
            id: 53,
            name: 'Hourdi, entrevous',
          },
          {
            id: 54,
            name: 'Mur et cloison maçonnés',
          },
          {
            id: 55,
            name: 'Pierre de taille',
          },
          {
            id: 56,
            name: 'Mur traditionnel',
          },
          {
            id: 57,
            name: 'Autre élément de maçonnerie',
          },
        ],
      },
      {
        id: 58,
        name: 'Structure et charpente bois',
        children: [
          {
            id: 59,
            name: "Bois d'œuvre",
          },
          {
            id: 60,
            name: 'Charpente bois',
          },
          {
            id: 61,
            name: 'Plancher traditionnel',
          },
          {
            id: 62,
            name: 'Panneau bois',
          },
          {
            id: 63,
            name: 'Portique',
          },
          {
            id: 64,
            name: 'Planche',
          },
          {
            id: 65,
            name: 'Autre élément de structure bois',
          },
        ],
      },
      {
        id: 66,
        name: 'Structure et charpente métallique',
        children: [
          {
            id: 67,
            name: 'Profilé métal non standard',
          },
          {
            id: 68,
            name: 'Profilé métal standard',
          },
          {
            id: 69,
            name: 'Structure métallique',
          },
          {
            id: 70,
            name: 'Autre élément de structure métallique',
          },
        ],
      },
    ],
  },
  {
    id: 71,
    name: 'Etanchéité, Couverture',
    children: [
      {
        id: 72,
        name: 'Etanchéité',
        children: [
          {
            id: 73,
            name: 'Etanchéité',
          },
          {
            id: 74,
            name: "Protections d'étanchéité",
          },
          {
            id: 75,
            name: "Autre élément d'étanchéité",
          },
        ],
      },
      {
        id: 76,
        name: 'Couverture',
        children: [
          {
            id: 77,
            name: 'Couverture métallique',
          },
          {
            id: 78,
            name: 'Fibro ciment',
          },
          {
            id: 79,
            name: 'Tuile',
          },
          {
            id: 80,
            name: 'Bardeau',
          },
          {
            id: 81,
            name: 'Couverture pierre',
          },
          {
            id: 82,
            name: 'Couverture végétale',
          },
          {
            id: 83,
            name: 'Autre élément de couverture',
          },
        ],
      },
      {
        id: 84,
        name: 'Élément technique de toiture',
        children: [
          {
            id: 85,
            name: 'Fumisterie',
          },
          {
            id: 86,
            name: 'Zinguerie',
          },
          {
            id: 87,
            name: 'Autre élément technique de toiture',
          },
        ],
      },
    ],
  },
  {
    id: 88,
    name: 'Façade, Menuiserie Extérieure',
    children: [
      {
        id: 89,
        name: 'Revêtement de façade',
        children: [
          {
            id: 90,
            name: 'Bardage',
          },
          {
            id: 91,
            name: 'Parement',
          },
          {
            id: 92,
            name: 'Enduit',
          },
          {
            id: 93,
            name: 'Remplissage maçonné',
          },
          {
            id: 94,
            name: 'Mur rideau, VEC',
          },
          {
            id: 95,
            name: 'Autre élément de revêtement de façade',
          },
        ],
      },
      {
        id: 96,
        name: 'Menuiserie extérieure',
        children: [
          {
            id: 97,
            name: 'Fenêtre',
          },
          {
            id: 98,
            name: 'Porte fenêtre',
          },
          {
            id: 99,
            name: 'Porte extérieure',
          },
          {
            id: 100,
            name: 'Châssis composé',
          },
          {
            id: 101,
            name: 'Fenêtre de toit',
          },
          {
            id: 102,
            name: 'Occultation',
          },
          {
            id: 103,
            name: 'Verrière',
          },
          {
            id: 104,
            name: 'Autre élément de menuiserie extérieure',
          },
        ],
      },
    ],
  },
  {
    id: 105,
    name: 'Isolation',
    children: [
      {
        id: 106,
        name: 'Isolation verticale, doublage',
        children: [
          {
            id: 107,
            name: 'Isolation thermique extérieure (ITE)',
          },
          {
            id: 108,
            name: 'Isolation thermique intérieure (ITI)',
          },
          {
            id: 109,
            name: 'Isolation acoustique des cloisons',
          },
          {
            id: 110,
            name: 'Membrane',
          },
          {
            id: 111,
            name: "Autre élément d'isolation verticale",
          },
        ],
      },
      {
        id: 112,
        name: 'Isolation horizontale',
        children: [
          {
            id: 113,
            name: 'Isolation des planchers haut-bas',
          },
          {
            id: 114,
            name: 'Isolation acoustique en faux plafond',
          },
          {
            id: 115,
            name: "Autre élément d'isolation horizontale",
          },
        ],
      },
    ],
  },
  {
    id: 116,
    name: 'Aménagement, menuiserie intérieure',
    children: [
      {
        id: 117,
        name: 'Cloison sèche',
        children: [
          {
            id: 118,
            name: 'Parement de cloison sèche',
          },
          {
            id: 119,
            name: 'Cloison modulaire',
          },
          {
            id: 120,
            name: 'Autre élément de cloison sèche',
          },
        ],
      },
      {
        id: 121,
        name: 'Faux plafond',
        children: [
          {
            id: 122,
            name: 'Parement de faux plafond',
          },
          {
            id: 123,
            name: 'Autre élément de faux plafond',
          },
        ],
      },
      {
        id: 124,
        name: 'Menuiserie intérieure',
        children: [
          {
            id: 125,
            name: 'Bloc porte',
          },
          {
            id: 126,
            name: 'Porte palière intérieure',
          },
          {
            id: 127,
            name: 'Porte ancienne',
          },
          {
            id: 128,
            name: 'Plancher technique',
          },
          {
            id: 129,
            name: 'Garde corps bois',
          },
          {
            id: 130,
            name: 'Mobilier intégré',
          },
          {
            id: 131,
            name: 'Chassis intérieur',
          },
          {
            id: 132,
            name: 'Escalier bois',
          },
          {
            id: 133,
            name: 'Autre menuiserie intérieure',
          },
        ],
      },
      {
        id: 134,
        name: 'Quincaillerie',
        children: [
          {
            id: 135,
            name: 'Poignée',
          },
          {
            id: 136,
            name: 'Barre anti-panique',
          },
          {
            id: 137,
            name: 'Serrure, verrou',
          },
          {
            id: 138,
            name: 'Ferme-porte',
          },
          {
            id: 139,
            name: 'Autre élément quincallerie',
          },
        ],
      },
    ],
  },
  {
    id: 140,
    name: 'Serrurerie,métallerie',
    children: [
      {
        id: 141,
        name: 'Métallerie',
        children: [
          {
            id: 142,
            name: 'Garde-corps métallique',
          },
          {
            id: 143,
            name: 'Escalier métallique',
          },
          {
            id: 144,
            name: 'Barreaudage, grille',
          },
          {
            id: 145,
            name: 'Autre élément de métallerie',
          },
        ],
      },
      {
        id: 146,
        name: 'Serrurerie',
        children: [
          {
            id: 147,
            name: 'Fermeture',
          },
          {
            id: 148,
            name: 'Porte métallique',
          },
          {
            id: 149,
            name: 'Autre élément de serrurerie',
          },
        ],
      },
    ],
  },
  {
    id: 150,
    name: 'Revêtement sols et murs',
    children: [
      {
        id: 151,
        name: 'Revêtement de sol',
        children: [
          {
            id: 152,
            name: 'Sol dur',
          },
          {
            id: 153,
            name: 'Sol souple',
          },
          {
            id: 154,
            name: 'Sols bois, parquet',
          },
          {
            id: 155,
            name: 'Plinthe',
          },
          {
            id: 156,
            name: 'Accessibilité PMR',
          },
          {
            id: 157,
            name: 'Autre revêtement de sol',
          },
        ],
      },
      {
        id: 158,
        name: 'Revêtement mural',
        children: [
          {
            id: 159,
            name: 'Revêtement minéral',
          },
          {
            id: 160,
            name: 'Plastique et dérivé',
          },
          {
            id: 161,
            name: 'Revêtement bois',
          },
          {
            id: 162,
            name: 'Autre revêtement mural',
          },
        ],
      },
    ],
  },
  {
    id: 163,
    name: 'Lots techniques',
    children: [
      {
        id: 164,
        name: 'Plomberie - ECS - sanitaire',
        children: [
          {
            id: 165,
            name: 'Production eau chaude sanitaire',
          },
          {
            id: 166,
            name: 'Adduction, évacuation (Pb)',
          },
          {
            id: 167,
            name: 'Evier, lavabo, lave-main',
          },
          {
            id: 168,
            name: 'WC',
          },
          {
            id: 169,
            name: 'Douche, baignoire',
          },
          {
            id: 170,
            name: 'Robinetterie',
          },
          {
            id: 171,
            name: 'Autre équipement Pb - ECS - sanitaire',
          },
        ],
      },
      {
        id: 172,
        name: 'CVC',
        children: [
          {
            id: 173,
            name: 'Production de chaud',
          },
          {
            id: 174,
            name: 'Réseau de chauffage',
          },
          {
            id: 175,
            name: 'Pompe à chaleur',
          },
          {
            id: 176,
            name: 'Emetteur, diffuseur',
          },
          {
            id: 177,
            name: 'Ventilation',
          },
          {
            id: 178,
            name: 'Autre équipement de CVC',
          },
        ],
      },
      {
        id: 179,
        name: 'Courant fort',
        children: [
          {
            id: 180,
            name: 'Tableau électrique',
          },
          {
            id: 181,
            name: 'Distribution électrique',
          },
          {
            id: 182,
            name: 'Eclairage extérieur',
          },
          {
            id: 183,
            name: 'Eclairage intérieur',
          },
          {
            id: 184,
            name: 'Prise',
          },
          {
            id: 185,
            name: 'Autre équipement CFO',
          },
        ],
      },
      {
        id: 186,
        name: 'Courant faible',
        children: [
          {
            id: 187,
            name: 'Réseau CFA',
          },
          {
            id: 188,
            name: "Contrôle d'accès",
          },
          {
            id: 189,
            name: 'Autre équipement CFA',
          },
        ],
      },
      {
        id: 190,
        name: 'Sécurité incendie',
        children: [
          {
            id: 191,
            name: 'SSI',
          },
        ],
      },
      {
        id: 192,
        name: 'Cuisine professionnelle',
        children: [
          {
            id: 193,
            name: 'Mobilier de cuisine professionnelle',
          },
          {
            id: 194,
            name: 'Equipement de cuisine professionnelle',
          },
          {
            id: 195,
            name: 'Autre élément de cuisine professionnelle',
          },
        ],
      },
      {
        id: 196,
        name: 'Equipement technique spécifique',
        children: [
          {
            id: 197,
            name: 'Ascenseur',
          },
          {
            id: 198,
            name: 'Monte-charge',
          },
          {
            id: 199,
            name: 'Escalier mécanique',
          },
          {
            id: 200,
            name: 'Équipement de production locale d’électricité',
          },
          {
            id: 201,
            name: 'Autre équipement technique spécifique',
          },
          {
            id: 202,
            name: 'Groupe électrogène',
          },
        ],
      },
    ],
  },
  {
    id: 203,
    name: 'Mobilier intérieur, divers',
    children: [
      {
        id: 204,
        name: 'Mobilier intérieur',
        children: [
          {
            id: 205,
            name: 'Table, chaise',
          },
          {
            id: 206,
            name: 'Rangement, stockage',
          },
          {
            id: 207,
            name: 'Affichage, signalétique, décoration',
          },
          {
            id: 208,
            name: 'Autre mobilier intérieur',
          },
        ],
      },
      {
        id: 209,
        name: 'Electronique, électroménager',
        children: [
          {
            id: 210,
            name: 'Electronique',
          },
          {
            id: 211,
            name: 'Electroménager',
          },
        ],
      },
      {
        id: 212,
        name: 'Divers',
        children: [
          {
            id: 213,
            name: 'Autre équipement divers',
          },
        ],
      },
    ],
  },
]

export const ResourceCategory: Cat[] = [
  {
    id: 1,
    name: 'VRD (Voirie, Réseaux Divers)',
    unite: null,
    exemples: null,
    label: '1 - VRD (Voirie, Réseaux Divers)',
    children: [
      {
        id: 15,
        name: 'Réseaux extérieurs (secs, humides, fourreaux, etc)',
        unite: 'ml',
        exemples: null,
        label: '1.1 - Réseaux extérieurs (secs, humides, fourreaux, etc)',
        children: [
          {
            id: 215,
            name: 'Gaines / Fourreaux',
            unite: null,
            exemples: null,
            label: '1.1.1 - Gaines / Fourreaux',
          },
          {
            id: 216,
            name: "Réseaux d'eau enterrés (AEP, EU, EP)",
            unite: null,
            exemples: null,
            label: "1.1.2 - Réseaux d'eau enterrés (AEP, EU, EP)",
          },
          {
            id: 217,
            name: "Réseaux d'évacuation et d'assainissement",
            unite: null,
            exemples: null,
            label: "1.1.3 - Réseaux d'évacuation et d'assainissement",
          },
          {
            id: 218,
            name: 'Systèmes de drainage',
            unite: null,
            exemples: null,
            label: '1.1.4 - Systèmes de drainage',
          },
          {
            id: 219,
            name: 'Autres éléments de réseaux extérieurs',
            unite: null,
            exemples: null,
            label: '1.1.5 - Autres éléments de réseaux extérieurs',
          },
        ],
      },
      {
        id: 16,
        name: 'Stockages (cuves, bassins, etc)',
        unite: 'U, m³',
        exemples:
          "Eléments pour le pompage d'eau, systèmes de pré-traitement d'eaux usées, systèmes de récupérations et de stockages des eaux pluviales",
        label: '1.2 - Stockages (cuves, bassins, etc)',
        children: [
          {
            id: 220,
            name: 'Bassins',
            unite: null,
            exemples: null,
            label: '1.2.1 - Bassins',
          },
          {
            id: 221,
            name: 'Silos',
            unite: null,
            exemples: null,
            label: '1.2.2 - Silos',
          },
          {
            id: 222,
            name: 'Citernes',
            unite: null,
            exemples: null,
            label: '1.2.3 - Citernes',
          },
          {
            id: 223,
            name: 'Cuves',
            unite: null,
            exemples: null,
            label: '1.2.4 - Cuves',
          },
          {
            id: 224,
            name: 'Réservoirs',
            unite: null,
            exemples: null,
            label: '1.2.5 - Réservoirs',
          },
          {
            id: 225,
            name: "Récupérateurs d'eau de pluie",
            unite: null,
            exemples: null,
            label: "1.2.6 - Récupérateurs d'eau de pluie",
          },
          {
            id: 226,
            name: 'Autres éléments de réseaux extérieurs',
            unite: null,
            exemples: null,
            label: '1.2.7 - Autres éléments de réseaux extérieurs',
          },
        ],
      },
      {
        id: 17,
        name: 'Voiries, revêtements',
        unite: 'm², ml',
        exemples:
          "Sous couches, revêtement et bordures de voies d'accès (sur parcelle), sol pour aire de jeu, dallage sur plots, platelage, etc.",
        label: '1.3 - Voiries, revêtements',
        children: [
          {
            id: 227,
            name: 'Sous couches / Couches de fondation',
            unite: null,
            exemples: null,
            label: '1.3.1 - Sous couches / Couches de fondation',
          },
          {
            id: 228,
            name: 'Bordures',
            unite: null,
            exemples: null,
            label: '1.3.2 - Bordures',
          },
          {
            id: 229,
            name: 'Dalles',
            unite: null,
            exemples: null,
            label: '1.3.3 - Dalles',
          },
          {
            id: 230,
            name: 'Gravillons',
            unite: null,
            exemples: null,
            label: '1.3.4 - Gravillons',
          },
          {
            id: 231,
            name: 'Murs de soutènement',
            unite: null,
            exemples: null,
            label: '1.3.5 - Murs de soutènement',
          },
          {
            id: 232,
            name: 'Pavés',
            unite: null,
            exemples: null,
            label: '1.3.6 - Pavés',
          },
          {
            id: 233,
            name: 'Platelage',
            unite: null,
            exemples: null,
            label: '1.3.7 - Platelage',
          },
          {
            id: 234,
            name: 'Revêtements de sol extérieurs',
            unite: null,
            exemples: null,
            label: '1.3.8 - Revêtements de sol extérieurs',
          },
          {
            id: 235,
            name: 'Signalisation / matériel de voirie',
            unite: null,
            exemples: null,
            label: '1.3.9 - Signalisation / matériel de voirie',
          },
          {
            id: 236,
            name: "Regards de visites / grilles d'avaloir",
            unite: null,
            exemples: null,
            label: "1.3.10 - Regards de visites / grilles d'avaloir",
          },
          {
            id: 237,
            name: 'Autres éléments de voirie et revêtements',
            unite: null,
            exemples: null,
            label: '1.3.11 - Autres éléments de voirie et revêtements',
          },
        ],
      },
      {
        id: 144,
        name: 'Clôtures',
        unite: 'ml, m²',
        exemples: 'Eléments de clôture de la parcelle : grilles, murs, murets, claustras, etc.',
        label: '1.4 - Clôtures',
        children: [
          {
            id: 238,
            name: 'Portails / Portillons',
            unite: null,
            exemples: null,
            label: '1.4.1 - Portails / Portillons',
          },
          {
            id: 239,
            name: 'Murets',
            unite: null,
            exemples: null,
            label: '1.4.2 - Murets',
          },
          {
            id: 240,
            name: 'Gabions',
            unite: null,
            exemples: null,
            label: '1.4.3 - Gabions',
          },
          {
            id: 241,
            name: 'Grillages',
            unite: null,
            exemples: null,
            label: '1.4.4 - Grillages',
          },
          {
            id: 242,
            name: 'Brises vues / Claustras',
            unite: null,
            exemples: null,
            label: '1.4.5 - Brises vues / Claustras',
          },
          {
            id: 243,
            name: 'Autres éléments de clôture',
            unite: null,
            exemples: null,
            label: '1.4.6 - Autres éléments de clôture',
          },
        ],
      },
    ],
  },
  {
    id: 2,
    name: 'Fondations et infrastructure',
    unite: null,
    exemples: null,
    label: '2 - Fondations et infrastructure',
    children: [
      {
        id: 18,
        name: 'Fondations',
        unite: 'm³',
        exemples: 'Béton de propreté, soubassement, longrines,',
        label: '2.1 - Fondations',
        children: [
          {
            id: 155,
            name: 'Hérissons',
            unite: null,
            exemples: null,
            label: '2.1.1 - Hérissons',
          },
          {
            id: 156,
            name: 'Longrines',
            unite: null,
            exemples: null,
            label: '2.1.2 - Longrines',
          },
          {
            id: 157,
            name: 'Murs de soutènement',
            unite: null,
            exemples: null,
            label: '2.1.3 - Murs de soutènement',
          },
          {
            id: 158,
            name: 'Palplanches',
            unite: null,
            exemples: null,
            label: '2.1.4 - Palplanches',
          },
          {
            id: 159,
            name: 'Pieux / Micropieux',
            unite: null,
            exemples: null,
            label: '2.1.5 - Pieux / Micropieux',
          },
          {
            id: 160,
            name: 'Plots',
            unite: null,
            exemples: null,
            label: '2.1.6 - Plots',
          },
          {
            id: 161,
            name: 'Sondes géothermiques',
            unite: null,
            exemples: null,
            label: '2.1.7 - Sondes géothermiques',
          },
          {
            id: 162,
            name: 'Autres éléments de fondation',
            unite: null,
            exemples: null,
            label: '2.1.8 - Autres éléments de fondation',
          },
        ],
      },
      {
        id: 19,
        name: 'Murs et structures enterrées (escalier de cave, parking, etc)',
        unite: 'm², m³ ou U',
        exemples: 'Structures porteuses pour parkings et locaux',
        label: '2.2 - Murs et structures enterrées (escalier de cave, parking, etc)',
        children: [
          {
            id: 163,
            name: 'Murs de soubassement',
            unite: null,
            exemples: null,
            label: '2.2.1 - Murs de soubassement',
          },
          {
            id: 164,
            name: 'Murs de sous sol',
            unite: null,
            exemples: null,
            label: '2.2.2 - Murs de sous sol',
          },
          {
            id: 165,
            name: 'Structure porteuse souterrains',
            unite: null,
            exemples: null,
            label: '2.2.3 - Structure porteuse souterrains',
          },
          {
            id: 166,
            name: 'Poteaux souterrains',
            unite: null,
            exemples: null,
            label: '2.2.4 - Poteaux souterrains',
          },
          {
            id: 167,
            name: 'Poutres souterraines',
            unite: null,
            exemples: null,
            label: '2.2.5 - Poutres souterraines',
          },
          {
            id: 168,
            name: "Parois de cage d'escalier",
            unite: null,
            exemples: null,
            label: "2.2.6 - Parois de cage d'escalier",
          },
          {
            id: 169,
            name: "Rampes d'accès véhicule",
            unite: null,
            exemples: null,
            label: "2.2.7 - Rampes d'accès véhicule",
          },
          {
            id: 170,
            name: 'Dalles de parking',
            unite: null,
            exemples: null,
            label: '2.2.8 - Dalles de parking',
          },
          {
            id: 171,
            name: 'Autres murs et structures enterrés',
            unite: null,
            exemples: null,
            label: '2.2.9 - Autres murs et structures enterrés',
          },
        ],
      },
    ],
  },
  {
    id: 3,
    name: 'Superstructure - Maçonnerie',
    unite: null,
    exemples: null,
    label: '3 - Superstructure - Maçonnerie',
    children: [
      {
        id: 20,
        name: 'Planchers, dalles, balcons',
        unite: 'm², m³',
        exemples: 'Dalles, planchers, planchers collaborants, dalles de compression, etc.',
        label: '3.1 - Planchers, dalles, balcons',
        children: [
          {
            id: 172,
            name: 'Bacs aciers pour plancher',
            unite: null,
            exemples: null,
            label: '3.1.1 - Bacs aciers pour plancher',
          },
          {
            id: 173,
            name: 'Structures spécifiques balcons',
            unite: null,
            exemples: null,
            label: '3.1.2 - Structures spécifiques balcons',
          },
          {
            id: 174,
            name: 'Dalles / Panneaux',
            unite: null,
            exemples: null,
            label: '3.1.3 - Dalles / Panneaux',
          },
          {
            id: 175,
            name: 'Dalles de toiture terrasse',
            unite: null,
            exemples: null,
            label: '3.1.4 - Dalles de toiture terrasse',
          },
          {
            id: 176,
            name: 'Hourdis / Entrevous',
            unite: null,
            exemples: null,
            label: '3.1.5 - Hourdis / Entrevous',
          },
          {
            id: 177,
            name: 'Poutrelles intégrées',
            unite: null,
            exemples: null,
            label: '3.1.6 - Poutrelles intégrées',
          },
          {
            id: 178,
            name: 'Prédalles',
            unite: null,
            exemples: null,
            label: '3.1.7 - Prédalles',
          },
          {
            id: 179,
            name: 'Solives',
            unite: null,
            exemples: null,
            label: '3.1.8 - Solives',
          },
          {
            id: 180,
            name: 'Autres éléments de plancher et dalles',
            unite: null,
            exemples: null,
            label: '3.1.9 - Autres éléments de plancher et dalles',
          },
        ],
      },
      {
        id: 21,
        name: 'Poutres',
        unite: 'ml, m³',
        exemples: 'Eléments porteurs horizontaux : poutres, linteaux,',
        label: '3.2 - Poutres',
        children: [
          {
            id: 181,
            name: 'Profilés métalliques',
            unite: null,
            exemples: null,
            label: '3.2.1 - Profilés métalliques',
          },
          {
            id: 182,
            name: 'Pannes',
            unite: null,
            exemples: null,
            label: '3.2.2 - Pannes',
          },
          {
            id: 183,
            name: 'Autres Poutres',
            unite: null,
            exemples: null,
            label: '3.2.3 - Autres Poutres',
          },
        ],
      },
      {
        id: 22,
        name: 'Façades',
        unite: 'ml, m², m³',
        exemples: 'Murs extérieurs, maçonnerie, voiles, armatures,',
        label: '3.3 - Façades',
        children: [
          {
            id: 184,
            name: 'Ossatures grille / Armatures',
            unite: null,
            exemples: null,
            label: '3.3.1 - Ossatures grille / Armatures',
          },
          {
            id: 185,
            name: 'Chainages',
            unite: null,
            exemples: null,
            label: '3.3.2 - Chainages',
          },
          {
            id: 186,
            name: 'Façades préfabriquées',
            unite: null,
            exemples: null,
            label: '3.3.3 - Façades préfabriquées',
          },
          {
            id: 187,
            name: 'Autres éléments de façade',
            unite: null,
            exemples: null,
            label: '3.3.4 - Autres éléments de façade',
          },
        ],
      },
      {
        id: 23,
        name: 'Refends',
        unite: 'ml, m², m³',
        exemples: 'Murs de refend',
        label: '3.4 - Refends',
        children: [
          {
            id: 188,
            name: 'Murs de refend',
            unite: null,
            exemples: null,
            label: '3.4.1 - Murs de refend',
          },
        ],
      },
      {
        id: 24,
        name: 'Poteaux',
        unite: 'ml, m², m³',
        exemples: null,
        label: '3.5 - Poteaux',
        children: [
          {
            id: 189,
            name: 'Poteaux',
            unite: null,
            exemples: null,
            label: '3.5.1 - Poteaux',
          },
          {
            id: 190,
            name: 'Autres poteaux',
            unite: null,
            exemples: null,
            label: '3.5.2 - Autres poteaux',
          },
        ],
      },
      {
        id: 25,
        name: 'Escaliers et rampes maçonnées',
        unite: 'U, ml',
        exemples:
          "Escaliers intérieurs, escaliers extérieurs, escaliers de secours, rampe d'accès piéton.",
        label: '3.6 - Escaliers et rampes maçonnées',
        children: [
          {
            id: 200,
            name: 'Escaliers',
            unite: null,
            exemples: null,
            label: '3.6.1 - Escaliers',
          },
          {
            id: 201,
            name: 'Rampes',
            unite: null,
            exemples: null,
            label: '3.6.2 - Rampes',
          },
          {
            id: 202,
            name: "Autres éléments d'escaliers et de rampes maçonnées",
            unite: null,
            exemples: null,
            label: "3.6.3 - Autres éléments d'escaliers et de rampes maçonnées",
          },
        ],
      },
      {
        id: 26,
        name: 'Éléments d’isolation',
        unite: 'ml, m², m³',
        exemples: 'Rupteurs thermiques et acoustiques.',
        label: '3.7 - Éléments d’isolation',
        children: [
          {
            id: 203,
            name: 'Isolation thermique',
            unite: null,
            exemples: null,
            label: '3.7.1 - Isolation thermique',
          },
          {
            id: 204,
            name: 'Rupteurs de pont thermique',
            unite: null,
            exemples: null,
            label: '3.7.2 - Rupteurs de pont thermique',
          },
          {
            id: 205,
            name: 'Pare-vapeurs / Ecrans',
            unite: null,
            exemples: null,
            label: '3.7.3 - Pare-vapeurs / Ecrans',
          },
          {
            id: 206,
            name: "Autres éléments d'isolation",
            unite: null,
            exemples: null,
            label: "3.7.4 - Autres éléments d'isolation",
          },
        ],
      },
      {
        id: 27,
        name: 'Maçonneries diverses (brique, parpaing, muret, etc)',
        unite: 'ml, m², m³',
        exemples: 'Appuis de baie.',
        label: '3.8 - Maçonneries diverses (brique, parpaing, muret, etc)',
        children: [
          {
            id: 207,
            name: 'Briques',
            unite: null,
            exemples: null,
            label: '3.8.1 - Briques',
          },
          {
            id: 208,
            name: 'Dalles alvéolées',
            unite: null,
            exemples: null,
            label: '3.8.2 - Dalles alvéolées',
          },
          {
            id: 209,
            name: 'Moellons',
            unite: null,
            exemples: null,
            label: '3.8.3 - Moellons',
          },
          {
            id: 210,
            name: 'Parpaings',
            unite: null,
            exemples: null,
            label: '3.8.4 - Parpaings',
          },
          {
            id: 211,
            name: 'Petits éléments de maçonnerie pour appuis de baie',
            unite: null,
            exemples: null,
            label: '3.8.5 - Petits éléments de maçonnerie pour appuis de baie',
          },
          {
            id: 212,
            name: 'Pierres',
            unite: null,
            exemples: null,
            label: '3.8.6 - Pierres',
          },
          {
            id: 213,
            name: 'Placage',
            unite: null,
            exemples: null,
            label: '3.8.7 - Placage',
          },
          {
            id: 214,
            name: 'Autres maçonneries diverses',
            unite: null,
            exemples: null,
            label: '3.8.8 - Autres maçonneries diverses',
          },
        ],
      },
    ],
  },
  {
    id: 4,
    name: 'Couverture - Étanchéité - Charpente - Zinguerie',
    unite: null,
    exemples: null,
    label: '4 - Couverture - Étanchéité - Charpente - Zinguerie',
    children: [
      {
        id: 28,
        name: 'Toitures terrasses',
        unite: 'm², m³',
        exemples:
          'Revêtement, protection lourde, étanchéité, parevapeur, complexe pour toiture végétalisée, etc. (hors dalle porteuse).',
        label: '4.1 - Toitures terrasses',
        children: [
          {
            id: 244,
            name: 'Dalles de toiture terrasse',
            unite: null,
            exemples: null,
            label: '4.1.1 - Dalles de toiture terrasse',
          },
          {
            id: 245,
            name: 'Plots sous dalles de toiture terrasse',
            unite: null,
            exemples: null,
            label: '4.1.2 - Plots sous dalles de toiture terrasse',
          },
          {
            id: 246,
            name: 'Eléments de toiture végétalisée',
            unite: null,
            exemples: null,
            label: '4.1.3 - Eléments de toiture végétalisée',
          },
          {
            id: 247,
            name: 'Gravillons',
            unite: null,
            exemples: null,
            label: '4.1.4 - Gravillons',
          },
          {
            id: 248,
            name: 'Isolation thermique et acoustique pour toiture terrasse',
            unite: null,
            exemples: null,
            label: '4.1.5 - Isolation thermique et acoustique pour toiture terrasse',
          },
          {
            id: 249,
            name: "Revêtement d'étanchéité",
            unite: null,
            exemples: null,
            label: "4.1.6 - Revêtement d'étanchéité",
          },
          {
            id: 250,
            name: "Protections d'étanchéité (lourdes / légères)",
            unite: null,
            exemples: null,
            label: "4.1.7 - Protections d'étanchéité (lourdes / légères)",
          },
          {
            id: 251,
            name: 'Autres éléments de toitures terrasses',
            unite: null,
            exemples: null,
            label: '4.1.8 - Autres éléments de toitures terrasses',
          },
        ],
      },
      {
        id: 29,
        name: 'Toitures en pente (tuiles, ardoises, etc)',
        unite: 'm²',
        exemples:
          'Charpentes, étanchéités, éléments de couverture (tuiles, tôles, ardoises, etc.), etc.',
        label: '4.2 - Toitures en pente (tuiles, ardoises, etc)',
        children: [
          {
            id: 252,
            name: 'Ardoises',
            unite: null,
            exemples: null,
            label: '4.2.1 - Ardoises',
          },
          {
            id: 253,
            name: 'Bacs acier',
            unite: null,
            exemples: null,
            label: '4.2.2 - Bacs acier',
          },
          {
            id: 254,
            name: 'Charpente en bois',
            unite: null,
            exemples: null,
            label: '4.2.3 - Charpente en bois',
          },
          {
            id: 255,
            name: 'Charpente métallique',
            unite: null,
            exemples: null,
            label: '4.2.4 - Charpente métallique',
          },
          {
            id: 256,
            name: 'Chevrons',
            unite: null,
            exemples: null,
            label: '4.2.5 - Chevrons',
          },
          {
            id: 257,
            name: 'Eléments de couverture en grands éléments',
            unite: null,
            exemples: null,
            label: '4.2.6 - Eléments de couverture en grands éléments',
          },
          {
            id: 258,
            name: 'Eléments de couverture en petits éléments',
            unite: null,
            exemples: null,
            label: '4.2.7 - Eléments de couverture en petits éléments',
          },
          {
            id: 259,
            name: 'Feuilles à base de bitume',
            unite: null,
            exemples: null,
            label: '4.2.8 - Feuilles à base de bitume',
          },
          {
            id: 260,
            name: 'Membranes synthétiques',
            unite: null,
            exemples: null,
            label: '4.2.9 - Membranes synthétiques',
          },
          {
            id: 261,
            name: 'Plaques ondulées fibro ciment',
            unite: null,
            exemples: null,
            label: '4.2.10 - Plaques ondulées fibro ciment',
          },
          {
            id: 262,
            name: 'Tôles',
            unite: null,
            exemples: null,
            label: '4.2.11 - Tôles',
          },
          {
            id: 263,
            name: 'Tuiles terre cuite',
            unite: null,
            exemples: null,
            label: '4.2.12 - Tuiles terre cuite',
          },
          {
            id: 264,
            name: 'Voligeages',
            unite: null,
            exemples: null,
            label: '4.2.13 - Voligeages',
          },
          {
            id: 265,
            name: 'Chaume',
            unite: null,
            exemples: null,
            label: '4.2.14 - Chaume',
          },
          {
            id: 266,
            name: 'Toitures végétales',
            unite: null,
            exemples: null,
            label: '4.2.15 - Toitures végétales',
          },
          {
            id: 267,
            name: 'Autres éléments de toiture en pente',
            unite: null,
            exemples: null,
            label: '4.2.16 - Autres éléments de toiture en pente',
          },
        ],
      },
      {
        id: 30,
        name: 'Éléments techniques de toiture',
        unite: 'ml, m², U',
        exemples: 'Cheminées, lanterneaux, désenfumages,',
        label: '4.3 - Éléments techniques de toiture',
        children: [
          {
            id: 268,
            name: 'Boisseaux',
            unite: null,
            exemples: null,
            label: '4.3.1 - Boisseaux',
          },
          {
            id: 269,
            name: 'Cheminées',
            unite: null,
            exemples: null,
            label: '4.3.2 - Cheminées',
          },
          {
            id: 270,
            name: 'Chéneaux / gouttières',
            unite: null,
            exemples: null,
            label: '4.3.3 - Chéneaux / gouttières',
          },
          {
            id: 271,
            name: 'Conduits de fumisterie',
            unite: null,
            exemples: null,
            label: '4.3.4 - Conduits de fumisterie',
          },
          {
            id: 272,
            name: "Descentes d'eaux pluviales",
            unite: null,
            exemples: null,
            label: "4.3.5 - Descentes d'eaux pluviales",
          },
          {
            id: 273,
            name: 'Lanterneaux',
            unite: null,
            exemples: null,
            label: '4.3.6 - Lanterneaux',
          },
          {
            id: 274,
            name: "Echelles d'accès en toiture",
            unite: null,
            exemples: null,
            label: "4.3.7 - Echelles d'accès en toiture",
          },
          {
            id: 275,
            name: 'Equipements de Protection Collective (EPC)',
            unite: null,
            exemples: null,
            label: '4.3.8 - Equipements de Protection Collective (EPC)',
          },
          {
            id: 276,
            name: 'Autres éléments techniques de toiture',
            unite: null,
            exemples: null,
            label: '4.3.9 - Autres éléments techniques de toiture',
          },
        ],
      },
    ],
  },
  {
    id: 5,
    name: 'Cloisonnement - Doublage - Plafonds suspendus - Menuiseries intérieures',
    unite: null,
    exemples: null,
    label: '5 - Cloisonnement - Doublage - Plafonds suspendus - Menuiseries intérieures',
    children: [
      {
        id: 31,
        name: 'Cloisons',
        unite: 'ml, m², U',
        exemples:
          'Cloisons de distribution, cloisons fixes, cloisons mobiles, cloisons amovibles, etc.',
        label: '5.1 - Cloisons',
        children: [
          {
            id: 277,
            name: 'Blocs de béton cellulaire',
            unite: null,
            exemples: null,
            label: '5.1.1 - Blocs de béton cellulaire',
          },
          {
            id: 279,
            name: 'Carreaux de plâtre',
            unite: null,
            exemples: null,
            label: '5.1.2 - Carreaux de plâtre',
          },
          {
            id: 280,
            name: 'Cloisons amovibles / Cloisons mobiles',
            unite: null,
            exemples: null,
            label: '5.1.3 - Cloisons amovibles / Cloisons mobiles',
          },
          {
            id: 281,
            name: 'Montants de fixation en bois',
            unite: null,
            exemples: null,
            label: '5.1.4 - Montants de fixation en bois',
          },
          {
            id: 282,
            name: 'Plaques / Panneaux',
            unite: null,
            exemples: null,
            label: '5.1.5 - Plaques / Panneaux',
          },
          {
            id: 541,
            name: 'Rails métalliques',
            unite: null,
            exemples: null,
            label: '5.1.6 - Rails métalliques',
          },
          {
            id: 283,
            name: 'Autres éléments de cloisons',
            unite: null,
            exemples: null,
            label: '5.1.7 - Autres éléments de cloisons',
          },
        ],
      },
      {
        id: 32,
        name: 'Doublages mur, matériaux de protection isolants et membranes',
        unite: 'ml, m²',
        exemples: 'Enduit intérieur, plaques de plâtre, matériaux',
        label: '5.2 - Doublages mur, matériaux de protection isolants et membranes',
        children: [
          {
            id: 284,
            name: 'Complexes isolants',
            unite: null,
            exemples: null,
            label: '5.2.1 - Complexes isolants',
          },
          {
            id: 285,
            name: 'Contres cloisons isolantes en plaques',
            unite: null,
            exemples: null,
            label: '5.2.2 - Contres cloisons isolantes en plaques',
          },
          {
            id: 286,
            name: "Films étanchéité à l'air",
            unite: null,
            exemples: null,
            label: "5.2.3 - Films étanchéité à l'air",
          },
          {
            id: 287,
            name: 'Isolation mince réfléchissante',
            unite: null,
            exemples: null,
            label: '5.2.4 - Isolation mince réfléchissante',
          },
          {
            id: 288,
            name: 'Isolation thermique et acoustique intérieure',
            unite: null,
            exemples: null,
            label: '5.2.5 - Isolation thermique et acoustique intérieure',
          },
          {
            id: 289,
            name: 'Matériaux de protection incendie',
            unite: null,
            exemples: null,
            label: '5.2.6 - Matériaux de protection incendie',
          },
          {
            id: 290,
            name: 'Panneaux sandwich',
            unite: null,
            exemples: null,
            label: '5.2.7 - Panneaux sandwich',
          },
          {
            id: 291,
            name: 'Pare vapeur',
            unite: null,
            exemples: null,
            label: '5.2.8 - Pare vapeur',
          },
          {
            id: 292,
            name: 'Membranes synthétiques',
            unite: null,
            exemples: null,
            label: '5.2.9 - Membranes synthétiques',
          },
          {
            id: 293,
            name: 'Autres doublages murs, matériaux de protection isolants et membranes',
            unite: null,
            exemples: null,
            label: '5.2.10 - Autres doublages murs, matériaux de protection isolants et membranes',
          },
        ],
      },
      {
        id: 33,
        name: 'Plafonds suspendus',
        unite: 'm²',
        exemples: 'Systèmes de fixation, systèmes de suspension, plafonds tendus, etc.',
        label: '5.3 - Plafonds suspendus',
        children: [
          {
            id: 294,
            name: 'Fixations pour plafonds suspendus',
            unite: null,
            exemples: null,
            label: '5.3.1 - Fixations pour plafonds suspendus',
          },
          {
            id: 295,
            name: 'Isolation acoustique pour plafonds suspendus ou tendus',
            unite: null,
            exemples: null,
            label: '5.3.2 - Isolation acoustique pour plafonds suspendus ou tendus',
          },
          {
            id: 296,
            name: 'Isolation thermique pour plafonds suspendus ou tendus',
            unite: null,
            exemples: null,
            label: '5.3.3 - Isolation thermique pour plafonds suspendus ou tendus',
          },
          {
            id: 297,
            name: 'Plafonds tendus',
            unite: null,
            exemples: null,
            label: '5.3.4 - Plafonds tendus',
          },
          {
            id: 298,
            name: 'Plaques / Panneaux plafonds suspendus',
            unite: null,
            exemples: null,
            label: '5.3.5 - Plaques / Panneaux plafonds suspendus',
          },
          {
            id: 299,
            name: 'Rails plafond suspendus',
            unite: null,
            exemples: null,
            label: '5.3.6 - Rails plafond suspendus',
          },
          {
            id: 300,
            name: 'Autres éléments de plafonds suspendus',
            unite: null,
            exemples: null,
            label: '5.3.7 - Autres éléments de plafonds suspendus',
          },
        ],
      },
      {
        id: 34,
        name: 'Planchers surélevés',
        unite: 'm²',
        exemples: 'Dalles sur plots (faux-planchers)',
        label: '5.4 - Planchers surélevés',
        children: [
          {
            id: 301,
            name: 'Dalles de plancher surélevés',
            unite: null,
            exemples: null,
            label: '5.4.1 - Dalles de plancher surélevés',
          },
          {
            id: 302,
            name: 'Verrins',
            unite: null,
            exemples: null,
            label: '5.4.2 - Verrins',
          },
          {
            id: 303,
            name: 'Plots de plancher surélevés',
            unite: null,
            exemples: null,
            label: '5.4.3 - Plots de plancher surélevés',
          },
          {
            id: 304,
            name: 'Autres éléments de plancher surélevés',
            unite: null,
            exemples: null,
            label: '5.4.4 - Autres éléments de plancher surélevés',
          },
        ],
      },
      {
        id: 35,
        name: 'Menuiseries intérieures',
        unite: 'U, ml',
        exemples:
          'Portes intérieures, portes palières intérieures, portes coupe-feu intérieures, fenêtres intérieures, etc.',
        label: '5.5 - Menuiseries intérieures',
        children: [
          {
            id: 305,
            name: 'Blocs portes coupe-feu intérieurs',
            unite: null,
            exemples: null,
            label: '5.5.1 - Blocs portes coupe-feu intérieurs',
          },
          {
            id: 306,
            name: 'Fenêtres intérieures',
            unite: null,
            exemples: null,
            label: '5.5.2 - Fenêtres intérieures',
          },
          {
            id: 307,
            name: 'Portes / Vantaux',
            unite: null,
            exemples: null,
            label: '5.5.3 - Portes / Vantaux',
          },
          {
            id: 308,
            name: 'Huisseries',
            unite: null,
            exemples: null,
            label: '5.5.4 - Huisseries',
          },
          {
            id: 309,
            name: 'Portes palières',
            unite: null,
            exemples: null,
            label: '5.5.5 - Portes palières',
          },
          {
            id: 310,
            name: 'Portes enroulables',
            unite: null,
            exemples: null,
            label: '5.5.6 - Portes enroulables',
          },
          {
            id: 311,
            name: 'Autres menuiseries intérieures',
            unite: null,
            exemples: null,
            label: '5.5.7 - Autres menuiseries intérieures',
          },
        ],
      },
      {
        id: 145,
        name: 'Métalleries et quincailleries',
        unite: 'U, ml',
        exemples: 'Garde corps, mains courantes, poignées d’accessibilité PMR, etc.',
        label: '5.6 - Métalleries et quincailleries',
        children: [
          {
            id: 312,
            name: 'Ferronerie',
            unite: null,
            exemples: null,
            label: '5.6.1 - Ferronerie',
          },
          {
            id: 313,
            name: 'Garde-corps',
            unite: null,
            exemples: null,
            label: '5.6.2 - Garde-corps',
          },
          {
            id: 314,
            name: 'Rampes et Mains courantes',
            unite: null,
            exemples: null,
            label: '5.6.3 - Rampes et Mains courantes',
          },
          {
            id: 315,
            name: 'Poignées de porte',
            unite: null,
            exemples: null,
            label: '5.6.4 - Poignées de porte',
          },
          {
            id: 316,
            name: 'Serrures / verroux',
            unite: null,
            exemples: null,
            label: '5.6.5 - Serrures / verroux',
          },
          {
            id: 317,
            name: 'Ferme-portes',
            unite: null,
            exemples: null,
            label: '5.6.6 - Ferme-portes',
          },
          {
            id: 318,
            name: 'Nez de marche',
            unite: null,
            exemples: null,
            label: '5.6.7 - Nez de marche',
          },
          {
            id: 319,
            name: 'Autres éléments de metallerie et quincallerie',
            unite: null,
            exemples: null,
            label: '5.6.8 - Autres éléments de metallerie et quincallerie',
          },
        ],
      },
    ],
  },
  {
    id: 6,
    name: 'Façades et menuiseries extérieures',
    unite: null,
    exemples: null,
    label: '6 - Façades et menuiseries extérieures',
    children: [
      {
        id: 36,
        name: 'Revêtement, isolation et doublage extérieur',
        unite: 'm²',
        exemples:
          'Eléments d’isolation des murs par l’extérieur, enduits extérieurs, façades légères non porteuses, bardages, parements de façade, pares-pluie, peintures, lasures, etc.',
        label: '6.1 - Revêtement, isolation et doublage extérieur',
        children: [
          {
            id: 321,
            name: 'Bardages double peau',
            unite: null,
            exemples: null,
            label: '6.1.1 - Bardages double peau',
          },
          {
            id: 320,
            name: 'Bardages simple peau',
            unite: null,
            exemples: null,
            label: '6.1.1 - Bardages simple peau',
          },
          {
            id: 322,
            name: 'Parement',
            unite: null,
            exemples: null,
            label: '6.1.2 - Parement',
          },
          {
            id: 323,
            name: 'Façades légères non porteuse / Murs rideaux',
            unite: null,
            exemples: null,
            label: '6.1.3 - Façades légères non porteuse / Murs rideaux',
          },
          {
            id: 324,
            name: 'Isolation thermique extérieure (ITE)',
            unite: null,
            exemples: null,
            label: '6.1.4 - Isolation thermique extérieure (ITE)',
          },
          {
            id: 325,
            name: 'Membranes pare pluie',
            unite: null,
            exemples: null,
            label: '6.1.5 - Membranes pare pluie',
          },
          {
            id: 326,
            name: "Système d'isolation thermique extérieure par enduit (ETICS) en panneaux",
            unite: null,
            exemples: null,
            label:
              "6.1.6 - Système d'isolation thermique extérieure par enduit (ETICS) en panneaux",
          },
          {
            id: 327,
            name: 'Vétages',
            unite: null,
            exemples: null,
            label: '6.1.7 - Vétages',
          },
          {
            id: 328,
            name: 'Vêtures',
            unite: null,
            exemples: null,
            label: '6.1.8 - Vêtures',
          },
          {
            id: 329,
            name: 'Autres revêtements extérieurs, isolation et doublage extérieur',
            unite: null,
            exemples: null,
            label: '6.1.9 - Autres revêtements extérieurs, isolation et doublage extérieur',
          },
        ],
      },
      {
        id: 37,
        name: 'Portes, fenêtres, fermetures, protections solaires',
        unite: 'U, m²',
        exemples: 'Fenêtres extérieures, portes-fenêtres, baies',
        label: '6.2 - Portes, fenêtres, fermetures, protections solaires',
        children: [
          {
            id: 330,
            name: 'Baies vitrées fixes',
            unite: null,
            exemples: null,
            label: '6.2.1 - Baies vitrées fixes',
          },
          {
            id: 331,
            name: 'Brises soleil / protections solaires',
            unite: null,
            exemples: null,
            label: '6.2.2 - Brises soleil / protections solaires',
          },
          {
            id: 332,
            name: 'Brises vue',
            unite: null,
            exemples: null,
            label: '6.2.3 - Brises vue',
          },
          {
            id: 542,
            name: 'Fenêtres de toit',
            unite: null,
            exemples: null,
            label: '6.2.4 - Fenêtres de toit',
          },
          {
            id: 333,
            name: 'Fenêtres extérieures',
            unite: null,
            exemples: null,
            label: '6.2.5 - Fenêtres extérieures',
          },
          {
            id: 334,
            name: 'Issues de secours',
            unite: null,
            exemples: null,
            label: '6.2.6 - Issues de secours',
          },
          {
            id: 335,
            name: 'Persiennes',
            unite: null,
            exemples: null,
            label: '6.2.7 - Persiennes',
          },
          {
            id: 336,
            name: 'Portes de garage extérieures',
            unite: null,
            exemples: null,
            label: '6.2.8 - Portes de garage extérieures',
          },
          {
            id: 337,
            name: 'Portes extérieures',
            unite: null,
            exemples: null,
            label: '6.2.9 - Portes extérieures',
          },
          {
            id: 338,
            name: 'Portes-fenêtres',
            unite: null,
            exemples: null,
            label: '6.2.10 - Portes-fenêtres',
          },
          {
            id: 339,
            name: "Rideaux d'occultation / Stores",
            unite: null,
            exemples: null,
            label: "6.2.11 - Rideaux d'occultation / Stores",
          },
          {
            id: 340,
            name: 'Verrières',
            unite: null,
            exemples: null,
            label: '6.2.12 - Verrières',
          },
          {
            id: 341,
            name: 'Volets',
            unite: null,
            exemples: null,
            label: '6.2.13 - Volets',
          },
          {
            id: 342,
            name: 'Portes automatique vitrées',
            unite: null,
            exemples: null,
            label: '6.2.14 - Portes automatique vitrées',
          },
          {
            id: 343,
            name: 'Portes tambours',
            unite: null,
            exemples: null,
            label: '6.2.15 - Portes tambours',
          },
          {
            id: 344,
            name: 'Autres portes, fenêtres, fermetures et protections solaires',
            unite: null,
            exemples: null,
            label: '6.2.16 - Autres portes, fenêtres, fermetures et protections solaires',
          },
        ],
      },
      {
        id: 38,
        name: 'Habillages et ossatures',
        unite: 'm²',
        exemples:
          'Habillages des tableaux et voussures, garde corps, claustras, grilles et barreaux de sécurité, vérandas, serres, couvertures vitrées d’atriums, coupoles, etc.',
        label: '6.3 - Habillages et ossatures',
        children: [
          {
            id: 345,
            name: 'Grilles et barreaux de sécurité',
            unite: null,
            exemples: null,
            label: '6.3.1 - Grilles et barreaux de sécurité',
          },
          {
            id: 346,
            name: 'Claustras',
            unite: null,
            exemples: null,
            label: '6.3.2 - Claustras',
          },
          {
            id: 347,
            name: 'Habillages de tableau',
            unite: null,
            exemples: null,
            label: '6.3.3 - Habillages de tableau',
          },
          {
            id: 348,
            name: 'Habillages de voussure',
            unite: null,
            exemples: null,
            label: '6.3.4 - Habillages de voussure',
          },
          {
            id: 349,
            name: 'Serres',
            unite: null,
            exemples: null,
            label: '6.3.5 - Serres',
          },
          {
            id: 350,
            name: 'Verandas',
            unite: null,
            exemples: null,
            label: '6.3.6 - Verandas',
          },
          {
            id: 351,
            name: 'Autres habillages et ossatures',
            unite: null,
            exemples: null,
            label: '6.3.7 - Autres habillages et ossatures',
          },
        ],
      },
    ],
  },
  {
    id: 7,
    name: 'Revêtement des sols, murs et plafonds - Chape - Peintures - Produits de décoration',
    unite: null,
    exemples: null,
    label: '7 - Revêtement des sols, murs et plafonds - Chape - Peintures - Produits de décoration',
    children: [
      {
        id: 39,
        name: 'Revêtement des sols (parquet, carrelage, moquette, etc)',
        unite: 'm²',
        exemples:
          'Chappes flottantes, ragréages, sous-couches acoustiques, revêtements de sol souples, revêtements de sol durs, revêtements de sol coulés, plinthes, barres de seuil, bandes podotactiles (accessibilité PMR), etc.',
        label: '7.1 - Revêtement des sols (parquet, carrelage, moquette, etc)',
        children: [
          {
            id: 352,
            name: 'Bandes podotactiles (accessibilité PMR)',
            unite: null,
            exemples: null,
            label: '7.1.1 - Bandes podotactiles (accessibilité PMR)',
          },
          {
            id: 353,
            name: 'Barres de seuil',
            unite: null,
            exemples: null,
            label: '7.1.2 - Barres de seuil',
          },
          {
            id: 354,
            name: 'Carrelage',
            unite: null,
            exemples: null,
            label: '7.1.3 - Carrelage',
          },
          {
            id: 355,
            name: 'Dalles',
            unite: null,
            exemples: null,
            label: '7.1.4 - Dalles',
          },
          {
            id: 356,
            name: 'Lames',
            unite: null,
            exemples: null,
            label: '7.1.5 - Lames',
          },
          {
            id: 357,
            name: 'Plinthes',
            unite: null,
            exemples: null,
            label: '7.1.6 - Plinthes',
          },
          {
            id: 358,
            name: 'Rouleaux',
            unite: null,
            exemples: null,
            label: '7.1.7 - Rouleaux',
          },
          {
            id: 359,
            name: 'Sous couches acoustiques',
            unite: null,
            exemples: null,
            label: '7.1.8 - Sous couches acoustiques',
          },
          {
            id: 360,
            name: 'Autres revêtements des sols',
            unite: null,
            exemples: null,
            label: '7.1.9 - Autres revêtements des sols',
          },
        ],
      },
      {
        id: 40,
        name: 'Revêtements des murs et plafonds',
        unite: 'm²',
        exemples:
          'Faïences murales, parements intérieurs (briquettes, lambris, etc.), revêtements de plafond (toile de verre, etc.)',
        label: '7.2 - Revêtements des murs et plafonds',
        children: [
          {
            id: 361,
            name: 'Briquettes',
            unite: null,
            exemples: null,
            label: '7.2.1 - Briquettes',
          },
          {
            id: 362,
            name: 'Faïence',
            unite: null,
            exemples: null,
            label: '7.2.2 - Faïence',
          },
          {
            id: 363,
            name: 'Lambris',
            unite: null,
            exemples: null,
            label: '7.2.3 - Lambris',
          },
          {
            id: 364,
            name: 'Panneaux muraux agrafés ou attachés',
            unite: null,
            exemples: null,
            label: '7.2.4 - Panneaux muraux agrafés ou attachés',
          },
          {
            id: 365,
            name: 'Plaques de revêtement / platelage',
            unite: null,
            exemples: null,
            label: '7.2.5 - Plaques de revêtement / platelage',
          },
          {
            id: 366,
            name: 'Autres revêtements des murs et plafonds',
            unite: null,
            exemples: null,
            label: '7.2.6 - Autres revêtements des murs et plafonds',
          },
        ],
      },
      {
        id: 41,
        name: 'Éléments de décoration et revêtements des menuiseries',
        unite: 'm² (ou U)',
        exemples: 'Lasures, boiseries, frises, etc.',
        label: '7.3 - Éléments de décoration et revêtements des menuiseries',
        children: [
          {
            id: 367,
            name: 'Boiseries',
            unite: null,
            exemples: null,
            label: '7.3.1 - Boiseries',
          },
          {
            id: 368,
            name: 'Frises',
            unite: null,
            exemples: null,
            label: '7.3.2 - Frises',
          },
          {
            id: 369,
            name: 'Autres éléments de décoration et revêtements des menuiseries',
            unite: null,
            exemples: null,
            label: '7.3.3 - Autres éléments de décoration et revêtements des menuiseries',
          },
        ],
      },
    ],
  },
  {
    id: 8,
    name: 'CVC (Chauffage - Ventilation - Climatisation)',
    unite: null,
    exemples: null,
    label: '8 - CVC (Chauffage - Ventilation - Climatisation)',
    children: [
      {
        id: 42,
        name: 'Équipements de chauffage (chaudières, radiateurs à eau, radiateurs électriques, etc)',
        unite: 'U',
        exemples: 'Chaudières, poêles à bois, cheminée, insert, éléments de régulation, etc.',
        label:
          '8.1 - Équipements de chauffage (chaudières, radiateurs à eau, radiateurs électriques, etc)',
        children: [
          {
            id: 370,
            name: 'Chaudières',
            unite: null,
            exemples: null,
            label: '8.1.1 - Chaudières',
          },
          {
            id: 371,
            name: 'Cheminées',
            unite: null,
            exemples: null,
            label: '8.1.2 - Cheminées',
          },
          {
            id: 372,
            name: 'Eléments de régulation',
            unite: null,
            exemples: null,
            label: '8.1.3 - Eléments de régulation',
          },
          {
            id: 373,
            name: 'Inserts',
            unite: null,
            exemples: null,
            label: '8.1.4 - Inserts',
          },
          {
            id: 374,
            name: 'Panneaux solaires photovoltaïques',
            unite: null,
            exemples: null,
            label: '8.1.5 - Panneaux solaires photovoltaïques',
          },
          {
            id: 375,
            name: 'Panneaux solaires thermiques',
            unite: null,
            exemples: null,
            label: '8.1.6 - Panneaux solaires thermiques',
          },
          {
            id: 376,
            name: 'Plafonds rayonnants',
            unite: null,
            exemples: null,
            label: '8.1.7 - Plafonds rayonnants',
          },
          {
            id: 377,
            name: 'Planchers chauffants',
            unite: null,
            exemples: null,
            label: '8.1.8 - Planchers chauffants',
          },
          {
            id: 378,
            name: 'Planchers rayonnants',
            unite: null,
            exemples: null,
            label: '8.1.9 - Planchers rayonnants',
          },
          {
            id: 379,
            name: 'Poêles',
            unite: null,
            exemples: null,
            label: '8.1.10 - Poêles',
          },
          {
            id: 380,
            name: 'Pompes à chaleur',
            unite: null,
            exemples: null,
            label: '8.1.11 - Pompes à chaleur',
          },
          {
            id: 381,
            name: 'Radiateurs à eau',
            unite: null,
            exemples: null,
            label: '8.1.12 - Radiateurs à eau',
          },
          {
            id: 382,
            name: 'Radiateurs électriques',
            unite: null,
            exemples: null,
            label: '8.1.13 - Radiateurs électriques',
          },
          {
            id: 383,
            name: "Rideaux d'air chaud",
            unite: null,
            exemples: null,
            label: "8.1.14 - Rideaux d'air chaud",
          },
          {
            id: 384,
            name: 'Sèches mains',
            unite: null,
            exemples: null,
            label: '8.1.15 - Sèches mains',
          },
          {
            id: 385,
            name: 'Seches serviettes',
            unite: null,
            exemples: null,
            label: '8.1.16 - Seches serviettes',
          },
          {
            id: 386,
            name: 'Tubes de chauffage par le sol',
            unite: null,
            exemples: null,
            label: '8.1.17 - Tubes de chauffage par le sol',
          },
          {
            id: 387,
            name: 'Ventilo-convecteurs',
            unite: null,
            exemples: null,
            label: '8.1.18 - Ventilo-convecteurs',
          },
          {
            id: 388,
            name: 'Autres équipements de chauffage',
            unite: null,
            exemples: null,
            label: '8.1.19 - Autres équipements de chauffage',
          },
        ],
      },
      {
        id: 43,
        name: 'Équipements de ventilation (CTA, unités intérieures, bouches de ventilation, etc)',
        unite: 'U',
        exemples: "Centrales de traitement d'air, filtres à air, VMC (simple/ double flux)",
        label:
          '8.2 - Équipements de ventilation (CTA, unités intérieures, bouches de ventilation, etc)',
        children: [
          {
            id: 389,
            name: 'Bouches de ventilation',
            unite: null,
            exemples: null,
            label: '8.2.1 - Bouches de ventilation',
          },
          {
            id: 390,
            name: "Bouches d'extraction",
            unite: null,
            exemples: null,
            label: "8.2.2 - Bouches d'extraction",
          },
          {
            id: 391,
            name: "Centrales de traitement d'air (CTA)",
            unite: null,
            exemples: null,
            label: "8.2.3 - Centrales de traitement d'air (CTA)",
          },
          {
            id: 392,
            name: "Centrales de traitement d'air (CTA) double flux",
            unite: null,
            exemples: null,
            label: "8.2.4 - Centrales de traitement d'air (CTA) double flux",
          },
          {
            id: 393,
            name: 'Diffuseurs',
            unite: null,
            exemples: null,
            label: '8.2.5 - Diffuseurs',
          },
          {
            id: 394,
            name: "Entrées d'air",
            unite: null,
            exemples: null,
            label: "8.2.6 - Entrées d'air",
          },
          {
            id: 395,
            name: 'Filtres à air',
            unite: null,
            exemples: null,
            label: '8.2.7 - Filtres à air',
          },
          {
            id: 396,
            name: 'Grilles extérieur',
            unite: null,
            exemples: null,
            label: '8.2.8 - Grilles extérieur',
          },
          {
            id: 397,
            name: 'Terminaux passifs',
            unite: null,
            exemples: null,
            label: '8.2.9 - Terminaux passifs',
          },
          {
            id: 398,
            name: 'Ventilateurs',
            unite: null,
            exemples: null,
            label: '8.2.10 - Ventilateurs',
          },
          {
            id: 399,
            name: 'VMC',
            unite: null,
            exemples: null,
            label: '8.2.11 - VMC',
          },
          {
            id: 400,
            name: 'Conduits de fumisterie',
            unite: null,
            exemples: null,
            label: '8.2.12 - Conduits de fumisterie',
          },
          {
            id: 401,
            name: 'Autres équipements de ventilation',
            unite: null,
            exemples: null,
            label: '8.2.13 - Autres équipements de ventilation',
          },
        ],
      },
      {
        id: 44,
        name: 'Équipements de climatisation (groupes froids, cassettes, etc)',
        unite: 'U',
        exemples: 'Pompes à chaleur',
        label: '8.3 - Équipements de climatisation (groupes froids, cassettes, etc)',
        children: [
          {
            id: 402,
            name: 'Débit de réfrigérant variable (DRV)',
            unite: null,
            exemples: null,
            label: '8.3.1 - Débit de réfrigérant variable (DRV)',
          },
          {
            id: 403,
            name: 'Groupes froids',
            unite: null,
            exemples: null,
            label: '8.3.2 - Groupes froids',
          },
          {
            id: 404,
            name: 'Cassettes',
            unite: null,
            exemples: null,
            label: '8.3.3 - Cassettes',
          },
          {
            id: 405,
            name: 'Tours aéroréfrigérantes',
            unite: null,
            exemples: null,
            label: '8.3.4 - Tours aéroréfrigérantes',
          },
          {
            id: 406,
            name: 'Autres équipements de climatisation',
            unite: null,
            exemples: null,
            label: '8.3.5 - Autres équipements de climatisation',
          },
        ],
      },
    ],
  },
  {
    id: 9,
    name: 'Installations sanitaires',
    unite: null,
    exemples: null,
    label: '9 - Installations sanitaires',
    children: [
      {
        id: 45,
        name: 'Production eau chaude sanitaire (ballons ECS, etc)',
        unite: 'U',
        exemples:
          'Chauffes-eau (thermodynamique, électrique, gaz), chauffes-eau solaire individuels, éléments de régulation, etc.',
        label: '9.1 - Production eau chaude sanitaire (ballons ECS, etc)',
        children: [
          {
            id: 407,
            name: 'Chauffe eau',
            unite: null,
            exemples: null,
            label: '9.1.1 - Chauffe eau',
          },
          {
            id: 408,
            name: 'Cumulus',
            unite: null,
            exemples: null,
            label: '9.1.2 - Cumulus',
          },
          {
            id: 409,
            name: 'Panneaux de production eau chaude sanitaire',
            unite: null,
            exemples: null,
            label: '9.1.3 - Panneaux de production eau chaude sanitaire',
          },
          {
            id: 410,
            name: 'Eléments de régulation',
            unite: null,
            exemples: null,
            label: '9.1.4 - Eléments de régulation',
          },
          {
            id: 411,
            name: 'Autres équipements production eau chaude sanitaire',
            unite: null,
            exemples: null,
            label: '9.1.5 - Autres équipements production eau chaude sanitaire',
          },
        ],
      },
      {
        id: 46,
        name: 'Réseaux intérieurs de distribution et d’évacuation',
        unite: 'ml, U, m²',
        exemples:
          'Conduits flexibles, conduits rigides, coudes et accessoires, canalisations, etc.',
        label: '9.2 - Réseaux intérieurs de distribution et d’évacuation',
        children: [
          {
            id: 412,
            name: 'Calorifugeage de canalisations',
            unite: null,
            exemples: null,
            label: '9.2.1 - Calorifugeage de canalisations',
          },
          {
            id: 413,
            name: 'Canalisations',
            unite: null,
            exemples: null,
            label: '9.2.2 - Canalisations',
          },
          {
            id: 414,
            name: 'Conduits flexibles',
            unite: null,
            exemples: null,
            label: '9.2.3 - Conduits flexibles',
          },
          {
            id: 415,
            name: 'Conduits rigides',
            unite: null,
            exemples: null,
            label: '9.2.4 - Conduits rigides',
          },
          {
            id: 416,
            name: 'Coudes et accessoires',
            unite: null,
            exemples: null,
            label: '9.2.5 - Coudes et accessoires',
          },
          {
            id: 417,
            name: 'Filtres',
            unite: null,
            exemples: null,
            label: '9.2.6 - Filtres',
          },
          {
            id: 418,
            name: 'Siphons de sol',
            unite: null,
            exemples: null,
            label: '9.2.7 - Siphons de sol',
          },
          {
            id: 419,
            name: "Autres éléments de réseaux intérieurs de distribution et d'évacuation",
            unite: null,
            exemples: null,
            label: "9.2.8 - Autres éléments de réseaux intérieurs de distribution et d'évacuation",
          },
        ],
      },
      {
        id: 47,
        name: 'Appareils sanitaires (lavabo, WC, vidoirs, urinoirs, vasques, éviers, mitigeurs, etc)',
        unite: 'U',
        exemples:
          'Receveurs de douches, baignoires, toilettes (cuvette et chasse), lavabos, éviers, fontaines à eau, robinetterie, portes et parois de cabines de douche, etc.',
        label:
          '9.3 - Appareils sanitaires (lavabo, WC, vidoirs, urinoirs, vasques, éviers, mitigeurs, etc)',
        children: [
          {
            id: 420,
            name: 'Baignoires',
            unite: null,
            exemples: null,
            label: '9.3.1 - Baignoires',
          },
          {
            id: 421,
            name: 'Bidets',
            unite: null,
            exemples: null,
            label: '9.3.2 - Bidets',
          },
          {
            id: 422,
            name: 'Colonnes de douche',
            unite: null,
            exemples: null,
            label: '9.3.3 - Colonnes de douche',
          },
          {
            id: 423,
            name: 'Cuvettes de WC',
            unite: null,
            exemples: null,
            label: '9.3.4 - Cuvettes de WC',
          },
          {
            id: 424,
            name: 'Douchettes',
            unite: null,
            exemples: null,
            label: '9.3.5 - Douchettes',
          },
          {
            id: 425,
            name: 'Eviers',
            unite: null,
            exemples: null,
            label: '9.3.6 - Eviers',
          },
          {
            id: 426,
            name: 'Fontaines à eau',
            unite: null,
            exemples: null,
            label: '9.3.7 - Fontaines à eau',
          },
          {
            id: 427,
            name: 'Lavabos',
            unite: null,
            exemples: null,
            label: '9.3.8 - Lavabos',
          },
          {
            id: 428,
            name: 'Laves-mains',
            unite: null,
            exemples: null,
            label: '9.3.9 - Laves-mains',
          },
          {
            id: 429,
            name: 'Mécanisme de chasse',
            unite: null,
            exemples: null,
            label: '9.3.10 - Mécanisme de chasse',
          },
          {
            id: 430,
            name: 'Mitigeurs / Mélangeur',
            unite: null,
            exemples: null,
            label: '9.3.11 - Mitigeurs / Mélangeur',
          },
          {
            id: 431,
            name: 'Portes et parois de cabine de douche',
            unite: null,
            exemples: null,
            label: '9.3.12 - Portes et parois de cabine de douche',
          },
          {
            id: 432,
            name: 'Receveurs de douche',
            unite: null,
            exemples: null,
            label: '9.3.13 - Receveurs de douche',
          },
          {
            id: 433,
            name: 'Siphons',
            unite: null,
            exemples: null,
            label: '9.3.14 - Siphons',
          },
          {
            id: 434,
            name: "Systèmes économiseurs d'eau",
            unite: null,
            exemples: null,
            label: "9.3.15 - Systèmes économiseurs d'eau",
          },
          {
            id: 435,
            name: 'Urinoirs',
            unite: null,
            exemples: null,
            label: '9.3.16 - Urinoirs',
          },
          {
            id: 436,
            name: 'Vidoirs / Déversoirs',
            unite: null,
            exemples: null,
            label: '9.3.17 - Vidoirs / Déversoirs',
          },
          {
            id: 437,
            name: 'Autres appareils sanitaires',
            unite: null,
            exemples: null,
            label: '9.3.18 - Autres appareils sanitaires',
          },
        ],
      },
      {
        id: 146,
        name: 'Sprinklages et réseaux',
        unite: 'U, ml',
        exemples: 'Réseaux de plomberie dédiés à la sécurité incendie',
        label: '9.4 - Sprinklages et réseaux',
        children: [
          {
            id: 438,
            name: 'Accessoires de sprinklage',
            unite: null,
            exemples: null,
            label: '9.4.1 - Accessoires de sprinklage',
          },
          {
            id: 439,
            name: 'Caisson de désenfumage',
            unite: null,
            exemples: null,
            label: '9.4.2 - Caisson de désenfumage',
          },
          {
            id: 440,
            name: 'Cartouche coupe-feu ou pare flamme',
            unite: null,
            exemples: null,
            label: '9.4.3 - Cartouche coupe-feu ou pare flamme',
          },
          {
            id: 441,
            name: 'Clapets coupe feu',
            unite: null,
            exemples: null,
            label: '9.4.4 - Clapets coupe feu',
          },
          {
            id: 442,
            name: 'Grilles ou volets de désenfumage',
            unite: null,
            exemples: null,
            label: '9.4.5 - Grilles ou volets de désenfumage',
          },
          {
            id: 443,
            name: 'Réseau de sprinklage',
            unite: null,
            exemples: null,
            label: '9.4.6 - Réseau de sprinklage',
          },
          {
            id: 444,
            name: "Réseaux et terminaux RIA (Robinet d'Incendie Armé)",
            unite: null,
            exemples: null,
            label: "9.4.7 - Réseaux et terminaux RIA (Robinet d'Incendie Armé)",
          },
          {
            id: 445,
            name: 'Enrouleurs et lances à incendie',
            unite: null,
            exemples: null,
            label: '9.4.8 - Enrouleurs et lances à incendie',
          },
          {
            id: 446,
            name: 'Autres éléments de sprinklage et de réseaux de sécurité incendie',
            unite: null,
            exemples: null,
            label: '9.4.9 - Autres éléments de sprinklage et de réseaux de sécurité incendie',
          },
        ],
      },
    ],
  },
  {
    id: 10,
    name: 'Réseaux (CFO/CFA)',
    unite: null,
    exemples: null,
    label: '10 - Réseaux (CFO/CFA)',
    children: [
      {
        id: 48,
        name: 'Tableau général basse tension et armoires divisionnaires',
        unite: 'U',
        exemples: null,
        label: '10.1 - Tableau général basse tension et armoires divisionnaires',
        children: [
          {
            id: 447,
            name: 'Armoires divisionnaires / Tableaux divisionnaires',
            unite: null,
            exemples: null,
            label: '10.1.1 - Armoires divisionnaires / Tableaux divisionnaires',
          },
          {
            id: 448,
            name: 'Tableaux généraux basse tension (TGBT)',
            unite: null,
            exemples: null,
            label: '10.1.2 - Tableaux généraux basse tension (TGBT)',
          },
          {
            id: 449,
            name: 'Transformateurs électriques',
            unite: null,
            exemples: null,
            label: '10.1.3 - Transformateurs électriques',
          },
          {
            id: 450,
            name: 'Protections modulaires',
            unite: null,
            exemples: null,
            label: '10.1.4 - Protections modulaires',
          },
          {
            id: 451,
            name: 'Disjoncteurs',
            unite: null,
            exemples: null,
            label: '10.1.5 - Disjoncteurs',
          },
          {
            id: 452,
            name: 'Cellules basse tension et moyenne tension',
            unite: null,
            exemples: null,
            label: '10.1.6 - Cellules basse tension et moyenne tension',
          },
          {
            id: 453,
            name: 'Installations de recharge de véhicule électrique',
            unite: null,
            exemples: null,
            label: '10.1.7 - Installations de recharge de véhicule électrique',
          },
          {
            id: 454,
            name: 'Autres éléments de tableau général basse tension et armoires divisionnaires',
            unite: null,
            exemples: null,
            label:
              '10.1.8 - Autres éléments de tableau général basse tension et armoires divisionnaires',
          },
        ],
      },
      {
        id: 49,
        name: 'Distributions électriques (câblages, chemins de câbles, réseaux informatiques et téléphoniques, etc.)',
        unite: 'ml, U',
        exemples:
          'Fils et câbles électriques, gaines, chemins de câbles, plinthes techniques, goulottes, fils et câbles de communication.',
        label:
          '10.2 - Distributions électriques (câblages, chemins de câbles, réseaux informatiques et téléphoniques, etc.)',
        children: [
          {
            id: 455,
            name: 'Câbles / Fils électriques',
            unite: null,
            exemples: null,
            label: '10.2.1 - Câbles / Fils électriques',
          },
          {
            id: 456,
            name: 'Chemins de câbles',
            unite: null,
            exemples: null,
            label: '10.2.2 - Chemins de câbles',
          },
          {
            id: 457,
            name: 'Gaines / Fourreaux',
            unite: null,
            exemples: null,
            label: '10.2.3 - Gaines / Fourreaux',
          },
          {
            id: 458,
            name: 'Goulottes',
            unite: null,
            exemples: null,
            label: '10.2.4 - Goulottes',
          },
          {
            id: 459,
            name: 'Plinthes techniques',
            unite: null,
            exemples: null,
            label: '10.2.5 - Plinthes techniques',
          },
          {
            id: 460,
            name: 'Prises de terre',
            unite: null,
            exemples: null,
            label: '10.2.6 - Prises de terre',
          },
          {
            id: 461,
            name: 'Paratonnerres',
            unite: null,
            exemples: null,
            label: '10.2.7 - Paratonnerres',
          },
          {
            id: 462,
            name: 'Autres élements de distribution électrique',
            unite: null,
            exemples: null,
            label: '10.2.8 - Autres élements de distribution électrique',
          },
        ],
      },
      {
        id: 50,
        name: 'Appareils d’éclairage',
        unite: 'U',
        exemples:
          "Eclairages intérieurs généraux (hors éclairage de sécurité), éclairages d'extérieur généraux (lampadaires, hublots, etc.), systèmes de contrôle et de régulation de l'éclairage, etc.",
        label: '10.3 - Appareils d’éclairage',
        children: [
          {
            id: 463,
            name: 'Balises',
            unite: null,
            exemples: null,
            label: '10.3.1 - Balises',
          },
          {
            id: 464,
            name: 'Bornes lumineuses',
            unite: null,
            exemples: null,
            label: '10.3.2 - Bornes lumineuses',
          },
          {
            id: 465,
            name: 'Colonnes lumineuses',
            unite: null,
            exemples: null,
            label: '10.3.3 - Colonnes lumineuses',
          },
          {
            id: 466,
            name: 'Eclairages en applique',
            unite: null,
            exemples: null,
            label: '10.3.4 - Eclairages en applique',
          },
          {
            id: 467,
            name: 'Eclairages en suspension',
            unite: null,
            exemples: null,
            label: '10.3.5 - Eclairages en suspension',
          },
          {
            id: 468,
            name: 'Hublots',
            unite: null,
            exemples: null,
            label: '10.3.6 - Hublots',
          },
          {
            id: 469,
            name: 'Lampadaires',
            unite: null,
            exemples: null,
            label: '10.3.7 - Lampadaires',
          },
          {
            id: 470,
            name: "Supports d'éclairage",
            unite: null,
            exemples: null,
            label: "10.3.8 - Supports d'éclairage",
          },
          {
            id: 471,
            name: "Systèmes de contrôle et de régulation de l'éclairage",
            unite: null,
            exemples: null,
            label: "10.3.9 - Systèmes de contrôle et de régulation de l'éclairage",
          },
          {
            id: 472,
            name: 'Dalles lumineuses',
            unite: null,
            exemples: null,
            label: '10.3.10 - Dalles lumineuses',
          },
          {
            id: 473,
            name: 'Réglettes',
            unite: null,
            exemples: null,
            label: '10.3.11 - Réglettes',
          },
          {
            id: 474,
            name: 'Eclairages encastrés',
            unite: null,
            exemples: null,
            label: '10.3.12 - Eclairages encastrés',
          },
          {
            id: 475,
            name: "Autres appareils d'éclairage",
            unite: null,
            exemples: null,
            label: "10.3.13 - Autres appareils d'éclairage",
          },
        ],
      },
      {
        id: 51,
        name: 'Équipements terminaux (interrupteurs, prises, etc)',
        unite: 'U',
        exemples: 'Interrupteurs, prises, etc',
        label: '10.4 - Équipements terminaux (interrupteurs, prises, etc)',
        children: [
          {
            id: 476,
            name: 'Prises courants forts',
            unite: null,
            exemples: null,
            label: '10.4.1 - Prises courants forts',
          },
          {
            id: 477,
            name: 'Prises courants faibles (RJ45...)',
            unite: null,
            exemples: null,
            label: '10.4.2 - Prises courants faibles (RJ45...)',
          },
          {
            id: 478,
            name: 'Autres équipements terminaux',
            unite: null,
            exemples: null,
            label: '10.4.3 - Autres équipements terminaux',
          },
        ],
      },
      {
        id: 52,
        name: 'Armoire VDI / baies informatiques',
        unite: 'U',
        exemples: null,
        label: '10.5 - Armoire VDI / baies informatiques',
        children: [
          {
            id: 479,
            name: 'Armoire VDI (Voix Données Images)',
            unite: null,
            exemples: null,
            label: '10.5.1 - Armoire VDI (Voix Données Images)',
          },
          {
            id: 480,
            name: 'Baie de brassage informatique',
            unite: null,
            exemples: null,
            label: '10.5.2 - Baie de brassage informatique',
          },
          {
            id: 481,
            name: "Autres éléments d'armoires et de baies informatiques",
            unite: null,
            exemples: null,
            label: "10.5.3 - Autres éléments d'armoires et de baies informatiques",
          },
        ],
      },
      {
        id: 53,
        name: "Équipements spécifiques (caméras, bornes Wi-Fi, équipements GTB, contrôles d'accès, etc.)",
        unite: 'U',
        exemples:
          "Systèmes de détection d'intrusion, système de contrôle d'accès, système de vidéosurveillance, système d'éclairage de sécurité, etc.",
        label:
          "10.6 - Équipements spécifiques (caméras, bornes Wi-Fi, équipements GTB, contrôles d'accès, etc.)",
        children: [
          {
            id: 482,
            name: 'Câbles de télécommunication',
            unite: null,
            exemples: null,
            label: '10.6.1 - Câbles de télécommunication',
          },
          {
            id: 483,
            name: 'Fils de télécommunications',
            unite: null,
            exemples: null,
            label: '10.6.2 - Fils de télécommunications',
          },
          {
            id: 484,
            name: 'Caméras',
            unite: null,
            exemples: null,
            label: '10.6.3 - Caméras',
          },
          {
            id: 485,
            name: 'Boitier répétiteur',
            unite: null,
            exemples: null,
            label: '10.6.4 - Boitier répétiteur',
          },
          {
            id: 486,
            name: 'Autres équipements spécifiques',
            unite: null,
            exemples: null,
            label: '10.6.5 - Autres équipements spécifiques',
          },
        ],
      },
      {
        id: 54,
        name: 'Systèmes de sécurité',
        unite: 'U',
        exemples: 'Systèmes de sécurité incendie, paratonnerres',
        label: '10.7 - Systèmes de sécurité',
        children: [
          {
            id: 487,
            name: "Blocs autonomes d'éclairage de sécurité (BAES)",
            unite: null,
            exemples: null,
            label: "10.7.1 - Blocs autonomes d'éclairage de sécurité (BAES)",
          },
          {
            id: 488,
            name: "Blocs autonomes d'éclairage d'habitation (BAEH)",
            unite: null,
            exemples: null,
            label: "10.7.2 - Blocs autonomes d'éclairage d'habitation (BAEH)",
          },
          {
            id: 489,
            name: 'Luminaires pour source centrale (LSC)',
            unite: null,
            exemples: null,
            label: '10.7.3 - Luminaires pour source centrale (LSC)',
          },
          {
            id: 490,
            name: "Systèmes de contrôle d'accès",
            unite: null,
            exemples: null,
            label: "10.7.4 - Systèmes de contrôle d'accès",
          },
          {
            id: 491,
            name: "Systèmes de détection d'intrusion",
            unite: null,
            exemples: null,
            label: "10.7.5 - Systèmes de détection d'intrusion",
          },
          {
            id: 492,
            name: 'Systèmes de sécurité incendie',
            unite: null,
            exemples: null,
            label: '10.7.6 - Systèmes de sécurité incendie',
          },
          {
            id: 493,
            name: 'Systèmes de vidéosurveillance',
            unite: null,
            exemples: null,
            label: '10.7.7 - Systèmes de vidéosurveillance',
          },
          {
            id: 494,
            name: "Systèmes d'éclairage de sécurité",
            unite: null,
            exemples: null,
            label: "10.7.8 - Systèmes d'éclairage de sécurité",
          },
          {
            id: 495,
            name: 'Autres système de sécurité',
            unite: null,
            exemples: null,
            label: '10.7.9 - Autres système de sécurité',
          },
        ],
      },
      {
        id: 55,
        name: 'Équipements spéciaux divers',
        unite: 'U',
        exemples: 'Transformateurs, motorisations de volets et portes, etc.',
        label: '10.8 - Équipements spéciaux divers',
        children: [
          {
            id: 496,
            name: 'Installations et appareillages pour réseaux de communication filaires ou sans fil',
            unite: null,
            exemples: null,
            label:
              '10.8.1 - Installations et appareillages pour réseaux de communication filaires ou sans fil',
          },
          {
            id: 497,
            name: 'Autres équipements spéciaux divers',
            unite: null,
            exemples: null,
            label: '10.8.2 - Autres équipements spéciaux divers',
          },
        ],
      },
    ],
  },
  {
    id: 11,
    name: 'Mobiliers',
    unite: null,
    exemples: null,
    label: '11 - Mobiliers',
    children: [
      {
        id: 147,
        name: 'Mobiliers intérieurs',
        unite: 'U',
        exemples: 'Mobiliers de bureaux, mobiliers de restauration, mobiliers scolaire, etc.',
        label: '11.1 - Mobiliers intérieurs',
        children: [
          {
            id: 498,
            name: "Banques d'acceuil / Consoles",
            unite: null,
            exemples: null,
            label: "11.1.1 - Banques d'acceuil / Consoles",
          },
          {
            id: 499,
            name: 'Boîtes aux lettres',
            unite: null,
            exemples: null,
            label: '11.1.2 - Boîtes aux lettres',
          },
          {
            id: 500,
            name: 'Bureaux',
            unite: null,
            exemples: null,
            label: '11.1.3 - Bureaux',
          },
          {
            id: 501,
            name: 'Casiers',
            unite: null,
            exemples: null,
            label: '11.1.4 - Casiers',
          },
          {
            id: 502,
            name: 'Chaises',
            unite: null,
            exemples: null,
            label: '11.1.5 - Chaises',
          },
          {
            id: 503,
            name: 'Fauteuils',
            unite: null,
            exemples: null,
            label: '11.1.6 - Fauteuils',
          },
          {
            id: 504,
            name: 'Mobiliers de restauration',
            unite: null,
            exemples: null,
            label: '11.1.7 - Mobiliers de restauration',
          },
          {
            id: 505,
            name: 'Placards préfabriqués ou menuisés',
            unite: null,
            exemples: null,
            label: '11.1.8 - Placards préfabriqués ou menuisés',
          },
          {
            id: 506,
            name: 'Tables',
            unite: null,
            exemples: null,
            label: '11.1.9 - Tables',
          },
          {
            id: 507,
            name: 'Tabourets',
            unite: null,
            exemples: null,
            label: '11.1.10 - Tabourets',
          },
          {
            id: 508,
            name: 'Autres mobiliers intérieurs',
            unite: null,
            exemples: null,
            label: '11.1.11 - Autres mobiliers intérieurs',
          },
        ],
      },
      {
        id: 148,
        name: 'Mobiliers extérieurs',
        unite: 'U',
        exemples: 'Jardinières, pots, racks à vélo, mobiliers de jardin, etc.',
        label: '11.2 - Mobiliers extérieurs',
        children: [
          {
            id: 509,
            name: 'Mobilier de jardin',
            unite: null,
            exemples: null,
            label: '11.2.1 - Mobilier de jardin',
          },
          {
            id: 510,
            name: 'Pots/ bacs à végétaux / jardinières',
            unite: null,
            exemples: null,
            label: '11.2.2 - Pots/ bacs à végétaux / jardinières',
          },
          {
            id: 511,
            name: 'Racks à vélo',
            unite: null,
            exemples: null,
            label: '11.2.3 - Racks à vélo',
          },
          {
            id: 512,
            name: 'Autres mobiliers extérieurs',
            unite: null,
            exemples: null,
            label: '11.2.4 - Autres mobiliers extérieurs',
          },
        ],
      },
      {
        id: 149,
        name: 'Équipements',
        unite: 'U',
        exemples:
          'Electroménager, équipement de cuisine, écrans, ordinateurs et accessoires, téléphonie, sèchemain, etc.',
        label: '11.3 - Équipements',
        children: [
          {
            id: 513,
            name: 'Abattans de WC',
            unite: null,
            exemples: null,
            label: '11.3.1 - Abattans de WC',
          },
          {
            id: 514,
            name: 'Dévidoirs à papier',
            unite: null,
            exemples: null,
            label: '11.3.2 - Dévidoirs à papier',
          },
          {
            id: 515,
            name: 'Distributeurs de savon',
            unite: null,
            exemples: null,
            label: '11.3.3 - Distributeurs de savon',
          },
          {
            id: 516,
            name: 'Ecrans',
            unite: null,
            exemples: null,
            label: '11.3.4 - Ecrans',
          },
          {
            id: 517,
            name: 'Equipements électroménager',
            unite: null,
            exemples: null,
            label: '11.3.5 - Equipements électroménager',
          },
          {
            id: 518,
            name: 'Equipements de cuisine',
            unite: null,
            exemples: null,
            label: '11.3.6 - Equipements de cuisine',
          },
          {
            id: 519,
            name: 'Equipements de téléphonie',
            unite: null,
            exemples: null,
            label: '11.3.7 - Equipements de téléphonie',
          },
          {
            id: 520,
            name: 'Equipements technique de RIE',
            unite: null,
            exemples: null,
            label: '11.3.8 - Equipements technique de RIE',
          },
          {
            id: 521,
            name: 'Autres équipements',
            unite: null,
            exemples: null,
            label: '11.3.9 - Autres équipements',
          },
        ],
      },
      {
        id: 150,
        name: 'Autres',
        unite: 'U',
        exemples: null,
        label: '11.4 - Autres',
        children: [
          {
            id: 522,
            name: 'Autres',
            unite: null,
            exemples: null,
            label: '11.4.1 - Autres',
          },
        ],
      },
    ],
  },
  {
    id: 12,
    name: 'Appareils élévateurs et autres équipements de transport intérieur',
    unite: null,
    exemples: null,
    label: '12 - Appareils élévateurs et autres équipements de transport intérieur',
    children: [
      {
        id: 56,
        name: 'Ascenseurs',
        unite: 'U',
        exemples: 'Y compris les auxiliaires (machinerie, sécurité).',
        label: '12.1 - Ascenseurs',
        children: [
          {
            id: 523,
            name: 'Ascenseurs',
            unite: null,
            exemples: null,
            label: '12.1.1 - Ascenseurs',
          },
          {
            id: 524,
            name: "Auxiliaires d'ascenseur",
            unite: null,
            exemples: null,
            label: "12.1.2 - Auxiliaires d'ascenseur",
          },
          {
            id: 525,
            name: "Autres éléments d'ascenseur",
            unite: null,
            exemples: null,
            label: "12.1.3 - Autres éléments d'ascenseur",
          },
        ],
      },
      {
        id: 57,
        name: 'Montes-charges',
        unite: 'U',
        exemples: 'Y compris les auxiliaires (machinerie, sécurité).',
        label: '12.2 - Montes-charges',
        children: [
          {
            id: 526,
            name: 'Montes charge',
            unite: null,
            exemples: null,
            label: '12.2.1 - Montes charge',
          },
          {
            id: 527,
            name: 'Auxiliaires de monte charge',
            unite: null,
            exemples: null,
            label: '12.2.2 - Auxiliaires de monte charge',
          },
          {
            id: 528,
            name: 'Autres éléments de monte-charge',
            unite: null,
            exemples: null,
            label: '12.2.3 - Autres éléments de monte-charge',
          },
        ],
      },
      {
        id: 58,
        name: 'Escaliers mécaniques',
        unite: 'U',
        exemples: 'Y compris les auxiliaires (machinerie, sécurité).',
        label: '12.3 - Escaliers mécaniques',
        children: [
          {
            id: 529,
            name: 'Escaliers mécaniques',
            unite: null,
            exemples: null,
            label: '12.3.1 - Escaliers mécaniques',
          },
          {
            id: 530,
            name: "Auxiliaires d'escalier mécanique",
            unite: null,
            exemples: null,
            label: "12.3.2 - Auxiliaires d'escalier mécanique",
          },
          {
            id: 531,
            name: "Autres éléments d'escaliers mécaniques",
            unite: null,
            exemples: null,
            label: "12.3.3 - Autres éléments d'escaliers mécaniques",
          },
        ],
      },
    ],
  },
  {
    id: 13,
    name: 'Équipement de production locale d’électricité',
    unite: null,
    exemples: null,
    label: '13 - Équipement de production locale d’électricité',
    children: [
      {
        id: 59,
        name: 'Groupe électrogène',
        unite: 'U',
        exemples: null,
        label: '13.1 - Groupe électrogène',
        children: [
          {
            id: 532,
            name: 'Groupes électrogènes',
            unite: null,
            exemples: null,
            label: '13.1.1 - Groupes électrogènes',
          },
          {
            id: 533,
            name: 'Panneaux photovoltaiques / Onduleurs',
            unite: null,
            exemples: null,
            label: '13.1.2 - Panneaux photovoltaiques / Onduleurs',
          },
          {
            id: 534,
            name: 'Onduleurs sur batteries',
            unite: null,
            exemples: null,
            label: '13.1.3 - Onduleurs sur batteries',
          },
          {
            id: 535,
            name: 'Autres types de groupe électrogène',
            unite: null,
            exemples: null,
            label: '13.1.4 - Autres types de groupe électrogène',
          },
        ],
      },
    ],
  },
  {
    id: 14,
    name: 'Autres',
    unite: null,
    exemples: null,
    label: '14 - Autres',
    children: [
      {
        id: 60,
        name: 'Autres',
        unite: 'U, m², ml, m³',
        exemples:
          'Matériaux bruts (pierre, sables, etc.), autres éléments ne correspondant pas aux catégories prédéfinies.',
        label: '14.1 - Autres',
        children: [
          {
            id: 536,
            name: "Eléments d'espaces verts",
            unite: null,
            exemples: null,
            label: "14.1.1 - Eléments d'espaces verts",
          },
          {
            id: 537,
            name: 'Extincteurs',
            unite: null,
            exemples: null,
            label: '14.1.2 - Extincteurs',
          },
          {
            id: 538,
            name: 'Matériaux bruts (pierre, sables, etc.)',
            unite: null,
            exemples: null,
            label: '14.1.3 - Matériaux bruts (pierre, sables, etc.)',
          },
          {
            id: 539,
            name: 'Remblais',
            unite: null,
            exemples: null,
            label: '14.1.4 - Remblais',
          },
          {
            id: 540,
            name: 'Autres éléments ne correspondant pas aux catégories prédéfinies',
            unite: null,
            exemples: null,
            label: '14.1.5 - Autres éléments ne correspondant pas aux catégories prédéfinies',
          },
        ],
      },
    ],
  },
]

export const WasteCategory: Cat[] = [
  {
    id: 61,
    abbreviation: 'DI',
    name: 'Déchets inertes (DI)',
    unite: null,
    exemples: null,
    label: '1 - Déchets inertes (DI)',
    children: [
      {
        id: 68,
        name: 'Béton',
        unite: null,
        exemples: null,
        label: '1.1 - Béton',
      },
      {
        id: 71,
        name: 'Briques',
        unite: null,
        exemples: null,
        label: '1.2 - Briques',
      },
      {
        id: 70,
        name: 'Tuiles et céramiques',
        unite: null,
        exemples: null,
        label: '1.3 - Tuiles et céramiques',
      },
      {
        id: 73,
        name: 'Mélanges de béton, tuiles et céramique ne contenant pas de substances dangereuses',
        unite: null,
        exemples: null,
        label:
          '1.4 - Mélanges de béton, tuiles et céramique ne contenant pas de substances dangereuses',
      },
      {
        id: 72,
        name: 'Verre (sans cadre ou montant de fenêtres)',
        unite: null,
        exemples: null,
        label: '1.5 - Verre (sans cadre ou montant de fenêtres)',
      },
      {
        id: 74,
        name: 'Mélange bitumineux ne contenant pas de goudron',
        unite: null,
        exemples: null,
        label: '1.6 - Mélange bitumineux ne contenant pas de goudron',
      },
      {
        id: 67,
        name: 'Terres et cailloux ne contenant pas de substance dangereuse',
        unite: null,
        exemples: null,
        label: '1.7 - Terres et cailloux ne contenant pas de substance dangereuse',
      },
      {
        id: 69,
        name: 'Terres et pierres',
        unite: null,
        exemples: null,
        label: '1.8 - Terres et pierres',
      },
      {
        id: 151,
        name: 'Déchets de matériaux à base de fibre et de verre',
        unite: null,
        exemples: null,
        label: '1.9 - Déchets de matériaux à base de fibre et de verre',
      },
      {
        id: 152,
        name: 'Emballage en verre',
        unite: null,
        exemples: null,
        label: '1.10 - Emballage en verre',
      },
      {
        id: 153,
        name: 'Verre (triés)',
        unite: null,
        exemples: null,
        label: '1.11 - Verre (triés)',
      },
    ],
  },
  {
    id: 62,
    abbreviation: 'DNDNI',
    name: 'Déchets non dangereux non inertes (DNDNI)',
    unite: null,
    exemples: null,
    label: '2 - Déchets non dangereux non inertes (DNDNI)',
    children: [
      {
        id: 75,
        name: 'Plâtre',
        unite: null,
        exemples: null,
        label: '2.1 - Plâtre',
        children: [
          {
            id: 84,
            name: 'Plaques et carreaux',
            unite: null,
            exemples: null,
            label: '2.1.1 - Plaques et carreaux',
          },
          {
            id: 85,
            name: 'Enduits et supports inertes',
            unite: null,
            exemples: null,
            label: '2.1.2 - Enduits et supports inertes',
          },
        ],
      },
      {
        id: 76,
        name: 'Bois',
        unite: null,
        exemples: null,
        label: '2.2 - Bois',
        children: [
          {
            id: 86,
            name: 'Bois A (emballages, palettes)',
            unite: null,
            exemples: null,
            label: '2.2.1 - Bois A (emballages, palettes)',
          },
          {
            id: 87,
            name: 'Bois B : BR1',
            unite: null,
            exemples: null,
            label: '2.2.2 - Bois B : BR1',
          },
          {
            id: 88,
            name: 'Bois B : BR2',
            unite: null,
            exemples: null,
            label: '2.2.3 - Bois B : BR2',
          },
        ],
      },
      {
        id: 77,
        name: 'Métaux',
        unite: null,
        exemples: null,
        label: '2.3 - Métaux',
        children: [
          {
            id: 89,
            name: 'Cuivre',
            unite: null,
            exemples: null,
            label: '2.3.1 - Cuivre',
          },
          {
            id: 90,
            name: 'Aluminium',
            unite: null,
            exemples: null,
            label: '2.3.2 - Aluminium',
          },
          {
            id: 91,
            name: 'Ferreux',
            unite: null,
            exemples: null,
            label: '2.3.3 - Ferreux',
          },
          {
            id: 92,
            name: 'Zinc',
            unite: null,
            exemples: null,
            label: '2.3.4 - Zinc',
          },
          {
            id: 93,
            name: 'Autres, non ferreux',
            unite: null,
            exemples: null,
            label: '2.3.5 - Autres, non ferreux',
          },
        ],
      },
      {
        id: 78,
        name: 'Plastiques',
        unite: null,
        exemples: null,
        label: '2.4 - Plastiques',
        children: [
          {
            id: 94,
            name: 'PP',
            unite: null,
            exemples: null,
            label: '2.4.1 - PP',
          },
          {
            id: 95,
            name: 'PE',
            unite: null,
            exemples: null,
            label: '2.4.2 - PE',
          },
          {
            id: 96,
            name: 'PVC',
            unite: null,
            exemples: null,
            label: '2.4.3 - PVC',
          },
          {
            id: 97,
            name: 'PS',
            unite: null,
            exemples: null,
            label: '2.4.4 - PS',
          },
          {
            id: 98,
            name: 'PSE',
            unite: null,
            exemples: null,
            label: '2.4.5 - PSE',
          },
          {
            id: 99,
            name: 'XPS',
            unite: null,
            exemples: null,
            label: '2.4.6 - XPS',
          },
          {
            id: 100,
            name: 'Polyuréthane',
            unite: null,
            exemples: null,
            label: '2.4.7 - Polyuréthane',
          },
          {
            id: 101,
            name: 'Autres plastiques',
            unite: null,
            exemples: null,
            label: '2.4.8 - Autres plastiques',
          },
        ],
      },
      {
        id: 79,
        name: 'Matériaux isolants hors isolants polymères',
        unite: null,
        exemples: null,
        label: '2.5 - Matériaux isolants hors isolants polymères',
        children: [
          {
            id: 102,
            name: 'Laines minérales de verre',
            unite: null,
            exemples: null,
            label: '2.5.1 - Laines minérales de verre',
          },
          {
            id: 103,
            name: 'Laines minérales de roche',
            unite: null,
            exemples: null,
            label: '2.5.2 - Laines minérales de roche',
          },
          {
            id: 104,
            name: 'Isolants biosourcés',
            unite: null,
            exemples: null,
            label: '2.5.3 - Isolants biosourcés',
          },
          {
            id: 108,
            name: 'Autres matériaux isolants',
            unite: null,
            exemples: null,
            label: '2.5.4 - Autres matériaux isolants',
          },
        ],
      },
      {
        id: 80,
        name: 'Revêtements de sol',
        unite: null,
        exemples: null,
        label: '2.6 - Revêtements de sol',
        children: [
          {
            id: 109,
            name: 'Moquette',
            unite: null,
            exemples: null,
            label: '2.6.1 - Moquette',
          },
          {
            id: 110,
            name: 'Linoléum',
            unite: null,
            exemples: null,
            label: '2.6.2 - Linoléum',
          },
          {
            id: 111,
            name: 'Parquet (massif, flottant)',
            unite: null,
            exemples: null,
            label: '2.6.3 - Parquet (massif, flottant)',
          },
          {
            id: 112,
            name: 'Autres revêtements de sol',
            unite: null,
            exemples: null,
            label: '2.6.4 - Autres revêtements de sol',
          },
        ],
      },
      {
        id: 81,
        name: 'Revêtement d’étancheité',
        unite: null,
        exemples: null,
        label: '2.7 - Revêtement d’étancheité',
        children: [
          {
            id: 113,
            name: "Revêtements d'étanchéité bitumineux",
            unite: null,
            exemples: null,
            label: "2.7.1 - Revêtements d'étanchéité bitumineux",
          },
          {
            id: 114,
            name: "Revêtements d'étanchéité synthétiques",
            unite: null,
            exemples: null,
            label: "2.7.2 - Revêtements d'étanchéité synthétiques",
          },
        ],
      },
      {
        id: 82,
        name: 'Complexes',
        unite: null,
        exemples: null,
        label: '2.8 - Complexes',
        children: [
          {
            id: 115,
            name: 'Staff, stuc et plaques de gypse cellulose',
            unite: null,
            exemples: null,
            label: '2.8.1 - Staff, stuc et plaques de gypse cellulose',
          },
          {
            id: 116,
            name: 'Panneaux sandwich',
            unite: null,
            exemples: null,
            label: '2.8.2 - Panneaux sandwich',
          },
          {
            id: 117,
            name: 'Complexes plâtre + isolant',
            unite: null,
            exemples: null,
            label: '2.8.3 - Complexes plâtre + isolant',
          },
          {
            id: 118,
            name: 'Autres matériaux complexes',
            unite: null,
            exemples: null,
            label: '2.8.4 - Autres matériaux complexes',
          },
        ],
      },
      {
        id: 83,
        name: 'Divers',
        unite: null,
        exemples: null,
        label: '2.9 - Divers',
        children: [
          {
            id: 119,
            name: 'Fenêtres et autres ouvertures vitrées : bois/alu/pvc et simple vitrage ou double vitrage',
            unite: null,
            exemples: null,
            label:
              '2.9.1 - Fenêtres et autres ouvertures vitrées : bois/alu/pvc et simple vitrage ou double vitrage',
          },
          {
            id: 120,
            name: 'Mélange de DND listés ci-dessus',
            unite: null,
            exemples: null,
            label: '2.9.2 - Mélange de DND listés ci-dessus',
          },
          {
            id: 121,
            name: 'Végétaux',
            unite: null,
            exemples: null,
            label: '2.9.3 - Végétaux',
          },
          {
            id: 122,
            name: 'Terre végétale',
            unite: null,
            exemples: null,
            label: '2.9.4 - Terre végétale',
          },
          {
            id: 123,
            name: 'Autres matériaux ou déchets non dangereux (DND)',
            unite: null,
            exemples: null,
            label: '2.9.5 - Autres matériaux ou déchets non dangereux (DND)',
          },
        ],
      },
    ],
  },
  {
    id: 64,
    abbreviation: 'DE',
    name: 'Déchets d’équipements',
    unite: null,
    exemples: null,
    label: '3 - Déchets d’équipements',
    children: [
      {
        id: 124,
        name: 'Équipements sanitaires (lavabos, éviers, WC, ...)',
        unite: null,
        exemples: null,
        label: '3.1 - Équipements sanitaires (lavabos, éviers, WC, ...)',
      },
      {
        id: 125,
        name: 'Génie climatique (chauffage, climatisation, ventilation)',
        unite: null,
        exemples: null,
        label: '3.2 - Génie climatique (chauffage, climatisation, ventilation)',
      },
      {
        id: 126,
        name: 'Équipements de chauffage, climatisation ou frigorifiques contenant des fluides frigorigènes dangereux',
        unite: null,
        exemples: null,
        label:
          '3.3 - Équipements de chauffage, climatisation ou frigorifiques contenant des fluides frigorigènes dangereux',
      },
      {
        id: 127,
        name: 'Matériel industriel ou mobilier',
        unite: null,
        exemples: null,
        label: '3.4 - Matériel industriel ou mobilier',
      },
      {
        id: 128,
        name: 'Conduits de fluide et canalisations',
        unite: null,
        exemples: null,
        label: '3.5 - Conduits de fluide et canalisations',
      },
      {
        id: 129,
        name: 'Câbles',
        unite: null,
        exemples: null,
        label: '3.6 - Câbles',
      },
      {
        id: 130,
        name: 'Équipement divers (ascenseurs, armoires TGBT, ...)',
        unite: null,
        exemples: null,
        label: '3.7 - Équipement divers (ascenseurs, armoires TGBT, ...)',
      },
      {
        id: 131,
        name: 'Déchets d’Équipements Électriques et Électroniques (DEEE)',
        unite: null,
        exemples: null,
        label: '3.8 - Déchets d’Équipements Électriques et Électroniques (DEEE)',
        children: [
          {
            id: 132,
            name: 'Luminaires (tubes fluorescents, lampes à décharges, lampes à LED)',
            unite: null,
            exemples: null,
            label: '3.8.1 - Luminaires (tubes fluorescents, lampes à décharges, lampes à LED)',
          },
          {
            id: 133,
            name: 'Électroménagers',
            unite: null,
            exemples: null,
            label: '3.8.2 - Électroménagers',
          },
          {
            id: 135,
            name: 'Autres DEEE contenant des substances dangereuses',
            unite: null,
            exemples: null,
            label: '3.8.9 - Autres DEEE contenant des substances dangereuses',
          },
          {
            id: 136,
            name: 'Autres DEEE non dangereux',
            unite: null,
            exemples: null,
            label: '3.8.10 - Autres DEEE non dangereux',
          },
        ],
      },
    ],
  },
  {
    id: 63,
    abbreviation: 'DD',
    name: 'Déchets dangereux (DD)',
    unite: null,
    exemples: null,
    label: '4 - Déchets dangereux (DD)',
    children: [
      {
        id: 137,
        name: 'Amiante (précisez)',
        unite: null,
        exemples: null,
        label: '4.1 - Amiante (précisez)',
      },
      {
        id: 138,
        name: 'Enrobés à chaud contenant du goudron',
        unite: null,
        exemples: null,
        label: '4.2 - Enrobés à chaud contenant du goudron',
      },
      {
        id: 139,
        name: 'Revêtements d’étanchéité contenant du goudron',
        unite: null,
        exemples: null,
        label: '4.3 - Revêtements d’étanchéité contenant du goudron',
      },
      {
        id: 140,
        name: 'Éléments recouverts de peinture contenant des substances dangereuses',
        unite: null,
        exemples: null,
        label: '4.4 - Éléments recouverts de peinture contenant des substances dangereuses',
      },
      {
        id: 141,
        name: 'Bois traités contenant des substances dangereuses (Bois C)',
        unite: null,
        exemples: null,
        label: '4.5 - Bois traités contenant des substances dangereuses (Bois C)',
      },
      {
        id: 142,
        name: 'Terres contenant des substances dangereuses',
        unite: null,
        exemples: null,
        label: '4.6 - Terres contenant des substances dangereuses',
      },
      {
        id: 143,
        name: 'Déchets dangereux (DD)',
        unite: null,
        exemples: null,
        label: '4.7 - Déchets dangereux (DD)',
      },
    ],
  },
  {
    id: 65,
    name: 'Autre',
    unite: null,
    exemples: null,
    label: '5 - Autre',
    children: [
      {
        id: 154,
        name: 'Autre',
        unite: null,
        exemples: null,
        label: '5.1 - Autre',
      },
    ],
  },
]
export const WasteDD = 63
export const wasteAsbestos = 137

export const ResourceCategory2Category = {
  155: {
    primaryCategory: 42,
    secondaryCategory: 43,
    tertiaryCategory: 48,
  },
  156: {
    primaryCategory: 42,
    secondaryCategory: 43,
    tertiaryCategory: 45,
  },
  157: {
    primaryCategory: 1,
    secondaryCategory: 32,
    tertiaryCategory: 33,
  },
  158: {
    primaryCategory: 42,
    secondaryCategory: 43,
    tertiaryCategory: 48,
  },
  159: {
    primaryCategory: 42,
    secondaryCategory: 43,
    tertiaryCategory: 44,
  },
  160: {
    primaryCategory: 42,
    secondaryCategory: 43,
    tertiaryCategory: 45,
  },
  161: {
    primaryCategory: 42,
    secondaryCategory: 43,
    tertiaryCategory: 48,
  },
  162: {
    primaryCategory: 42,
    secondaryCategory: 43,
    tertiaryCategory: 48,
  },
  163: {
    primaryCategory: 42,
    secondaryCategory: 43,
    tertiaryCategory: 48,
  },
  164: {
    primaryCategory: 42,
    secondaryCategory: 43,
    tertiaryCategory: 48,
  },
  165: {
    primaryCategory: 42,
    secondaryCategory: 49,
    tertiaryCategory: 50,
  },
  166: {
    primaryCategory: 42,
    secondaryCategory: 49,
    tertiaryCategory: 50,
  },
  167: {
    primaryCategory: 42,
    secondaryCategory: 49,
    tertiaryCategory: 50,
  },
  168: {
    primaryCategory: 42,
    secondaryCategory: 49,
    tertiaryCategory: 50,
  },
  169: {
    primaryCategory: 42,
    secondaryCategory: 49,
    tertiaryCategory: 51,
  },
  170: {
    primaryCategory: 42,
    secondaryCategory: 49,
    tertiaryCategory: 50,
  },
  171: {
    primaryCategory: 42,
    secondaryCategory: 49,
    tertiaryCategory: 54,
  },
  172: {
    primaryCategory: 42,
    secondaryCategory: 49,
    tertiaryCategory: 52,
  },
  173: {
    primaryCategory: 42,
    secondaryCategory: 49,
    tertiaryCategory: 50,
  },
  174: {
    primaryCategory: 42,
    secondaryCategory: 49,
    tertiaryCategory: 50,
  },
  175: {
    primaryCategory: 71,
    secondaryCategory: 72,
    tertiaryCategory: 74,
  },
  176: {
    primaryCategory: 42,
    secondaryCategory: 49,
    tertiaryCategory: 53,
  },
  177: {
    primaryCategory: 42,
    secondaryCategory: 66,
    tertiaryCategory: 67,
  },
  178: {
    primaryCategory: 42,
    secondaryCategory: 49,
    tertiaryCategory: 50,
  },
  179: {
    primaryCategory: 42,
    secondaryCategory: 58,
    tertiaryCategory: 60,
  },
  180: {
    primaryCategory: 42,
    secondaryCategory: 49,
    tertiaryCategory: 57,
  },
  181: {
    primaryCategory: 42,
    secondaryCategory: 66,
    tertiaryCategory: 68,
  },
  182: {
    primaryCategory: 42,
    secondaryCategory: 58,
    tertiaryCategory: 60,
  },
  183: {
    primaryCategory: 42,
    secondaryCategory: 58,
    tertiaryCategory: 60,
  },
  184: {
    primaryCategory: 140,
    secondaryCategory: 141,
    tertiaryCategory: 144,
  },
  185: {
    primaryCategory: 42,
    secondaryCategory: 49,
    tertiaryCategory: 57,
  },
  186: {
    primaryCategory: 88,
    secondaryCategory: 89,
    tertiaryCategory: 95,
  },
  187: {
    primaryCategory: 88,
    secondaryCategory: 89,
    tertiaryCategory: 95,
  },
  188: {
    primaryCategory: 42,
    secondaryCategory: 49,
    tertiaryCategory: 50,
  },
  189: {
    primaryCategory: 42,
    secondaryCategory: 49,
    tertiaryCategory: 50,
  },
  190: {
    primaryCategory: 42,
    secondaryCategory: 49,
    tertiaryCategory: 50,
  },
  200: {
    primaryCategory: 42,
    secondaryCategory: 49,
    tertiaryCategory: 51,
  },
  201: {
    primaryCategory: 42,
    secondaryCategory: 49,
    tertiaryCategory: 51,
  },
  202: {
    primaryCategory: 42,
    secondaryCategory: 49,
    tertiaryCategory: 51,
  },
  203: {
    primaryCategory: 105,
    secondaryCategory: 106,
    tertiaryCategory: 108,
  },
  204: {
    primaryCategory: 105,
    secondaryCategory: 112,
    tertiaryCategory: 115,
  },
  205: {
    primaryCategory: 105,
    secondaryCategory: 106,
    tertiaryCategory: 110,
  },
  206: {
    primaryCategory: 105,
    secondaryCategory: 106,
    tertiaryCategory: 111,
  },
  207: {
    primaryCategory: 42,
    secondaryCategory: 49,
    tertiaryCategory: 54,
  },
  208: {
    primaryCategory: 42,
    secondaryCategory: 49,
    tertiaryCategory: 57,
  },
  209: {
    primaryCategory: 42,
    secondaryCategory: 49,
    tertiaryCategory: 55,
  },
  210: {
    primaryCategory: 42,
    secondaryCategory: 49,
    tertiaryCategory: 54,
  },
  211: {
    primaryCategory: 42,
    secondaryCategory: 49,
    tertiaryCategory: 57,
  },
  212: {
    primaryCategory: 42,
    secondaryCategory: 49,
    tertiaryCategory: 55,
  },
  213: {
    primaryCategory: 42,
    secondaryCategory: 49,
    tertiaryCategory: 55,
  },
  214: {
    primaryCategory: 42,
    secondaryCategory: 49,
    tertiaryCategory: 57,
  },
  215: {
    primaryCategory: 1,
    secondaryCategory: 13,
    tertiaryCategory: 15,
  },
  216: {
    primaryCategory: 1,
    secondaryCategory: 13,
    tertiaryCategory: 14,
  },
  217: {
    primaryCategory: 1,
    secondaryCategory: 13,
    tertiaryCategory: 14,
  },
  218: {
    primaryCategory: 1,
    secondaryCategory: 13,
    tertiaryCategory: 18,
  },
  219: {
    primaryCategory: 1,
    secondaryCategory: 13,
    tertiaryCategory: 18,
  },
  220: {
    primaryCategory: 1,
    secondaryCategory: 27,
    tertiaryCategory: 30,
  },
  221: {
    primaryCategory: 1,
    secondaryCategory: 27,
    tertiaryCategory: 28,
  },
  222: {
    primaryCategory: 1,
    secondaryCategory: 27,
    tertiaryCategory: 28,
  },
  223: {
    primaryCategory: 1,
    secondaryCategory: 27,
    tertiaryCategory: 28,
  },
  224: {
    primaryCategory: 1,
    secondaryCategory: 27,
    tertiaryCategory: 28,
  },
  225: {
    primaryCategory: 1,
    secondaryCategory: 27,
    tertiaryCategory: 28,
  },
  226: {
    primaryCategory: 1,
    secondaryCategory: 13,
    tertiaryCategory: 18,
  },
  227: {
    primaryCategory: 1,
    secondaryCategory: 2,
    tertiaryCategory: 6,
  },
  228: {
    primaryCategory: 1,
    secondaryCategory: 2,
    tertiaryCategory: 4,
  },
  229: {
    primaryCategory: 1,
    secondaryCategory: 8,
    tertiaryCategory: 12,
  },
  230: {
    primaryCategory: 1,
    secondaryCategory: 2,
    tertiaryCategory: 3,
  },
  231: {
    primaryCategory: 1,
    secondaryCategory: 32,
    tertiaryCategory: 33,
  },
  232: {
    primaryCategory: 1,
    secondaryCategory: 8,
    tertiaryCategory: 11,
  },
  233: {
    primaryCategory: 1,
    secondaryCategory: 8,
    tertiaryCategory: 12,
  },
  234: {
    primaryCategory: 1,
    secondaryCategory: 8,
    tertiaryCategory: 12,
  },
  235: {
    primaryCategory: 1,
    secondaryCategory: 2,
    tertiaryCategory: 5,
  },
  236: {
    primaryCategory: 1,
    secondaryCategory: 13,
    tertiaryCategory: 16,
  },
  237: {
    primaryCategory: 1,
    secondaryCategory: 8,
    tertiaryCategory: 12,
  },
  238: {
    primaryCategory: 1,
    secondaryCategory: 19,
    tertiaryCategory: 24,
  },
  239: {
    primaryCategory: 42,
    secondaryCategory: 49,
    tertiaryCategory: 54,
  },
  240: {
    primaryCategory: 1,
    secondaryCategory: 19,
    tertiaryCategory: 26,
  },
  241: {
    primaryCategory: 1,
    secondaryCategory: 19,
    tertiaryCategory: 23,
  },
  242: {
    primaryCategory: 1,
    secondaryCategory: 19,
    tertiaryCategory: 23,
  },
  243: {
    primaryCategory: 1,
    secondaryCategory: 19,
    tertiaryCategory: 23,
  },
  244: {
    primaryCategory: 140,
    secondaryCategory: 141,
    tertiaryCategory: 145,
  },
  245: {
    primaryCategory: 71,
    secondaryCategory: 72,
    tertiaryCategory: 74,
  },
  246: {
    primaryCategory: 71,
    secondaryCategory: 72,
    tertiaryCategory: 74,
  },
  247: {
    primaryCategory: 71,
    secondaryCategory: 72,
    tertiaryCategory: 74,
  },
  248: {
    primaryCategory: 105,
    secondaryCategory: 112,
    tertiaryCategory: 113,
  },
  249: {
    primaryCategory: 71,
    secondaryCategory: 72,
    tertiaryCategory: 73,
  },
  250: {
    primaryCategory: 71,
    secondaryCategory: 72,
    tertiaryCategory: 74,
  },
  251: {
    primaryCategory: 71,
    secondaryCategory: 72,
    tertiaryCategory: 75,
  },
  252: {
    primaryCategory: 71,
    secondaryCategory: 76,
    tertiaryCategory: 81,
  },
  253: {
    primaryCategory: 71,
    secondaryCategory: 76,
    tertiaryCategory: 77,
  },
  254: {
    primaryCategory: 42,
    secondaryCategory: 58,
    tertiaryCategory: 60,
  },
  255: {
    primaryCategory: 42,
    secondaryCategory: 66,
    tertiaryCategory: 69,
  },
  256: {
    primaryCategory: 42,
    secondaryCategory: 58,
    tertiaryCategory: 60,
  },
  257: {
    primaryCategory: 71,
    secondaryCategory: 76,
    tertiaryCategory: 83,
  },
  258: {
    primaryCategory: 71,
    secondaryCategory: 76,
    tertiaryCategory: 83,
  },
  259: {
    primaryCategory: 71,
    secondaryCategory: 76,
    tertiaryCategory: 83,
  },
  260: {
    primaryCategory: 105,
    secondaryCategory: 106,
    tertiaryCategory: 110,
  },
  261: {
    primaryCategory: 71,
    secondaryCategory: 76,
    tertiaryCategory: 78,
  },
  262: {
    primaryCategory: 71,
    secondaryCategory: 76,
    tertiaryCategory: 77,
  },
  263: {
    primaryCategory: 71,
    secondaryCategory: 76,
    tertiaryCategory: 79,
  },
  264: {
    primaryCategory: 42,
    secondaryCategory: 58,
    tertiaryCategory: 60,
  },
  265: {
    primaryCategory: 71,
    secondaryCategory: 76,
    tertiaryCategory: 83,
  },
  266: {
    primaryCategory: 71,
    secondaryCategory: 76,
    tertiaryCategory: 83,
  },
  267: {
    primaryCategory: 71,
    secondaryCategory: 76,
    tertiaryCategory: 83,
  },
  268: {
    primaryCategory: 71,
    secondaryCategory: 84,
    tertiaryCategory: 85,
  },
  269: {
    primaryCategory: 71,
    secondaryCategory: 84,
    tertiaryCategory: 85,
  },
  270: {
    primaryCategory: 71,
    secondaryCategory: 84,
    tertiaryCategory: 86,
  },
  271: {
    primaryCategory: 71,
    secondaryCategory: 84,
    tertiaryCategory: 85,
  },
  272: {
    primaryCategory: 71,
    secondaryCategory: 84,
    tertiaryCategory: 86,
  },
  273: {
    primaryCategory: 88,
    secondaryCategory: 96,
    tertiaryCategory: 101,
  },
  274: {
    primaryCategory: 88,
    secondaryCategory: 96,
    tertiaryCategory: 102,
  },
  275: {
    primaryCategory: 140,
    secondaryCategory: 141,
    tertiaryCategory: 142,
  },
  276: {
    primaryCategory: 71,
    secondaryCategory: 84,
    tertiaryCategory: 87,
  },
  277: {
    primaryCategory: 42,
    secondaryCategory: 49,
    tertiaryCategory: 54,
  },
  279: {
    primaryCategory: 42,
    secondaryCategory: 49,
    tertiaryCategory: 54,
  },
  280: {
    primaryCategory: 116,
    secondaryCategory: 117,
    tertiaryCategory: 119,
  },
  281: {
    primaryCategory: 116,
    secondaryCategory: 117,
    tertiaryCategory: 120,
  },
  282: {
    primaryCategory: 116,
    secondaryCategory: 117,
    tertiaryCategory: 118,
  },
  283: {
    primaryCategory: 116,
    secondaryCategory: 117,
    tertiaryCategory: 120,
  },
  284: {
    primaryCategory: 105,
    secondaryCategory: 106,
    tertiaryCategory: 108,
  },
  285: {
    primaryCategory: 105,
    secondaryCategory: 106,
    tertiaryCategory: 108,
  },
  286: {
    primaryCategory: 105,
    secondaryCategory: 106,
    tertiaryCategory: 110,
  },
  287: {
    primaryCategory: 105,
    secondaryCategory: 106,
    tertiaryCategory: 108,
  },
  288: {
    primaryCategory: 105,
    secondaryCategory: 106,
    tertiaryCategory: 108,
  },
  289: {
    primaryCategory: 105,
    secondaryCategory: 112,
    tertiaryCategory: 113,
  },
  290: {
    primaryCategory: 88,
    secondaryCategory: 89,
    tertiaryCategory: 94,
  },
  291: {
    primaryCategory: 105,
    secondaryCategory: 106,
    tertiaryCategory: 110,
  },
  292: {
    primaryCategory: 105,
    secondaryCategory: 106,
    tertiaryCategory: 110,
  },
  293: {
    primaryCategory: 105,
    secondaryCategory: 106,
    tertiaryCategory: 108,
  },
  294: {
    primaryCategory: 116,
    secondaryCategory: 121,
    tertiaryCategory: 123,
  },
  295: {
    primaryCategory: 105,
    secondaryCategory: 112,
    tertiaryCategory: 114,
  },
  296: {
    primaryCategory: 105,
    secondaryCategory: 112,
    tertiaryCategory: 114,
  },
  297: {
    primaryCategory: 116,
    secondaryCategory: 121,
    tertiaryCategory: 123,
  },
  298: {
    primaryCategory: 116,
    secondaryCategory: 121,
    tertiaryCategory: 122,
  },
  299: {
    primaryCategory: 116,
    secondaryCategory: 121,
    tertiaryCategory: 123,
  },
  300: {
    primaryCategory: 116,
    secondaryCategory: 121,
    tertiaryCategory: 123,
  },
  301: {
    primaryCategory: 116,
    secondaryCategory: 124,
    tertiaryCategory: 128,
  },
  302: {
    primaryCategory: 116,
    secondaryCategory: 124,
    tertiaryCategory: 128,
  },
  303: {
    primaryCategory: 116,
    secondaryCategory: 124,
    tertiaryCategory: 128,
  },
  304: {
    primaryCategory: 116,
    secondaryCategory: 124,
    tertiaryCategory: 128,
  },
  305: {
    primaryCategory: 116,
    secondaryCategory: 124,
    tertiaryCategory: 133,
  },
  306: {
    primaryCategory: 116,
    secondaryCategory: 124,
    tertiaryCategory: 133,
  },
  307: {
    primaryCategory: 116,
    secondaryCategory: 124,
    tertiaryCategory: 133,
  },
  308: {
    primaryCategory: 116,
    secondaryCategory: 124,
    tertiaryCategory: 133,
  },
  309: {
    primaryCategory: 116,
    secondaryCategory: 124,
    tertiaryCategory: 133,
  },
  310: {
    primaryCategory: 116,
    secondaryCategory: 124,
    tertiaryCategory: 133,
  },
  311: {
    primaryCategory: 116,
    secondaryCategory: 124,
    tertiaryCategory: 133,
  },
  312: {
    primaryCategory: 140,
    secondaryCategory: 141,
    tertiaryCategory: 144,
  },
  313: {
    primaryCategory: 140,
    secondaryCategory: 141,
    tertiaryCategory: 142,
  },
  314: {
    primaryCategory: 140,
    secondaryCategory: 141,
    tertiaryCategory: 143,
  },
  315: {
    primaryCategory: 116,
    secondaryCategory: 134,
    tertiaryCategory: 139,
  },
  316: {
    primaryCategory: 116,
    secondaryCategory: 134,
    tertiaryCategory: 137,
  },
  317: {
    primaryCategory: 116,
    secondaryCategory: 134,
    tertiaryCategory: 139,
  },
  318: {
    primaryCategory: 150,
    secondaryCategory: 151,
    tertiaryCategory: 156,
  },
  319: {
    primaryCategory: 116,
    secondaryCategory: 134,
    tertiaryCategory: 139,
  },
  320: {
    primaryCategory: 88,
    secondaryCategory: 89,
    tertiaryCategory: 90,
  },
  321: {
    primaryCategory: 88,
    secondaryCategory: 89,
    tertiaryCategory: 90,
  },
  322: {
    primaryCategory: 88,
    secondaryCategory: 89,
    tertiaryCategory: 90,
  },
  323: {
    primaryCategory: 88,
    secondaryCategory: 89,
    tertiaryCategory: 94,
  },
  324: {
    primaryCategory: 105,
    secondaryCategory: 106,
    tertiaryCategory: 107,
  },
  325: {
    primaryCategory: 105,
    secondaryCategory: 106,
    tertiaryCategory: 110,
  },
  326: {
    primaryCategory: 105,
    secondaryCategory: 106,
    tertiaryCategory: 107,
  },
  327: {
    primaryCategory: 88,
    secondaryCategory: 89,
    tertiaryCategory: 90,
  },
  328: {
    primaryCategory: 88,
    secondaryCategory: 89,
    tertiaryCategory: 90,
  },
  329: {
    primaryCategory: 88,
    secondaryCategory: 89,
    tertiaryCategory: 95,
  },
  330: {
    primaryCategory: 88,
    secondaryCategory: 96,
    tertiaryCategory: 97,
  },
  331: {
    primaryCategory: 88,
    secondaryCategory: 96,
    tertiaryCategory: 102,
  },
  332: {
    primaryCategory: 88,
    secondaryCategory: 96,
    tertiaryCategory: 102,
  },
  333: {
    primaryCategory: 88,
    secondaryCategory: 96,
    tertiaryCategory: 97,
  },
  334: {
    primaryCategory: 88,
    secondaryCategory: 96,
    tertiaryCategory: 99,
  },
  335: {
    primaryCategory: 88,
    secondaryCategory: 96,
    tertiaryCategory: 102,
  },
  336: {
    primaryCategory: 140,
    secondaryCategory: 146,
    tertiaryCategory: 147,
  },
  337: {
    primaryCategory: 88,
    secondaryCategory: 96,
    tertiaryCategory: 99,
  },
  338: {
    primaryCategory: 88,
    secondaryCategory: 96,
    tertiaryCategory: 98,
  },
  339: {
    primaryCategory: 88,
    secondaryCategory: 96,
    tertiaryCategory: 102,
  },
  340: {
    primaryCategory: 88,
    secondaryCategory: 96,
    tertiaryCategory: 103,
  },
  341: {
    primaryCategory: 88,
    secondaryCategory: 96,
    tertiaryCategory: 102,
  },
  342: {
    primaryCategory: 140,
    secondaryCategory: 146,
    tertiaryCategory: 147,
  },
  343: {
    primaryCategory: 140,
    secondaryCategory: 146,
    tertiaryCategory: 149,
  },
  344: {
    primaryCategory: 88,
    secondaryCategory: 96,
    tertiaryCategory: 104,
  },
  345: {
    primaryCategory: 140,
    secondaryCategory: 141,
    tertiaryCategory: 144,
  },
  346: {
    primaryCategory: 140,
    secondaryCategory: 141,
    tertiaryCategory: 144,
  },
  347: {
    primaryCategory: 88,
    secondaryCategory: 89,
    tertiaryCategory: 95,
  },
  348: {
    primaryCategory: 88,
    secondaryCategory: 89,
    tertiaryCategory: 95,
  },
  349: {
    primaryCategory: 88,
    secondaryCategory: 96,
    tertiaryCategory: 103,
  },
  350: {
    primaryCategory: 88,
    secondaryCategory: 96,
    tertiaryCategory: 103,
  },
  351: {
    primaryCategory: 88,
    secondaryCategory: 89,
    tertiaryCategory: 95,
  },
  352: {
    primaryCategory: 150,
    secondaryCategory: 151,
    tertiaryCategory: 156,
  },
  353: {
    primaryCategory: 116,
    secondaryCategory: 134,
    tertiaryCategory: 139,
  },
  354: {
    primaryCategory: 150,
    secondaryCategory: 151,
    tertiaryCategory: 152,
  },
  355: {
    primaryCategory: 150,
    secondaryCategory: 151,
    tertiaryCategory: 153,
  },
  356: {
    primaryCategory: 150,
    secondaryCategory: 151,
    tertiaryCategory: 154,
  },
  357: {
    primaryCategory: 150,
    secondaryCategory: 151,
    tertiaryCategory: 155,
  },
  358: {
    primaryCategory: 150,
    secondaryCategory: 151,
    tertiaryCategory: 153,
  },
  359: {
    primaryCategory: 150,
    secondaryCategory: 151,
    tertiaryCategory: 157,
  },
  360: {
    primaryCategory: 150,
    secondaryCategory: 151,
    tertiaryCategory: 157,
  },
  361: {
    primaryCategory: 42,
    secondaryCategory: 49,
    tertiaryCategory: 54,
  },
  362: {
    primaryCategory: 150,
    secondaryCategory: 158,
    tertiaryCategory: 159,
  },
  363: {
    primaryCategory: 150,
    secondaryCategory: 158,
    tertiaryCategory: 162,
  },
  364: {
    primaryCategory: 150,
    secondaryCategory: 158,
    tertiaryCategory: 162,
  },
  365: {
    primaryCategory: 150,
    secondaryCategory: 158,
    tertiaryCategory: 162,
  },
  366: {
    primaryCategory: 150,
    secondaryCategory: 158,
    tertiaryCategory: 162,
  },
  367: {
    primaryCategory: 150,
    secondaryCategory: 158,
    tertiaryCategory: 162,
  },
  368: {
    primaryCategory: 150,
    secondaryCategory: 158,
    tertiaryCategory: 162,
  },
  369: {
    primaryCategory: 150,
    secondaryCategory: 158,
    tertiaryCategory: 162,
  },
  370: {
    primaryCategory: 163,
    secondaryCategory: 172,
    tertiaryCategory: 173,
  },
  371: {
    primaryCategory: 71,
    secondaryCategory: 84,
    tertiaryCategory: 85,
  },
  372: {
    primaryCategory: 163,
    secondaryCategory: 172,
    tertiaryCategory: 177,
  },
  373: {
    primaryCategory: 71,
    secondaryCategory: 84,
    tertiaryCategory: 85,
  },
  374: {
    primaryCategory: 163,
    secondaryCategory: 196,
    tertiaryCategory: 200,
  },
  375: {
    primaryCategory: 163,
    secondaryCategory: 196,
    tertiaryCategory: 201,
  },
  376: {
    primaryCategory: 163,
    secondaryCategory: 172,
    tertiaryCategory: 176,
  },
  377: {
    primaryCategory: 163,
    secondaryCategory: 172,
    tertiaryCategory: 176,
  },
  378: {
    primaryCategory: 163,
    secondaryCategory: 172,
    tertiaryCategory: 176,
  },
  379: {
    primaryCategory: 163,
    secondaryCategory: 172,
    tertiaryCategory: 176,
  },
  380: {
    primaryCategory: 163,
    secondaryCategory: 172,
    tertiaryCategory: 175,
  },
  381: {
    primaryCategory: 163,
    secondaryCategory: 172,
    tertiaryCategory: 176,
  },
  382: {
    primaryCategory: 163,
    secondaryCategory: 172,
    tertiaryCategory: 173,
  },
  383: {
    primaryCategory: 163,
    secondaryCategory: 172,
    tertiaryCategory: 173,
  },
  384: {
    primaryCategory: 163,
    secondaryCategory: 164,
    tertiaryCategory: 171,
  },
  385: {
    primaryCategory: 163,
    secondaryCategory: 172,
    tertiaryCategory: 173,
  },
  386: {
    primaryCategory: 163,
    secondaryCategory: 172,
    tertiaryCategory: 176,
  },
  387: {
    primaryCategory: 163,
    secondaryCategory: 172,
    tertiaryCategory: 176,
  },
  388: {
    primaryCategory: 163,
    secondaryCategory: 164,
    tertiaryCategory: 171,
  },
  389: {
    primaryCategory: 163,
    secondaryCategory: 172,
    tertiaryCategory: 177,
  },
  390: {
    primaryCategory: 163,
    secondaryCategory: 172,
    tertiaryCategory: 177,
  },
  391: {
    primaryCategory: 163,
    secondaryCategory: 172,
    tertiaryCategory: 177,
  },
  392: {
    primaryCategory: 163,
    secondaryCategory: 172,
    tertiaryCategory: 177,
  },
  393: {
    primaryCategory: 163,
    secondaryCategory: 172,
    tertiaryCategory: 177,
  },
  394: {
    primaryCategory: 163,
    secondaryCategory: 172,
    tertiaryCategory: 177,
  },
  395: {
    primaryCategory: 163,
    secondaryCategory: 172,
    tertiaryCategory: 177,
  },
  396: {
    primaryCategory: 163,
    secondaryCategory: 172,
    tertiaryCategory: 177,
  },
  397: {
    primaryCategory: 163,
    secondaryCategory: 172,
    tertiaryCategory: 177,
  },
  398: {
    primaryCategory: 163,
    secondaryCategory: 172,
    tertiaryCategory: 177,
  },
  399: {
    primaryCategory: 163,
    secondaryCategory: 172,
    tertiaryCategory: 177,
  },
  400: {
    primaryCategory: 71,
    secondaryCategory: 84,
    tertiaryCategory: 85,
  },
  401: {
    primaryCategory: 163,
    secondaryCategory: 172,
    tertiaryCategory: 177,
  },
  402: {
    primaryCategory: 163,
    secondaryCategory: 172,
    tertiaryCategory: 175,
  },
  403: {
    primaryCategory: 163,
    secondaryCategory: 192,
    tertiaryCategory: 194,
  },
  404: {
    primaryCategory: 163,
    secondaryCategory: 172,
    tertiaryCategory: 176,
  },
  405: {
    primaryCategory: 163,
    secondaryCategory: 172,
    tertiaryCategory: 175,
  },
  406: {
    primaryCategory: 163,
    secondaryCategory: 172,
    tertiaryCategory: 178,
  },
  407: {
    primaryCategory: 163,
    secondaryCategory: 164,
    tertiaryCategory: 165,
  },
  408: {
    primaryCategory: 163,
    secondaryCategory: 164,
    tertiaryCategory: 165,
  },
  409: {
    primaryCategory: 163,
    secondaryCategory: 164,
    tertiaryCategory: 165,
  },
  410: {
    primaryCategory: 163,
    secondaryCategory: 164,
    tertiaryCategory: 165,
  },
  411: {
    primaryCategory: 163,
    secondaryCategory: 164,
    tertiaryCategory: 165,
  },
  412: {
    primaryCategory: 163,
    secondaryCategory: 164,
    tertiaryCategory: 166,
  },
  413: {
    primaryCategory: 163,
    secondaryCategory: 164,
    tertiaryCategory: 166,
  },
  414: {
    primaryCategory: 163,
    secondaryCategory: 164,
    tertiaryCategory: 166,
  },
  415: {
    primaryCategory: 163,
    secondaryCategory: 164,
    tertiaryCategory: 166,
  },
  416: {
    primaryCategory: 163,
    secondaryCategory: 164,
    tertiaryCategory: 166,
  },
  417: {
    primaryCategory: 163,
    secondaryCategory: 164,
    tertiaryCategory: 166,
  },
  418: {
    primaryCategory: 163,
    secondaryCategory: 164,
    tertiaryCategory: 166,
  },
  419: {
    primaryCategory: 163,
    secondaryCategory: 164,
    tertiaryCategory: 166,
  },
  420: {
    primaryCategory: 163,
    secondaryCategory: 164,
    tertiaryCategory: 169,
  },
  421: {
    primaryCategory: 163,
    secondaryCategory: 164,
    tertiaryCategory: 169,
  },
  422: {
    primaryCategory: 163,
    secondaryCategory: 164,
    tertiaryCategory: 169,
  },
  423: {
    primaryCategory: 163,
    secondaryCategory: 164,
    tertiaryCategory: 168,
  },
  424: {
    primaryCategory: 163,
    secondaryCategory: 164,
    tertiaryCategory: 169,
  },
  425: {
    primaryCategory: 163,
    secondaryCategory: 164,
    tertiaryCategory: 167,
  },
  426: {
    primaryCategory: 163,
    secondaryCategory: 164,
    tertiaryCategory: 167,
  },
  427: {
    primaryCategory: 163,
    secondaryCategory: 164,
    tertiaryCategory: 167,
  },
  428: {
    primaryCategory: 163,
    secondaryCategory: 164,
    tertiaryCategory: 167,
  },
  429: {
    primaryCategory: 163,
    secondaryCategory: 164,
    tertiaryCategory: 168,
  },
  430: {
    primaryCategory: 163,
    secondaryCategory: 164,
    tertiaryCategory: 170,
  },
  431: {
    primaryCategory: 163,
    secondaryCategory: 164,
    tertiaryCategory: 169,
  },
  432: {
    primaryCategory: 163,
    secondaryCategory: 164,
    tertiaryCategory: 169,
  },
  433: {
    primaryCategory: 163,
    secondaryCategory: 164,
    tertiaryCategory: 166,
  },
  434: {
    primaryCategory: 163,
    secondaryCategory: 164,
    tertiaryCategory: 170,
  },
  435: {
    primaryCategory: 163,
    secondaryCategory: 164,
    tertiaryCategory: 168,
  },
  436: {
    primaryCategory: 163,
    secondaryCategory: 164,
    tertiaryCategory: 167,
  },
  437: {
    primaryCategory: 163,
    secondaryCategory: 164,
    tertiaryCategory: 171,
  },
  438: {
    primaryCategory: 163,
    secondaryCategory: 190,
    tertiaryCategory: 191,
  },
  439: {
    primaryCategory: 163,
    secondaryCategory: 190,
    tertiaryCategory: 191,
  },
  440: {
    primaryCategory: 163,
    secondaryCategory: 190,
    tertiaryCategory: 191,
  },
  441: {
    primaryCategory: 163,
    secondaryCategory: 190,
    tertiaryCategory: 191,
  },
  442: {
    primaryCategory: 163,
    secondaryCategory: 190,
    tertiaryCategory: 191,
  },
  443: {
    primaryCategory: 163,
    secondaryCategory: 190,
    tertiaryCategory: 191,
  },
  444: {
    primaryCategory: 163,
    secondaryCategory: 190,
    tertiaryCategory: 191,
  },
  445: {
    primaryCategory: 163,
    secondaryCategory: 190,
    tertiaryCategory: 191,
  },
  446: {
    primaryCategory: 163,
    secondaryCategory: 190,
    tertiaryCategory: 191,
  },
  447: {
    primaryCategory: 163,
    secondaryCategory: 179,
    tertiaryCategory: 180,
  },
  448: {
    primaryCategory: 163,
    secondaryCategory: 179,
    tertiaryCategory: 180,
  },
  449: {
    primaryCategory: 163,
    secondaryCategory: 179,
    tertiaryCategory: 185,
  },
  450: {
    primaryCategory: 163,
    secondaryCategory: 179,
    tertiaryCategory: 180,
  },
  451: {
    primaryCategory: 163,
    secondaryCategory: 179,
    tertiaryCategory: 180,
  },
  452: {
    primaryCategory: 163,
    secondaryCategory: 179,
    tertiaryCategory: 185,
  },
  453: {
    primaryCategory: 163,
    secondaryCategory: 179,
    tertiaryCategory: 185,
  },
  454: {
    primaryCategory: 163,
    secondaryCategory: 179,
    tertiaryCategory: 180,
  },
  455: {
    primaryCategory: 163,
    secondaryCategory: 179,
    tertiaryCategory: 181,
  },
  456: {
    primaryCategory: 163,
    secondaryCategory: 179,
    tertiaryCategory: 181,
  },
  457: {
    primaryCategory: 163,
    secondaryCategory: 179,
    tertiaryCategory: 181,
  },
  458: {
    primaryCategory: 163,
    secondaryCategory: 179,
    tertiaryCategory: 181,
  },
  459: {
    primaryCategory: 163,
    secondaryCategory: 179,
    tertiaryCategory: 181,
  },
  460: {
    primaryCategory: 163,
    secondaryCategory: 179,
    tertiaryCategory: 181,
  },
  461: {
    primaryCategory: 163,
    secondaryCategory: 179,
    tertiaryCategory: 181,
  },
  462: {
    primaryCategory: 163,
    secondaryCategory: 179,
    tertiaryCategory: 181,
  },
  463: {
    primaryCategory: 163,
    secondaryCategory: 179,
    tertiaryCategory: 182,
  },
  464: {
    primaryCategory: 163,
    secondaryCategory: 179,
    tertiaryCategory: 182,
  },
  465: {
    primaryCategory: 163,
    secondaryCategory: 179,
    tertiaryCategory: 182,
  },
  466: {
    primaryCategory: 163,
    secondaryCategory: 179,
    tertiaryCategory: 183,
  },
  467: {
    primaryCategory: 163,
    secondaryCategory: 179,
    tertiaryCategory: 183,
  },
  468: {
    primaryCategory: 163,
    secondaryCategory: 179,
    tertiaryCategory: 183,
  },
  469: {
    primaryCategory: 163,
    secondaryCategory: 179,
    tertiaryCategory: 182,
  },
  470: {
    primaryCategory: 163,
    secondaryCategory: 179,
    tertiaryCategory: 183,
  },
  471: {
    primaryCategory: 163,
    secondaryCategory: 179,
    tertiaryCategory: 183,
  },
  472: {
    primaryCategory: 163,
    secondaryCategory: 179,
    tertiaryCategory: 183,
  },
  473: {
    primaryCategory: 163,
    secondaryCategory: 179,
    tertiaryCategory: 183,
  },
  474: {
    primaryCategory: 163,
    secondaryCategory: 179,
    tertiaryCategory: 183,
  },
  475: {
    primaryCategory: 163,
    secondaryCategory: 179,
    tertiaryCategory: 183,
  },
  476: {
    primaryCategory: 163,
    secondaryCategory: 179,
    tertiaryCategory: 184,
  },
  477: {
    primaryCategory: 163,
    secondaryCategory: 186,
    tertiaryCategory: 187,
  },
  478: {
    primaryCategory: 163,
    secondaryCategory: 186,
    tertiaryCategory: 187,
  },
  479: {
    primaryCategory: 163,
    secondaryCategory: 186,
    tertiaryCategory: 187,
  },
  480: {
    primaryCategory: 163,
    secondaryCategory: 186,
    tertiaryCategory: 187,
  },
  481: {
    primaryCategory: 163,
    secondaryCategory: 186,
    tertiaryCategory: 187,
  },
  482: {
    primaryCategory: 163,
    secondaryCategory: 186,
    tertiaryCategory: 187,
  },
  483: {
    primaryCategory: 163,
    secondaryCategory: 186,
    tertiaryCategory: 187,
  },
  484: {
    primaryCategory: 163,
    secondaryCategory: 186,
    tertiaryCategory: 188,
  },
  485: {
    primaryCategory: 163,
    secondaryCategory: 186,
    tertiaryCategory: 187,
  },
  486: {
    primaryCategory: 163,
    secondaryCategory: 186,
    tertiaryCategory: 189,
  },
  487: {
    primaryCategory: 163,
    secondaryCategory: 190,
    tertiaryCategory: 191,
  },
  488: {
    primaryCategory: 163,
    secondaryCategory: 190,
    tertiaryCategory: 191,
  },
  489: {
    primaryCategory: 163,
    secondaryCategory: 190,
    tertiaryCategory: 191,
  },
  490: {
    primaryCategory: 163,
    secondaryCategory: 186,
    tertiaryCategory: 188,
  },
  491: {
    primaryCategory: 163,
    secondaryCategory: 186,
    tertiaryCategory: 188,
  },
  492: {
    primaryCategory: 163,
    secondaryCategory: 190,
    tertiaryCategory: 191,
  },
  493: {
    primaryCategory: 163,
    secondaryCategory: 186,
    tertiaryCategory: 188,
  },
  494: {
    primaryCategory: 163,
    secondaryCategory: 190,
    tertiaryCategory: 191,
  },
  495: {
    primaryCategory: 163,
    secondaryCategory: 190,
    tertiaryCategory: 191,
  },
  496: {
    primaryCategory: 163,
    secondaryCategory: 186,
    tertiaryCategory: 187,
  },
  497: {
    primaryCategory: 163,
    secondaryCategory: 186,
    tertiaryCategory: 189,
  },
  498: {
    primaryCategory: 116,
    secondaryCategory: 124,
    tertiaryCategory: 130,
  },
  499: {
    primaryCategory: 203,
    secondaryCategory: 204,
    tertiaryCategory: 206,
  },
  500: {
    primaryCategory: 203,
    secondaryCategory: 204,
    tertiaryCategory: 205,
  },
  501: {
    primaryCategory: 203,
    secondaryCategory: 204,
    tertiaryCategory: 206,
  },
  502: {
    primaryCategory: 203,
    secondaryCategory: 204,
    tertiaryCategory: 205,
  },
  503: {
    primaryCategory: 203,
    secondaryCategory: 204,
    tertiaryCategory: 205,
  },
  504: {
    primaryCategory: 203,
    secondaryCategory: 204,
    tertiaryCategory: 205,
  },
  505: {
    primaryCategory: 116,
    secondaryCategory: 124,
    tertiaryCategory: 130,
  },
  506: {
    primaryCategory: 203,
    secondaryCategory: 204,
    tertiaryCategory: 205,
  },
  507: {
    primaryCategory: 203,
    secondaryCategory: 204,
    tertiaryCategory: 205,
  },
  508: {
    primaryCategory: 203,
    secondaryCategory: 204,
    tertiaryCategory: 208,
  },
  509: {
    primaryCategory: 1,
    secondaryCategory: 35,
    tertiaryCategory: 41,
  },
  510: {
    primaryCategory: 1,
    secondaryCategory: 35,
    tertiaryCategory: 41,
  },
  511: {
    primaryCategory: 1,
    secondaryCategory: 35,
    tertiaryCategory: 36,
  },
  512: {
    primaryCategory: 1,
    secondaryCategory: 35,
    tertiaryCategory: 41,
  },
  513: {
    primaryCategory: 163,
    secondaryCategory: 164,
    tertiaryCategory: 168,
  },
  514: {
    primaryCategory: 163,
    secondaryCategory: 164,
    tertiaryCategory: 171,
  },
  515: {
    primaryCategory: 163,
    secondaryCategory: 164,
    tertiaryCategory: 171,
  },
  516: {
    primaryCategory: 203,
    secondaryCategory: 209,
    tertiaryCategory: 210,
  },
  517: {
    primaryCategory: 203,
    secondaryCategory: 209,
    tertiaryCategory: 211,
  },
  518: {
    primaryCategory: 163,
    secondaryCategory: 192,
    tertiaryCategory: 194,
  },
  519: {
    primaryCategory: 203,
    secondaryCategory: 209,
    tertiaryCategory: 210,
  },
  520: {
    primaryCategory: 163,
    secondaryCategory: 192,
    tertiaryCategory: 194,
  },
  521: {
    primaryCategory: 203,
    secondaryCategory: 212,
    tertiaryCategory: 213,
  },
  522: {
    primaryCategory: 203,
    secondaryCategory: 212,
    tertiaryCategory: 213,
  },
  523: {
    primaryCategory: 163,
    secondaryCategory: 196,
    tertiaryCategory: 197,
  },
  524: {
    primaryCategory: 163,
    secondaryCategory: 196,
    tertiaryCategory: 197,
  },
  525: {
    primaryCategory: 163,
    secondaryCategory: 196,
    tertiaryCategory: 197,
  },
  526: {
    primaryCategory: 163,
    secondaryCategory: 196,
    tertiaryCategory: 198,
  },
  527: {
    primaryCategory: 163,
    secondaryCategory: 196,
    tertiaryCategory: 198,
  },
  528: {
    primaryCategory: 163,
    secondaryCategory: 196,
    tertiaryCategory: 198,
  },
  529: {
    primaryCategory: 163,
    secondaryCategory: 196,
    tertiaryCategory: 199,
  },
  530: {
    primaryCategory: 163,
    secondaryCategory: 196,
    tertiaryCategory: 199,
  },
  531: {
    primaryCategory: 163,
    secondaryCategory: 196,
    tertiaryCategory: 199,
  },
  532: {
    primaryCategory: 163,
    secondaryCategory: 196,
    tertiaryCategory: 202,
  },
  533: {
    primaryCategory: 163,
    secondaryCategory: 196,
    tertiaryCategory: 200,
  },
  534: {
    primaryCategory: 163,
    secondaryCategory: 196,
    tertiaryCategory: 201,
  },
  535: {
    primaryCategory: 163,
    secondaryCategory: 196,
    tertiaryCategory: 202,
  },
  536: {
    primaryCategory: 1,
    secondaryCategory: 19,
    tertiaryCategory: 26,
  },
  537: {
    primaryCategory: 163,
    secondaryCategory: 190,
    tertiaryCategory: 191,
  },
  538: {
    primaryCategory: 1,
    secondaryCategory: 2,
    tertiaryCategory: 3,
  },
  539: {
    primaryCategory: 1,
    secondaryCategory: 19,
    tertiaryCategory: 20,
  },
  540: {
    primaryCategory: 203,
    secondaryCategory: 212,
    tertiaryCategory: 213,
  },
  541: {
    primaryCategory: 116,
    secondaryCategory: 117,
    tertiaryCategory: 120,
  },
  542: {
    primaryCategory: 88,
    secondaryCategory: 96,
    tertiaryCategory: 101,
  },
}

export const getOrder = (
  type: 'rae' | 'waste' | 'resource',
  primary?: number,
  secondary?: number,
  tertiary?: number,
): number => {
  const categories = {
    rae: Category,
    waste: WasteCategory,
    resource: ResourceCategory,
  }[type]
  if (!primary && !secondary && !tertiary) {
    return -1
  }
  let totalIndex = 0
  let primaryCat, secondaryCat, tertiaryCat
  for (let i = 0; i < categories.length; i++) {
    primaryCat = categories[i]
    totalIndex++

    if (primary === primaryCat.id && !secondary) {
      return totalIndex
    }

    for (let j = 0; j < (primaryCat.children?.length ?? 0); j++) {
      secondaryCat = primaryCat.children![j]
      totalIndex++

      if (secondary === secondaryCat.id && !tertiary) {
        return totalIndex
      }

      for (let k = 0; k < (secondaryCat.children?.length ?? 0); k++) {
        tertiaryCat = secondaryCat.children![k]
        totalIndex++
        if (tertiary === tertiaryCat.id) {
          return totalIndex
        }
      }
    }
  }

  return -1
}

export const checkCategory = (
  type: 'rae' | 'waste' | 'resource',
  primary?: number,
  secondary?: number,
  tertiary?: number,
): boolean => {
  const categories = {
    rae: Category,
    waste: WasteCategory,
    resource: ResourceCategory,
  }[type]
  if (!primary && !secondary && !tertiary) {
    return true
  }
  const primaryCat = categories.find((cat) => cat.id === primary)
  if (!primaryCat) {
    return false
  } else if (!primaryCat.children || primaryCat.children.length === 0) {
    return !secondary && !tertiary
  } else {
    const secondaryCat = primaryCat.children.find((cat) => cat.id === secondary)
    if (!secondaryCat) {
      return false
    } else if (!secondaryCat.children || secondaryCat.children.length === 0) {
      return !tertiary
    } else {
      return !!secondaryCat.children.find((cat) => cat.id === tertiary)
    }
  }
}
const getBaseName = (
  type: 'rae' | 'waste' | 'resource',
  category: number,
  abbreviation = false,
): string => {
  const categories = {
    rae: Category,
    waste: WasteCategory,
    resource: ResourceCategory,
  }[type]

  let primaryCat, secondaryCat, tertiaryCat
  for (let i = 0; i < categories.length; i++) {
    primaryCat = categories[i]
    if (category === primaryCat.id) {
      return abbreviation ? primaryCat.abbreviation ?? '' : primaryCat.name
    }

    for (let j = 0; j < (primaryCat.children?.length ?? 0); j++) {
      secondaryCat = primaryCat.children![j]
      if (category === secondaryCat.id) {
        return abbreviation ? secondaryCat.abbreviation ?? '' : secondaryCat.name
      }

      for (let k = 0; k < (secondaryCat.children?.length ?? 0); k++) {
        tertiaryCat = secondaryCat.children![k]
        if (category === tertiaryCat.id) {
          return abbreviation ? tertiaryCat.abbreviation ?? '' : tertiaryCat.name
        }
      }
    }
  }
  return ''
}
const traduce = (
  type: 'rae' | 'waste' | 'resource',
  category: number,
  abbreviation = false,
): string | undefined => {
  const key = `${type}${abbreviation ? '-abbreviation' : ''}.${category}`
  const traduction = i18n.t(`categories:${key}` as any)
  return key === traduction ? undefined : traduction
}
export const traduceCategory = (
  type: 'rae' | 'waste' | 'resource',
  category: number,
  abbreviation = false,
): string => {
  return traduce(type, category, abbreviation) || getBaseName(type, category, abbreviation)
}

const parse = (type: 'rae' | 'waste' | 'resource', cat: any): SelectOption => ({
  value: cat.id,
  label: traduce(type, cat.id) || cat.name,
})
export const getCategoryChoices = (
  type: 'rae' | 'waste' | 'resource',
  parentCategory?: number,
): SelectOption[] => {
  const parseType = parse.bind(null, type)
  const categories = {
    rae: Category,
    waste: WasteCategory,
    resource: ResourceCategory,
  }[type]

  if (!parentCategory) {
    return categories.map(parseType)
  } else {
    let primaryCat, secondaryCat
    for (let i = 0; i < categories.length; i++) {
      primaryCat = categories[i]
      if (parentCategory === primaryCat.id) {
        return primaryCat.children?.map(parseType) ?? []
      }

      for (let j = 0; j < (primaryCat?.children?.length ?? 0); j++) {
        secondaryCat = primaryCat.children![j]
        if (parentCategory === secondaryCat.id) {
          return secondaryCat?.children?.map(parseType) ?? []
        }
      }
    }
  }
  return []
}
export const findCategory = (
  type: 'rae' | 'waste' | 'resource',
  finalCategory: number,
):
  | {
      tertiaryCategory: number | undefined
      secondaryCategory: number | undefined
      primaryCategory: number | undefined
    }
  | undefined => {
  const categories = {
    rae: Category,
    waste: WasteCategory,
    resource: ResourceCategory,
  }[type]
  for (let i = 0; i < categories.length; i++) {
    if (finalCategory === categories[i].id) {
      return {
        tertiaryCategory: undefined,
        secondaryCategory: undefined,
        primaryCategory: categories[i].id,
      }
    }
    for (let j = 0; j < (categories[i].children?.length ?? 0); j++) {
      if (finalCategory === categories[i].children![j].id) {
        return {
          tertiaryCategory: undefined,
          secondaryCategory: categories[i].children![j].id,
          primaryCategory: categories[i].id,
        }
      }
      for (let k = 0; k < (categories[i].children![j].children?.length ?? 0); k++) {
        if (finalCategory === categories[i].children![j].children![k].id) {
          return {
            tertiaryCategory: categories[i].children![j].children![k].id,
            secondaryCategory: categories[i].children![j].id,
            primaryCategory: categories[i].id,
          }
        }
      }
    }
  }

  return
}
