import sessionsTranslations from './sessions.fr.json'
import catalogsTranslations from './catalogs.fr.json'
import categoriesTranslations from './categories.fr.json'
import organizationsTranslations from './organizations.fr.json'
import errorsTranslations from './errors.fr.json'
import globalTranslations from './global.fr.json'
import materialModelsTranslations from './materialModels.fr.json'
import carbonModelsTranslations from './carbonModels.fr.json'
import materialsTranslations from './materials.fr.json'
import ordersTranslations from './orders.fr.json'
import usersTranslations from './users.fr.json'
import requestsTranslations from './requests.fr.json'
import reportsTranslations from './reports.fr.json'
import chatsTranslations from './chats.fr.json'

const FR = {
  chats: chatsTranslations,
  catalogs: catalogsTranslations,
  categories: categoriesTranslations,
  organizations: organizationsTranslations,
  errors: errorsTranslations,
  global: globalTranslations,
  materialModels: materialModelsTranslations,
  carbonModels: carbonModelsTranslations,
  materials: materialsTranslations,
  orders: ordersTranslations,
  users: usersTranslations,
  requests: requestsTranslations,
  sessions: sessionsTranslations,
  reports: reportsTranslations,
}

export default FR
