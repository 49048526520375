import React, { useCallback, useMemo, useState } from 'react'
import { Box, styled, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Modal from '../layout/Modal.layout'
import Stack from '../common/Stack.common'
import OrderListProduct from './ListProduct.order'
import { Info as InfoIcon } from '@mui/icons-material'
import {
  Order,
  OrderStatus,
  Product,
  computeOrderBeforeDiscount,
  computeOrderTotal,
} from '../../models/orders.models'
import { FileDetails } from '../../models/files.models'
import { PlatformReference, getPlatform } from '../../models/platforms.models'
import LinkButton from '../common/button/Link.button'
import { ordersService } from '../../store/orders'
import LoaderOverlay from '../layout/LoaderOverlay.layout'
import useSnackbar from '../../hooks/useSnackbar.hooks'

const OneLineTypography = styled(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
})
const RowsTitle = styled(OneLineTypography)({ fontSize: '.75rem', fontWeight: 700 })
const RowDetailStyled = styled(Stack)({
  borderBottom: 'solid 1px #DFDFDF',
  padding: '14px 0',
  width: '100%',
  marginRight: 'auto',
})
const RowDetail = (props: any) => (
  <RowDetailStyled
    {...props}
    direction="row"
    alignItems="center"
    justifyContent="space-between"
    spacing={1.5}
  />
)
const RowTextLeft = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem',
  fontWeight: 400,
  color: theme.palette.blockLabel,
}))
const RowTextRight = styled(Typography)({
  fontSize: '0.875rem',
  fontWeight: 400,
  textAlign: 'right',
})
const Observations = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  fontWeight: 400,
  color: theme.palette.blockLabel,
  marginBottom: '10px',
}))

const StyledInfoIcon = styled(InfoIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
  marginRight: '15px',
}))
const Informations = styled(Box)(({ theme }) => ({
  padding: '15px',
  fontSize: '0.875rem',
  display: 'flex',
  alignItems: 'center',
  fontWeight: 400,
  backgroundColor: theme.palette.textFieldBackground,
  border: `1px solid ${theme.palette.blockBorder}`,
  borderRadius: '4px',
  marginBottom: '10px',
}))

interface OrderDetailsModalProps {
  order: Order
  isClient?: boolean
  adminPlatform?: PlatformReference
  onClose: () => void
}

const OrderDetailsModal: React.FC<OrderDetailsModalProps> = ({
  order,
  adminPlatform,
  onClose,
  isClient,
}): JSX.Element => {
  const { t } = useTranslation()
  const show = useSnackbar()
  const [loading, setLoading] = useState(false)
  const downloadRemovalOrder = useCallback(async () => {
    if (order.removalOrder) {
      setLoading(true)
      try {
        await ordersService.downloadRemovalOrder(order.removalOrder as FileDetails)
      } catch (err: any) {
        show(err)
      }
      setLoading(false)
    }
  }, [show, order])

  const hasMaterialWithDefaultRetrieval = useMemo(
    () =>
      order?.products.some((product: Product) => product.material.retrieval.fromDefault !== false),
    [order],
  )

  if (!order) return <></>

  const total = computeOrderTotal(order)
  const totalBeforeDiscount = computeOrderBeforeDiscount(order)
  const totalHT = `${total.toFixed(2)}${t(`global:currency.${order.catalog.currency}`)}`
  const totalDiscountHT = `${totalBeforeDiscount.toFixed(2)}${t(
    `global:currency.${order.catalog.currency}`,
  )}`
  const totalTTC = order.tax
    ? `${(total * (1 + order.tax)).toFixed(2)}${t(`global:currency.${order.catalog.currency}`)}`
    : ''
  const totalDiscountTTC = order.tax
    ? `${(totalBeforeDiscount * (1 + order.tax)).toFixed(2)}${t(
        `global:currency.${order.catalog.currency}`,
      )}`
    : ''

  return (
    <Box>
      {loading && <LoaderOverlay />}

      <Modal
        maxWidth="lg"
        onClose={onClose}
        hideAction
        title={t('orders:components.modalDetails.title', {
          orderNumber: order.orderNumber,
          catalog: order.catalog.name,
          createdAt: new Date(order.createdAt),
        })}>
        <Box display="flex" flexDirection="column" width="100%">
          {(order.status === OrderStatus.pricePending || order.status === OrderStatus.pending) && (
            <Informations>
              <StyledInfoIcon />
              <Box>
                <Typography>{t('orders:components.modalDetails.editablePrice')}</Typography>
                {order.priceModified && (
                  <Typography>{t('orders:components.modalDetails.priceModified')}</Typography>
                )}
              </Box>
            </Informations>
          )}

          {order.deniedFor && (
            <>
              <RowsTitle>{t('orders:attributes.deniedFor')}</RowsTitle>
              <Observations>{order.deniedFor}</Observations>
            </>
          )}

          {!isClient && (
            <Box>
              <RowsTitle>{t('orders:components.modalDetails.clientTitle')}</RowsTitle>
              <RowDetail>
                <RowTextLeft>{t('orders:attributes.client')}</RowTextLeft>
                <Box display="flex" alignItems="center">
                  {adminPlatform && order.client.organization.platform !== adminPlatform && (
                    <img
                      alt=""
                      height="24px"
                      src={getPlatform(order.client.organization.platform).icon}
                      style={{ marginRight: '5px' }}
                    />
                  )}
                  <RowTextRight>
                    {order.client.lastname} {order.client.firstname}
                  </RowTextRight>
                </Box>
              </RowDetail>
              <RowDetail>
                <RowTextLeft>{t('orders:attributes.clientOrganization')}</RowTextLeft>
                <RowTextRight>{order.client.organization.name}</RowTextRight>
              </RowDetail>
              <RowDetail>
                <RowTextLeft>{t('orders:attributes.clientLegalForm')}</RowTextLeft>
                <RowTextRight>
                  {order.client.organization.legalForm
                    ? t(`organizations:legalForms.${order.client.organization.legalForm}`)
                    : ''}
                </RowTextRight>
              </RowDetail>
              <RowDetail>
                <RowTextLeft>{t('orders:attributes.clientAddress')}</RowTextLeft>
                <RowTextRight>{order.client.organization.location?.fullAddress || ''}</RowTextRight>
              </RowDetail>
              <RowDetail>
                <RowTextLeft>{t('orders:attributes.clientSiret')}</RowTextLeft>
                <RowTextRight>{order.client.organization.siret || ''}</RowTextRight>
              </RowDetail>
              {!!order.client.phoneNumber && (
                <RowDetail>
                  <RowTextLeft>{t('orders:attributes.clientPhoneNumber')}</RowTextLeft>
                  <RowTextRight>{order.client.phoneNumber}</RowTextRight>
                </RowDetail>
              )}
              <RowDetail>
                <RowTextLeft>{t('orders:attributes.clientEmail')}</RowTextLeft>
                <RowTextRight>{order.client.email}</RowTextRight>
              </RowDetail>
            </Box>
          )}
          <RowsTitle marginTop="30px">{t('orders:components.modalDetails.paymentTitle')}</RowsTitle>
          <RowDetail>
            <RowTextLeft>{t('orders:attributes.totalHT')}</RowTextLeft>
            <RowTextRight>
              {order.status === OrderStatus.pricePending ? (
                t('orders:status.pricePending')
              ) : order.discount ? (
                <>
                  <b>{totalHT}</b>
                  <span style={{ textDecoration: 'line-through', marginLeft: '5px' }}>
                    {totalDiscountHT}
                  </span>
                </>
              ) : (
                totalHT
              )}
            </RowTextRight>
          </RowDetail>
          {order.tax && (
            <>
              <RowDetail>
                <RowTextLeft>{t('orders:attributes.tax')}</RowTextLeft>
                <RowTextRight>
                  {t('global:format.percent', { value: (order.tax * 100).toFixed(2) })}
                </RowTextRight>
              </RowDetail>
              <RowDetail>
                <RowTextLeft>{t('orders:attributes.totalTTC')}</RowTextLeft>
                <RowTextRight>
                  {order.status === OrderStatus.pricePending ? (
                    t('orders:status.pricePending')
                  ) : order.discount ? (
                    <>
                      <b>{totalTTC}</b>
                      <span style={{ textDecoration: 'line-through', marginLeft: '5px' }}>
                        {totalDiscountTTC}
                      </span>
                    </>
                  ) : (
                    totalTTC
                  )}
                </RowTextRight>
              </RowDetail>
            </>
          )}

          <RowsTitle marginTop="30px">
            {t('orders:components.modalDetails.withdrawalTitle')}
          </RowsTitle>
          <RowDetail>
            <RowTextLeft>{t('orders:attributes.seller')}</RowTextLeft>
            <Box display="flex" alignItems="center">
              {adminPlatform && order.catalog.organization.platform !== adminPlatform && (
                <img
                  alt=""
                  height="24px"
                  src={getPlatform(order.catalog.organization.platform).icon}
                  style={{ marginRight: '5px' }}
                />
              )}
              <RowTextRight>
                {order.catalog.projectOwner?.name ??
                  order.catalog.customProjectOwner ??
                  order.catalog.organization.name}
              </RowTextRight>
            </Box>
          </RowDetail>
          {hasMaterialWithDefaultRetrieval && (
            <RowDetail>
              <RowTextLeft>{t('orders:attributes.withdrawalStartDate')}</RowTextLeft>
              <RowTextRight>
                {t('orders:attributes.fromTo', {
                  from: new Date(order.catalog.retrieval?.startDate || 'invalid'),
                  to: new Date(order.catalog.retrieval?.endDate || 'invalid'),
                })}
              </RowTextRight>
            </RowDetail>
          )}
          <RowDetail>
            <RowTextLeft>{t('orders:attributes.withdrawalAddress')}</RowTextLeft>
            <RowTextRight>{order.catalog.retrieval?.location?.fullAddress}</RowTextRight>
          </RowDetail>
          {hasMaterialWithDefaultRetrieval && (
            <RowDetail>
              <RowTextLeft>{t('orders:attributes.product.retrieval.modality')}</RowTextLeft>
              <RowTextRight>
                {!!order.catalog.retrieval?.retrievalModality
                  ? t(`global:retrievalModality.${order.catalog.retrieval?.retrievalModality}`)
                  : '-'}
              </RowTextRight>
            </RowDetail>
          )}
          <RowDetail>
            <RowTextLeft>{t('orders:attributes.withdrawalInformation')}</RowTextLeft>
            <RowTextRight>{order.catalog.retrievalInformation}</RowTextRight>
          </RowDetail>

          <RowsTitle marginTop="30px">
            {t('orders:components.modalDetails.materialsTitle')}
          </RowsTitle>
          <Box>
            <OrderListProduct values={order.products} />
          </Box>

          {order.finishedComment && (
            <>
              <RowsTitle marginTop="30px">{t('orders:attributes.finishedComment')}</RowsTitle>
              <Observations>{order.finishedComment}</Observations>
            </>
          )}
          {order.removalOrder && (
            <>
              <RowsTitle marginTop="30px">{t('orders:attributes.removalOrder')}</RowsTitle>
              <Box>
                <LinkButton onClick={downloadRemovalOrder}>
                  {(order.removalOrder as FileDetails).path?.split(/\\|\//)?.pop() ?? ''}
                </LinkButton>
              </Box>
            </>
          )}
        </Box>
      </Modal>
    </Box>
  )
}
export default OrderDetailsModal
