import { LinearProgress, styled } from '@mui/material'

const Stepper = styled(LinearProgress)(({ theme }) => ({
  backgroundColor: theme.palette.stepper.background,
  height: '5px',
  '& .MuiLinearProgress-bar': {
    backgroundColor: theme.palette.stepper.active,
  },
}))

interface StepperComponentProps {
  progress: number
}

const StepperComponent = (props: StepperComponentProps): JSX.Element => {
  const { progress } = props

  return <Stepper variant="determinate" value={progress} />
}
export default StepperComponent
