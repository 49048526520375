import { useCallback, useState, useReducer, useMemo } from 'react'
import { FileDetails } from '../models/files.models'
import { Point } from '../models/commons.models'
import { Catalog, PlanRoom, Room, Plan, CatalogType } from '../models/catalogs.models'
import {
  LocatedMaterial,
  Material,
  PlanLocatedMaterial,
  MaterialQuality,
  MaterialQuantity,
  MaterialType,
  ManageMaterial,
} from '../models/materials.models'
import useView, { ViewProps } from './useView.hooks'
import useEditPlan from './useEditPlan.hooks'
import LocatedMaterialToolbar from '../components/catalog/plan/LocatedMaterialToolbar.plan'
import RoomToolbar from '../components/catalog/plan/RoomToolbar.plan'
import { materialsService } from '../store/materials'

import { ObjectUtils } from '../utils/commons.utils'
import { PlanUtils } from '../utils/plan.utils'

import { useTranslation } from 'react-i18next'
import { catalogsService } from '../store/catalogs'
import { TraducedCarbonModel } from '../models/carbonModels.models'

const basePlanRoom = {
  _id: 'tmpRoom',
  isTmp: true,
  name: '',
}
const basePlanLocatedMaterial: Partial<PlanLocatedMaterial> = {
  _id: 'tmpLocatedMaterial',
  isTmp: true,
  quality: MaterialQuality.slightlyDamaged,
  quantity: 1,
  position: [-Infinity, -Infinity] as Point,
}

type PlanState = {
  hiddenCategories: number[]
  showToolbar: boolean
  showModal: string
  state: string
  tmpRoom?: PlanRoom
  tmpLocatedMaterial?: PlanLocatedMaterial
  tmpMaterial?: Partial<Material>
}
type PlanStateAction =
  | (Partial<PlanState> & { type?: never })
  | { categoryId: number; type: 'toogleCategory' }
  | (Partial<PlanRoom> & { type: 'editPlanRoom' })
  | (Partial<PlanLocatedMaterial> & { type: 'editPlanLocatedMaterial' })

type UsePlan = {
  initedPlanId: string
  updatedRooms: Room[]
  updatedMaterials: Material[]
  View: React.FC<ViewProps>
  drawnedRooms: (PlanRoom & { cornerSize: number; wallSize: number })[]
  drawnedLocatedMaterials: (PlanLocatedMaterial & { size: number })[]
  currentMaterial?: Material
  currentRoom?: Room
  init: (plan: Plan, materials: Material[], catalog: Catalog) => void
  canUndo: boolean
  undo: () => void
  canRedo: boolean
  redo: () => void
  center: () => void
  isAddingRoom: boolean
  startAddRoom: () => void
  isAddingRectangleRoom: boolean
  startAddRectangleRoom: () => void
  startAddMaterial: () => void
  viewProps: {
    image?: FileDetails
    viewbox: string
    viewAngle: number
    toolbar: React.ReactNode | React.ReactNode[]
  }
  asideProps: {
    canUpdate: boolean
    toogleOpenAside: boolean
    planName: string
    materials: Material[]
    hiddenCategories: number[]
    select: (materialId?: string) => void
    onOpen: (material: Material) => void
    onEdit: (material: Material) => void
    onDuplicate: (material: Material) => void
    onDrag: (position: Point, material: Material) => void
    onDragEnd: () => void
    onDragCancel: () => void
    onToogle: (categoryId: number) => void
  }
  modalProps: {
    show: string
    showValidationWeight: boolean
    submitLabel: string
    title: string
    material: ManageMaterial
    onSubmit: (material: ManageMaterial) => Promise<Material>
    onSuccess: (material: Material) => void
    onHideValidationWeight: () => Promise<void>
    actions: { label: string; color?: string; onClick: () => void }[]
    onClose: () => void
  }
}

const fromPlanRoom = (tmpRoom: PlanRoom): Room => {
  const { isTmp, pointIndex, lineIndex, closed, isSelected, ...room } = tmpRoom
  return room
}
const fromPlanLocatedMaterial = (tmpLocatedMaterial: PlanLocatedMaterial): LocatedMaterial => {
  const { isTmp, isSelected, isMaterialSelected, ...locatedMaterial } = tmpLocatedMaterial
  return locatedMaterial
}
const createPlanLocatedMaterial = (
  material: Material,
  materialQuantity: MaterialQuantity,
): LocatedMaterial => {
  return {
    ...materialQuantity,
    plan: materialQuantity.plan?._id,
    room: materialQuantity.room?._id,
    material: material._id,
    primaryCategory: material.primaryCategory,
    secondaryCategory: material.secondaryCategory,
    tertiaryCategory: material.tertiaryCategory,
  } as LocatedMaterial
}

const createId = (): string => {
  return `local${Math.random().toString().split('.')[1]}`
}

const usePlan = (
  showCerfa: boolean,
  canUpdate: boolean,
  useImperials: boolean,
  carbonModels: TraducedCarbonModel[],
): UsePlan => {
  const [image, setImage] = useState<FileDetails>()
  const [catalogCopy, setCatalogCopy] = useState<Catalog>()
  const [toogleOpenAside, setToogleOpenAside] = useState<boolean>()
  const { t } = useTranslation()

  const {
    planData,
    currentMaterial,
    currentRoom,
    currentLocatedMaterial,
    loadPlan,
    select,
    addMaterial,
    addLocatedMaterial,
    addRoom,
    editRoom,
    editMaterial,
    editLocatedMaterial,
    deleteRoom,
    deleteMaterial,
    deleteLocatedMaterial,
    canUndo,
    canRedo,
    undo,
    redo,
  } = useEditPlan()
  const [planState, dispatchPlanState] = useReducer(
    (current: PlanState, action: PlanStateAction): PlanState => {
      const { type, ...update } = action
      if (type === 'toogleCategory') {
        if (current.hiddenCategories.find((id) => action.categoryId === id)) {
          return {
            ...current,
            hiddenCategories: current.hiddenCategories.filter((id) => action.categoryId !== id),
          }
        } else {
          return {
            ...current,
            hiddenCategories: [...current.hiddenCategories, action.categoryId],
          }
        }
      } else if (type === 'editPlanRoom') {
        return { ...current, tmpRoom: { ...(current.tmpRoom || {}), ...update } as PlanRoom }
      } else if (type === 'editPlanLocatedMaterial') {
        return {
          ...current,
          tmpLocatedMaterial: {
            ...(current.tmpLocatedMaterial || {}),
            ...update,
          } as PlanLocatedMaterial,
        }
      }
      return { ...current, ...update }
    },
    {
      hiddenCategories: [],
      showToolbar: false,
      showModal: '',
      state: 'idle',
      tmpRoom: undefined,
      tmpLocatedMaterial: undefined,
      tmpMaterial: undefined,
    },
  )

  const {
    View,
    viewbox,
    viewAngle,
    htmlPointToSvg,
    setView,
    initView,
    locatedMaterialSize,
    cornerSize,
    wallSize,
  } = useView({
    onKey: (key, ctrl) => {
      if (!canUpdate) {
        return
      }
      if (key === 'z' && ctrl) {
        if (canUndo) {
          undo()
        }
      } else if (key === 'Z' && ctrl) {
        if (canRedo) {
          redo()
        }
      } else if (key === 'Delete' || key === 'Backspace') {
        if (currentLocatedMaterial) {
          deleteLocatedMaterial(currentLocatedMaterial._id)
        } else if (currentRoom) {
          deleteRoom(currentRoom._id)
        }
      }
    },
    onDragEnd: () => {
      if (!canUpdate) {
        return
      }

      if (planState.state === 'addRectangleRoom') {
        if (planState.tmpRoom) {
          const newId = createId()
          addRoom(fromPlanRoom({ ...(planState.tmpRoom as PlanRoom), _id: newId }))
          dispatchPlanState({ state: 'idle', tmpRoom: undefined, showToolbar: true })
        }
      } else if (planState.state === 'dragPoint' || planState.state === 'dragLine') {
        editRoom(fromPlanRoom(planState.tmpRoom as PlanRoom))
        dispatchPlanState({ state: 'idle', tmpRoom: undefined, showToolbar: true })
      } else if (planState.state === 'dragLocatedMaterial') {
        editLocatedMaterial(
          fromPlanLocatedMaterial(planState.tmpLocatedMaterial as PlanLocatedMaterial),
        )
        dispatchPlanState({
          state: 'idle',
          tmpLocatedMaterial: undefined,
          showToolbar: true,
        })
      }
    },
    onDrag: (delta, position, targetId) => {
      if (!canUpdate) {
        return true
      }

      if (planState.state === 'addRectangleRoom') {
        if (!planState.tmpRoom) {
          const { magneticPosition } = PlanUtils.getClickInfo(position, planData.rooms, targetId)
          const editedRoom = {
            ...basePlanRoom,
            points: [magneticPosition],
            closed: true,
          }
          if (PlanUtils.isRoomValid(editedRoom, planData.rooms, false)) {
            dispatchPlanState({
              type: 'editPlanRoom',
              ...editedRoom,
            })
          }
        } else {
          const editedRoom = {
            ...(planState.tmpRoom as PlanRoom),
            points: PlanUtils.editRectangleRoom(
              planState.tmpRoom as PlanRoom,
              position,
              planData.angle,
            ),
            closed: true,
          }
          if (PlanUtils.isRoomValid(editedRoom, planData.rooms)) {
            dispatchPlanState({
              type: 'editPlanRoom',
              points: editedRoom.points,
            })
          }
        }
        return false
      } else if (planState.state === 'dragPoint') {
        const editedRoom = {
          ...(planState.tmpRoom as PlanRoom),
          points: PlanUtils.editRoomPoint(
            planState.tmpRoom as PlanRoom,
            (planState.tmpRoom as PlanRoom).pointIndex as number,
            position,
          ),
        }
        if (PlanUtils.isRoomValid(editedRoom, planData.rooms)) {
          dispatchPlanState({
            type: 'editPlanRoom',
            points: editedRoom.points,
          })
        }
        return false
      } else if (planState.state === 'dragLine') {
        const editedRoom = {
          ...(planState.tmpRoom as PlanRoom),
          points: PlanUtils.editRoomLine(
            planState.tmpRoom as PlanRoom,
            (planState.tmpRoom as PlanRoom).lineIndex as number,
            delta,
            planData.angle,
          ),
        }
        if (PlanUtils.isRoomValid(editedRoom, planData.rooms)) {
          dispatchPlanState({
            type: 'editPlanRoom',
            points: editedRoom.points,
          })
        }
        return false
      } else if (planState.state === 'dragLocatedMaterial') {
        const editedLocatedMaterial = {
          ...(planState.tmpLocatedMaterial as PlanLocatedMaterial),
          position: PlanUtils.editLocatedMaterialPosition(
            planState.tmpLocatedMaterial as PlanLocatedMaterial,
            position,
          ),
        }
        if (PlanUtils.isLocatedMaterialValid(editedLocatedMaterial)) {
          dispatchPlanState({
            type: 'editPlanLocatedMaterial',
            position: editedLocatedMaterial.position,
          })
        }
        return false
      } else if (planState.state === 'idle' && targetId) {
        const { roomId, lineIndex, pointIndex, locatedMaterialId } = PlanUtils.getClickInfo(
          position,
          planData.rooms,
          targetId,
        )
        if (locatedMaterialId) {
          let locatedMaterial: LocatedMaterial
          planData.materials.forEach((material: Material) => {
            const materialQuantity = material.quantities.find(
              (locatedMaterial: MaterialQuantity) => locatedMaterial._id === locatedMaterialId,
            )
            if (materialQuantity) {
              locatedMaterial = {
                ...materialQuantity,
                plan: materialQuantity.plan?._id as string,
                room: materialQuantity.room?._id,
                material: material._id,
                primaryCategory: material.primaryCategory,
                secondaryCategory: material.secondaryCategory,
                tertiaryCategory: material.tertiaryCategory,
              } as LocatedMaterial
              dispatchPlanState({
                state: 'dragLocatedMaterial',
                tmpLocatedMaterial: {
                  ...locatedMaterial,
                  isTmp: true,
                  position,
                },
                showToolbar: false,
              })
              select({ locatedMaterialId: locatedMaterial._id, roomId: undefined })
            }
          })

          return false
        } else if (currentRoom && roomId === currentRoom._id) {
          if (pointIndex >= 0 || lineIndex >= 0) {
            dispatchPlanState({
              state: pointIndex >= 0 ? 'dragPoint' : 'dragLine',
              tmpRoom: {
                ...currentRoom,
                isTmp: true,
                pointIndex,
                lineIndex,
                points: currentRoom.points.map((point: Point) => [...point]),
              },
              showToolbar: false,
            })
            return false
          }
        }
      }
      return true
    },
    onDoubleClick: (position, targetId) => {
      if (canUpdate && planState.state === 'idle' && targetId) {
        const { roomId, lineIndex, pointIndex, magneticPosition } = PlanUtils.getClickInfo(
          position,
          planData.rooms,
          targetId,
        )
        if (currentRoom && roomId === currentRoom._id) {
          if (pointIndex >= 0) {
            const editedRoom = {
              ...currentRoom,
              points: PlanUtils.removeRoomPoint(currentRoom, pointIndex),
            }
            if (PlanUtils.isRoomValid(editedRoom, planData.rooms)) {
              editRoom(fromPlanRoom(editedRoom))
            }
          } else if (lineIndex >= 0) {
            const editedRoom = {
              ...currentRoom,
              points: PlanUtils.cutRoomLine(currentRoom, lineIndex, magneticPosition),
            }
            if (PlanUtils.isRoomValid(editedRoom, planData.rooms)) {
              editRoom(fromPlanRoom(editedRoom))
            }
          }
          return
        }
      }
      dispatchPlanState({
        state: 'idle',
        showToolbar: false,
        tmpRoom: undefined,
        tmpLocatedMaterial: undefined,
      })
      select({}, true)
    },
    onSingleClick: (position, targetId) => {
      if (canUpdate && planState.state === 'addRoom') {
        if (targetId === 'room.tmpRoom.point.0') {
          if (PlanUtils.isRoomValid(planState.tmpRoom as PlanRoom, planData.rooms)) {
            const newId = createId()
            addRoom(fromPlanRoom({ ...(planState.tmpRoom as PlanRoom), _id: newId }))
            dispatchPlanState({
              state: 'idle',
              tmpRoom: undefined,
              showToolbar: true,
            })
            select({ roomId: newId, locatedMaterialId: undefined })
          }
        } else {
          let tmpRoom: PlanRoom = planState.tmpRoom || { ...basePlanRoom, points: [] }

          const { magneticPosition } = PlanUtils.getClickInfo(position, planData.rooms, targetId)
          const editedRoom = {
            ...tmpRoom,
            points: tmpRoom.points.concat([magneticPosition]),
          }

          if (PlanUtils.isRoomValid(editedRoom, planData.rooms, false)) {
            if (!planState.tmpRoom) {
              dispatchPlanState({
                tmpRoom: editedRoom,
              })
            } else {
              dispatchPlanState({
                type: 'editPlanRoom',
                points: editedRoom.points,
              })
            }
          }
        }
      } else {
        const { roomId, locatedMaterialId } = PlanUtils.getClickInfo(
          position,
          planData.rooms,
          targetId,
          true,
        )
        select({ roomId, locatedMaterialId })
        dispatchPlanState({
          showToolbar: true,
        })
      }
    },
  })

  // draw selected last to make sur to have target element (wall and point) of selected room drawwn after
  // sorting locatedmaterial when dragging cause drag event to be lost (no error on room since the dragged room is selected so is already render last )
  type DrawnedRoom = PlanRoom & { cornerSize: number; wallSize: number }
  let drawnedRooms = useMemo<DrawnedRoom[]>((): DrawnedRoom[] => {
    let drawnedRooms = planData.rooms.map((room) => {
      if (room._id === planState.tmpRoom?._id) {
        // force draw as it is not tmp (edit case)
        return { ...planState.tmpRoom, isTmp: false, isSelected: true, cornerSize, wallSize }
      }
      return {
        ...room,
        isSelected: currentRoom && currentRoom._id === room._id,
        cornerSize,
        wallSize,
      }
    })
    if (planState.tmpRoom?._id === 'tmpRoom' && planState.tmpRoom?.points.length > 0) {
      drawnedRooms.push({ ...planState.tmpRoom, isSelected: true, cornerSize, wallSize })
    }
    drawnedRooms = drawnedRooms.sort((room1: DrawnedRoom, room2: DrawnedRoom) => {
      return room1.isSelected || room1.isTmp ? 1 : room2.isSelected || room2.isTmp ? -1 : 0
    })
    return drawnedRooms
  }, [planData.rooms, cornerSize, wallSize, currentRoom, planState.tmpRoom])

  type DrawnedLocatedMaterial = PlanLocatedMaterial & { size: number }
  let drawnedLocatedMaterials = useMemo<DrawnedLocatedMaterial[]>((): DrawnedLocatedMaterial[] => {
    let drawnedLocatedMaterials = planData.materials.reduce(
      (locatedMaterials: (PlanLocatedMaterial & { size: number })[], material: Material) => {
        material.quantities.forEach((materialQuantity: MaterialQuantity) => {
          if (materialQuantity.plan?._id === planData._id && materialQuantity.position) {
            if (materialQuantity._id === planState.tmpLocatedMaterial?._id) {
              locatedMaterials.push({
                ...planState.tmpLocatedMaterial,
                isTmp: false,
                isSelected: true,
                isMaterialSelected: currentMaterial && currentMaterial._id === material._id,
                size: locatedMaterialSize,
              } as PlanLocatedMaterial & { size: number })
            } else {
              let isHidden = !!planState.hiddenCategories.find(
                (catId: number) =>
                  material?.primaryCategory === catId ||
                  material?.secondaryCategory === catId ||
                  material?.tertiaryCategory === catId,
              )
              if (!isHidden) {
                locatedMaterials.push({
                  ...createPlanLocatedMaterial(material, materialQuantity),
                  isSelected:
                    currentLocatedMaterial && currentLocatedMaterial._id === materialQuantity._id,
                  isMaterialSelected: currentMaterial && currentMaterial._id === material._id,
                  size: locatedMaterialSize,
                } as PlanLocatedMaterial & { size: number })
              }
            }
          }
        })
        return locatedMaterials
      },
      [],
    )
    if (
      planState.tmpLocatedMaterial?._id === 'tmpLocatedMaterial' &&
      planState.tmpLocatedMaterial.position.length === 2 &&
      planState.tmpLocatedMaterial.position[0] !== -Infinity
    ) {
      drawnedLocatedMaterials.push({
        ...planState.tmpLocatedMaterial,
        isSelected: true,
        isMaterialSelected:
          currentMaterial && currentMaterial._id === planState.tmpLocatedMaterial.material,
        size: locatedMaterialSize,
      } as PlanLocatedMaterial & { size: number })
    }

    return drawnedLocatedMaterials
  }, [
    planData._id,
    planData.materials,
    locatedMaterialSize,
    currentMaterial,
    currentLocatedMaterial,
    planState.tmpLocatedMaterial,
    planState.hiddenCategories,
  ])

  let toolbar
  if (planState.showToolbar) {
    if (currentLocatedMaterial) {
      const toolbarMaterial = planData.materials.find(
        (material) => material._id === currentLocatedMaterial.material,
      ) as Material
      toolbar = (
        <LocatedMaterialToolbar
          showCerfa={showCerfa}
          canUpdate={canUpdate}
          planScale={planData.scale}
          rooms={planData.rooms}
          useImperials={useImperials}
          locatedMaterial={currentLocatedMaterial}
          material={toolbarMaterial}
          onDelete={() => deleteLocatedMaterial(currentLocatedMaterial._id)}
          onReveal={() => {
            setToogleOpenAside((toogleOpenAside) => !toogleOpenAside)
            select({ materialId: currentLocatedMaterial.material })
          }}
          onEdit={(locatedMaterial) => editLocatedMaterial(locatedMaterial, false)}
        />
      )
    } else if (currentRoom) {
      toolbar = (
        <RoomToolbar
          canUpdate={canUpdate}
          room={currentRoom}
          useImperials={useImperials}
          planScale={planData.scale}
          onDelete={() => deleteRoom(currentRoom._id)}
          onEdit={(room) => editRoom(room, false)}
        />
      )
    }
  }

  return {
    initedPlanId: planData._id,
    updatedRooms: planData.rooms,
    updatedMaterials: planData.materials,
    View,
    drawnedRooms,
    drawnedLocatedMaterials,
    currentRoom,
    currentMaterial,
    init: useCallback(
      (plan: Plan, materials: Material[], catalog: Catalog) => {
        setImage(plan?.file)
        initView()
        setView({ angle: plan.angle })
        setCatalogCopy(catalog)
        loadPlan({
          ...plan,
          materials,
        })
      },
      [setView, loadPlan, initView],
    ),
    canUndo,
    undo: useCallback(() => {
      dispatchPlanState({ state: 'idle', tmpRoom: undefined, tmpLocatedMaterial: undefined })
      undo()
    }, [undo]),
    canRedo,
    redo: useCallback(() => {
      dispatchPlanState({ state: 'idle', tmpRoom: undefined, tmpLocatedMaterial: undefined })
      redo()
    }, [redo]),
    center: useCallback(() => {
      setView({ scale: 1, center: [0, 0] })
    }, [setView]),
    isAddingRoom: planState.state === 'addRoom',
    startAddRoom: useCallback(() => {
      dispatchPlanState({
        state: 'addRoom',
        showToolbar: false,
        tmpRoom: undefined,
        showModal: '',
      })
    }, []),
    isAddingRectangleRoom: planState.state === 'addRectangleRoom',
    startAddRectangleRoom: useCallback(() => {
      dispatchPlanState({
        state: 'addRectangleRoom',
        showToolbar: false,
        tmpRoom: undefined,
        showModal: '',
      })
    }, []),
    startAddMaterial: useCallback(() => {
      dispatchPlanState({
        showModal: 'creation',
        tmpMaterial: {
          type: MaterialType.resource,
          catalog: catalogCopy,
        },
        tmpRoom: undefined,
        tmpLocatedMaterial: undefined,
      })
    }, [catalogCopy]),
    viewProps: {
      image,
      viewbox,
      viewAngle,
      toolbar,
    },
    asideProps: {
      canUpdate: canUpdate,
      toogleOpenAside: toogleOpenAside as boolean,
      planName: planData.name,
      hiddenCategories: planState.hiddenCategories,
      materials: planData.materials,
      select: useCallback((materialId?: string) => select({ materialId }), [select]),
      onToogle: useCallback((categoryId: number) => {
        dispatchPlanState({
          type: 'toogleCategory',
          categoryId,
        })
      }, []),

      onDrag: useCallback(
        (htmlPosition: Point, material: Material) => {
          let position = htmlPointToSvg(htmlPosition)
          if (planState.state === 'dragLocatedMaterial') {
            const editedLocatedMaterial = {
              ...planState.tmpLocatedMaterial,
              position: PlanUtils.editLocatedMaterialPosition(
                planState.tmpLocatedMaterial as PlanLocatedMaterial,
                position,
              ),
            }
            if (PlanUtils.isLocatedMaterialValid(editedLocatedMaterial as PlanLocatedMaterial)) {
              dispatchPlanState({
                type: 'editPlanLocatedMaterial',
                position: editedLocatedMaterial.position,
              })
            }
          } else {
            dispatchPlanState({
              state: 'dragLocatedMaterial',
              tmpRoom: undefined,
              tmpLocatedMaterial: {
                ...basePlanLocatedMaterial,
                reusable: !material.wasteOnly,
                plan: planData._id,
                material: material._id,
                primaryCategory: material.primaryCategory,
                secondaryCategory: material.secondaryCategory,
                tertiaryCategory: material.tertiaryCategory,
                position,
              } as LocatedMaterial,
              showToolbar: false,
            })
          }
        },
        [htmlPointToSvg, planData._id, planState.state, planState.tmpLocatedMaterial],
      ),
      onDragCancel: useCallback(() => {
        dispatchPlanState({
          state: 'idle',
          tmpLocatedMaterial: undefined,
        })
      }, []),
      onDragEnd: useCallback(() => {
        const newId = createId()
        select({
          locatedMaterialId: newId,
          roomId: currentRoom ? currentRoom._id : undefined,
        })
        addLocatedMaterial({
          ...fromPlanLocatedMaterial(planState.tmpLocatedMaterial as LocatedMaterial),
          plan: planData._id,
          _id: newId,
        })
        dispatchPlanState({
          state: 'idle',
          tmpLocatedMaterial: undefined,
          showToolbar: true,
        })
      }, [planData._id, planState.tmpLocatedMaterial, addLocatedMaterial, currentRoom, select]),
      onOpen: useCallback((material: Material) => {
        dispatchPlanState({
          showModal: 'detail',
          tmpRoom: undefined,
          tmpLocatedMaterial: undefined,
          tmpMaterial: ObjectUtils.copy(material),
        })
      }, []),
      onEdit: useCallback((material: Material) => {
        dispatchPlanState({
          showModal: 'edition',
          tmpRoom: undefined,
          tmpLocatedMaterial: undefined,
          tmpMaterial: ObjectUtils.copy(material),
        })
      }, []),
      onDuplicate: useCallback(
        (material: Material) => {
          const {
            _id,
            reference,
            initialQty,
            reservedQty,
            currentQty,
            state,
            quality,
            mainImageFile,
            imageFiles,
            files,
            ...duplicatedValues
          } = ObjectUtils.copy(material)
          dispatchPlanState({
            showModal: 'creation',
            tmpRoom: undefined,
            tmpLocatedMaterial: undefined,
            tmpMaterial: {
              ...duplicatedValues,
              catalog: catalogCopy,
              quantities: [],
              initialQty: 0,
              currentQty: 0,
              reservedQty: 0,
            },
          })
        },
        [catalogCopy],
      ),
    },
    modalProps: {
      show: planState.showModal,
      material: {
        ...planState.tmpMaterial,
        catalog: (catalogCopy as Catalog)?._id,
        carbonModel: carbonModels
          .map((carbonModel) => ({
            label: carbonModel.name,
            value: carbonModel._id,
          }))
          .find((option) => option.value === planState.tmpMaterial?.carbonModel),
      } as ManageMaterial,
      submitLabel: t(`global:actions.finish`),
      title:
        planState.showModal === 'creation'
          ? t('materials:actions.addresource.label')
          : t('materials:actions.updateresource.label'),
      showValidationWeight:
        catalogCopy?.type === CatalogType.deconstruction && !catalogCopy?.hideValidationWeight,
      onHideValidationWeight: async () => {
        if (catalogCopy) {
          await catalogsService.updateCatalog(catalogCopy._id, { hideValidationWeight: true })
          setCatalogCopy((catalogCopy) => ({ ...catalogCopy!, hideValidationWeight: true }))
        }
      },

      onSubmit: materialsService.saveTmpMaterial.bind(null, catalogCopy as Catalog),
      onSuccess: (material: Material) => {
        if (planState.showModal === 'creation') {
          addMaterial({
            ...material,
            _id: createId(),
          })
        } else {
          editMaterial(material)
        }
        dispatchPlanState({
          showModal: '',
          tmpMaterial: undefined,
        })
      },
      actions: planState.tmpMaterial?._id
        ? [
            {
              label: t('global:actions.delete'),
              color: 'error',
              onClick: () => {
                deleteMaterial(planState.tmpMaterial!._id!)
                dispatchPlanState({
                  state: 'idle',
                  showModal: '',
                  tmpMaterial: undefined,
                  tmpRoom: undefined,
                  tmpLocatedMaterial: undefined,
                })
              },
            },
          ]
        : [],
      onClose: () => {
        dispatchPlanState({
          showModal: '',
          tmpMaterial: undefined,
        })
      },
    },
  }
}

export default usePlan
