import { createStore, withProps } from '@ngneat/elf'
import { CarbonModel } from '../../models/carbonModels.models'
import { withRequestsStatus } from '@ngneat/elf-requests'

export const carbonModelsStore = createStore(
  { name: 'carbonModels' },
  withProps<{ list: CarbonModel[] }>({
    list: [],
  }),
  withRequestsStatus<'init'>(),
)
