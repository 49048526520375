import { useState, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { createOptionsFromEnum } from '../../utils/i18n.utils'
import ModalForm from '../common/ModalForm.common'
import {
  CerfaWaste,
  MaterialQuality,
  MaterialQuantity,
  QuantityType,
  Unit,
  computeResourceQuantities,
} from '../../models/materials.models'
import { Typography, styled } from '@mui/material'
import { FormItem, ItemType } from '../../models/props.models'
import { YupUtils } from '../../utils/yup.utils'
import { traduceCategory } from '../../models/categories.models'

const EndAdornmentLabel = styled(Typography)({ fontSize: '0.875rem', fontWeight: 500 })

interface ModalQuantitiesProps {
  wasteOnly: boolean
  showCerfa: boolean
  isCreation: boolean
  unit?: Unit
  cerfaWaste?: CerfaWaste[]
  quantities: MaterialQuantity[]
  onClose: () => void
  onSubmit: (quantities: MaterialQuantity[]) => void
}

type UpdatedQuantities = {
  currentQty: number
  quality: MaterialQuality
  wasteQuantities: {
    totalQty: number
    initialQty: number
  }[]
}

const ModalQuantities: React.FC<ModalQuantitiesProps> = ({
  onClose,
  onSubmit,
  quantities,
  unit,
  cerfaWaste,
  wasteOnly,
  isCreation,
  showCerfa,
}) => {
  const { t } = useTranslation()
  const items: FormItem[] = useMemo(() => {
    const severalWaste = showCerfa && cerfaWaste && cerfaWaste.length > 1
    const createItems = (cerfaIndex: number): FormItem[] => {
      return [
        {
          type: ItemType.number,
          grid: !severalWaste ? { xs: 4 } : { xs: 6 },
          key: `wasteQuantities.${cerfaIndex}.totalQty`,
          required: true,
          hideItem: !showCerfa,
          rules: [
            YupUtils.FieldValidationType.positive,
            (mat: UpdatedQuantities) => {
              return (mat.wasteQuantities[cerfaIndex]?.totalQty ?? 0) <
                (mat.wasteQuantities[cerfaIndex]?.initialQty ?? 0)
                ? t('errors:totalSuperiorInitial')
                : undefined
            },
          ],
          props: {
            label: t('materials:attributes.quantities.total'),
            endAdornment: unit ? (
              <EndAdornmentLabel>{t(`materials:unitSymbol.${unit}`)}</EndAdornmentLabel>
            ) : undefined,
          },
        },
        {
          type: ItemType.number,
          grid: !severalWaste ? { xs: 4 } : { xs: 6 },
          key: `wasteQuantities.${cerfaIndex}.initialQty`,
          required: true,
          hideItem: wasteOnly,
          rules: [YupUtils.FieldValidationType.positive],
          props: {
            label: t('materials:attributes.quantities.initial'),
            endAdornment: unit ? (
              <EndAdornmentLabel>{t(`materials:unitSymbol.${unit}`)}</EndAdornmentLabel>
            ) : undefined,
          },
        },
      ]
    }

    const res: FormItem[] = [
      {
        type: ItemType.select,
        grid: { xs: 6, sm: 4 },
        key: 'quality',
        required: true,
        hideItem: wasteOnly,
        props: {
          label: t('materials:attributes.globalQuality'),
          items: createOptionsFromEnum(MaterialQuality, 'materials:quality'),
        },
      },
      ...(!severalWaste ? createItems(0) : []),
      {
        type: ItemType.number,
        grid: { xs: 6, md: 4 },
        hideItem: isCreation || wasteOnly,
        key: 'currentQty',
        required: true,
        rules: [(value: UpdatedQuantities) => (value.currentQty >= 0 ? '' : t('errors:positive'))],
        props: {
          label: t('materials:attributes.quantities.current'),
          endAdornment: unit ? (
            <EndAdornmentLabel>{t(`materials:unitSymbol.${unit}`)}</EndAdornmentLabel>
          ) : undefined,
        },
      },
    ]

    if (!severalWaste) {
      return res
    } else {
      return [
        ...res,
        ...cerfaWaste.map((cerfaWaste: CerfaWaste, cerfaIndex: number) => {
          const title: string = cerfaWaste.tertiaryCategory
            ? t('materials:attributes.cerfaWaste.labelCategory', {
                category: traduceCategory('waste', cerfaWaste.tertiaryCategory),
              })
            : cerfaWaste.secondaryCategory
            ? t('materials:attributes.cerfaWaste.labelCategory', {
                category: traduceCategory('waste', cerfaWaste.secondaryCategory),
              })
            : cerfaWaste.primaryCategory
            ? t('materials:attributes.cerfaWaste.labelCategory', {
                category: traduceCategory('waste', cerfaWaste.primaryCategory),
              })
            : t('materials:attributes.cerfaWaste.labelIndex', { index: cerfaIndex })
          return {
            type: ItemType.group,
            key: `cerfaWaste.${cerfaIndex}`,
            props: {
              title,
            },
            grid: { xs: 12 },
            items: createItems(cerfaIndex),
          }
        }),
      ] as FormItem[]
    }
  }, [t, unit, cerfaWaste, isCreation, showCerfa, wasteOnly])

  const [updatedQuantities, setUpdatedQuantities] = useState<UpdatedQuantities>(() => {
    const res: UpdatedQuantities = {
      ...computeResourceQuantities(quantities ?? []),
      wasteQuantities: [],
    }
    const valueCount = Math.max(1, cerfaWaste?.length ?? 0)

    for (let i = 0; i < valueCount; i++) {
      let { totalQty, initialQty } = computeResourceQuantities(
        quantities?.filter((quantity: MaterialQuantity) => {
          return (i === 0 && !quantity.cerfaWaste) || quantity.cerfaWaste === i
        }) ?? [],
      )
      res.wasteQuantities.push({
        totalQty,
        initialQty,
      })
    }
    return res
  })

  const updateQuantities = useCallback(
    (updatedQuantities: UpdatedQuantities) => {
      let res: MaterialQuantity[] = []
      const valueCount = Math.max(1, cerfaWaste?.length ?? 0)
      for (let i = 0; i < valueCount; i++) {
        let wasteQuantities = quantities?.filter((quantity: MaterialQuantity) => {
          return (
            // good cerfa waste
            ((i === 0 && !quantity.cerfaWaste) || quantity.cerfaWaste === i) &&
            // special item (so we make the array simpler)
            (QuantityType.inventory !== quantity.type ||
              !!quantity.plan ||
              !!quantity.order ||
              !!quantity.description)
          )
        })

        const { initialQty } = computeResourceQuantities(wasteQuantities)
        if (updatedQuantities.wasteQuantities[i].initialQty !== initialQty) {
          wasteQuantities.push({
            _id: `local${Math.random().toString().split('.')[1]}`,
            type: QuantityType.inventory,
            initial: true,
            reusable: true,
            cerfaWaste: cerfaWaste && cerfaWaste.length > 0 ? i : undefined,
            quantity: updatedQuantities.wasteQuantities[i].initialQty - initialQty,
            quality: updatedQuantities.quality,
            linkToRoom: false,
          })
        }

        const { totalQty } = computeResourceQuantities(wasteQuantities)
        if (updatedQuantities.wasteQuantities[i].totalQty !== totalQty) {
          res.push({
            _id: `local${Math.random().toString().split('.')[1]}`,
            type: QuantityType.inventory,
            initial: true,
            reusable: false,
            cerfaWaste: cerfaWaste && cerfaWaste.length > 0 ? i : undefined,
            quantity: updatedQuantities.wasteQuantities[i].totalQty - totalQty,
            quality: updatedQuantities.quality,
            linkToRoom: false,
          })
        }

        res = res.concat(wasteQuantities)
      }

      const { currentQty } = computeResourceQuantities(res)
      if (updatedQuantities.currentQty !== currentQty) {
        res.push({
          _id: `local${Math.random().toString().split('.')[1]}`,
          type: QuantityType.inventory,
          initial: false,
          quantity: updatedQuantities.currentQty - currentQty,
          quality: updatedQuantities.quality,
          linkToRoom: false,
        })
      }

      const { quality } = computeResourceQuantities(res)
      // We can not keep separate quantities...
      if (quality !== updatedQuantities.quality) {
        res = res.map((q: MaterialQuantity) => ({ ...q, quality: updatedQuantities.quality }))
      }

      onSubmit(res)
    },
    [onSubmit, quantities, cerfaWaste],
  )

  return (
    <ModalForm
      title={t('materials:components.modalQuantities.title')}
      submitLabel={t('global:actions.validate')}
      maxWidth="md"
      items={items}
      value={updatedQuantities}
      setValue={setUpdatedQuantities}
      onClose={onClose}
      onSubmit={updateQuantities}
    />
  )
}
export default ModalQuantities
