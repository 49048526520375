import DetailsComponent from '../common/Details.common'
import { User, Profession, NotificationFrequency } from '../../models/users.models'
import { Actions, ItemType, DetailItem } from '../../models/props.models'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { Mode } from '../../models/commons.models'
import constants from '../../constants'
import { sessionService } from '../../store/session'

type InformationUserProps = {
  title: string
  user: User
  additionals?: DetailItem[]
  onClick?: () => void
  actions?: Actions
}
const InformationUser: React.FC<InformationUserProps> = ({
  title,
  user,
  additionals,
  actions,
  onClick,
}) => {
  const { t } = useTranslation()

  const [canUseImperials, setCanUseImperials] = useState<boolean>(false)
  useEffect(() => {
    const getCounryParams = async () => {
      if (constants.mode === Mode.admin && user?.organization?.location?.countryCode) {
        const country = await sessionService.getCountry(user?.organization?.location?.countryCode)
        setCanUseImperials(!!country.canUseImperials)
      } else {
        setCanUseImperials(!!sessionService.getCountryParam('canUseImperials'))
      }
    }
    getCounryParams()
  }, [user?.organization?.location?.countryCode])

  return (
    <DetailsComponent
      title={title}
      value={user}
      actions={actions}
      onClick={onClick}
      items={[
        { label: t('users:attributes.lastname'), key: 'lastname' },
        { label: t('users:attributes.firstname'), key: 'firstname' },
        { label: t('users:attributes.email'), key: 'email' },
        {
          label: t('users:attributes.phoneNumber'),
          key: 'phoneNumber',
          formatValue: (phoneNumber: string) => phoneNumber || '',
        },
        {
          label: t('users:attributes.address'),
          key: 'address',
          formatValue: (address: string) => address || '',
        },
        {
          label: t('users:attributes.professions'),
          key: 'professions',
          formatValue: (professions: Profession[]) =>
            professions?.length
              ? professions.map((profression) => t(`users:professions.${profression}`)).join('\n')
              : '',
        },
        ...(canUseImperials
          ? [
              {
                label: t('users:attributes.useImperials'),
                type: ItemType.checkbox,
                key: 'useImperials',
                props: {
                  disabled: true,
                },
              },
            ]
          : []),
        {
          label: t('users:attributes.receiveNewsEmails'),
          type: ItemType.checkbox,
          key: 'receiveNewsEmails',
          props: {
            disabled: true,
          },
        },
        {
          label: t('users:attributes.receiveMatchingEmails'),
          type: ItemType.checkbox,
          key: 'receiveMatchingEmails',
          props: {
            disabled: true,
          },
        },
        {
          label: t('users:attributes.notificationFrequency'),
          key: 'notificationFrequency',
          formatValue: (notificationFrequency: NotificationFrequency | undefined) =>
            t(
              `users:notificationFrequency.${
                notificationFrequency ?? NotificationFrequency.onEvent
              }`,
            ),
        },
        ...(additionals || []),
      ]}
    />
  )
}
export default InformationUser
